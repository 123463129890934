import React, {Component} from "react";
import AvgHorizontalBarChart from "../components/barchart/AvgHorizontalBarChart";
import {connect} from "react-redux";
import {BAR_CHART_TYPE, SPEND_FILTER} from "../../../../../helper/const";
import Body from "../../../../other/Body";
import {Form, Header, Table} from "semantic-ui-react";
import LabelCircle from "../components/LabelCircle";
import DataTable from "../components/DataTable";
import DataFilter from "../components/DataFilter";
import ScatterPlotChart from "../components/scatter/ScatterPlotChart";
import ReportHeader from "../components/header/ReportHeader";
import ReportSummary from "../components/header/ReportSummary";
import {orgState} from "../../../../../helper/stateUtil";
import {OrgMenuBar, reportsPath} from "../../other/OrgMenuBar";
import OrgSubHeader from "../../other/OrgSubHeader";
import NoDataMessage from "../components/other/NoDataMessage";
import reportAction from "../../../../../actions/report.action";
import Report from "../components/other/Report";
import {color} from "../components/barchart/utils";
import {sort} from "../utils";
import * as d3 from "d3";
import HelpPopup from "../../../../other/HelpPopup";

class AllMatterReportPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            by_matrix: true, // true - by qualmet score, false - by indivisual question
            clients: null,
            score: null,
            spend: SPEND_FILTER.NONE,
            cycles: null,
            practice_areas: null,
            status: null,
            questions: null
        };
        this.onSelect = this.onSelect.bind(this);
        this.onClick = this.onClick.bind(this);
        this.compileData = this.compileData.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onSwitchMatrixToQuestion = this.onSwitchMatrixToQuestion.bind(this);
    }

    componentDidMount() {
        const {org} = this.props;
        this.props.dispatch(reportAction.getAllMattersSummaryAndFilters({org_id: org.id, org}));
        this.props.dispatch(reportAction.getAllMattersReport({
            org_id: org.id,
            by_matrix: true
        }));
    }

    componentWillReceiveProps(nextProps) {
        const {data, loadingData, loadingFiltersAndSummary, isDone} = nextProps;
        let s = {};
        if (!loadingData) {
            s.data = this.compileData(data);
        }
        if (!loadingFiltersAndSummary && isDone) {
            [
                'clients',
                'score',
                'spend',
                'cycles',
                'practice_areas',
                'status',
                'questions'
            ].forEach((item) => {
                if (nextProps[item] && nextProps[item].length > 0) {
                    s[item] = nextProps[item][0].value;
                }
            });
        }
        this.setState((state) => {
            return {...state, ...s};
        });
    }

    onSwitchMatrixToQuestion = () => {
        this.setState((state) => ({by_matrix: !state.by_matrix}));
        this.props.dispatch(reportAction.getAllMattersReport({
            org_id: this.props.org.id,
            ...this.state,
            by_matrix: !this.state.by_matrix
        }));
    };

    onChange = (_, {name, value}) => {
        this.setState({[name]: value});
        this.props.dispatch(reportAction.getAllMattersReport({
            org_id: this.props.org.id,
            ...this.state,
            [name]: value
        }));
    };

    onClick = (item) => {
        this.setState((state) => {
            const {data} = state;
            const i = data.findIndex((d) => d.id === item.id);
            data[i].is_selected = !data[i].is_selected;
            return data;
        });
    };

    onSelect = (item, yIndex) => {
        const {history} = this.props;
        switch (yIndex) {
            case 0:
                history.push(`${reportsPath}/matters/${item.y[yIndex].value}/summary`);
                break;
            case 1:
                // history.push(`${reportsPath}/clients-firms/${item.y[yIndex].value}/summary`);
                break;
            default:
                break;
        }
    };

    compileData = (data) => {
        sort('score', data);
        const {spend} = this.state;
        data.forEach((item, index) => {
            item.id = index + 1;
            item.x = item.score;
            if (spend === SPEND_FILTER.NONE) {
                item.y = [];
                item.y[0] = {text: item.matter_name, value: item.matter_id};
                item.y[1] = {text: item.client_name, value: item.client_id};
            } else {
                item.y = parseInt(item.spend);
            }
        });
        return data;
    };

    render() {
        const {by_matrix, clients, score, spend, cycles, practice_areas, status, questions, data} = this.state;
        const {totals, summary, responses, org, loadingFiltersAndSummary, loadingData} = this.props;
        let chart;
        if (!loadingData) {
            switch (spend) {
                case SPEND_FILTER.NONE:
                    // bar chart
                    chart = <AvgHorizontalBarChart
                        data={data}
                        type={BAR_CHART_TYPE.ALL}
                        onClick={this.onClick}
                        onSelect={this.onSelect}
                        maxX={100}
                        xTicks={10}
                        xLabel={"Score"}
                    />;
                    break;
                case SPEND_FILTER.CLIENT_ESTIMATED_SPEND:
                case SPEND_FILTER.ESTIMATED_SPEND:
                case SPEND_FILTER.ACTUAL_SPEND:
                case SPEND_FILTER.SPEND_DELTA:
                    // scatter plot chart
                    chart = <ScatterPlotChart
                        popupKeys={[`matter_name`, `client_name`]}
                        popupLabels={[`Matter`, org.str_client_firm]}
                        xLabel={"Score"}
                        yLabel={"Spend"}
                        maxX={100}
                        xTicks={10}
                        onClick={this.onClick}
                        data={data}/>;
                    break;
                default:
                    break;
            }
        }
        return (
            <Body loading={loadingFiltersAndSummary}>
            <OrgMenuBar/>
            <OrgSubHeader title={"Matters"} backArrow={false}/>
            <Report>
                <ReportHeader>
                    <Header>Summary: All Matters</Header>
                </ReportHeader>
                <ReportSummary qualmetScore={summary.qualmet_score}
                               overallSatisfaction={summary.overall_satisfaction}
                               valueForMoney={summary.value_for_money}
                               wouldRecommend={summary.would_recommend}
                               totals={totals}
                               diversityTracking={org.diversity_tracking ? summary.diversity_tracking : null}
                               responses={responses}/>

                <DataFilter>
                    <Form.Group widths='equal'>
                        <Form.Field>
                            <Form.Dropdown
                                label={`By Score:`}
                                selection name={"score"}
                                value={score}
                                onChange={this.onChange}
                                options={this.props.score}/>
                        </Form.Field>
                        <Form.Field>
                            <Form.Dropdown
                                label={`By Cycle:`}
                                value={cycles}
                                selection name={'cycles'}
                                onChange={this.onChange}
                                options={this.props.cycles}/>
                        </Form.Field>
                        <Form.Field>
                            <Form.Dropdown
                                label={`By Status:`}
                                selection name={"status"}
                                value={status}
                                onChange={this.onChange}
                                options={this.props.status}/>
                        </Form.Field>
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Field>
                            <Form.Dropdown
                                label={`By Practice Area:`}
                                selection name={'practice_areas'}
                                value={practice_areas}
                                onChange={this.onChange}
                                options={this.props.practice_areas}/>
                        </Form.Field>
                        <Form.Field>
                            <label>{`By Matter Spend:`} <HelpPopup
                                message={"Matter Spend is the sum of spend for all matters for the selected firms and practice areas."}/>
                            </label>
                            <Form.Dropdown
                                selection name={'spend'}
                                value={spend}
                                onChange={this.onChange}
                                options={this.props.spend}/>
                        </Form.Field>
                        <Form.Field>
                            <Form.Dropdown
                                label={`By ${org.str_client_firm}s:`}
                                selection name={'clients'}
                                value={clients}
                                onChange={this.onChange}
                                options={this.props.clients}/>
                        </Form.Field>
                    </Form.Group>
                    <Form.Field>
                        {!by_matrix && <Form.Dropdown
                            label={`By Evaluation Question:`}
                            selection name={'questions'}
                            value={questions}
                            onChange={this.onChange}
                            options={this.props.questions}/>}
                        <label style={{color: color.light_green, cursor: "pointer"}}
                               onClick={this.onSwitchMatrixToQuestion}>
                            {!by_matrix ? "Ranking by Qualmet Metrics" : "Ranking by Individual Questions"}</label>
                    </Form.Field>
                </DataFilter>

                <NoDataMessage active={data.length === 0}/>

                {chart}

                {!loadingData && <DataTable headers={['ID', 'Matter Name', `${org.str_client_firm} Name`, 'Score']}
                                            active={data.length > 0 && spend === SPEND_FILTER.NONE}>
                    {
                        data.map((d, i) =>
                            <Table.Row key={i} className={'row'} onClick={() => this.onClick(d)}>
                                <Table.Cell>
                                    <LabelCircle text={d.id} active={d.is_selected}/>
                                </Table.Cell>
                                <Table.Cell>{d.matter_name}</Table.Cell>
                                <Table.Cell>{d.client_name}</Table.Cell>
                                <Table.Cell>{d.score}</Table.Cell>
                            </Table.Row>)
                    }
                </DataTable>}

                {!loadingData && <DataTable headers={['ID', 'Matter Name', `${org.str_client_firm} Name`, 'Spend', 'Score']}
                                            active={data.length > 0 && spend !== SPEND_FILTER.NONE}>
                    {
                        data.map((d, i) =>
                            <Table.Row key={i} className={'row'} onClick={() => this.onClick(d)}>
                                <Table.Cell>
                                    <LabelCircle text={d.id} active={d.is_selected}/>
                                </Table.Cell>
                                <Table.Cell>{d.matter_name}</Table.Cell>
                                <Table.Cell>{d.client_name}</Table.Cell>
                                <Table.Cell>{d3.format('$,.0f')(d.spend || 0)}</Table.Cell>
                                <Table.Cell>{d.score}</Table.Cell>
                            </Table.Row>)
                    }
                </DataTable>}
            </Report>
            </Body>
        );
    }
}

function mapStateToProps(state) {
    return {...orgState(state), ...state.report};
}

const cAllMatterReportPage = connect(mapStateToProps)(AllMatterReportPage);
export {cAllMatterReportPage as AllMatterReportPage};
