import api from "../helper/api";

function add(data) {
    return api.instance().post("qa/question-group/create", data);
}

function update(data) {
    data._method = "PUT";
    return api.instance().put("qa/question-group/update", data);
}

function readAll(data) {
    return api.instance().post("qa/question-group/get-all", data);
}

function getAllActiveQueGroups(data) {
    return api.instance().post("qa/question-group/get-all-form-data", data);
}

function read(data) {
    return api.instance().post("qa/question-group/get", data);
}

const questionGroupService = {
    add,
    update,
    readAll,
    read,
    getAllActiveQueGroups
};

export default questionGroupService;