import {USER} from "../actions/user.action";

const initState = {
    isAllowed: true,
    loading: false,
    loadingA: false,
    isDone: false,
    isDoneA: false,
    users: [],
    pending_users: 0,
    error: [],
    user: null
};

export default function userReducer(state = initState, action) {
    // reset
    state.error = [];
    state.isDone = false;
    state.isDoneA = false;
    state.loading = false;
    state.loadingA = false;
    state.isAllowed = true;
    // state.user = null;

    // filter
    switch (action.type) {

        // common
        case USER.REQUEST:
            return {...state, loading: true};

        case USER.VALIDATION_ERROR:
            return {...state, error: action.payload};

        case USER.ERROR:
            return {...state, error: [...action.payload]};

        // not allowed to access
        case USER.NOT_ALLOWED:
            return {...state, isAllowed: false};

        // add user
        case USER.ADD_SUCCESS:
            return {...state, isDone: true, users: [...state.users, action.payload]};

        // update user
        case USER.UPDATE_SUCCESS:
            return {...state, isDone: true};

        // read all users
        case USER.READ_ALL_SUCCESS:
            return {...state, users: action.payload.data, pending_users: action.payload.pending_users || 0};

        case USER.READ_SUCCESS:
            return {...state, user: action.payload};

        case USER.NOT_FOUND:
            return {...state, not_found: true};

        case USER.CHANGE_STATUS_SUCCESS:
            state.users[state.users.findIndex((user) => user.id === action.payload.id)].status = action.payload.status;
            return {...state};

        case USER.CHANGE_INFO_SUCCESS:
            if (state.user) {
                state.user.name = action.payload.name;
            }
            return {...state, isDone: true};

        case USER.CHANGE_PASSWORD_SUCCESS:
            return {...state, isDone: true};

        case USER.REQUEST_SU:
            return {...state, loadingA: true};

        case USER.SU_SUCCESS:
            return {...state, isDoneA: true};

        case USER.ACTIVATE_PENDING_USER:
            return {...state, isDone: true};

        case USER.IMPORTED_USER_SUCCESS:
            return {...state, isDone: true};

        case USER.DELETE_IMPORT_USER_SUCCESS:
            return {...state, isDone: true};

        case USER.ADD_IMPORT_USER_SUCCESS:
            return {...state, isDone: true};

        case USER.ACTIVATE_IMPORT_USER_SUCCESS:
            return {...state, isDone: true};

        default:
            return state;
    }
}
