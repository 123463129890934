import helper from "./helper";
import axios from "axios";
import {bug} from "./tracker";
import storage from "./storage";

const baseURL = url => {
    if (url) {
        axios.defaults.baseURL = url + "/api/";
    } else {
        axios.defaults.baseURL = process.env.REACT_APP_API_HOST_DOMAIN + "/api/";
    }
};

let url = storage.get("TEMP_API");
console.log("HOST:", process.env.REACT_APP_RELEASE_STAGE !== "PRODUCTION" ? url : "");
axios.defaults.baseURL = (url || process.env.REACT_APP_API_HOST_DOMAIN) + "/api/";

// Add a response interceptor
axios.interceptors.response.use(
    response => {
        bug.response(response);
        return response;
    },
    (error) => {
        bug.resError(error);
        if (error.response.status === 401 && !window.location.pathname.includes("login")) {
            helper.logout();
        }
        return Promise.reject(error);
    }
);

axios.interceptors.request.use(request => {
    bug.request(request);
    return request;
}, error => {
    bug.reqError(error);
    return error;
});

function init(user) {
    if (!user) {
        user = helper.user();
    }
    if (user) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + user.token;
    }
    axios.defaults.headers.common["Content-Type"] = 'application/json';
    axios.defaults.headers.common["Accept"] = 'application/json';
}

function instance() {
    return axios;
}

const api = {
    instance,
    init,
    baseURL
};

export default api;