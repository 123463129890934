import api from "../helper/api";

function add(data) {
    return api.instance().post(`${data.org_id ? "org/user" : "qa/admins"}/store`, data);
}

function update(data) {
    data._method = "PUT";
    return api.instance().put(`${data.org_id ? "org/user" : "qa/admins"}/update`, data);
}

function changeInfo(data) {
    data._method = "PUT";
    return api.instance().put(`change-user-info`, data);
}

function changePassword(data) {
    data._method = "PUT";
    return api.instance().put(`user-change-password`, data);
}

function changeStatus(data) {
    data._method = "PUT";
    return api.instance().put(`${data.org_id ? "org/user" : "qa/admins"}/change-status`, data);
}

function readAll(data) {
    return api.instance().post(`${data.org_id ? "org/user" : "qa/admins"}/get-all`, data);
}

function read(data) {
    return api.instance().post(`${data.org_id ? "org/user" : "qa/admins"}/get`, data);
}

function getUserInfo() {
    return api.instance().post('me');
}

function sendEmailToUser(data) {
    return api.instance().post('org/user/send-email', data);
}

function readAllPendingUsers(data) {
    return api.instance().post(`pending-organization-user`, data);
}

function activatePendingUser(data) {
    return api.instance().post(`update-pous`, data);
}

function getAllImportUsers(data) {
    return api.instance().post(`org/sso/users/get-all`, data);
}

function deleteImportUser(data) {
    return api.instance().post(`org/sso/users/delete`, data);
}

function deleteUser(data) {
    return api.instance().post(`org/user/delete`, data);
}

function addImportUser(data) {
    return api.instance().post(`org/sso/users/add`, data);
}

function activateImportUsers(data) {
    return api.instance().post(`org/sso/users/activate`, data);
}

function uploadImportUsersFile(data) {
    let form = new FormData();
    Object.entries(data).forEach((d) => {
        form.append(d[0], d[1]);
    });
    return api.instance().post(`org/sso/users/bulk-add`, form);
}

const userService = {
    add,
    update,
    readAll,
    read,
    changeStatus,
    getUserInfo,
    changeInfo,
    changePassword,
    sendEmailToUser,
    readAllPendingUsers,
    activatePendingUser,
    getAllImportUsers,
    uploadImportUsersFile,
    deleteImportUser,
    deleteUser,
    addImportUser,
    activateImportUsers
};

export default userService;