import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Form, Label, List, Rating} from "semantic-ui-react";
import '../../css/rating.css';
import helper from "../../helper/helper";
import {E_QUESTION_TYPE} from "../../helper/const";
import OnHoverPopUp from "../pages/org/my_evaluations/evaluation/components/OnHoverPopUp";
import {color} from "../pages/org/reports/components/barchart/utils";

class AnswerSelector extends Component {
    constructor(props) {
        super(props);
        this.options = [];
        this.state = {...props.data};
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.data) {
            this.setState({...nextProps.data});
        }
    }

    render() {
        const {onChange, loading} = this.props;
        const {answer_type, answer, text_answer} = this.state;
        this.options = helper.getAnsOptions(answer_type);
        let ans_label;
        const al = this.options.find((ans) => ans && answer === ans.value);
        if (al) {
            ans_label = al.quote;
        }
        let content;
        switch (answer_type) {
            case E_QUESTION_TYPE.ANSWER_TYPE_U_ENA:
            case E_QUESTION_TYPE.ANSWER_TYPE_U_E:
                content = (
                    <List style={{minHeight: "120px"}}>
                        <List.Item>
                            <List horizontal>
                                <List.Item>
                                    <List.Content verticalAlign='top'>
                                        <Label>
                                            TERRIBLE
                                        </Label>
                                    </List.Content>
                                </List.Item>
                                <List.Item>
                                    <List.Content verticalAlign='middle'>
                                        <Rating maxRating={5} rating={answer}
                                                clearable={answer_type === E_QUESTION_TYPE.ANSWER_TYPE_U_ENA}
                                                style={{fontSize: "3rem", paddingTop: "16px"}}
                                                onRate={(_, {rating}) => {
                                                    if (!loading) {
                                                        onChange({...this.state, answer: rating});
                                                        this.setState({answer: rating});
                                                    }
                                                }}/>
                                    </List.Content>
                                </List.Item>
                                <List.Item>
                                    <List.Content>
                                        <Label>
                                            EXCELLENT
                                        </Label>
                                    </List.Content>
                                </List.Item>
                            </List>
                        </List.Item>
                        <List.Item style={{
                            fontSize: "18px",
                            textTransform: "uppercase",
                            marginTop: "16px",
                            marginBottom: "16px",
                        }}>{ans_label || "Select Rating"}</List.Item>
                    </List>
                );
                break;
            case E_QUESTION_TYPE.ANSWER_TYPE_NS_S:
                content = (
                    <List style={{minHeight: "120px"}}>
                        <List.Item>
                            <List horizontal>
                                <List.Item>
                                    <List.Header>
                                        POOR
                                    </List.Header>
                                </List.Item>
                                {this.options.map((ans, index) => {
                                        const active = ans.value === answer;
                                        return <List.Item key={index} as='a'
                                                          onClick={() => {
                                                              if (!loading) {
                                                                  onChange({...this.state, answer: ans.value});
                                                                  this.setState({answer: ans.value, label_ans: ans});
                                                              }
                                                          }}>
                                            <OnHoverPopUp
                                                answers={this.options}
                                                rating={ans.value}
                                                element={
                                                    <Label circular size={"huge"}
                                                           style={{
                                                               backgroundColor: active ? color.dark_green : "transparent",
                                                               border: "solid 1.5px",
                                                               borderColor: active ? color.dark_green : color.grey_50,
                                                               color: active ? 'white' : color.dark_green
                                                           }}>{ans.i_code}</Label>
                                                }/>
                                        </List.Item>
                                    }
                                )}
                                <List.Item>
                                    <List.Header>
                                        EXCELLENT
                                    </List.Header>
                                </List.Item>
                            </List>
                        </List.Item>
                        <List.Item>{ans_label || "Click on the number to select rating"}</List.Item>
                    </List>
                );
                break;
            case E_QUESTION_TYPE.ANSWER_TYPE_TEXT_ONLY:
                content = (
                    <Form>
                        <Form.TextArea
                            placeholder={"Comments..."} value={text_answer || ""}
                            onChange={(e) => {
                                if (!loading) {
                                    onChange({...this.state, text_answer: e.target.value});
                                    this.setState({text_answer: e.target.value});
                                }
                            }}/>
                    </Form>
                );
                break;
            case E_QUESTION_TYPE.ANSWER_TYPE_F_NL_EL:
            case E_QUESTION_TYPE.ANSWER_TYPE_LV_HV:
                content = (
                    <List>
                        <List.Item>
                            <List horizontal>
                                <div style={{display: 'flex', justifyContent: "space-between"}}>
                                    <Label
                                        size={"tiny"}>{answer_type === E_QUESTION_TYPE.ANSWER_TYPE_F_NL_EL ? "Not at all likely" : "Low Value"}</Label>
                                    <Label
                                        size={"tiny"}>{answer_type === E_QUESTION_TYPE.ANSWER_TYPE_F_NL_EL ? "Extremely likely" : "High Value"}</Label>
                                </div>
                                <br/>
                                {this.options.map((_, index) =>
                                    <List.Item key={index} as='a' style={{margin: "0 0.5em"}} onClick={() => {
                                        if (!loading) {
                                            onChange({...this.state, answer: index});
                                            this.setState({answer: index});
                                        }
                                    }}>
                                        <Label circular color={answer === index ? "teal" : "grey"} size={"large"}>
                                            {index}
                                        </Label>
                                    </List.Item>
                                )}
                            </List>
                        </List.Item>
                    </List>
                );
                break;
            case E_QUESTION_TYPE.ANSWER_TYPE_YES_NO:
                content = (
                    <List>
                        <List.Item>
                            <List horizontal>
                                <Button circular primary={answer === 0} disabled={answer === 0}
                                        onClick={() => {
                                            if (!loading) {
                                                onChange({...this.state, answer: 0});
                                                this.setState({answer: 0});
                                            }
                                        }}
                                        style={{paddingLeft: "48px", paddingRight: "48px"}}>No</Button>
                                <Button circular primary={answer === 1} disabled={answer === 1}
                                        onClick={() => {
                                            if (!loading) {
                                                onChange({...this.state, answer: 1});
                                                this.setState({answer: 1});
                                            }
                                        }}
                                        style={{paddingLeft: "48px", paddingRight: "48px"}}>Yes</Button>
                            </List>
                        </List.Item>
                        <List.Item>{text_answer}</List.Item>
                    </List>
                );
                break;
            default:
                content = (<div>Invalid answer type!</div>);
                break;
        }
        return (content);
    }
}

AnswerSelector.propTypes = {
    data: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    loading: PropTypes.bool
};

export default AnswerSelector;
