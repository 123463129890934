import React, {Component} from "react";
import "./App.css";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import NotFoundPage from "./components/pages/NotFoundPage";
import {ListOrgPage} from "./components/pages/qualmetadmin/org/ListOrgPage";
import {ForgotPasswordPage} from "./components/pages/auth/ForgotPasswordPage";
import {ResetPasswordPage} from "./components/pages/auth/ResetPasswordPage";
import PrivateRoute from "./components/other/PrivateRoute";
import authAction from "./actions/auth.action";
import {connect} from "react-redux";
import {AddOrgPage} from "./components/pages/qualmetadmin/org/AddOrgPage";
import {EditOrgPage} from "./components/pages/qualmetadmin/org/EditOrgPage";
import {OrgAdminPage} from "./components/pages/org/orgadmin/OrgAdminPage";
import {OrgUsersPage} from "./components/pages/org/orgadmin/users/OrgUsersPage";
import {OrgAddUserPage} from "./components/pages/org/orgadmin/users/OrgAddUserPage";
import {OrgManageUserPage} from "./components/pages/org/orgadmin/users/OrgManageUserPage";
import {OrgViewUserPage} from "./components/pages/org/orgadmin/users/OrgViewUserPage";
import ForbiddenPage from "./components/pages/ForbiddenPage";
import helper from "./helper/helper";
import {RedirectPage} from "./components/pages/RedirectPage";
import {OrgClientsPage} from "./components/pages/org/orgadmin/clients/OrgClientsPage";
import {OrgAddClientPage} from "./components/pages/org/orgadmin/clients/OrgAddClientPage";
import {OrgManageClientPage} from "./components/pages/org/orgadmin/clients/OrgManageClientPage";
import {OrgLawyersPage} from "./components/pages/org/orgadmin/lawyers/OrgLawyersPage";
import {OrgAddLawyerPage} from "./components/pages/org/orgadmin/lawyers/OrgAddLawyerPage";
import {OrgManageLawyerPage} from "./components/pages/org/orgadmin/lawyers/OrgManageLawyerPage";
import {OrgPracticeAreasPage} from "./components/pages/org/orgadmin/practices/OrgPracticeAreasPage";
import {OrgAddPracticeAreaPage} from "./components/pages/org/orgadmin/practices/OrgAddPracticeAreaPage";
import {OrgManagePracticeAreaPage} from "./components/pages/org/orgadmin/practices/OrgManagePracticeAreaPage";
import {ANY_ROLE, EVALUATOR, MATTER, ORG_ADMIN, REPORT_VIEWER, SUPER_ADMIN} from "./helper/const";
import InternalServerErrorPage from "./components/pages/InternalServerErrorPage";
import {OrgQuestionPage} from "./components/pages/org/orgadmin/questions/OrgQuestionPage";
import {OrgQuestionSetPage} from "./components/pages/org/orgadmin/questions/OrgQuestionSetPage";
import {OrgAddQuestionSetPage} from "./components/pages/org/orgadmin/questions/add/OrgAddQuestionSetPage";
import {OrgAddMatterPage} from "./components/pages/org/orgadmin/matters/OrgAddMatterPage";
import {SuperAdminPracticeAreasPage} from "./components/pages/qualmetadmin/practices/SuperAdminPracticeAreasPage";
import {SuperAdminAddPracticeAreaPage} from "./components/pages/qualmetadmin/practices/SuperAdminAddPracticeAreaPage";
import {SuperAdminManagePracticeAreaPage} from "./components/pages/qualmetadmin/practices/SuperAdminManagePracticeAreaPage";
import {SuperAdminUsersPage} from "./components/pages/qualmetadmin/users/SuperAdminUsersPage";
import {SuperAdminAddUserPage} from "./components/pages/qualmetadmin/users/SuperAdminAddUserPage";
import {SuperAdminManageUserPage} from "./components/pages/qualmetadmin/users/SuperAdminManageUserPage";
import {SAOrgUsersPage} from "./components/pages/qualmetadmin/org/users/SAOrgUsersPage";
import {SAOrgAddUserPage} from "./components/pages/qualmetadmin/org/users/SAOrgAddUserPage";
import {SAOrgManageUserPage} from "./components/pages/qualmetadmin/org/users/SAOrgManageUserPage";
import {SuperAdminQuestionSetPage} from "./components/pages/qualmetadmin/questions/SuperAdminQuestionSetPage";
import {SuperAdminQuestionPage} from "./components/pages/qualmetadmin/questions/SuperAdminQuestionPage";
import {SuperAdminAddQuestionSetPage} from "./components/pages/qualmetadmin/questions/add/SuperAdminAddQuestionSetPage";
import {OrgMattersPage} from "./components/pages/org/orgadmin/matters/OrgMattersPage";
import {OrgEditMatterPage} from "./components/pages/org/orgadmin/matters/OrgEditMatterPage";
import {OrgEvaluationsCyclesPage} from "./components/pages/org/orgadmin/evaluations_cycles/OrgEvaluationsCyclesPage";
import {MyEvaluationsPage} from "./components/pages/org/my_evaluations/MyEvaluationsPage";
import {EvaluationPage} from "./components/pages/org/my_evaluations/evaluation/EvaluationPage";
import {MattersPage} from "./components/pages/org/matters/MattersPage";
import {MatterSummaryPage} from "./components/pages/org/matters/MatterSummaryPage";
import {OrgRegionsPage} from "./components/pages/org/orgadmin/regions_locations/regions/page/OrgRegionsPage";
import {OrgRegionAreasPage} from "./components/pages/org/orgadmin/regions_locations/regions/page/OrgRegionAreasPage";
import {OrgRegionAreaOfficesPage} from "./components/pages/org/orgadmin/regions_locations/regions/page/OrgRegionAreaOfficesPage";
import {OrgSettingsPage} from "./components/pages/org/orgadmin/settings/OrgSettingsPage";
import {UserProfilePage} from "./components/pages/UserProfilePage";
import {OrgViewMatterPage} from "./components/pages/org/orgadmin/matters/OrgViewMatterPage";
import {ClientsPage} from "./components/pages/org/clients/ClientsPage";
import {LawyersPage} from "./components/pages/org/lawyers/LawyersPage";
import {AllMatterReportPage} from "./components/pages/org/reports/matters/AllMatterReportPage";
import {reportsPath} from "./components/pages/org/other/OrgMenuBar";
import {SAListFinalQuestionsPage} from "./components/pages/qualmetadmin/final-questions/SAListFinalQuestionsPage";
import {JoinQualmetPage} from "./components/pages/auth/JoinQualmetPage";
import {AllClientsReportPage} from "./components/pages/org/reports/clients/AllClientsReportPage";
import {MatterReportPage} from "./components/pages/org/reports/matters/MatterReportPage";
import {ClientReportPage} from "./components/pages/org/reports/clients/ClientReportPage";
import {HelpCatsPage} from "./components/pages/qualmetadmin/help/HelpCatsPage";
import {HelpDocsPage} from "./components/pages/qualmetadmin/help/HelpDocsPage";
import {AddHelpDocsPage} from "./components/pages/qualmetadmin/help/AddHelpDocsPage";
import {EditHelpDocsPage} from "./components/pages/qualmetadmin/help/EditHelpDocsPage";
import {HelpPage} from "./components/pages/HelpPage";
import {AllLawyersReportPage} from "./components/pages/org/reports/lawyers/AllLawyersReportPage";
import {LawyerReportPage} from "./components/pages/org/reports/lawyers/LawyerReportPage";
import {AllPracticeAreasReportPage} from "./components/pages/org/reports/practice_areas/AllPracticeAreasReportPage";
import {NewLoginPage} from "./components/pages/auth/NewLoginPage";
import {NewUserAccountPage} from "./components/pages/auth/NewUserAccountPage";
import TermsAndConditionPage from "./components/pages/auth/TermsAndConditionPage";
import {VerifyUserAccount} from "./components/pages/auth/VerifyUserAccount";
import {UserPendingApprovalPage} from "./components/pages/auth/UserPendingApprovalPage";
import NewOrgGetStartedPage from "./components/pages/auth/NewOrgGetStartedPage";
import {NewOrgSetupPage} from "./components/pages/auth/NewOrgSetupPage";
import NewOrgSetupDonePage from "./components/pages/auth/NewOrgSetupDonePage";
import PrivacyPage from "./components/pages/PrivacyPage";
import {ContactPage} from "./components/pages/ContactPage";
import {ViewEvaluationPage} from "./components/pages/org/my_evaluations/ViewEvaluationPage";
import {ClientsEvaluationsPage} from "./components/pages/org/clients/ClientsEvaluationsPage";
import {ClientSummaryPage} from "./components/pages/org/clients/ClientSummaryPage";
import {AddClientEvalPage} from "./components/pages/org/clients/evals/AddClientEvalPage";
import {EditClientEvalPage} from "./components/pages/org/clients/evals/EditClientEvalPage";
import {SAOrgSSOPage} from "./components/pages/qualmetadmin/org/sso/SAOrgSSOPage";
import {OrgSettingsDomainsPage} from "./components/pages/org/orgadmin/settings/OrgSettingsDomainsPage";
import {OrgSettingsSSOPage} from "./components/pages/org/orgadmin/settings/OrgSettingsSSOPage";
import {LogoutPage} from "./components/pages/auth/LogoutPage";
import {OrgImportUsersPage} from "./components/pages/org/orgadmin/users/import/OrgImportUsersPage";
import {SSOLoginCallbackPage} from "./components/pages/auth/SSOLoginCallbackPage";
import {PlanListPage} from './components/pages/payment/plan/PlanListPage';
import {AddPlanPage} from "./components/pages/payment/plan/AddPlanPage";
import OrgPlanAndBiliing from "./components/pages/payment/billing/OrgPlanAndBiliing";
import TermsOfUsePage from "./components/pages/app/TermsOfUsePage";
import SAOrgBillingPage from "./components/pages/payment/billing/SAOrgBillingPage";
import SAOrgSubsPage from "./components/pages/payment/billing/SAOrgSubsPage";
import SAOrgAddPayment from "./components/pages/payment/billing/SAOrgAddPayment";
import {AddLawyerEvalPage} from "./components/pages/org/lawyers/evals/AddLawyerEvalPage";
import {EditLawyerEvalPage} from "./components/pages/org/lawyers/evals/EditLawyerEvalPage";
import {LawyerEvaluationsPage} from "./components/pages/org/lawyers/LawyerEvaluationsPage";
import {LawyerSummaryPage} from "./components/pages/org/lawyers/LawyerSummaryPage";
import {OrgPlanPage} from "./components/pages/org/orgadmin/settings/OrgPlanPage";
import {OrgPrefsPage} from "./components/pages/org/orgadmin/settings/OrgPrefsPage";
import SAOrgManagePayment from "./components/pages/payment/billing/SAOrgManagePayment";
import {EditPlanPage} from "./components/pages/payment/plan/EditPlanPage";
import {PublicRoute} from "./components/other/PublicRoute";
import {SAListQuestionGroupsPage} from "./components/pages/qualmetadmin/question-groups/SAListQuestionGroupsPage";
import {AllExportRequestsPage} from "./components/pages/org/orgadmin/export/AllExportRequestsPage";
import {LoginCallbackPage} from "./components/pages/auth/LoginCallbackPage";
import AdminSettingsPage from "./components/pages/qualmetadmin/settings/AdminSettingsPage";
import TestPage from "./components/pages/TestPage";
import {IDLoginPage} from "./components/pages/auth/IDLoginPage";

class App extends Component {

    componentWillMount() {
        if (helper.isLoggedIn()) {
            authAction.validate();
        }
    }

    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <PrivateRoute path="/" exact component={RedirectPage} role={ANY_ROLE}/>
                    <PrivateRoute path="/profile" exact component={UserProfilePage} role={ANY_ROLE}/>

                    {/*############################ SUPER ADMIN ############################*/}
                    <PrivateRoute path="/superadmin/settings" exact component={AdminSettingsPage} role={SUPER_ADMIN}/>

                    {/*path for organisation related operation like add, update, archive or delete*/}
                    <PrivateRoute path="/superadmin/orgs" exact component={ListOrgPage} role={SUPER_ADMIN}/>
                    <PrivateRoute path="/superadmin/orgs/add" exact component={AddOrgPage} role={SUPER_ADMIN}/>
                    <PrivateRoute path="/superadmin/orgs/edit/:id" exact component={EditOrgPage}
                                  role={SUPER_ADMIN}/>
                    <PrivateRoute path="/superadmin/orgs/:id/users" exact component={SAOrgUsersPage}
                                  role={SUPER_ADMIN}/>
                    <PrivateRoute path="/superadmin/orgs/:id/sso" exact component={SAOrgSSOPage}
                                  role={SUPER_ADMIN}/>
                    <PrivateRoute path="/superadmin/orgs/:id/users/add" exact component={SAOrgAddUserPage}
                                  role={SUPER_ADMIN}/>
                    <PrivateRoute path="/superadmin/orgs/:id/users/edit/:uid" exact component={SAOrgManageUserPage}
                                  role={SUPER_ADMIN}/>
                    <PrivateRoute path="/superadmin/orgs/:id/billing" exact role={SUPER_ADMIN}
                                  component={OrgPlanAndBiliing}/>

                    {/* super admin users */}
                    <PrivateRoute path="/superadmin/users" exact role={SUPER_ADMIN}
                                  component={SuperAdminUsersPage}/>
                    <PrivateRoute path="/superadmin/users/add" exact role={SUPER_ADMIN}
                                  component={SuperAdminAddUserPage}/>
                    <PrivateRoute path="/superadmin/users/manage/:id" exact role={SUPER_ADMIN}
                                  component={SuperAdminManageUserPage}/>

                    {/* practice-areas manage*/}
                    <PrivateRoute path="/superadmin/master-data/practice-areas" exact role={SUPER_ADMIN}
                                  component={SuperAdminPracticeAreasPage}/>
                    <PrivateRoute path="/superadmin/master-data/practice-areas/add" exact role={SUPER_ADMIN}
                                  component={SuperAdminAddPracticeAreaPage}/>
                    <PrivateRoute path="/superadmin/master-data/practice-areas/manage/:id" exact role={SUPER_ADMIN}
                                  component={SuperAdminManagePracticeAreaPage}/>

                    {/* evaluation questions */}
                    {/* evaluation question screen 1 for list of sets */}
                    <PrivateRoute path="/superadmin/master-data/evaluation-questions" exact role={SUPER_ADMIN}
                                  component={SuperAdminQuestionSetPage}/>
                    {/* evaluation question screen 2 for list of groups as per set */}
                    {/*<PrivateRoute path="/superadmin/master-data/evaluation-questions/set/:id" exact*/}
                    {/*role={SUPER_ADMIN}*/}
                    {/*component={SuperAdminQuestionGroupPage}/>*/}
                    {/* evaluation question screen 2 for list of questions as per group */}
                    <PrivateRoute path="/superadmin/master-data/evaluation-questions/set/:id" exact
                                  role={SUPER_ADMIN}
                                  component={SuperAdminQuestionPage}/>
                    {/* evaluation question - add question set, group, and question steps screen */}
                    <PrivateRoute path="/superadmin/master-data/evaluation-questions/add/:filter" exact
                                  role={SUPER_ADMIN}
                                  component={SuperAdminAddQuestionSetPage}/>
                    {/* evaluation question - add question set group, and question steps screen */}
                    {/*<PrivateRoute path="/superadmin/master-data/evaluation-questions/add/group/:id" exact*/}
                    {/*role={SUPER_ADMIN}*/}
                    {/*component={SuperAdminAddQuestionSetGroupPage}/>*/}

                    {/*question groups*/}
                    <PrivateRoute path="/superadmin/master-data/question-groups" exact role={SUPER_ADMIN}
                                  component={SAListQuestionGroupsPage}/>

                    {/*final questions*/}
                    <PrivateRoute path="/superadmin/master-data/final-questions" exact role={SUPER_ADMIN}
                                  component={SAListFinalQuestionsPage}/>

                    {/*faqs*/}
                    <PrivateRoute path="/superadmin/help" exact role={SUPER_ADMIN}
                                  component={HelpCatsPage}/>
                    <PrivateRoute path="/superadmin/help/:key" exact role={SUPER_ADMIN}
                                  component={HelpDocsPage}/>
                    <PrivateRoute path="/superadmin/help/:key/add" exact role={SUPER_ADMIN}
                                  component={AddHelpDocsPage}/>
                    <PrivateRoute path="/superadmin/help/:key/edit/:id" exact role={SUPER_ADMIN}
                                  component={EditHelpDocsPage}/>

                    {/*billing & plans*/}
                    <PrivateRoute path="/superadmin/payment/plans/list" exact role={SUPER_ADMIN}
                                  component={PlanListPage}/>
                    <PrivateRoute path="/superadmin/payment/plans/add" exact role={SUPER_ADMIN}
                                  component={AddPlanPage}/>
                    <PrivateRoute path="/superadmin/payment/plans/edit/:id" exact role={SUPER_ADMIN}
                                  component={EditPlanPage}/>

                    <PrivateRoute path="/superadmin/payment/billing" exact role={SUPER_ADMIN}
                                  component={SAOrgBillingPage}/>
                    <PrivateRoute path="/superadmin/payment/billing/org/:id" exact role={SUPER_ADMIN}
                                  component={SAOrgSubsPage}/>
                    <PrivateRoute path="/superadmin/payment/billing/org/:id/add-payment" exact role={SUPER_ADMIN}
                                  component={SAOrgAddPayment}/>
                    <PrivateRoute path="/superadmin/payment/billing/org/:id/manage-payment/:sid" exact
                                  role={SUPER_ADMIN}
                                  component={SAOrgManagePayment}/>

                    {/*terms of use edit page*/}
                    <PrivateRoute path="/superadmin/terms-of-use" exact role={SUPER_ADMIN}
                                  component={TermsOfUsePage}/>
                    {/*############################ SUPER ADMIN ############################*/}

                    {/*############################ ORGANIZATION ADMIN ############################*/}
                    <PrivateRoute path="/orgadmin" exact component={OrgAdminPage} role={ORG_ADMIN}/>

                    {/*user manage*/}
                    <PrivateRoute path="/orgadmin/users" exact role={ORG_ADMIN} component={OrgUsersPage}/>
                    <PrivateRoute path="/orgadmin/users/add" exact role={ORG_ADMIN} component={OrgAddUserPage}/>
                    <PrivateRoute path="/orgadmin/users/view/:id" exact role={ORG_ADMIN}
                                  component={OrgViewUserPage}/>
                    <PrivateRoute path="/orgadmin/users/manage/:id" exact role={ORG_ADMIN}
                                  component={OrgManageUserPage}/>
                    <PrivateRoute path="/orgadmin/users/import" exact role={ORG_ADMIN}
                                  component={OrgImportUsersPage}/>

                    {/* clients and firms both are same*/}
                    {/*clients manage*/}
                    <PrivateRoute path="/orgadmin/client_firm" exact role={ORG_ADMIN} component={OrgClientsPage}/>
                    <PrivateRoute path="/orgadmin/client_firm/add" exact role={ORG_ADMIN}
                                  component={OrgAddClientPage}/>
                    <PrivateRoute path="/orgadmin/client_firm/manage/:id" exact role={ORG_ADMIN}
                                  component={OrgManageClientPage}/>

                    {/*lawyers manage*/}
                    <PrivateRoute path="/orgadmin/lawyers" exact role={ORG_ADMIN} component={OrgLawyersPage}/>
                    <PrivateRoute path="/orgadmin/lawyers/add" exact role={ORG_ADMIN} component={OrgAddLawyerPage}/>
                    <PrivateRoute path="/orgadmin/lawyers/manage/:id" exact role={ORG_ADMIN}
                                  component={OrgManageLawyerPage}/>

                    {/* practice-areas manage*/}
                    <PrivateRoute path="/orgadmin/practice-areas" exact role={ORG_ADMIN}
                                  component={OrgPracticeAreasPage}/>
                    <PrivateRoute path="/orgadmin/practice-areas/add" exact role={ORG_ADMIN}
                                  component={OrgAddPracticeAreaPage}/>
                    <PrivateRoute path="/orgadmin/practice-areas/manage/:id" exact role={ORG_ADMIN}
                                  component={OrgManagePracticeAreaPage}/>

                    {/* regions & locations */}
                    <PrivateRoute path="/orgadmin/regions" exact role={ORG_ADMIN}
                                  component={OrgRegionsPage}/>
                    <PrivateRoute path="/orgadmin/regions/area/:id" exact role={ORG_ADMIN}
                                  component={OrgRegionAreasPage}/>
                    <PrivateRoute path="/orgadmin/regions/area/office/:id" exact role={ORG_ADMIN}
                                  component={OrgRegionAreaOfficesPage}/>
                    {/*<PrivateRoute path="/orgadmin/locations" exact role={ORG_ADMIN}*/}
                    {/*component={OrgRegionsLocationsPage}/>*/}

                    {/* evaluation questions */}
                    {/* evaluation question screen 1 for list of sets */}
                    <PrivateRoute path="/orgadmin/evaluation-questions" exact role={ORG_ADMIN}
                                  component={OrgQuestionSetPage}/>
                    {/* evaluation question screen 2 for list of groups as per set */}
                    {/*<PrivateRoute path="/orgadmin/evaluation-questions/set/:id" exact role={ORG_ADMIN}*/}
                    {/*component={OrgQuestionGroupPage}/>*/}
                    {/* evaluation question screen 2 for list of questions */}
                    <PrivateRoute path="/orgadmin/evaluation-questions/set/:id" exact role={ORG_ADMIN}
                                  component={OrgQuestionPage}/>
                    {/* evaluation question - add question set, group, and question steps screen */}
                    <PrivateRoute path="/orgadmin/evaluation-questions/add" exact role={ORG_ADMIN}
                                  component={OrgAddQuestionSetPage}/>
                    {/* evaluation question - add question set group, and question steps screen */}
                    {/*<PrivateRoute path="/orgadmin/evaluation-questions/add/group/:id" exact role={ORG_ADMIN}*/}
                    {/*component={OrgAddQuestionSetGroupPage}/>*/}

                    {/* matters manage */}
                    <PrivateRoute path="/orgadmin/matters" exact role={ORG_ADMIN}
                                  component={OrgMattersPage}/>
                    <PrivateRoute path="/orgadmin/matters/add" exact role={ORG_ADMIN}
                                  component={OrgAddMatterPage}/>
                    <PrivateRoute path="/orgadmin/matters/edit/:id" exact role={ORG_ADMIN}
                                  component={OrgEditMatterPage}/>
                    <PrivateRoute path="/orgadmin/matters/view/:id" exact role={ORG_ADMIN}
                                  component={OrgViewMatterPage}/>
                    {/*<PrivateRoute path="/orgadmin/matters/evaluations/:id" exact role={[ORG_ADMIN, MATTER]}*/}
                    {/*component={OrgAddMatterPage}/>*/}
                    {/*<PrivateRoute path="/orgadmin/matters/comments/:id" exact role={[ORG_ADMIN, MATTER]}*/}
                    {/*component={OrgAddMatterPage}/>*/}

                    {/* evaluations cycles */}
                    <PrivateRoute path="/orgadmin/evaluation-cycles" exact role={ORG_ADMIN}
                                  component={OrgEvaluationsCyclesPage}/>

                    {/*export data*/}
                    <PrivateRoute path="/orgadmin/export" exact component={AllExportRequestsPage} role={ORG_ADMIN}/>

                    {/* org settings */}
                    <PrivateRoute path="/orgadmin/settings" exact role={ORG_ADMIN}
                                  component={OrgPrefsPage}/>
                    <PrivateRoute path="/orgadmin/settings/info" exact role={ORG_ADMIN}
                                  component={OrgSettingsPage}/>
                    <PrivateRoute path="/orgadmin/settings/domains" exact role={ORG_ADMIN}
                                  component={OrgSettingsDomainsPage}/>
                    <PrivateRoute path="/orgadmin/settings/sso" exact role={ORG_ADMIN}
                                  component={OrgSettingsSSOPage}/>
                    <PrivateRoute path="/orgadmin/settings/plan" exact role={ORG_ADMIN}
                                  component={OrgPlanPage}/>

                    {/*############################ ORGANIZATION ADMIN ############################*/}

                    {/*############################ MY EVALUATIONS ############################*/}

                    <PrivateRoute path="/my-evaluations" exact role={EVALUATOR}
                                  component={MyEvaluationsPage}/>
                    {/* multi page evaluation */}
                    <PrivateRoute path="/evaluation/:id" exact role={EVALUATOR}
                                  component={EvaluationPage}/>
                    <PrivateRoute path="/evaluation/view/:id" exact role={EVALUATOR}
                                  component={ViewEvaluationPage}/>

                    {/*############################ MY EVALUATIONS ############################*/}

                    {/*############################ MATTERS ############################*/}

                    <PrivateRoute path="/matters" exact role={MATTER}
                                  component={MattersPage}/>
                    <PrivateRoute path="/matters/add" exact role={MATTER}
                                  component={OrgAddMatterPage}/>
                    <PrivateRoute path="/matters/edit/:id" exact role={MATTER}
                                  component={OrgEditMatterPage}/>
                    <PrivateRoute path="/matters/:id/:type" exact role={MATTER}
                                  component={MatterSummaryPage}/>

                    {/*############################ MATTERS ############################*/}

                    {/*############################ CLIENTS ############################*/}
                    <PrivateRoute path="/clients-firms" exact role={MATTER} component={ClientsPage}/>
                    <PrivateRoute path="/clients-firms/:id/evaluations" exact role={MATTER}
                                  component={ClientsEvaluationsPage}/>
                    <PrivateRoute path="/clients-firms/:id/new-evaluation" exact role={MATTER}
                                  component={AddClientEvalPage}/>
                    <PrivateRoute path="/clients-firms/:id/edit-evaluation/:eid" exact role={MATTER}
                                  component={EditClientEvalPage}/>
                    <PrivateRoute path="/clients-firms/:id/evaluation/:eid/:type" exact role={MATTER}
                                  component={ClientSummaryPage}/>
                    {/*############################ CLIENTS ############################*/}

                    {/*############################ LAWYERS ############################*/}
                    <PrivateRoute path="/lawyers" exact role={MATTER}
                                  component={LawyersPage}/>
                    <PrivateRoute path="/lawyers/:id/evaluations" exact role={MATTER}
                                  component={LawyerEvaluationsPage}/>
                    <PrivateRoute path="/lawyers/:id/new-evaluation" exact role={MATTER}
                                  component={AddLawyerEvalPage}/>
                    <PrivateRoute path="/lawyers/:id/edit-evaluation/:eid" exact role={MATTER}
                                  component={EditLawyerEvalPage}/>
                    <PrivateRoute path="/lawyers/:id/evaluation/:eid/:type" exact role={MATTER}
                                  component={LawyerSummaryPage}/>
                    {/*############################ LAWYERS ############################*/}

                    {/*############################ REPORTS ############################*/}
                    {/* matters*/}
                    <PrivateRoute path={`${reportsPath}/matters`} exact role={REPORT_VIEWER}
                                  component={AllMatterReportPage}/>
                    <PrivateRoute path={`${reportsPath}/matters/:id/:type`} exact role={REPORT_VIEWER}
                                  component={MatterReportPage}/>
                    {/*clients*/}
                    <PrivateRoute path={`${reportsPath}/clients-firms`} exact role={REPORT_VIEWER}
                                  component={AllClientsReportPage}/>
                    <PrivateRoute path={`${reportsPath}/clients-firms/:id/:type`} exact role={REPORT_VIEWER}
                                  component={ClientReportPage}/>

                    {/*lawyers/evaluatees*/}
                    <PrivateRoute path={`${reportsPath}/lawyers`} exact role={REPORT_VIEWER}
                                  component={AllLawyersReportPage}/>
                    <PrivateRoute path={`${reportsPath}/lawyers/:id/:type`} exact role={REPORT_VIEWER}
                                  component={LawyerReportPage}/>

                    {/*practice areas*/}
                    <PrivateRoute path={`${reportsPath}/practice-areas`} exact role={REPORT_VIEWER}
                                  component={AllPracticeAreasReportPage}/>
                    {/*############################ REPORTS ############################*/}

                    {/*############################ HELP ############################*/}
                    <PrivateRoute path={`/help`} exact role={ANY_ROLE}
                                  component={HelpPage}/>
                    {/*############################ HELP ############################*/}

                    <PublicRoute path="/login" exact component={NewLoginPage}/>
                    <PublicRoute path="/login/sso/callback" exact component={SSOLoginCallbackPage}/>
                    <PublicRoute path="/login/bypass" exact component={IDLoginPage}/>
                    <PublicRoute path="/login/callback" exact component={LoginCallbackPage}/>
                    <PublicRoute path="/logout" exact component={LogoutPage}/>
                    <PublicRoute path="/register" exact component={TermsAndConditionPage}/>
                    <PublicRoute path="/register/form" exact component={NewUserAccountPage}/>
                    <PublicRoute path="/register/verify" exact component={VerifyUserAccount}/>
                    <PublicRoute path="/account-pending" exact component={UserPendingApprovalPage}/>
                    <PublicRoute path="/org-setup" exact component={NewOrgGetStartedPage}/>
                    <PublicRoute path="/org-setup/form" exact component={NewOrgSetupPage}/>
                    <PublicRoute path="/org-setup/done" exact component={NewOrgSetupDonePage}/>
                    <PublicRoute path="/forgot-password" exact component={ForgotPasswordPage}/>
                    <PublicRoute path="/reset-password" exact component={ResetPasswordPage}/>
                    <PublicRoute path="/join-qualmet" exact component={JoinQualmetPage}/>

                    <Route path="/internal-server-error" exact component={InternalServerErrorPage}/>
                    <Route path="/forbidden" exact component={ForbiddenPage}/>
                    <Route path="/privacy" exact component={PrivacyPage}/>
                    <Route path="/contact" exact component={ContactPage}/>

                    <Route path={"/test"} exact component={TestPage}/>

                    <Route component={NotFoundPage}/>
                </Switch>
            </BrowserRouter>
        );
    }
}

function mapStateToProps(state) {
    return state.auth;
}

const connectedApp = connect(mapStateToProps)(App);
export {connectedApp as App};
