import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {orgState} from "../../../../../../helper/stateUtil";
import ReportHeader from "../../components/header/ReportHeader";
import {Form, Grid, List, Table} from "semantic-ui-react";
import DataFilter from "../../components/DataFilter";
import NoDataMessage from "../../components/other/NoDataMessage";
import reportAction from "../../../../../../actions/report.action";
import FullScreenLoader from "../../../../../other/FullScreenLoader";

class ReportLawyerComments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            evaluations: null,
            questions: null
        };
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        const {lawyerId} = this.props;
        this.props.dispatch(reportAction.getLawyerCommentsFilter({
            id: lawyerId
        }));
    }

    componentWillReceiveProps(nextProps) {
        const {step, isDone} = nextProps;
        if (isDone) {
            if (step === 1) {
                // got evaluations
                if (nextProps.hasOwnProperty("evaluations") && nextProps["evaluations"].length > 0) {
                    this.setState({evaluations: nextProps["evaluations"][0].value});
                    this.props.dispatch(reportAction.getLawyerCommentsQuesFilter({
                        lawyer_id: this.props.lawyerId,
                        id: nextProps["evaluations"][0].value
                    }));
                }
            } else if (step === 2) {
                // got evaluation's questions;
                if (nextProps.hasOwnProperty("questions") && nextProps["questions"].length > 0) {
                    this.setState({questions: nextProps["questions"][0].value});
                    this.props.dispatch(reportAction.getLawyerEvalComments({
                        lawyer_id: this.props.lawyerId,
                        evaluations: this.state.evaluations,
                        questions: nextProps['questions'][0].value
                    }));
                }
            } else if (step === 3) {
                // got data of the report
            }
        }
    }

    onChange = (_, {name, value}) => {
        this.setState({[name]: value});
        if (name === "evaluations") {
            this.props.dispatch(reportAction.getLawyerCommentsQuesFilter({
                lawyer_id: this.props.lawyerId,
                id: value
            }));
        } else if (name === "questions") {
            this.props.dispatch(reportAction.getLawyerEvalComments({
                lawyer_id: this.props.lawyerId,
                evaluations: this.state.evaluations,
                questions: value
            }));
        }
    };

    render() {
        const {evaluations, questions} = this.state;
        const {loadingData, header, data, totals} = this.props;
        // totals as evaluator_comments and data as general_comments
        let que = this.props.questions.find((q) => q.value === questions) || {};
        return (
            <React.Fragment>
                <FullScreenLoader active={loadingData}/>
                <ReportHeader>
                    <Grid columns={'equal'} stackable>
                        <Grid.Column>
                            <List>
                                {/*<List.Item>Matter Name: <b>{header ? header.matter_name : ""}</b></List.Item>*/}
                                <List.Item>Evaluatee Name: <b>{header ? header.name : "Lawyer Name"}</b></List.Item>
                            </List>
                        </Grid.Column>
                        {/*<Grid.Column>*/}
                        {/*    Status: <b>{header.status ? "Open" : `Closed on ${header.closed_at}`}</b>*/}
                        {/*</Grid.Column>*/}
                    </Grid>
                </ReportHeader>
                <DataFilter>
                    <Form.Group>
                        <Form.Field width={6}>
                            <Form.Dropdown
                                label={`By Evaluation:`}
                                selection
                                name={"evaluations"}
                                value={evaluations}
                                onChange={this.onChange}
                                options={this.props.evaluations}/>
                        </Form.Field>
                        <Form.Field width={10}>
                            <Form.Dropdown
                                label={`By Individual Question:`}
                                value={questions}
                                selection name={'questions'}
                                onChange={this.onChange}
                                options={this.props.questions}/>
                        </Form.Field>
                    </Form.Group>
                </DataFilter>

                <b>Question: </b>{que.text}
                <NoDataMessage active={data.length === 0 && totals.length === 0}/>
                <br/>
                {data.length > 0 && <Table>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>
                                General Comments
                                <br/>
                                Evaluator
                            </Table.HeaderCell>
                            <Table.HeaderCell>Comment</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {data.map((dd, ddi) =>
                            <Table.Row key={ddi}>
                                <Table.Cell>
                                    {dd.name}
                                </Table.Cell>
                                <Table.Cell>{dd.comment}</Table.Cell>
                            </Table.Row>
                        )}
                        {data.length === 0 && <Table.Row>
                            <Table.Cell colSpan={2} key={0}>
                                No comments
                            </Table.Cell>
                        </Table.Row>}
                    </Table.Body>
                </Table>}
                <br/>
                {totals.map((t, ti) =>
                    t.data ? <Table key={ti}>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell colSpan={2}>
                                    Question: {t.question}
                                </Table.HeaderCell>
                            </Table.Row>
                            <Table.Row>
                                <Table.HeaderCell>
                                    Evaluator
                                </Table.HeaderCell>
                                <Table.HeaderCell>Comment</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {t.data.map((dd, ddi) =>
                                <Table.Row key={ddi}>
                                    <Table.Cell>
                                        {dd.name}
                                    </Table.Cell>
                                    <Table.Cell>{dd.comments}</Table.Cell>
                                </Table.Row>
                            )}
                        </Table.Body>
                    </Table> : <div key={ti}/>
                )}

            </React.Fragment>
        );
    }
}

ReportLawyerComments.propTypes = {
    lawyerId: PropTypes.string.isRequired
};

function mapStateToProps(state) {
    return {...orgState(state), ...state.report};
}

const cReportLawyerComments = connect(mapStateToProps)(ReportLawyerComments);
export {cReportLawyerComments as ReportLawyerComments};
