import React, {Component} from 'react';
import {Button, Form, Header, Icon, Segment, Table} from "semantic-ui-react";
import {connect} from "react-redux";
import {OrgMenuBar} from "../../other/OrgMenuBar";
import evaluationQuestionAction from "../../../../../actions/evaluation_question.action";
import {QUESTION_SET} from "../../../../../helper/const";
import EditQuestionSetModal from "../../../form/questions/modal/EditQuestionSetModal";
import {orgState} from "../../../../../helper/stateUtil";
import Body from "../../../../other/Body";
import OrgSubHeader from "../../other/OrgSubHeader";
import helper, {log} from "../../../../../helper/helper";
import QMCheckBox from "../../../../other/form/QMCheckBox";

// This page is for list out all sets of evaluation questions
class OrgQuestionSetPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false, // flag for modal open and close
            set: null,
            filter: 0
        };
        this.onOpenModal = this.onOpenModal.bind(this);
    }

    onOpenModal(set) {
        if (set != null) {
            this.setState({set: set, open: true}); // for edit
        } else {
            this.props.history.push(`/orgadmin/evaluation-questions/add`);
            // this.setState({set: null, open: true, title: ""}); // for add
        }
    }

    componentDidMount() {
        this.getAll(this.state.filter);
    }

    getAll = filter => {
        this.props.dispatch(evaluationQuestionAction.readAllQuestionSets({
            org_id: this.props.org.id,
            filter: filter
        }));
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.props.eq.isAllowed) {
            this.props.history.replace("/forbidden");
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.eq.isDone) {
            this.setState({set: null, open: false});
        }
    }

    getEvalType = ({eval_type}) => {
        log(eval_type);
        const {org} = this.props;
        switch (eval_type) {
            case 1:
                return "Matter and Evaluatee";
            case 2:
                return org.str_client_firm;
            default:
                return "Unknown";
        }
    };

    render() {
        const {org} = this.props;
        const {loading, items, error} = this.props.eq;
        const {open, set, filter} = this.state;
        let content;
        if (items.length > 0) {
            content = (
                <Segment padded={"very"} basic style={{paddingTop: "0"}}>
                    <Table basic='very' selectable stackable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Question Set</Table.HeaderCell>
                                {filter === 0 && <Table.HeaderCell>Evaluation Category</Table.HeaderCell>}
                                <Table.HeaderCell>Set Type</Table.HeaderCell>
                                <Table.HeaderCell></Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {items.map((item, index) => {
                                return (
                                    <Table.Row className={"row"} key={index}>
                                        <Table.Cell style={{paddingLeft: "16px"}}
                                                    onClick={() => this.props.history.push(`/orgadmin/evaluation-questions/set/${item.id}?h=${item.name}`)}>
                                            <Icon name="list alternate outline" color={"grey"} size={"large"}/>
                                            <span><b>{item.name}</b></span>
                                        </Table.Cell>
                                        {filter === 0 && <Table.Cell>
                                            {this.getEvalType(item)}
                                        </Table.Cell>}
                                        <Table.Cell>
                                            {helper.getStrTypeOfQS(item.type)}
                                        </Table.Cell>
                                        <Table.Cell collapsing>
                                            <Form>
                                                <Form.Group inline>
                                                    <Form.Field>
                                                        <QMCheckBox checked={item.status}
                                                                    onChange={checked => {
                                                                        this.props.dispatch(evaluationQuestionAction.changeStatus({
                                                                            ...item,
                                                                            code: QUESTION_SET,
                                                                            org_id: this.props.org.id,
                                                                            status: checked
                                                                        }));
                                                                    }}/>
                                                    </Form.Field>
                                                    {!item.is_master && <Form.Field>
                                                        <Button icon={"pencil"} circular color={"grey"}
                                                                title={"Edit Question Set"} size={"mini"}
                                                                onClick={() => {
                                                                    this.onOpenModal(item)
                                                                }}/>
                                                    </Form.Field>}
                                                </Form.Group>
                                            </Form>
                                        </Table.Cell>
                                    </Table.Row>
                                );
                            })}
                        </Table.Body>
                    </Table>
                </Segment>
            );
        } else {
            content = (
                <Segment basic placeholder piled>
                    <Header icon>
                        <Icon name='file outline'/>
                        No evaluation question sets are listed.
                    </Header>
                    <Button color={"teal"} circular
                            onClick={() => this.onOpenModal(null)}>
                        Add Question Set
                    </Button>
                </Segment>
            );
        }
        return (
            <div>
                <Body loading={!open && loading}>

                    <OrgMenuBar/>

                    <OrgSubHeader title={`Evaluation Questions - ${this.props.org.name}`} backArrow>
                        <Button circular size={"medium"}
                                onClick={() => this.onOpenModal(null)}
                                title="Add User" floated="right" color={"teal"}>Add Question Set</Button>
                    </OrgSubHeader>

                    <Segment basic style={{margin: "1em 3em 0 3em"}}>
                        <Form>
                            <Form.Group>
                                <Form.Dropdown selection value={this.state.filter}
                                               label={"Question Category"}
                                               onChange={(e, {value}) => {
                                                   this.setState({filter: value});
                                                   this.getAll(value);
                                               }}
                                               name={"type"}
                                               options={[
                                                   {text: "All", value: 0},
                                                   {text: "Matter and Evaluatee", value: 1},
                                                   {text: `${org.str_client_firm}`, value: 2}
                                               ]}/>
                            </Form.Group>
                        </Form>
                    </Segment>
                    {content}

                </Body>

                {/* edit question set modal */}
                {open && <EditQuestionSetModal
                    error={error}
                    loading={loading}
                    set={set}
                    onSave={(value) => {
                        this.props.dispatch(evaluationQuestionAction.update({
                            code: QUESTION_SET,
                            org_id: this.props.org.id,
                            ...value
                        }));
                    }}
                    onClose={() => {
                        this.setState({open: false, set: null});
                    }}/>}
            </div>
        );
    }
}

function mapStateToProps(state) {
    let s = orgState(state);
    return {...s, eq: state.evaluation_question};
}

const cOrgQuestionSetPage = connect(mapStateToProps)(OrgQuestionSetPage);
export {cOrgQuestionSetPage as OrgQuestionSetPage};
