import React, {Component} from 'react';
import {connect} from "react-redux";
import {OrgMenuBar} from "../other/OrgMenuBar";
import OrgSubHeader from "../other/OrgSubHeader";
import {orgState} from "../../../../helper/stateUtil";
import Body from "../../../other/Body";
import EvaluationHeader from "./evaluation/common/EvaluationHeader";
import evaluationAction from "../../../../actions/evaluation.action";
import {Button, Grid, GridColumn, Segment, Table} from "semantic-ui-react";
import AnswerViewer from "../reports/components/answer/AnswerViewer";
import * as qs from "query-string";

class ViewEvaluationPage extends Component {
    constructor(props) {
        super(props);
        this.params = qs.parse(props.location.search);
    }

    componentDidMount() {
        const {org, match} = this.props;
        const {type} = this.params;
        this.props.dispatch(evaluationAction.viewEvaluation({
            org_id: org.id,
            id: match.params.id
        }, type));
    }

    render() {
        const {loading, evaluation, history} = this.props;
        let {questions} = this.props;
        const {type} = this.params;
        questions = questions.filter((q) => q.type !== 1);
        let que = questions.filter(q => q.type > 1);
        if (que.length > 0) {
            const wr = que.find(q => q.type === 2);
            if (wr) {
                if (wr.answer > 8) {
                    // answer is 9 or 10
                    questions = questions.filter(q => q.type !== 3);
                } else {
                    // answer is in between 1 to 8
                    questions = questions.filter(q => q.type !== 4);
                }
            }
        }
        return (
            <Body loading={loading}>

                <OrgMenuBar/>

                <OrgSubHeader backArrow title={"View Evaluations"}>
                    {evaluation.is_editable &&
                    <Button floated={'right'} circular color={'teal'}
                            onClick={() => history.replace(`/evaluation/${evaluation.id}?action=edit&type=${type}`)}>Edit</Button>}
                </OrgSubHeader>

                <EvaluationHeader
                    isView={true}
                    data={evaluation}/>

                <Segment basic padded textAlign={'center'}>
                    <Segment basic>
                        <Table>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>
                                        Question & Comments
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>Score</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {questions.map((d, ddi) =>
                                    <Table.Row key={ddi}>
                                        <Table.Cell colSpan={2}>
                                            <Grid>
                                                <GridColumn width={9}>
                                                    <b>Question:</b> {d.question}
                                                    {d.comments &&
                                                    <React.Fragment>
                                                        <br/>
                                                        <br/>
                                                        <b>Comments:</b> {d.comments}
                                                    </React.Fragment>}
                                                </GridColumn>
                                                <GridColumn width={7}>
                                                    <AnswerViewer
                                                        answer_type={d.answer_type}
                                                        answer={d.answer}
                                                        text_answer={d.text_answer}/>
                                                </GridColumn>
                                            </Grid>
                                        </Table.Cell>
                                    </Table.Row>
                                )}
                            </Table.Body>
                        </Table>
                    </Segment>
                </Segment>
            </Body>
        );
    }
}

function mapStateToProps(state) {
    let s = orgState(state);
    return {...s, ...state.evaluations};
}

const cViewEvaluationPage = connect(mapStateToProps)(ViewEvaluationPage);
export {cViewEvaluationPage as ViewEvaluationPage};