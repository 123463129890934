import React, {Component} from 'react';
import {connect} from "react-redux";
import {OrgMenuBar} from "../../other/OrgMenuBar";
import {AddPracticeAreaForm} from "../../../form/practiceareas/AddPracticeAreaForm";
import {orgState} from "../../../../../helper/stateUtil";
import Body from "../../../../other/Body";
import OrgSubHeader from "../../other/OrgSubHeader";

class OrgAddPracticeAreaPage extends Component {
    render() {
        const {loading, org} = this.props;
        return (
            <Body loading={loading}>
            <OrgMenuBar/>

            <OrgSubHeader title={'Add Practice Area'} backArrow/>

            <AddPracticeAreaForm orgid={org.id}/>

            </Body>
        );
    }
}

function mapStateToProps(state) {
    const s = orgState(state);
    return {
        ...s,
        ...state.practice
    };
}

const connectedOrgAddPracticeAreaPage = connect(mapStateToProps)(OrgAddPracticeAreaPage);
export {connectedOrgAddPracticeAreaPage as OrgAddPracticeAreaPage};