import practiceService from "../services/practice.service";

// common
function request() {
    return {type: PRACTICE.REQUEST};
}

function error(reason) {
    reason = reason.response;
    switch (reason.status) {
        case 422:
            return {type: PRACTICE.VALIDATION_ERROR, payload: reason.data};
        case 404:
            return {type: PRACTICE.NOT_FOUND};
        case 403:
            return {type: PRACTICE.NOT_ALLOWED};
        default:
            return {type: PRACTICE.ERROR, payload: reason.data};
    }
}

function create(data) {

    return dispatch => {
        dispatch(request());
        practiceService.add(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        if (res.status === 201) {
            return {type: PRACTICE.ADD_SUCCESS, payload: res.data};
        }
        return error(res);
    }

}

function update(data) {
    return dispatch => {
        dispatch(request());
        practiceService.update(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        if (res.status === 202) {
            return {type: PRACTICE.UPDATE_SUCCESS, payload: res.data};
        }
        return error(res);
    }
}

function readAll(data) {
    return dispatch => {
        dispatch(request());
        practiceService.readAll(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: PRACTICE.READ_ALL_SUCCESS, payload: res.data};
    }
}

function read(data) {
    return dispatch => {
        dispatch(request());
        practiceService.read(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: PRACTICE.READ_SUCCESS, payload: res.data};
    }
}

function changeStatus(data) {
    return dispatch => {
        dispatch(request());
        practiceService.changeStatus(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        if (res.status === 202) {
            return {type: PRACTICE.CHANGE_STATUS_SUCCESS, payload: data};
        }
        return error(res);
    }
}

const practiceAction = {
    create,
    update,
    readAll,
    read,
    changeStatus
};

// constants
export const PRACTICE = {

    REQUEST: "PRACTICE_REQUEST",
    ERROR: "PRACTICE_ERROR",
    NOT_FOUND: "PRACTICE_NOT_FOUND",
    VALIDATION_ERROR: "PRACTICE_VALIDATION_ERROR ",
    NOT_ALLOWED: "PRACTICE_NOT_ALLOWED",

    ADD_SUCCESS: "PRACTICE_ADD_SUCCESS",
    UPDATE_SUCCESS: "PRACTICE_UPDATE_SUCCESS",
    READ_ALL_SUCCESS: "PRACTICE_READ_ALL_SUCCESS",
    READ_SUCCESS: "PRACTICE_READ_SUCCESS",
    CHANGE_STATUS_SUCCESS: "PRACTICE_CHANGE_STATUS_SUCCESS"
};

export default practiceAction;