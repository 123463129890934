import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Container} from "semantic-ui-react";
import FullScreenLoader from "./FullScreenLoader";
import {OrgHeader} from "../pages/org/other/OrgHeader";
import PageFooter from "./PageFooter";

class Body extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const {loading, children, bgColor} = this.props;
        return (
            <div style={{backgroundColor: bgColor || "#f4f5f6"}}>
                <Container id={"container"}>
                    <div style={{minHeight: "91.8vh"}}>
                        <FullScreenLoader active={loading}/>

                        <OrgHeader/>

                        <div style={{marginBottom: "1em"}}>
                            {children}
                        </div>

                    </div>
                    <PageFooter/>
                </Container>
            </div>
        );
    }
}

Body.propTypes = {
    loading: PropTypes.bool.isRequired,
    bgColor: PropTypes.string
};

export default Body;
