import React, {Component} from 'react';
import SABody from "../qualmetadmin/other/SABody";
import SASubHeader from "../qualmetadmin/other/SASubHeader";
import {Button, Confirm, Form, Segment} from "semantic-ui-react";
import {connect} from "react-redux";
import appAction from "../../../actions/app.action";

class TermsOfUsePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            content: '',
            open: false
        };
    }

    componentDidMount() {
        this.props.dispatch(appAction.getTermsOfUse());
    }

    componentWillReceiveProps(nextProps) {
        const {isDone, content, history} = nextProps;
        if (isDone) {
            history.goBack();

        } else if (content) {
            this.setState({content: content});
        }
    }

    onAction = flag => {
        this.props.dispatch(appAction.updateTermsOfUse({content: this.state.content, flag}));
        this.setState({open: false});
    };

    render() {
        const {loading} = this.props;
        const {content, open} = this.state;
        return (
            <SABody loading={loading}>

                <SASubHeader title={"Terms of Use"}/>

                <Segment padded={'very'} basic>

                    <Form method={'post'}>

                        <Form.TextArea label={"Terms Of Use Content:"} rows={24} value={content}
                                       placeholder={"Write terms of use content here..."}
                                       onChange={e => this.setState({content: e.target.value})}/>

                        <Button floated={'right'} disabled={loading || !content} loading={loading} circular
                                onClick={() => this.setState({open: true})}
                                color={'teal'}>
                            Update
                        </Button>

                        <Confirm size="mini"
                                 content={"Do you want to force users to re-accept Terms of Use?"}
                                 open={open}
                                 cancelButton={"No"}
                                 confirmButton={"YES"}
                                 onCancel={() => this.onAction(false)} onConfirm={() => this.onAction(true)}/>

                    </Form>
                </Segment>

            </SABody>
        );
    }
}

export default connect(state => {
    return {...state.app};
})(TermsOfUsePage);
