import React, {Component} from 'react';
import {Button, Form, Header, Icon, Segment, Table} from "semantic-ui-react";
import {connect} from "react-redux";
import {OrgMenuBar} from "../../../../other/OrgMenuBar";
import ErrorMessage from "../../../../../../other/ErrorMessage";
import regionLocationAction from "../../../../../../../actions/region_location.action";
import Body from "../../../../../../other/Body";
import OrgSubHeader from "../../../../other/OrgSubHeader";
import {orgState} from "../../../../../../../helper/stateUtil";
import RegionAreaModal from "../modals/RegionAreaModal";
import qs from 'query-string';
import QMCheckBox from "../../../../../../other/form/QMCheckBox";

class OrgRegionAreasPage extends Component {
    constructor(props) {
        super(props);
        this.params = qs.parse(props.location.search);
        this.state = {
            open: false,
            item: null
        };
        this.onOpenModal = this.onOpenModal.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
    };

    onOpenModal = item => {
        this.setState({open: true, item: item});
    };

    onCloseModal = () => {
        this.setState({open: false, item: null});
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.isDone) {
            this.onCloseModal();
        }
    }

    componentDidMount() {
        this.props.dispatch(regionLocationAction.readAllRegionAreas({
            org_id: this.props.org.id,
            region_id: this.props.match.params.id
        }));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.props.isAllowed) {
            this.props.history.replace("forbidden");
        }
    }

    render() {
        const {loading, error, org, items} = this.props;
        const {open, item} = this.state;
        let content;
        if (items.length > 0) {
            content = (
                <Segment basic padded={"very"}>
                    <Table basic='very' selectable stackable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Region({this.params.r}) > Area</Table.HeaderCell>
                                <Table.HeaderCell></Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {items.map((area, index) => {
                                return (
                                    <Table.Row className={"row"} key={index}>
                                        <Table.Cell style={{paddingLeft: "16px"}}
                                                    onClick={() => this.props.history.push(`/orgadmin/regions/area/office/${area.id}?r=${encodeURIComponent(this.params.r)}&ra=${encodeURIComponent(area.title)}`)}>
                                            <Icon name={"map"}
                                                  color={"grey"}
                                                  size={"large"}/>
                                            <span><b>{area.title}</b></span>
                                        </Table.Cell>
                                        <Table.Cell collapsing>
                                            <Form>
                                                <Form.Group inline>
                                                    <Form.Field>
                                                        <QMCheckBox checked={area.status}
                                                                    onChange={checked => {
                                                                        this.props.dispatch(regionLocationAction.changeStatusOfRegionArea({
                                                                            org_id: org.id,
                                                                            region_id: this.props.match.params.id,
                                                                            id: area.id,
                                                                            status: checked
                                                                        }))
                                                                    }}/>
                                                    </Form.Field>
                                                    <Form.Field>
                                                        <Icon name={"pencil alternate"} circular inverted
                                                              color={"grey"} title={`Edit Region`}
                                                              onClick={() => this.onOpenModal(area)}/>
                                                    </Form.Field>
                                                </Form.Group>
                                            </Form>
                                        </Table.Cell>
                                    </Table.Row>
                                );
                            })}
                        </Table.Body>
                    </Table>
                </Segment>
            );
        } else {
            content = (
                <Segment basic placeholder piled>
                    <Header icon>
                        <Icon name='file outline'/>
                        No region areas are listed.
                    </Header>
                    <Button color={"teal"} circular onClick={() => this.onOpenModal(null)}>
                        Add Area
                    </Button>
                </Segment>
            );
        }
        return (
            <Body loading={loading}>

                <OrgMenuBar/>

                <OrgSubHeader backArrow title={`Regions - ${org.name}`}>
                    <Button circular size={"medium"}
                            onClick={() => this.onOpenModal(null)}
                            floated="right"
                            color={"teal"}>Add Area</Button>
                </OrgSubHeader>

                <ErrorMessage errors={error}/>

                {content}

                {/* add or edit region area modal*/}
                <RegionAreaModal
                    open={open}
                    errors={error}
                    area={item}
                    loading={loading}
                    onSubmit={(data) => {
                        if (item != null) {
                            // update region area
                            this.props.dispatch(regionLocationAction.updateRegionArea({
                                ...item,
                                org_id: org.id,
                                region_id: this.props.match.params.id,
                                title: data.title
                            }));
                        } else {
                            // add region area
                            this.props.dispatch(regionLocationAction.createRegionArea({
                                org_id: org.id,
                                region_id: this.props.match.params.id,
                                title: data.title
                            }));
                        }
                    }}
                    onClose={() => {
                        this.setState({open: false, item: null, is_office: false});
                    }}/>

            </Body>
        );
    }
}

function mapStateToProps(state) {
    let s = orgState(state);
    return {...s, ...state.region_location};
}

const cOrgRegionAreasPage = connect(mapStateToProps)(OrgRegionAreasPage);
export {cOrgRegionAreasPage as OrgRegionAreasPage};