import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Form, Message, Modal, Segment} from "semantic-ui-react";
import {EVALUATION_TYPE} from "../../../../../helper/const";

// props - error, loading, set, onClose, onSave
class EditQuestionSetModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: props.set.name,
            type: props.set.type
        };
    }

    render() {
        const {name, type} = this.state;
        const {error, loading, set, isFromMaterData} = this.props;
        return (
            <Modal size={"tiny"} open
                   closeOnDimmerClick={false}
                   onClose={() => this.onCloseModal()}>
                <Modal.Header>Edit Set</Modal.Header>
                <Modal.Content>
                    <Segment basic>
                        <Form>
                            <Message color='red' hidden={error.length === 0}>
                                <Message.List>
                                    {error.map((item, index) => <Message.Item key={index}>{item}</Message.Item>)}
                                </Message.List>
                            </Message>
                            <br/>
                            <Form.Field>
                                <label>Question set title:</label>
                                <input placeholder={`Question set title`}
                                       onChange={(e) => this.setState({name: e.target.value})}
                                       type="text" value={name}/>
                            </Form.Field>
                            {!isFromMaterData && <Form.Field>
                                <Form.Dropdown label='Question Set Type:'
                                               selection value={type}
                                               onChange={(e, {value}) => this.setState({type: value})}
                                               options={[
                                                   {
                                                       text: "External Evaluation",
                                                       value: EVALUATION_TYPE.EXTERNAL_EVALUATION
                                                   },
                                                   {text: "Self Evaluation", value: EVALUATION_TYPE.SELF_EVALUATION}
                                               ]}/>
                            </Form.Field>}
                        </Form>
                    </Segment>
                </Modal.Content>
                <Modal.Actions>
                    <Button secondary circular onClick={() => this.props.onClose()}
                            disabled={loading}>Cancel</Button>
                    <Button color={"teal"} circular content='Submit'
                            type="submit"
                            loading={loading}
                            onClick={() => {
                                set.name = name;
                                set.type = type;
                                this.props.onSave({
                                    ...set
                                });
                            }}
                            disabled={(loading) || (!name)}/>
                </Modal.Actions>
            </Modal>
        );
    }
}

EditQuestionSetModal.propTypes = {
    isFromMaterData: PropTypes.bool
};

export default EditQuestionSetModal;
