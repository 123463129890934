import React, {Component} from 'react';
import {connect} from "react-redux";
import {EditUserForm} from "../../form/users/EditUserForm";
import SABody from "../other/SABody";
import SASubHeader from "../other/SASubHeader";

class SuperAdminManageUserPage extends Component {
    render() {
        const {loading} = this.props;
        return (
            <SABody loading={loading}>
                <SASubHeader title={"Manage User"} backArrow/>
                <EditUserForm userid={this.props.match.params.id}/>
            </SABody>


        );
    }
}

function mapStateToProps(state) {
    return {
        ...state.user
    };
}

const cSuperAdminManageUserPage = connect(mapStateToProps)(SuperAdminManageUserPage);
export {cSuperAdminManageUserPage as SuperAdminManageUserPage};