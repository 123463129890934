// for auth json key for local storage
export const AUTH_KEY = "FI3CLG1684";

// for role json key for local storage
export const ROLE_KEY = "F2TN5U89TO";

// for storage of select org
export const ORG_KEY = "HZA06L6LIR";

// for storage of setup new org
export const NEW_ORG = "D8A9F6A8EF";

// for login another user while already logged in as another user specially for
// organization admin and super admin
export const SUB_USER = "KCI3FHBZ9L";

// role types
export const REPORT_VIEWER = "AA8Z6TK99S";
export const EVALUATOR = "ABI5CP7PYH";
export const MATTER = "RWKPDR9GW8";
export const ORG_ADMIN = "M059VZE27H";
export const SUPER_ADMIN = "EC56LG26IN";

// not official role but we use for navigate only
export const ANY_ROLE = "ANY_ROLE";

// code for identify question module component from server
export const QUESTION_SET = "qs";
export const QUESTION_SET_GROUP = "qsg";
export const QUESTION_SET_GROUP_QUESTION = "qsq";

// types of organization
export const ORG_TYPES = {
    CLIENT: 1,
    FIRM: 2
};

// plans of organization
export const ORG_PLANS = {
    LEVEL_1: 1
};

// evaluation cycles status
export const EVALUATION_STATUS = {
    ALL: -1,
    PENDING: 1,
    DUE: 2,
    STARTED: 3,
    COMPLETED: 4,
    REJECTED: 5,
};

// evaluation question answer type
export const E_QUESTION_TYPE = {
    // U = Unsatisfactory = N/A Exceptional
    ANSWER_TYPE_U_ENA: 1,
    // U = Unsatisfactory = Exceptional
    ANSWER_TYPE_U_E: 2,
    // Not at all Satisfied to Completely Satisfied
    ANSWER_TYPE_NS_S: 3,
    // NL = not likely EL= extremely likely
    ANSWER_TYPE_F_NL_EL: 4,

    ANSWER_TYPE_TEXT_ONLY: 5,

    ANSWER_TYPE_YES_NO: 6,
    // Low = 0 High = 10
    ANSWER_TYPE_LV_HV: 7,
};

// evaluation type
export const EVALUATION_TYPE = {
    // rep eval
    REP_EVAL: 3,
    // external evaluation
    EXTERNAL_EVALUATION: 2,
    // SELF EVALUATION
    SELF_EVALUATION: 1
};

// evaluation style
export const EVALUATION_STYLE = {
    MULTI_PAGE: 1,
    SINGLE_PAGE: 2
};

// bar chart type
export const BAR_CHART_TYPE = {
    ALL: 1,
    TOP_25: 2,
    MIDDLE_50: 3,
    BOTTOM_25: 4
};

// chart types
export const CHART_TYPES = {
    NONE: 0,
    HORIZONTAL_BAR_CHART: 1,
    SCATTER_PLOT_CHART: 2
};

// spend type
export const SPEND_FILTER = {
    NONE: 0,
    CLIENT_ESTIMATED_SPEND: 1,
    ESTIMATED_SPEND: 2,
    ACTUAL_SPEND: 3,
    SPEND_DELTA: 4
};

// final questions type
export const FINAL_QUESTION_TYPE = {
    GENERAL: 0,
    QUE_CONFIRMATION: 1,
    QUE_WOULD_RECOMMEND: 2,
    QUE_SUGGESTION_0_8: 3,
    QUE_SUGGESTION_9_10: 4,
    QUE_VALUE: 5
};

// help faqs - main categories
export const HELP_CATS = {
    USER_GUIDES: 1,
    VIDEOS: 2,
    FAQS: 3
};

// EVALUATOR & EVALUATEE limit while creating matter & firm evaluations
export const LIMIT = {
    EVALUATOR: 50,
    EVALUATEE: 50
};

export const PLAN_TYPE = {
    NORMAL: 1,
    TRIAL: 2,
    QUOTE: 3
};

export const PLAN_DURATION_TYPE = {
    DAY: 1,
    MONTH: 2,
    YEAR: 3
};

export const ORG_SUB_STATUS = {
    PENDING: 2,
    CANCELED: 3,
    DENIED: 4,
    REFUNDED: 5,
    INVOICED: 6,
    PAID: 7,
};
