import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Form, Header, Icon, Segment, Table} from "semantic-ui-react";
import helper from "../../../../helper/helper";
import {EVALUATION_STATUS} from "../../../../helper/const";

class ListLawyersEvaluations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            query: "",
            status: EVALUATION_STATUS.ALL
        };
    }

    render() {
        let {items, onClick, onClickOfNewEvaluation} = this.props;
        const {query, status} = this.state;
        if (status !== EVALUATION_STATUS.ALL) {
            items = items.filter((item) => item.status === status);
        }
        if (query.length > 0) {
            items = items.filter((item) => {
                const {name} = item;
                return (
                    name.toLowerCase().includes(query)
                );
            });
        }
        let content;
        if (items.length > 0) {
            content = (
                <Segment basic style={{padding: "0 3em"}}>
                    <Table basic='very' selectable stackable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>#</Table.HeaderCell>
                                <Table.HeaderCell>Evaluation Name</Table.HeaderCell>
                                <Table.HeaderCell>Total Cycles</Table.HeaderCell>
                                <Table.HeaderCell>Date Created</Table.HeaderCell>
                                <Table.HeaderCell>Status</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {items.map((item, index) => {
                                return (
                                    <Table.Row key={index} className={"row"} onClick={() => onClick(item)}>
                                        <Table.Cell>{index + 1}</Table.Cell>
                                        <Table.Cell>{item.name}</Table.Cell>
                                        <Table.Cell>{item.cycles}</Table.Cell>
                                        <Table.Cell>{item.date}</Table.Cell>
                                        <Table.Cell>{item.status ? "Open" : "Close"}</Table.Cell>
                                    </Table.Row>
                                );
                            })}
                        </Table.Body>
                    </Table>
                </Segment>
            );
        } else {
            content = (
                <Segment basic placeholder piled clearing>
                    <Header icon>
                        <Icon name='file outline'/>
                        No Evaluatees Evaluations are listed.
                    </Header>
                </Segment>
            );
        }

        return (
            <div>
                <Segment basic style={{padding: "2em 3em 1em 3em"}}>
                    <Form>
                        <Form.Group>
                            <Form.Input placeholder='Search' value={this.state.query}
                                        onChange={(e) => this.setState({query: e.target.value.toLowerCase()})}/>
                            <Form.Dropdown selection value={status}
                                           onChange={(e, {value}) => this.setState({status: value})}
                                           options={helper.matterOrClientStatus}/>
                            <Button circular color={'teal'} onClick={() => onClickOfNewEvaluation()}>New
                                Evaluation</Button>
                        </Form.Group>
                    </Form>
                </Segment>
                <br/>
                {content}
            </div>
        );
    }
}

ListLawyersEvaluations.propTypes = {
    items: PropTypes.array.isRequired,
    org: PropTypes.object.isRequired,
    onClick: PropTypes.func,
    onClickOfNewEvaluation: PropTypes.func,
};

export default ListLawyersEvaluations;
