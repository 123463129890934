import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Icon} from "semantic-ui-react";

class ReminderIcon extends Component {
    render() {
        return (
            <Icon {...this.props} link name={"mail"} size='large' onClick={() => this.props.onClick()}/>
        );
    }
}

ReminderIcon.propTypes = {
    onClick: PropTypes.func
};

export default ReminderIcon;
