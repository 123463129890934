import api from "../helper/api";

// ############################## regions ##############################
function addRegion(data) {
    return api.instance().post("org/region/store", data);
}

function updateRegion(data) {
    data._method = "PUT";
    return api.instance().put("org/region/update", data);
}

function readAllRegions(data) {
    return api.instance().post("org/region/get-regions", data);
}

function changeStatusOfRegion(data) {
    return api.instance().put("org/region/change-status", data);
}

// region area
function addRegionArea(data) {
    return api.instance().post("org/region-area/store", data);
}

function updateRegionArea(data) {
    data._method = "PUT";
    return api.instance().put("org/region-area/update", data);
}

function readAllRegionAreas(data) {
    return api.instance().post("org/region/get-region-areas", data);
}

function changeStatusOfRegionArea(data) {
    return api.instance().put("org/region-area/change-status", data);
}

// office
function addOffice(data) {
    return api.instance().post("org/office/store", data);
}

function updateOffice(data) {
    data._method = "PUT";
    return api.instance().put("org/office/update", data);
}

function readAllRegionAreaOffices(data) {
    return api.instance().post("org/region/get-region-area-offices", data);
}

function changeStatusOfOffice(data) {
    return api.instance().put("org/office/change-status", data);
}

// ############################## regions ##############################

// locations
function addLocation(data) {
    return api.instance().post("org/location/store", data);
}

function updateLocation(data) {
    data._method = "PUT";
    return api.instance().put("org/location/update", data);
}

function readAllLocations(data) {
    return api.instance().post("org/location/get-all", data);
}

function changeStatusOfLocation(data) {
    return api.instance().put("org/location/change-status", data);
}

// location area
function addLocationArea(data) {
    return api.instance().post("org/location-area/store", data);
}

function updateLocationArea(data) {
    data._method = "PUT";
    return api.instance().put("org/location-area/update", data);
}

function changeStatusOfLocationArea(data) {
    return api.instance().put("org/location-area/change-status", data);
}

const regionLocationService = {
    // region
    addRegion,
    updateRegion,
    readAllRegions,
    changeStatusOfRegion,

    // location
    addLocation,
    updateLocation,
    readAllLocations,
    changeStatusOfLocation,

    // region area
    addRegionArea,
    updateRegionArea,
    changeStatusOfRegionArea,
    readAllRegionAreas,

    // location area
    addLocationArea,
    updateLocationArea,
    changeStatusOfLocationArea,

    // office under region area
    addOffice,
    updateOffice,
    changeStatusOfOffice,
    readAllRegionAreaOffices
};

export default regionLocationService;