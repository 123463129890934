import React, {Component} from 'react';
import Body from "../other/Body";
import {connect} from "react-redux";
import OrgSubHeader from "./org/other/OrgSubHeader";
import {Grid, Message, Segment} from "semantic-ui-react";
import {UserInfo} from "./components/UserInfo";
import userAction from "../../actions/user.action";
import ErrorMessage from "../other/ErrorMessage";
import {UserChangePassword} from "./components/UserChangePassword";
import orgState from "../../helper/stateUtil";

class UserProfilePage extends Component {

    componentWillMount() {
        this.props.dispatch(userAction.getUserInfo({}));
    }

    render() {
        const {error, loading, user, isDone, org} = this.props;
        return (
            <Body loading={loading}>

                <OrgSubHeader title={"Profile"} backArrow/>

                <Segment basic padded={"very"}>
                    <ErrorMessage errors={error}/>
                    {isDone && <Message positive>
                        <Message.Header>Successfully Changed!</Message.Header>
                    </Message>}
                    <br/>
                    <Grid columns='equal'>
                        <Grid.Column>
                            <UserInfo data={user}/>
                        </Grid.Column>
                        <Grid.Column>
                            {!(org && org.sso_status) && <UserChangePassword data={user}/>}
                        </Grid.Column>
                    </Grid>
                </Segment>

            </Body>
        );
    }
}

function mapStateToProps(state) {
    return {...orgState(state), ...state.user};
}

const cUserProfilePage = connect(mapStateToProps)(UserProfilePage);
export {cUserProfilePage as UserProfilePage};
