import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Progress} from "semantic-ui-react";

class InfoProgress extends Component {
    render() {
        let {color, value, percentage} = this.props;
        if (typeof percentage === "string") {
            percentage = parseInt(percentage);
        }
        return (
            <div style={{display: 'flex', justifyContent: "space-between", marginTop: "0.5em"}}>
                <style>{`.ui.small.progress .bar { border-radius: 0.5em; background-color: ${color} }`}</style>
                <span style={{
                    padding: "0",
                    minWidth: "22px",
                    fontSize: "14px",
                    fontWeight: 600,
                    color: color,
                    textAlign: "right"
                }}>
                    {value || ""}
                    </span>
                <Progress percent={percentage}
                          size={'small'}
                          style={{margin: "auto 0.75em", width: "100%", borderRadius: "0.5em"}}/>
                <span style={{
                    padding: "0",
                    fontSize: "14px",
                    fontWeight: 600,
                    minWidth: "22px",
                    color: color
                }}>
                    {percentage && percentage.toFixed(0)}%
                </span>
            </div>
        );
    }
}

InfoProgress.defaultProps = {
    color: "#0674FD"
};

InfoProgress.propTypes = {
    value: PropTypes.any,
    percentage: PropTypes.any,
    color: PropTypes.string
};

export default InfoProgress;
