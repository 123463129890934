import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {orgState} from "../../../../../../helper/stateUtil";
import {connect} from "react-redux";
import {sort} from "../../utils";
import {BAR_CHART_TYPE} from "../../../../../../helper/const";
import AvgHorizontalBarChart from "../../components/barchart/AvgHorizontalBarChart";
import reportAction from "../../../../../../actions/report.action";
import ReportHeader from "../../components/header/ReportHeader";
import {Form, Grid, List, Table} from "semantic-ui-react";
import ReportSummary from "../../components/header/ReportSummary";
import DataFilter from "../../components/DataFilter";
import NoDataMessage from "../../components/other/NoDataMessage";
import DataTable from "../../components/DataTable";
import LabelCircle from "../../components/LabelCircle";
import FullScreenLoader from "../../../../../other/FullScreenLoader";

class ReportMatterSummary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            by_matrix: true,
            score: null,
            evaluatees: null
        };
        this.onSelect = this.onSelect.bind(this);
        this.onClick = this.onClick.bind(this);
        this.compileData = this.compileData.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        const {org, matterId} = this.props;
        this.props.dispatch(reportAction.getMatterSummaryAndFilters({
            matter_id: matterId,
            org_id: org.id,
            org
        }));
        this.props.dispatch(reportAction.getMatterReport({
            matter_id: matterId,
            by_matrix: true
        }));
    }

    componentWillReceiveProps(nextProps) {
        const {data, loadingData, loadingFiltersAndSummary, isDone} = nextProps;
        let s = {};
        if (!loadingData) {
            s.data = this.compileData(data);
        }
        if (!loadingFiltersAndSummary && isDone) {
            [
                'evaluatees',
                'score',
            ].forEach((item) => {
                if (nextProps[item] && nextProps[item].length > 0) {
                    s[item] = nextProps[item][0].value;
                }
            });
        }
        this.setState((state) => {
            return {...state, ...s};
        });
    }

    onChange = (_, {name, value}) => {
        this.setState({[name]: value});
        this.props.dispatch(reportAction.getMatterReport({
            org_id: this.props.org.id,
            matter_id: this.props.matterId,
            ...this.state,
            [name]: value
        }));
    };

    onClick = (item) => {
        this.setState((state) => {
            const {data} = state;
            const i = data.findIndex((d) => d.id === item.id);
            data[i].is_selected = !data[i].is_selected;
            return data;
        });
    };

    onSelect = (item, yIndex) => {

    };

    compileData = (data) => {
        if (data && Array.isArray(data)) {
            sort('score', data);
            data.forEach((item, index) => {
                item.id = index + 1;
                item.x = item.score;
                item.y = [];
                item.y[0] = {text: item.cycle};
                if (item.completed_on)
                    item.y[1] = {text: `Completed On ${item.completed_on}`};
            });
        }
        return data;
    };

    render() {
        const {score, evaluatees, data} = this.state;
        const {totals, summary, responses, loadingData, header, org} = this.props;
        let chart;
        if (!loadingData) {
            // bar chart
            chart = <AvgHorizontalBarChart
                data={data}
                type={BAR_CHART_TYPE.ALL}
                onClick={this.onClick}
                onSelect={this.onSelect}
                maxX={100}
                xTicks={10}
                xLabel={"Score"}
            />;
        }
        return (
            <React.Fragment>
                <FullScreenLoader active={loadingData}/>
                <ReportHeader>
                    <Grid columns={'equal'} stackable>
                        <Grid.Column>
                            <List>
                                <List.Item>Matter Name: <b>{header.matter_name}</b></List.Item>
                                <List.Item>{org.str_client_firm} Name: <b>{header.client_name}</b></List.Item>
                            </List>
                        </Grid.Column>
                        <Grid.Column>
                            Status: <b>{header.status ? "Open" : `Closed on ${header.closed_at}`}</b>
                        </Grid.Column>
                    </Grid>
                </ReportHeader>
                <ReportSummary qualmetScore={summary.qualmet_score}
                               overallSatisfaction={summary.overall_satisfaction}
                               valueForMoney={summary.value_for_money}
                               wouldRecommend={summary.would_recommend}
                               totals={totals}
                               diversityTracking={summary.diversity_tracking}
                               responses={responses}/>
                <DataFilter>
                    <Form.Group>
                        <Form.Field width={6}>
                            <Form.Dropdown
                                label={`By Score:`}
                                selection name={"score"}
                                value={score}
                                onChange={this.onChange}
                                options={this.props.score}/>
                        </Form.Field>
                        {(this.props.evaluatees) && <Form.Field width={6}>
                            <Form.Dropdown
                                label={`By Evaluatee:`}
                                value={evaluatees}
                                selection name={'evaluatees'}
                                onChange={this.onChange}
                                options={this.props.evaluatees}/>
                        </Form.Field>}
                    </Form.Group>
                </DataFilter>

                <NoDataMessage active={data.length === 0}/>

                {chart}

                {!loadingData && <DataTable headers={['ID', 'Cycle', 'Completed On', 'Score']}
                                            active={data.length > 0}>
                    {
                        data.map((d, i) =>
                            <Table.Row key={i} className={'row'} onClick={() => this.onClick(d)}>
                                <Table.Cell>
                                    <LabelCircle text={d.id} active={d.is_selected}/>
                                </Table.Cell>
                                <Table.Cell>{d.cycle}</Table.Cell>
                                <Table.Cell>{d.completed_on}</Table.Cell>
                                <Table.Cell>{d.score}</Table.Cell>
                            </Table.Row>)
                    }
                </DataTable>}
            </React.Fragment>
        );
    }
}

ReportMatterSummary.propTypes = {
    matterId: PropTypes.string.isRequired
};

function mapStateToProps(state) {
    return {...orgState(state), ...state.report};
}

const cReportMatterSummary = connect(mapStateToProps)(ReportMatterSummary);
export {cReportMatterSummary as ReportMatterSummary};
