import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {Button, Form, Header, Modal, Segment, Table} from "semantic-ui-react";
import {withRouter} from "react-router-dom";
import api from "../../../helper/api";

class NewEvaluationBtn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            query: "",
            loading: false,
            items: [], // firms or lawyers
            modal1: false, // open for select evaluation type
            modal2: false, // select firms or evaluatees
            type: -1, // -1 is null, 1 is firm and 2 is lawyer
        };
    }

    getItems = (type) => {
        let url;
        if (type === 1) {
            // firms
            url = "org/firm-evaluations/firms";
        } else if (type === 2) {
            // lawyers
            url = "org/lawyer-evaluations/lawyers";
        }
        api.instance()
            .post(url, {org_id: this.props.org.id})
            .then(value => {
                if (value && value.data && Array.isArray(value.data)) {
                    this.setState({items: value.data, loading: false});
                } else
                    this.setState({loading: false});
            })
            .catch(() => {
                this.setState({loading: false});
            });
    };

    close = (isEval) => {
        if (isEval) {
            this.setState({modal1: false, modal2: false, type: -1, loading: false, items: []});
        } else {
            this.setState({modal2: false, type: -1, loading: false, items: []});
        }
    };

    render() {
        const {history, org} = this.props;
        let {modal1, modal2, type, loading, items, query} = this.state;
        if (query) {
            items = items.filter(item => item && item.name && item.name.toLowerCase().includes(query.toLowerCase()));
        }
        return (
            <Fragment>
                <Button primary circular onClick={() => this.setState({modal1: true})}>
                    New Evaluation
                </Button>
                <Modal
                    open={modal1}
                    closeIcon
                    onClose={() => this.close(true)}
                    size='small'
                >
                    <Modal.Header>
                        Select from the following options:
                    </Modal.Header>
                    <Modal.Content>
                        <Table basic='very' padded stackable>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell textAlign={"center"}>
                                        <Button primary circular size='large'
                                                style={{width: "60%"}}
                                                onClick={() => {
                                                    history.push("/matters");
                                                    history.push("/matters/add");
                                                }}>
                                            Create evaluations for legal matters
                                        </Button>
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell textAlign={"center"}>
                                        <Button primary circular size='large'
                                                style={{width: "60%"}}
                                                onClick={() => {
                                                    this.setState({modal2: true, type: 1, loading: true});
                                                    this.getItems(1);
                                                }}>
                                            Create evaluations for {org.str_client_firm}
                                        </Button>
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell textAlign={"center"}>
                                        <Button primary circular size='large'
                                                style={{width: "60%"}}
                                                onClick={() => {
                                                    this.setState({modal2: true, type: 2, loading: true});
                                                    this.getItems(2);
                                                }}>
                                            Create evaluations for individuals (Evaluatees)
                                        </Button>
                                    </Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    </Modal.Content>
                </Modal>
                <Modal
                    open={modal2}
                    closeIcon
                    onClose={() => this.close(false)}
                    size='tiny'
                >
                    <Modal.Header>Select {type === 1 ? org.str_client_firm : "Evaluatee"}</Modal.Header>
                    <Modal.Content scrolling>
                        <Segment basic loading={loading}>
                            <Form>
                                <Form.Input placeholder={"Search"} value={query}
                                            onChange={e => this.setState({query: e.target.value})}/>
                            </Form>
                            <br/>
                            <Table basic='very' stackable selectable={items.length > 0}>
                                <Table.Body>
                                    {items.map((item, index) =>
                                        <Table.Row className={"row"} key={index} onClick={() => {
                                            if (type === 1) {
                                                history.push(`/clients-firms/${item.id}/evaluations?h=${encodeURIComponent(item.name)}`);
                                                history.push(`/clients-firms/${item.id}/new-evaluation?h=${encodeURIComponent(item.name)}`);
                                            } else if (type === 2) {
                                                history.push(`/lawyers/${item.id}/evaluations?h=${encodeURIComponent(item.name)}`);
                                                history.push(`/lawyers/${item.id}/new-evaluation?h=${encodeURIComponent(item.name)}`);
                                            }
                                        }}>
                                            <Table.Cell>
                                                <Header as={"h3"}>{item.name}</Header>
                                            </Table.Cell>
                                        </Table.Row>
                                    )}
                                    {items.length === 0 &&
                                    <Table.Row>
                                        <Table.Cell>
                                            <Header as={"h3"}>
                                                No {type === 1 ? org.str_client_firm : "Evaluatee"}s are listed
                                            </Header>
                                        </Table.Cell>
                                    </Table.Row>
                                    }
                                </Table.Body>
                            </Table>
                        </Segment>
                    </Modal.Content>
                </Modal>
            </Fragment>
        );
    }
}

NewEvaluationBtn.propTypes = {
    org: PropTypes.object.isRequired
};

export default withRouter(NewEvaluationBtn);
