import React, {Component} from 'react';
import {Button, Form, Message, Segment} from "semantic-ui-react";
import practiceAction from "../../../../actions/practice.action";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import QMCheckBox from "../../../other/form/QMCheckBox";

class EditPracticeAreaForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: "",
            status: true
        };
        this.onChange = this.onChange.bind(this);
        this.onSave = this.onSave.bind(this);
    }

    componentDidMount() {
        this.props.dispatch(practiceAction.read({org_id: this.props.orgid, id: this.props.pid}));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.isDone) {
            this.props.history.go(-1);
        }
        if (this.props.not_found) {
            this.props.history.replace("/not-found");
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.practice) {
            this.setState({
                title: nextProps.practice.title,
                status: nextProps.practice.status
            });
        }
    }

    onSave(e) {
        e.preventDefault();
        this.props.dispatch(practiceAction.update({
            org_id: this.props.orgid,
            id: this.props.pid,
            title: this.state.title,
            status: this.state.status
        }));
    }

    onChange(e) {
        this.setState({title: e.target.value});
    }

    render() {
        const {error} = this.props;
        return (
            <Segment basic padded='very'>
                <Form>
                    <Message color='red' hidden={error.length === 0}>
                        <Message.List>
                            {error.map((item, index) => <Message.Item key={index}>{item}</Message.Item>)}
                        </Message.List>
                    </Message>
                    <br/>
                    <Form.Field>
                        <label>Practice Area Title:</label>
                        <input placeholder='Practice Area Title' onChange={this.onChange} type="text"
                               value={this.state.title}/>
                    </Form.Field>
                    <Form.Field>
                        <label>Status:</label>
                        <QMCheckBox toggle onChange={(checked) => this.setState({status: checked})}
                                    checked={this.state.status}/>
                    </Form.Field>
                    <br/>
                    <br/>
                    <Button type='submit' primary onClick={this.onSave} floated='right' circular
                            disabled={!(this.state.title)}>Update</Button>
                    <Button type='button' secondary inverted floated='right' circular
                            onClick={() => this.props.history.go(-1)}>Cancel</Button>
                    <br/>
                </Form>
            </Segment>
        );
    }
}


function mapStateToProps(state) {
    return {
        ...state.practice
    };
}

const cEditPracticeAreaForm = withRouter(connect(mapStateToProps)(EditPracticeAreaForm));
export {cEditPracticeAreaForm as EditPracticeAreaForm};
