import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Form} from "semantic-ui-react";
import {connect} from "react-redux";

class ClientEvalCommentForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            comment: ""
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.isDone) {
            this.setState({
                comment: ""
            });
        }
    }

    render() {
        const {loading, onSubmit} = this.props;
        const {comment} = this.state;
        return (
            <Form reply>
                <Form.TextArea placeholder={"comment..."} value={comment}
                               onChange={(e) => this.setState({comment: e.target.value})}/>
                <Button content='Clear' labelPosition='left' icon='eraser' secondary basic circular
                        disabled={loading || comment.length === 0} onClick={() => this.setState({comment: ""})}/>
                <Button content='Add Comment' labelPosition='left' icon='edit' primary circular
                        loading={loading}
                        disabled={loading || comment.length === 0} onClick={() => onSubmit(this.state)}/>
            </Form>
        );
    }
}

ClientEvalCommentForm.propTypes = {
    loading: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {...state.matter};
}

const cClientEvalCommentForm = connect(mapStateToProps)(ClientEvalCommentForm);
export {cClientEvalCommentForm as ClientEvalCommentForm};
