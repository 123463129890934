import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Message} from "semantic-ui-react";

class SuccessMessage extends Component {
    render() {
        const {messages} = this.props;
        let content;
        if (messages.length > 0) {
            content = (
                <div>
                    <Message positive hidden={messages.length === 0}>
                        <Message.List>
                            {messages.map((item, index) => <Message.Item key={index}>{item}</Message.Item>)}
                        </Message.List>
                    </Message>
                    <br/>
                </div>
            );
        }
        return (<div>{content}</div>);
    }
}

SuccessMessage.propTypes = {
    messages: PropTypes.array.isRequired
};

export default SuccessMessage;
