import React, {Component} from 'react';
import {Button, Form, Header, Icon, Segment, Table} from "semantic-ui-react";
import {connect} from "react-redux";
import {OrgMenuBar} from "../../other/OrgMenuBar";
import clientAction from "../../../../../actions/client.action";
import {orgState} from "../../../../../helper/stateUtil";
import OrgSubHeader from "../../other/OrgSubHeader";
import Body from "../../../../other/Body";
import QMCheckBox from "../../../../other/form/QMCheckBox";

class OrgClientsPage extends Component {

    componentDidMount() {
        this.props.dispatch(clientAction.readAll({org_id: this.props.org.id}));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.props.client.isAllowed) {
            this.props.history.replace("/forbidden");
        }
    }

    render() {
        const {org} = this.props;
        const {loading, clients} = this.props.client;

        let content;
        if (clients.length > 0) {
            content = (
                <Segment padded={"very"} basic>
                    <Table basic='very' selectable stackable>
                        <Table.Body>
                            {clients.map((client, index) => {
                                return (
                                    <Table.Row className={"row"} key={index}>
                                        <Table.Cell style={{paddingLeft: "16px"}}
                                                    onClick={() => this.props.history.push(`/orgadmin/client_firm/manage/${client.id}`)}>
                                            <Icon name="group" color={"grey"} size={"large"}/>
                                            <span><b>{client.name}</b></span>
                                        </Table.Cell>
                                        <Table.Cell collapsing>
                                            <Form>
                                                <Form.Group inline>
                                                    <Form.Field>
                                                        <QMCheckBox checked={client.status}
                                                                    onChange={checked => {
                                                                        this.props.dispatch(clientAction.changeStatus({
                                                                            id: client.id,
                                                                            status: checked
                                                                        }));
                                                                    }}/>
                                                    </Form.Field>
                                                </Form.Group>
                                            </Form>
                                        </Table.Cell>
                                    </Table.Row>
                                );
                            })}
                        </Table.Body>
                    </Table>
                </Segment>
            );
        } else {
            content = (
                <Segment basic placeholder piled>
                    <Header icon>
                        <Icon name='file outline'/>
                        No {org.str_client_firm}s are listed.
                    </Header>
                    <Button color={"teal"} circular
                            onClick={() => this.props.history.push(`/orgadmin/client_firm/add`)}>
                        Add {org.str_client_firm}
                    </Button>
                </Segment>
            );
        }
        return (
            <Body loading={loading}>

                <OrgMenuBar/>

                <OrgSubHeader title={`${org.str_client_firm}s - ${this.props.org.name}`} backArrow>
                    <Button circular size={"medium"}
                            onClick={() => this.props.history.push(`/orgadmin/client_firm/add`)}
                            title="Add User" floated="right" color={"teal"}>Add {org.str_client_firm}</Button>
                </OrgSubHeader>

                {content}

            </Body>
        );
    }
}

function mapStateToProps(state) {
    let s = orgState(state);
    return {...s, client: state.client};
}

const connectedOrgClientsPage = connect(mapStateToProps)(OrgClientsPage);
export {connectedOrgClientsPage as OrgClientsPage};