import api from "../helper/api";

function add(data) {
    return api.instance().post("org/matters/store", data);
}

function update(data) {
    data._method = "PUT";
    return api.instance().put("org/matters/update", data);
}

function readAll(data) {
    return api.instance().post("org/matters/get-all", data);
}

function read(data) {
    return api.instance().post("org/matters/get-matter-details", data);
}

function readSummary(data) {
    return api.instance().post(`org/matters/get-matter-summary`, data);
}

function changeStatus(data) {
    return api.instance().put("org/matters/change-status", data);
}

function getMasterData(data) {
    return api.instance().post("org/matters/master-data", data);
}

function getComments(data) {
    return api.instance().post("org/matters/get-comments", data);
}

function addComments(data) {
    return api.instance().post("org/matters/add-comment", data);
}

function getEvaluations(data) {
    return api.instance().post("org/matters/evaluations", data);
}

function getMyEvaluations(data) {
    return api.instance().post("org/matters/my-evaluations", data);
}

function rejectEvaluation(data, type) {
    let url;
    if (type === 'lawyer') {
        url = "org/lawyer-evaluations/reject-evaluation";
    } else if (type === 'firm') {
        url = "org/firm-evaluations/reject-evaluation";
    } else {
        url = "org/matters/reject-evaluation";
    }
    return api.instance().post(url, data);
}

function resendReminder(data, type) {
    let url;
    if (type === 'lawyer') {
        url = "org/lawyer-evaluations/send-evaluation-reminder";
    } else if (type === 'firm') {
        url = "org/firm-evaluations/send-evaluation-reminder";
    } else {
        url = "org/matters/send-evaluation-reminder";
    }
    return api.instance().post(url, data);
}

function getAllMattersEvaluationsCycles(data) {
    return api.instance().post("org/matters/all-evaluations", data);
}

function getDiversityData(data) {
    return api.instance().post("org/matters/get-diversity-score", data);
}

function updateDiversityData(data) {
    data._method = "PUT";
    return api.instance().put("org/matters/update-diversity-score", data);
}

// Client Evaluation
function getAllCommentsOfClientEval(data) {
    return api.instance().post("org/firm-evaluations/get-comments", data);
}

function addCommentOfClientEval(data) {
    return api.instance().post("org/firm-evaluations/add-comment", data);
}

function getDiversityDataOfClientEval(data) {
    return api.instance().post("org/firm-evaluations/get-diversity-score", data);
}

function updateDiversityDataOfClientEval(data) {
    data._method = "PUT";
    return api.instance().put("org/firm-evaluations/update-diversity-score", data);
}

function getClientEvalEvaluations(data) {
    return api.instance().post("org/firm-evaluations/get-current-cycles", data);
}

function readMasterDataOfClientEval(data) {
    return api.instance().post("org/firm-evaluations/master-data", data);
}

function createClientEval(data) {
    return api.instance().post("org/firm-evaluations/store", data);
}

function readOfClientEval(data) {
    return api.instance().post("org/firm-evaluations/get-details", data);
}

function updateOfClientEval(data) {
    data._method = "PUT";
    return api.instance().put("org/firm-evaluations/update", data);
}

function readSummaryOfClientEval(data) {
    return api.instance().post(`org/firm-evaluations/summary`, data);
}

// Lawyer Evaluation
function getAllCommentsOfLawyerEval(data) {
    return api.instance().post("org/lawyer-evaluations/get-comments", data);
}

function addCommentOfLawyerEval(data) {
    return api.instance().post("org/lawyer-evaluations/add-comment", data);
}

function getDiversityDataOfLawyerEval(data) {
    return api.instance().post("org/lawyer-evaluations/get-diversity-score", data);
}

function updateDiversityDataOfLawyerEval(data) {
    data._method = "PUT";
    return api.instance().put("org/lawyer-evaluations/update-diversity-score", data);
}

function getLawyerEvalEvaluations(data) {
    return api.instance().post("org/lawyer-evaluations/get-current-cycles", data);
}

function readMasterDataOfLawyerEval(data) {
    return api.instance().post("org/lawyer-evaluations/master-data", data);
}

function createLawyerEval(data) {
    return api.instance().post("org/lawyer-evaluations/store", data);
}

function readOfLawyerEval(data) {
    return api.instance().post("org/lawyer-evaluations/get-details", data);
}

function updateOfLawyerEval(data) {
    data._method = "PUT";
    return api.instance().put("org/lawyer-evaluations/update", data);
}

function readSummaryOfLawyerEval(data) {
    return api.instance().post(`org/lawyer-evaluations/summary`, data);
}

// close matter, firm evaluation, evaluatee evaluation
function close(data, type) {
    let url = "";
    // eslint-disable-next-line default-case
    switch (type) {
        case "matter":
            url = "org/matters/close";
            break;
        case "firm":
            url = "org/firm-evaluations/close";
            break;
        case 'lawyer':
            url = "org/lawyer-evaluations/close";
            break;
    }
    return api.instance().post(url, data);
}

const matterService = {
    add,
    update,
    readAll,
    read,
    readSummary,
    changeStatus,
    getMasterData,
    getComments,
    addComments,
    getEvaluations,
    getMyEvaluations,
    rejectEvaluation,
    resendReminder,
    getAllMattersEvaluationsCycles,
    getDiversityData,
    updateDiversityData,

    // Client Evaluation
    getAllCommentsOfClientEval,
    addCommentOfClientEval,
    getDiversityDataOfClientEval,
    updateDiversityDataOfClientEval,
    getClientEvalEvaluations,
    readMasterDataOfClientEval,
    createClientEval,
    readOfClientEval,
    updateOfClientEval,
    readSummaryOfClientEval,

    // Lawyer Evaluation
    getAllCommentsOfLawyerEval,
    addCommentOfLawyerEval,
    getDiversityDataOfLawyerEval,
    updateDiversityDataOfLawyerEval,
    getLawyerEvalEvaluations,
    readMasterDataOfLawyerEval,
    createLawyerEval,
    readOfLawyerEval,
    updateOfLawyerEval,
    readSummaryOfLawyerEval,

    close
};

export default matterService;