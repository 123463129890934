import React, {Component} from 'react';
import {connect} from "react-redux";
import {PLAN_DURATION_TYPE, PLAN_TYPE} from "../../../../helper/const";
import SABody from "../../qualmetadmin/other/SABody";
import SASubHeader from "../../qualmetadmin/other/SASubHeader";
import {Button, Form, Header, Input, Label, Segment, Table} from "semantic-ui-react";
import ErrorMessage from "../../../other/ErrorMessage";
import QMCheckBox from "../../../other/form/QMCheckBox";
import helper from "../../../../helper/helper";
import planAction from "../../../../actions/plan.action";

class AddPlanPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: '',
            price_title: '',
            price: '',
            features: [],
            feature: '',
            type: PLAN_TYPE.NORMAL,
            status: true,
            is_custom_plan: false,
            no_users: "",
            duration: '',
            duration_type: 3,
            org_id: null,
            is_unlimited_users: false
        };
        this.onChange = this.onChange.bind(this);
        this.onSave = this.onSave.bind(this);
        this.value = 1;
    }

    onChange = e => {
        this.setState({[e.target.name]: e.target.value});
    };

    onSave = e => {
        e.preventDefault();
        this.props.dispatch(planAction.create(this.state));
    };

    componentWillReceiveProps(nextProps) {
        const {isDone, history} = nextProps;
        if (isDone) {
            history.goBack();
        }
    }

    // componentDidMount() {
    //     log("ADD MOUNT");
    //     // this.props.dispatch(planAction.getAllOrg());
    // }

    componentWillUnmount() {
        this.props.dispatch(planAction.unmount());
    }

    render() {
        const {loading, error} = this.props;
        const {title, price_title, price, features, feature, type, status, no_users, duration, duration_type, is_unlimited_users} = this.state;
        return (
            <SABody loading={loading}>

                <SASubHeader title={"Add Plan"} backArrow/>

                <Segment basic padded='very'>
                    <Form>
                        <ErrorMessage errors={error}/>

                        <Form.Field width={8}>
                            <label>Plan Title:</label>
                            <input placeholder={`e.g. Team`} name={"title"} value={title}
                                   onChange={this.onChange} type="text"/>
                        </Form.Field>
                        <Form.Field width={8}>
                            <label>Price Title:</label>
                            <input placeholder={`e.g. $3,500 / year`} name={"price_title"} value={price_title}
                                   onChange={this.onChange} type="text"/>
                        </Form.Field>
                        <Form.Input label={"Price"} placeholder={"e.g. 3500"} name={'price'}
                                    width={8} value={price} iconPosition='left' icon={'dollar'}
                                    onChange={this.onChange}/>
                        <Form.Group>
                            <Form.Dropdown
                                label='Plan Type:' width={4}
                                selection value={type}
                                onChange={(e, {value}) => this.setState({type: value})}
                                options={helper.pricePlanTypes}/>
                            {/*<Form.Dropdown disabled={type !== PLAN_TYPE.QUOTE}*/}
                            {/*               label='Organization:' width={4} search clearable*/}
                            {/*               selection value={this.state.org_id}*/}
                            {/*               onChange={(e, {value}) => this.setState({org_id: value})}*/}
                            {/*               options={orgs || []}/>*/}
                        </Form.Group>
                        <Form.Group>
                            <Form.Input label={"Up to no of users"} placeholder={"e.g. 10"} name={'no_users'}
                                        width={4} value={no_users} disabled={is_unlimited_users}
                                        onChange={this.onChange}/>
                            <Form.Field>
                                <label>Unlimited Users:</label>
                                <QMCheckBox type={3} toggle
                                            onChange={(checked) => this.setState({is_unlimited_users: checked})}
                                            checked={is_unlimited_users}/>
                            </Form.Field>
                        </Form.Group>
                        <Form.Group>
                            <Form.Input label={"Plan Duration"} placeholder={"e.g. 30"} name={'duration'}
                                        width={4} value={duration}
                                        onChange={this.onChange}/>
                            <Form.Dropdown label={`Plan Duration Type`}
                                           width={4}
                                           value={duration_type}
                                           search selection
                                           onChange={(_, {value}) => {
                                               this.setState({duration_type: value});
                                           }}
                                           options={[
                                               {text: "Day(s)", value: PLAN_DURATION_TYPE.DAY},
                                               {text: "Month(s)", value: PLAN_DURATION_TYPE.MONTH},
                                               {text: "Year(s)", value: PLAN_DURATION_TYPE.YEAR},
                                           ]}/>
                        </Form.Group>
                        <Form.Field>
                            <label>Status:</label>
                            <QMCheckBox toggle onChange={(checked) => this.setState({status: checked})}
                                        checked={status}/>
                        </Form.Field>

                        <br/>
                        <Header as={'h3'}>Plan Features: </Header>
                        <br/>
                        <Form.Field width={8}>
                            <Table basic stackable>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>#</Table.HeaderCell>
                                        <Table.HeaderCell>Feature Title</Table.HeaderCell>
                                        <Table.HeaderCell>Actions</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {features.map((item, index) => {
                                        return (
                                            <Table.Row className={"row"} key={index}>
                                                <Table.Cell>
                                                    {index + 1}
                                                </Table.Cell>
                                                <Table.Cell>{item.text}</Table.Cell>
                                                <Table.Cell>
                                                    <Label color={'red'}
                                                           onClick={() => this.setState((state) => {
                                                               return {
                                                                   features: [...state.features.filter((_, i) => i !== index)]
                                                               }
                                                           })}>Delete</Label>
                                                </Table.Cell>
                                            </Table.Row>
                                        );
                                    })}
                                </Table.Body>
                            </Table>
                        </Form.Field>
                        <Form.Field width={8}>
                            <label>Add Plan Feature:</label>
                            <Input name={'feature'}
                                   action={<Button disabled={!feature}
                                                   onClick={() => this.setState((state) => {
                                                       return {
                                                           features: [...state.features, {
                                                               text: feature,
                                                               value: this.value++
                                                           }],
                                                           feature: ''
                                                       }
                                                   })}>Add</Button>}
                                   placeholder='e.g. Up to 10 Users' value={feature}
                                   onChange={this.onChange}/>
                        </Form.Field>
                        <br/>
                        <Button type='submit' primary onClick={this.onSave} floated='right' circular
                                disabled={!(title && price_title && features.length > 0)}>Create</Button>
                        <Button type='button' secondary inverted floated='right' circular
                                onClick={() => this.props.history.go(-1)}>Cancel</Button>
                        <br/>
                    </Form>
                </Segment>

            </SABody>
        );
    }
}

function mapStateToProps(state) {
    return {...state.plan};
}

const cAddPlanPage = connect(mapStateToProps)(AddPlanPage);
export {cAddPlanPage as AddPlanPage};