import api from "../helper/api";

function read(data) {
    return api.instance().post("org/get-setup-checklist", data);
}

function update(data) {
    return api.instance().post("org/store-setup-checklist", data);
}

const checklistService = {
    update,
    read,
};

export default checklistService;