import React, {Component} from 'react';
import {Header, Icon, Segment} from "semantic-ui-react";
import PropTypes from 'prop-types';
import {withRouter} from "react-router-dom";

class SASubHeader extends Component {
    render() {
        const {title, backArrow, children, history} = this.props;
        let s = {backgroundColor: "#e5e5e5"};
        return (
            <Segment basic style={s} className={"mini padded"}>
                {children}
                <Header as="h3" style={{margin: `0.1em auto`}}>
                    {backArrow && <Icon name="arrow left" size='tiny' link onClick={() => history.go(-1)}/>}
                    {title}
                </Header>
            </Segment>
        );
    }
}

SASubHeader.defaultProps = {
    backArrow: true
};

SASubHeader.propTypes = {
    title: PropTypes.string.isRequired,
    backArrow: PropTypes.bool
};

export default withRouter(SASubHeader);

