import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {orgState} from "../../../../../../helper/stateUtil";
import {connect} from "react-redux";
import {sort} from "../../utils";
import {BAR_CHART_TYPE} from "../../../../../../helper/const";
import AvgHorizontalBarChart from "../../components/barchart/AvgHorizontalBarChart";
import reportAction from "../../../../../../actions/report.action";
import ReportHeader from "../../components/header/ReportHeader";
import {Form, Grid, List, Table} from "semantic-ui-react";
import ReportSummary from "../../components/header/ReportSummary";
import DataFilter from "../../components/DataFilter";
import NoDataMessage from "../../components/other/NoDataMessage";
import DataTable from "../../components/DataTable";
import LabelCircle from "../../components/LabelCircle";
import {withRouter} from "react-router-dom";
import FullScreenLoader from "../../../../../other/FullScreenLoader";

class ReportClientSummary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            by_matrix: true,
            score: null,
            evaluatees: null,
            cycles: null,
            practice_areas: null,
            status: null,
            questions: null
        };
        this.onSelect = this.onSelect.bind(this);
        this.onClick = this.onClick.bind(this);
        this.compileData = this.compileData.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        const {org, clientId} = this.props;
        this.props.dispatch(reportAction.getClientSummaryAndFilters({
            client_id: clientId,
            org_id: org.id,
            org
        }));
        this.props.dispatch(reportAction.getClientReport({
            client_id: clientId,
            by_matrix: true
        }));
    }

    componentWillReceiveProps(nextProps) {
        const {data, loadingData, loadingFiltersAndSummary, isDone} = nextProps;
        let s = {};
        if (!loadingData) {
            s.data = this.compileData(data);
        }
        if (!loadingFiltersAndSummary && isDone) {
            [
                'cycles',
                'practice_areas',
                'status',
                'evaluatees',
                'score',
                'questions'
            ].forEach((item) => {
                if (nextProps[item] && nextProps[item].length > 0) {
                    s[item] = nextProps[item][0].value;
                }
            });
        }
        this.setState((state) => {
            return {...state, ...s};
        });
    }

    onSwitchMatrixToQuestion = () => {
        this.setState((state) => ({by_matrix: !state.by_matrix}));
        this.props.dispatch(reportAction.getClientReport({
            org_id: this.props.org.id,
            client_id: this.props.clientId,
            ...this.state,
            by_matrix: !this.state.by_matrix
        }));
    };

    onChange = (_, {name, value}) => {
        this.setState({[name]: value});
        this.props.dispatch(reportAction.getClientReport({
            org_id: this.props.org.id,
            client_id: this.props.clientId,
            ...this.state,
            [name]: value
        }));
    };

    onClick = (item) => {
        this.setState((state) => {
            const {data} = state;
            const i = data.findIndex((d) => d.id === item.id);
            data[i].is_selected = !data[i].is_selected;
            return data;
        });
    };

    onSelect = (item, yIndex) => {
        // const {history} = this.props;
        switch (yIndex) {
            case 0:
                // history.push(`${reportsPath}/matters/${item.y[yIndex].value}/summary`);
                break;
            default:
                break;
        }
    };

    compileData = (data) => {
        if (data && Array.isArray(data)) {
            sort('score', data);
            data.forEach((item, index) => {
                item.id = index + 1;
                item.x = item.score;
                item.y = [];
                item.y[0] = {text: item.matter_name, value: item.matter_id};
            });
        }
        return data;
    };

    render() {
        const {data, score, cycles, practice_areas, status} = this.state;
        const {totals, summary, responses, loadingData, header, org} = this.props;
        let chart;
        if (!loadingData) {
            // bar chart
            chart = <AvgHorizontalBarChart
                data={data}
                type={BAR_CHART_TYPE.ALL}
                onClick={this.onClick}
                onSelect={this.onSelect}
                maxX={100}
                xTicks={10}
                xLabel={"Score"}
            />;
        }
        return (
            <React.Fragment>
                <FullScreenLoader active={loadingData}/>
                <ReportHeader>
                    <Grid columns={'equal'} stackable>
                        <Grid.Column>
                            <List>
                                <List.Item>{org.str_client_firm} Name: <b>{header && header.name}</b></List.Item>
                            </List>
                        </Grid.Column>
                    </Grid>
                </ReportHeader>
                <ReportSummary qualmetScore={summary.qualmet_score}
                               overallSatisfaction={summary.overall_satisfaction}
                               valueForMoney={summary.value_for_money}
                               wouldRecommend={summary.would_recommend}
                               totals={totals}
                               diversityTracking={summary.diversity_tracking}
                               responses={responses}/>
                <DataFilter>
                    <Form.Group>
                        <Form.Field>
                            <Form.Dropdown
                                label={`By Score:`}
                                selection name={"score"}
                                value={score}
                                onChange={this.onChange}
                                options={this.props.score}/>
                        </Form.Field>
                        <Form.Field>
                            <Form.Dropdown
                                label={`By Cycle:`}
                                value={cycles}
                                selection name={'cycles'}
                                onChange={this.onChange}
                                options={this.props.cycles}/>
                        </Form.Field>
                        <Form.Field>
                            <Form.Dropdown
                                label={`By Practice Area:`}
                                selection name={'practice_areas'}
                                value={practice_areas}
                                onChange={this.onChange}
                                options={this.props.practice_areas}/>
                        </Form.Field>
                        <Form.Field>
                            <Form.Dropdown
                                label={`By Status:`}
                                selection name={"status"}
                                value={status}
                                onChange={this.onChange}
                                options={this.props.status}/>
                        </Form.Field>
                    </Form.Group>
                    {/*<Form.Field>*/}
                        {/*{!by_matrix && <Form.Dropdown*/}
                            {/*label={`By Evaluation Question:`}*/}
                            {/*selection name={'questions'}*/}
                            {/*value={questions}*/}
                            {/*onChange={this.onChange}*/}
                            {/*options={this.props.questions}/>}*/}
                        {/*<label style={{color: color.light_green, cursor: "pointer"}}*/}
                               {/*onClick={this.onSwitchMatrixToQuestion}>*/}
                            {/*{!by_matrix ? "Ranking by Qualmet Metrics" : "Ranking by Individual Questions"}</label>*/}
                    {/*</Form.Field>*/}
                </DataFilter>

                <NoDataMessage active={data.length === 0}/>

                {chart}

                {!loadingData && <DataTable headers={['ID', 'Name', 'Score']}
                                            active={data.length > 0}>
                    {
                        data.map((d, i) =>
                            <Table.Row key={i} className={'row'} onClick={() => this.onClick(d)}>
                                <Table.Cell>
                                    <LabelCircle text={d.id} active={d.is_selected}/>
                                </Table.Cell>
                                <Table.Cell>{d.matter_name}</Table.Cell>
                                <Table.Cell>{d.score}</Table.Cell>
                            </Table.Row>)
                    }
                </DataTable>}
            </React.Fragment>
        );
    }
}

ReportClientSummary.propTypes = {
    clientId: PropTypes.string.isRequired
};

function mapStateToProps(state) {
    return {...orgState(state), ...state.report};
}

const cReportClientSummary = withRouter(connect(mapStateToProps)(ReportClientSummary));
export {cReportClientSummary as ReportClientSummary};
