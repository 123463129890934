import authService from "../services/auth.service";
import helper from "../helper/helper";
import storage from "../helper/storage";
import ReactGA from 'react-ga';
import {ORG_KEY} from "../helper/const";

function login(email, password) {
    return dispatch => {
        dispatch(request());
        authService.login({
            email: email,
            password: password
        }).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function request() {
        return {type: AUTH.REQUEST};
    }

    function success(res) {
        if (res.status === 202) {
            helper.storeLogin(res.data);
            ReactGA.set({
                email: res.data.email,
                name: res.data.email,
                is_sso_login: false
            });
            return {type: AUTH.LOGIN_SUCCESS, payload: res.data};
        }
        return error(res);
    }

    function error(res) {
        res = res.response;
        switch (res.status) {
            case 400:
                return {type: AUTH.ACTION, payload: res.data};
            case 401:
                return {type: AUTH.ERROR, payload: [res.data]};
            case 403:
                return {type: AUTH.ERROR, payload: [res.data]};
            case 422:
                return {type: AUTH.VALIDATION_ERROR, payload: res.data};
            case 404:
                return {type: AUTH.ERROR, payload: ["Not found!"]};
            default:
                return {type: AUTH.ERROR, payload: ["Something went wrong, please try again later..."]};
        }
    }
}

function loginWithToken(data) {
    return dispatch => {
        dispatch(request());
        authService.loginWithToken(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function request() {
        return {type: AUTH.REQUEST};
    }

    function success(res) {
        if (res.status === 202) {
            helper.storeLogin(res.data);
            ReactGA.set({
                email: res.data.email,
                name: res.data.email,
                is_sso_login: true
            });
            return {type: AUTH.LOGIN_SUCCESS, payload: res.data};
        }
        return error(res);
    }

    function error(res) {
        res = res.response;
        switch (res.status) {
            case 400:
                return {type: AUTH.ACTION, payload: res.data};
            case 401:
                return {type: AUTH.ERROR, payload: [res.data]};
            case 403:
                return {type: AUTH.ERROR, payload: [res.data]};
            case 422:
                return {type: AUTH.VALIDATION_ERROR, payload: res.data};
            case 404:
                return {type: AUTH.ERROR, payload: ["Not found!"]};
            default:
                return {type: AUTH.ERROR, payload: ["Something went wrong, please try again later..."]};
        }
    }
}

function loginWithID(data) {
    return dispatch => {
        dispatch(request());
        authService.loginWithID(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function request() {
        return {type: AUTH.REQUEST};
    }

    function success(res) {
        if (res.status === 202) {
            helper.storeLogin(res.data);
            ReactGA.set({
                email: res.data.email,
                name: res.data.email,
                is_sso_login: true
            });
            return {type: AUTH.LOGIN_SUCCESS, payload: res.data};
        }
        return error(res);
    }

    function error(res) {
        res = res.response;
        switch (res.status) {
            case 400:
                return {type: AUTH.ACTION, payload: res.data};
            case 401:
                return {type: AUTH.ERROR, payload: [res.data]};
            case 403:
                return {type: AUTH.ERROR, payload: [res.data]};
            case 422:
                return {type: AUTH.VALIDATION_ERROR, payload: res.data};
            case 404:
                return {type: AUTH.ERROR, payload: ["Not found!"]};
            default:
                return {type: AUTH.ERROR, payload: ["Something went wrong, please try again later..."]};
        }
    }
}

function loginViaToken(data) {
    return dispatch => {
        dispatch(request());
        authService.loginViaToken(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function request() {
        return {type: AUTH.REQUEST};
    }

    function success(res) {
        if (res.status === 202) {
            helper.storeLogin(res.data);
            ReactGA.set({
                email: res.data.email,
                name: res.data.email,
                is_sso_login: true
            });
            return {type: AUTH.LOGIN_SUCCESS, payload: res.data};
        }
        return error(res);
    }

    function error(res) {
        res = res.response;
        switch (res.status) {
            case 400:
                return {type: AUTH.ACTION, payload: res.data};
            case 401:
                return {type: AUTH.ERROR, payload: [res.data]};
            case 403:
                return {type: AUTH.ERROR, payload: [res.data]};
            case 422:
                return {type: AUTH.VALIDATION_ERROR, payload: res.data};
            case 404:
                return {type: AUTH.ERROR, payload: ["Not found!"]};
            default:
                return {type: AUTH.ERROR, payload: ["Something went wrong, please try again later..."]};
        }
    }
}

function isSSOUser(email) {
    return dispatch => {
        dispatch(request());
        authService.isSSOUser({
            email: email
        }).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function request() {
        return {type: AUTH.REQUEST};
    }

    function success(res) {
        return {type: AUTH.IS_SSO_USER_SUCCESS, payload: res.data};
    }

    function error(res) {
        res = res.response;
        if (res) {
            switch (res.status) {
                case 400:
                    return {type: AUTH.ACTION, payload: res.data};
                case 401:
                    return {type: AUTH.ERROR, payload: [res.data]};
                case 403:
                    return {type: AUTH.ERROR, payload: [res.data]};
                case 422:
                    return {type: AUTH.VALIDATION_ERROR, payload: res.data};
                case 404:
                    return {type: AUTH.ERROR, payload: ["Not found!"]};
                default:
                    return {type: AUTH.ERROR, payload: ["Something went wrong, please try again later..."]};
            }
        }
        return {type: AUTH.ERROR, payload: ["Something went wrong, please try again later..."]};
    }
}

function logout() {
    return () => {
        authService.logout();
        helper.logout();
    };
}

function validate() {
    return () => {
        authService.validate()
            .then(res => {
                console.log("RESPONSE CODE: ", res.status);
            }, res => {
                console.log("ERROR CODE: ", res.response.status);
            });
    }
}

function request() {
    return {type: AUTH.REQUEST};
}

function error(reason) {
    if (reason) {
        reason = reason.response;
        switch (reason.status) {
            case 403:
                return {type: AUTH.ERROR, payload: ['Not Allowed!']};
            case 422:
                return {type: AUTH.VALIDATION_ERROR, payload: reason.data};
            case 404:
                return {type: AUTH.ERROR, payload: ["Not found!"]};
            case 500:
                return {type: AUTH.VALIDATION_ERROR, payload: reason.data};
            default:
                return {type: AUTH.ERROR, payload: ["Something went wrong, please try again later..."]};
        }
    }
    return {type: AUTH.ERROR, payload: ["Something went wrong, please try again later..."]};
}

function forgotPassword(data) {
    return dispatch => {
        dispatch(request());
        authService.forgotPassword(data)
            .then(data => {
                dispatch(success(data));
            }, er => {
                dispatch(error(er));
            })
    };

    function success(res) {
        if (res.status === 202) {
            return {type: AUTH.FORGOT_PASSWORD_SUCCESS, payload: res.data}
        }
        return error(res);
    }
}

function resetPassword(data) {
    return dispatch => {
        dispatch(request());
        authService.resetPassword(data)
            .then(data => {
                dispatch(success(data));
            }, er => {
                dispatch(error(er));
            })
    };

    function success(res) {
        if (res.status === 202) {
            return {type: AUTH.FORGOT_PASSWORD_SUCCESS, payload: res.data}
        }
        return error(res);
    }
}

function join(data) {
    return dispatch => {
        dispatch(request());
        authService.join(data)
            .then(data => {
                dispatch(success(data));
            }, er => {
                dispatch(error(er));
            })
    };

    function request() {
        return {type: AUTH.REQUEST};
    }

    function success(res) {
        if (res.status === 202) {
            return {type: AUTH.JOIN_QUALMET_SUCCESS};
        }
        return error(res);
    }
}

function assignOrganization(org) {
    return dispatch => {
        storage.set(ORG_KEY, org);
        dispatch({type: AUTH.ASSIGN_ORGANIZATION, payload: org});
    };
}

function removeOrganization() {
    return dispatch => {
        storage.remove(ORG_KEY);
        dispatch({type: AUTH.REMOVE_ORGANIZATION});
    };
}

// for self registration
function register(data) {
    return dispatch => {
        dispatch(request());
        authService.register(data).then((res) => dispatch(success(res)), (res) => dispatch(error(res)));
    };

    function success(res) {
        if (res.status === 201) {
            return {type: AUTH.REGISTER_SUCCESS, payload: res.data};
        }
        return error(res);
    }
}

function validateAccount(data) {
    return dispatch => {
        dispatch(request());
        authService.validateAccount(data).then((res) => dispatch(success(res)), (res) => dispatch(error(res)));
    };

    function success(res) {
        if (res.status === 202) {
            return {type: AUTH.VALIDATE_ACCOUNT_SUCCESS, payload: res.data};
        }
        return error(res);
    }
}

function isFirstUser(data) {
    return dispatch => {
        dispatch({type: AUTH.IS_REQUEST_IS_FIRST_USER});
        authService.isFirstUser(data).then((res) => dispatch(success(res)), (res) => dispatch(error(res)));
    };

    function success(res) {
        return {type: AUTH.IS_FIRST_TIME_USER_SUCCESS, payload: res.data};
    }
}

function getEmailOfUser(data) {
    return dispatch => {
        dispatch(request());
        authService.getEmailOfUser(data).then((res) => dispatch(success(res)), (res) => dispatch(error(res)));
    };

    function success(res) {
        return {type: AUTH.GET_USER_EMAIL_SUCCESS, payload: res.data};
    }
}

function createOrg(data) {
    return dispatch => {
        dispatch(request());
        authService.createOrg(data).then((res) => dispatch(success(res)), (res) => dispatch(error(res)));
    };

    function success(res) {
        return {type: AUTH.CREATE_ORG_SUCCESS, payload: res.data};
    }
}

function resendVerificationCode(data) {
    return dispatch => {
        dispatch(request());
        authService.resendVerificationCode(data).then((res) => dispatch(success(res)), (res) => dispatch(error(res)));
    };

    function success(res) {
        if (res.status === 202) {
            return {type: AUTH.EMAIL_VERIFICATION_CODE_SENT_SUCCESS};
        }
        return error(res);
    }
}

function contactUs(data) {
    return dispatch => {
        dispatch(request());
        authService.contactUs(data).then((res) => dispatch(success(res)), (res) => dispatch(error(res)));
    };

    function success(res) {
        if (res.status === 202) {
            return {type: AUTH.CONTACT_US_SUCCESS};
        }
        return error(res);
    }
}

function deleteCode() {
    return {type: AUTH.DELETE_CODE};
}

function unmount() {
    return dispatch => dispatch({type: AUTH.UNMOUNT_COMPONENT});
}

const authAction = {
    login,
    loginWithToken,
    loginWithID,
    isSSOUser,
    logout,
    validate,
    forgotPassword,
    resetPassword,
    assignOrganization,
    removeOrganization,
    join,
    register,
    validateAccount,
    isFirstUser,
    getEmailOfUser,
    createOrg,
    resendVerificationCode,
    contactUs,
    deleteCode,
    unmount,
    loginViaToken
};

// constants
export const AUTH = {

    REQUEST: "AUTH_REQUEST",
    ERROR: "AUTH_ERROR",
    VALIDATION_ERROR: "AUTH_VALIDATION_ERROR",
    ACTION: "AUTH_ACTION",

    LOGIN_SUCCESS: "LOGIN_SUCCESS",
    IS_SSO_USER_SUCCESS: "AUTH_IS_SSO_USER_SUCCESS",
    ASSIGN_ORGANIZATION: "ASSIGN_ORGANIZATION",
    REMOVE_ORGANIZATION: "REMOVE_ORGANIZATION",

    JOIN_QUALMET_SUCCESS: "AUTH_JOIN_QUALMET_SUCCESS",
    FORGOT_PASSWORD_SUCCESS: "AUTH_FORGOT_PASSWORD_SUCCESS",
    RESET_PASSWORD_SUCCESS: "AUTH_RESET_PASSWORD_SUCCESS",

    IS_FIRST_TIME_USER_SUCCESS: "AUTH_IS_FIRST_TIME_USER_SUCCESS",
    VALIDATE_ACCOUNT_SUCCESS: "AUTH_VALIDATE_ACCOUNT_SUCCESS",
    REGISTER_SUCCESS: "AUTH_REGISTER_SUCCESS",

    GET_USER_EMAIL_SUCCESS: "AUTH_GET_USER_EMAIL_SUCCESS",
    CREATE_ORG_SUCCESS: "AUTH_CREATE_ORG_SUCCESS",

    EMAIL_VERIFICATION_CODE_SENT_SUCCESS: "AUTH_EMAIL_VERIFICATION_CODE_SENT_SUCCESS",
    CONTACT_US_SUCCESS: 'AUTH_CONTACT_US_ACCESS',

    DELETE_CODE: "AUTH_DELETE_CODE",

    UNMOUNT_COMPONENT: "AUTH_UNMOUNT_COMPONENT",

    IS_REQUEST_IS_FIRST_USER: "AUTH_IS_REQUEST_IS_FIRST_USER"
};

export default authAction;
