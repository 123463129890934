import React, {Component} from 'react';
import {Button, Header, Icon, Segment} from "semantic-ui-react";
import ColoredBody from "../other/ColoredBody";
import BoxHeader from "../other/BoxHeader";
import BoxFooter from "../other/BoxFooter";

class NotFoundPage extends Component {

    render() {
        return (
            <ColoredBody textAlign={"center"} boxWidth={"512px"}>
                <BoxHeader/>
                <Segment>
                    <br/>
                    <br/>
                    <span style={{fontSize: "56px"}}>404</span>
                    <br/>
                    <br/>
                    <Header as='h1' style={{fontSize: '36px'}} textAlign='center'>
                        Not found!
                    </Header>
                    <br/>
                    <br/>
                    <br/>
                    <Button color='teal' size='large' circular onClick={() => this.props.history.go(-1)}>
                        <Icon name={"arrow left"}/>
                        Go Back
                    </Button>
                    <Button color='teal' size='large' circular onClick={() => this.props.history.push("/")}>
                        <Icon name="home"/> Home
                    </Button>
                </Segment>
                <BoxFooter/>
            </ColoredBody>
        );
    }
}

export default NotFoundPage;