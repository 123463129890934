import {EVALUATION} from "../actions/evaluation.action";
import {EVALUATION_STYLE, FINAL_QUESTION_TYPE} from "../helper/const";
import {MATTER} from "../actions/matter.action";

const initState = {
    isAllowed: true,
    loading: false,
    isDone: false,
    evaluations: [],
    error: [],
    evaluation: {},
    questions: [],
    activeIndex: -1,
    current: 0,
    totals: 0
};

function filter(state, {type, answer}, isFirstTime) {
    switch (type) {
        case FINAL_QUESTION_TYPE.QUE_CONFIRMATION:
            if (answer === 0) {
                state.questions = state.questions.map((q) => {
                    if (q.type > FINAL_QUESTION_TYPE.QUE_CONFIRMATION) {
                        q.hidden = true;
                        q.answer = -1;
                        q.text_answer = null;
                    }
                    return q;
                });
            } else if (answer === 1) {
                state.questions = state.questions.map((q) => {
                    if (q.type === FINAL_QUESTION_TYPE.QUE_WOULD_RECOMMEND) {
                        q.hidden = false;
                    }
                    return q;
                });
            }
            break;
        case FINAL_QUESTION_TYPE.QUE_WOULD_RECOMMEND:
            state.questions = state.questions.map((q) => {
                if (q.type === FINAL_QUESTION_TYPE.QUE_SUGGESTION_9_10) {
                    q.hidden = answer <= 8;
                    if (!isFirstTime)
                        q.text_answer = null;
                }
                if (q.type === FINAL_QUESTION_TYPE.QUE_SUGGESTION_0_8) {
                    q.hidden = answer > 8;
                    if (!isFirstTime)
                        q.text_answer = null;
                }
                if (q.type > FINAL_QUESTION_TYPE.QUE_SUGGESTION_9_10) {
                    q.hidden = false;
                }
                return q;
            });
            break;
        default:
            break;
    }
    const conQue = state.questions.find((q) => q.type === FINAL_QUESTION_TYPE.QUE_CONFIRMATION);
    if (conQue && conQue.answer === -1) {
        state.questions = state.questions.map((q) => {
            if (q.type > FINAL_QUESTION_TYPE.QUE_CONFIRMATION) {
                q.hidden = true;
            }
            return q;
        });
    }
    return state;
}

export default function evaluationReducer(state = initState, action) {

    // reset
    state.error = [];
    state.isDone = false;
    state.loading = false;
    state.isAllowed = true;
    state.isRejected = false;

    // filter
    switch (action.type) {

        // common
        case EVALUATION.REQUEST:
            return {...state, loading: true};

        case EVALUATION.VALIDATION_ERROR:
            return {...state, error: action.payload};

        case EVALUATION.ERROR:
            return {...state, error: [...action.payload]};

        // not allowed to access
        case EVALUATION.NOT_ALLOWED:
            return {...state, isAllowed: false};

        // add EVALUATION
        case EVALUATION.ADD_SUCCESS:
            return {...state, isDone: true, matters: [...state.matters, action.payload]};

        case EVALUATION.UPDATE_SUCCESS:
            return {...state, isDone: true};

        // under the org admin >> evaluation cycles
        case EVALUATION.READ_EVALUATIONS_CYCLES:
            return {...state, matters: action.payload};

        case EVALUATION.READ_SUCCESS:
            return {...state, matter: action.payload};

        case EVALUATION.NOT_FOUND:
            return {...state, not_found: true};

        case EVALUATION.CHANGE_STATUS_SUCCESS:
            state.matters[state.matters.findIndex((client) => client.id === action.payload.id)].status = action.payload.status;
            return {...state};

        case EVALUATION.READ_MASTER_DATA:
            return {...state, master_data: action.payload};

        case EVALUATION.START_EVALUATION_SUCCESS:
            state.evaluation = action.payload;
            if (action.payload.evaluations) {
                state.questions = action.payload.evaluations;
                state.totals = state.questions.filter((q) => q.type === 0).length;
            }
            switch (state.evaluation.evaluation_style) {
                case EVALUATION_STYLE.MULTI_PAGE:
                    for (let i = 0; i < state.questions.length; i++) {
                        state.question = state.questions[i];
                        const {answer, type, text_answer} = state.question;
                        if (state.evaluation.has_final_questions) {
                            switch (type) {
                                case FINAL_QUESTION_TYPE.QUE_CONFIRMATION:
                                    if (answer === 0) {
                                        state.activeIndex = -1;
                                        break;
                                    }
                                    break;
                                case FINAL_QUESTION_TYPE.QUE_WOULD_RECOMMEND:
                                    if (answer > 8) {
                                        state.activeIndex++;
                                    }
                                    break;
                                case FINAL_QUESTION_TYPE.QUE_SUGGESTION_0_8:
                                    if (text_answer !== null) {
                                        state.activeIndex++;
                                    }
                                    break;
                                default:
                                    break;
                            }
                            if (type === FINAL_QUESTION_TYPE.QUE_VALUE) {
                                state.activeIndex = -1;
                            } else {
                                state.activeIndex++;
                            }
                            state.current++;
                            // eslint-disable-next-line
                            if ((type != FINAL_QUESTION_TYPE.QUE_SUGGESTION_0_8 || type != FINAL_QUESTION_TYPE.QUE_SUGGESTION_9_10)
                                && answer === -1 && text_answer === null) {
                                break;
                            }
                        } else {
                            if (state.activeIndex === state.questions.length - 1) {
                                state.activeIndex = -1;
                            } else {
                                state.activeIndex++;
                                state.current++;
                            }
                            if (answer === -1 && text_answer === null) {
                                break;
                            }
                        }
                    }
                    break;

                case EVALUATION_STYLE.SINGLE_PAGE:
                    if (state.evaluation.has_final_questions) {
                        let flag = false;
                        state.questions
                            .filter((q) => q.type > 0)
                            .forEach((q) => {
                                if (flag)
                                    return;
                                state = filter(state, q, true);
                                if (q.type === FINAL_QUESTION_TYPE.QUE_CONFIRMATION && q.answer === 0) {
                                    flag = true;
                                }
                            });
                    }
                    break;
                default:
                    break;
            }
            return {...state, isDone: true};

        case EVALUATION.EVALUATE_SUCCESS:
            state.questions[state.activeIndex] = action.payload;
            if (state.evaluation.has_final_questions) {
                const {answer, type} = action.payload;
                switch (type) {
                    case FINAL_QUESTION_TYPE.QUE_CONFIRMATION:
                        if (answer === 0) {
                            state.activeIndex = -1;
                        }
                        break;
                    case FINAL_QUESTION_TYPE.QUE_WOULD_RECOMMEND:
                        if (answer > 8) {
                            state.activeIndex++;
                        }
                        break;
                    case FINAL_QUESTION_TYPE.QUE_SUGGESTION_0_8:
                        state.activeIndex++;
                        break;
                    default:
                        break;
                }
                if (type === FINAL_QUESTION_TYPE.QUE_VALUE) {
                    state.activeIndex = -1;
                } else {
                    // eslint-disable-next-line
                    if (state.activeIndex != -1)
                        state.activeIndex++;
                }
            } else {
                if (state.activeIndex === state.questions.length - 1) {
                    state.activeIndex = -1;
                } else {
                    state.activeIndex++;
                }
            }
            if (state.activeIndex !== -1) {
                state.question = state.questions[state.activeIndex];
            }
            state.current++;
            return {...state, isDone: true};

        case EVALUATION.EVALUATE_ALL_SUCCESS:
            return {...state, isDone: true, questions: []};

        case EVALUATION.EVALUATION_ON_CHANGE:
            const {data, index: i} = action.payload;
            state.questions[i] = data;
            state = filter(state, data);
            return {...state};

        case EVALUATION.EVALUATION_ON_PREVIOUS:
            if (state.question.type === FINAL_QUESTION_TYPE.QUE_SUGGESTION_9_10) {
                state.activeIndex--;
                state.activeIndex--;
            } else {
                state.activeIndex--;
            }
            state.current--;
            state.question = state.questions[state.activeIndex];
            return {...state};

        case MATTER.READ_ALL_MY_EVALUATIONS_SUCCESS:
            state.questions = [];
            state.activeIndex = -1;
            state.question = {};
            state.current = 0;
            state.totals = 0;
            state.evaluation = {};
            return {...state};

        case EVALUATION.VIEW_EVALUATION_SUCCESS:
            state.evaluation = action.payload;
            if (action.payload.evaluations) {
                state.questions = action.payload.evaluations;
                state.totals = state.questions.filter((q) => q.type === 0).length;
            }
            return {...state};

        case EVALUATION.EDIT_EVALUATION:
            state.evaluation = action.payload;
            if (action.payload.evaluations) {
                state.questions = action.payload.evaluations;
                state.totals = state.questions.filter((q) => q.type === 0).length;
            }
            switch (state.evaluation.evaluation_style) {
                case EVALUATION_STYLE.MULTI_PAGE:
                    state.question = state.questions[0];
                    state.activeIndex = 0;
                    state.current = 1;
                    break;

                case EVALUATION_STYLE.SINGLE_PAGE:
                    if (state.evaluation.has_final_questions) {
                        state.questions.forEach((q) => {
                            state = filter(state, q, true);
                        });
                    }
                    break;
                default:
                    break;
            }
            return {...state, isDone: true};

        case EVALUATION.REJECT_EVALUATION_SUCCESS:
            return {...state, isRejected: true};

        default:
            return state;
    }
}
