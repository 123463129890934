import React, {Component} from 'react';
import {Button, Form, Header, Segment} from "semantic-ui-react";
import authAction from "../../../actions/auth.action";
import ColoredBody from "../../other/ColoredBody";
import BoxHeader from "../../other/BoxHeader";
import BoxFooter from "../../other/BoxFooter";
import ErrorMessage from "../../other/ErrorMessage";
import {connect} from "react-redux";
import * as qs from "query-string";

class JoinQualmetPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            confirm_password: '',
            password: ''
        };
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.parsed = qs.parse(this.props.location.search);
    }

    componentDidMount() {
        if (Object.keys(this.parsed).length !== 2) {
            this.props.history.replace("/not-found");
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.isDone) {
            alert("Successfully registered!");
            this.props.history.replace("/login");
        }
    }

    onChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    onSubmit(e) {
        e.preventDefault();
        const {password} = this.state;
        this.props.dispatch(authAction.join({
            password,
            ...this.parsed
        }));
    }

    render() {
        const {error, loading} = this.props;
        const {password, confirm_password} = this.state;
        return (
            <ColoredBody textAlign={"center"}>
                <BoxHeader/>
                <Segment basic padded={'very'}>
                    <Header as={'h2'}>
                        Join Qualmet
                    </Header>
                    <br/>
                    <ErrorMessage errors={error}/>
                    <Form autoComplete='off'>
                        <Form.Input
                            fluid
                            icon='lock'
                            iconPosition='left'
                            placeholder='Password'
                            type='password'
                            name='password'
                            onChange={this.onChange}
                        />
                        <Form.Input
                            fluid
                            icon='lock'
                            iconPosition='left'
                            placeholder='Confirm Password'
                            type='password'
                            name='confirm_password'
                            onChange={this.onChange}
                        />
                        <Button circular color='teal' fluid size='large' onClick={this.onSubmit}
                                loading={loading}
                                disabled={loading || (!password || password !== confirm_password)}>
                            Submit
                        </Button>
                    </Form>
                </Segment>
                <BoxFooter/>
            </ColoredBody>
        );
    }
}

function mapStateToProps(state) {
    return state.auth;
}

const cJoinQualmetPage = connect(mapStateToProps)(JoinQualmetPage);
export {cJoinQualmetPage as JoinQualmetPage};