import React, {Component} from "react";
import {orgState} from "../../../../../../helper/stateUtil";
import {connect} from "react-redux";
import {Segment} from "semantic-ui-react";
import EvalProgressBar from "../../../../../other/EvalProgressBar";
import evaluationAction from "../../../../../../actions/evaluation.action";
import QuestionBox from "../../../../../other/QuestionBox";
import {withRouter} from "react-router-dom";
import * as qs from "query-string";

class MultiPageEvaluation extends Component {
    constructor(props) {
        super(props);
        this.params = qs.parse(props.location.search);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.isDone && nextProps.activeIndex === -1) {
            this.props.history.go(-1);
        }
    }

    render() {
        const {loading, questions, current, totals, activeIndex} = this.props;
        const {type} = this.params;
        let que = null;
        if (activeIndex !== -1) {
            que = questions[activeIndex];
        }
        if (!que)
            return <div/>;
        return (
            <Segment basic textAlign='center'>
                {(que && que.type === 0) && <div style={{marginTop: "2em"}}>
                    <EvalProgressBar
                        total={totals}
                        current={current}
                    />
                </div>}

                <div style={{marginTop: "2em"}}>
                    <QuestionBox
                        isMultiPage={true}
                        loading={loading}
                        style={{marginTop: ""}}
                        data={que}
                        activeIndex={activeIndex}
                        lastIndex={questions.length - 1}
                        onPrevious={() => this.props.dispatch(evaluationAction.onPrevious())}
                        onSubmit={(data) => this.props.dispatch(evaluationAction.evaluate(data, type))}/>
                </div>
            </Segment>
        );
    }
}

MultiPageEvaluation.propTypes = {};

function mapStateToProps(state) {
    let s = orgState(state);
    return {...s, ...state.evaluations};
}

const cMultiPageEvaluation = withRouter(connect(mapStateToProps)(MultiPageEvaluation));
export {cMultiPageEvaluation as MultiPageEvaluation};
