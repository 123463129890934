import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Form} from "semantic-ui-react";

class CommentBox extends Component {
    constructor(props) {
        super(props);
        const {value} = props;
        this.state = {
            comments: value,
            active: value != null && value !== undefined
        };
    }

    componentWillReceiveProps(nextProps) {
        const {value} = nextProps;
        this.setState({
            comments: nextProps.value,
            active: value != null && value !== undefined
        })
    }

    render() {
        const {comments} = this.state;
        const {onChange} = this.props;
        let content;
        // if (active) {
            content = (<Form.TextArea placeholder="Additional comments (optional)"
                                      rows={2}
                                      value={comments || ""}
                                      onChange={(e) => {
                                          onChange && onChange(e.target.value);
                                          this.setState({comments: e.target.value});
                                      }}/>);
        // } else {
        //     content = (<label style={{color: "#68a6a6", cursor: "pointer"}} onClick={() => {
        //         this.setState({active: true});
        //     }}><Icon name={"add"}/> Add Comment</label>);
        // }
        return (
            <Form>
                {content}
            </Form>
        );
    }
}

CommentBox.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
};

export default CommentBox;
