import React, {Component} from 'react';
import {connect} from "react-redux";
import Body from "../../../../other/Body";
import {orgState} from "../../../../../helper/stateUtil";
import {OrgMenuBar, reportsPath} from "../../other/OrgMenuBar";
import Report from "../components/other/Report";
import {ReportMatterSummary} from "./components/ReportMatterSummary";
import {ReportMatterDetails} from "./components/ReportMatterDetails";
import {ReportMatterComments} from "./components/ReportMatterComments";
import TabHeader from "../../other/TabHeader";

class MatterReportPage extends Component {
    constructor(props) {
        super(props);
        const matterId = props.match.params.id;
        this.tabs = [
            {
                text: "Summary",
                value: "summary",
                render: <ReportMatterSummary matterId={matterId}/>
            },
            {
                text: "Details",
                value: "details",
                render: <ReportMatterDetails matterId={matterId}/>
            },
            {
                text: "Comments",
                value: "comments",
                render: <ReportMatterComments matterId={matterId}/>
            }
        ];
    }

    render() {
        const {loadingFiltersAndSummary, match} = this.props;
        const type = match.params.type;
        const matterId = match.params.id;
        let activeIndex = 0;
        let content;
        this.tabs.forEach((tab, index) => {
            if (tab.value === type) {
                activeIndex = index;
                content = tab.render;
            }
        });
        return (
            <Body loading={loadingFiltersAndSummary}>
            <OrgMenuBar/>
            <TabHeader
                title={`Matters`}
                backArrow
                tabs={this.tabs}
                activeIndex={activeIndex}
                onTabChange={(tab) => {
                    this.props.history.replace(`${reportsPath}/matters/${matterId}/${tab.value}`);
                }}/>
            <Report>
                {content}
            </Report>
            </Body>
        );
    }
}

function mapStateToProps(state) {
    return {...orgState(state), ...state.report};
}

const cMatterReportPage = connect(mapStateToProps)(MatterReportPage);
export {cMatterReportPage as MatterReportPage};
