import React, {Component} from 'react';
import {Button, Form, Header, Icon, Segment, Table} from "semantic-ui-react";
import {connect} from "react-redux";
import evaluationQuestionAction from "../../../../actions/evaluation_question.action";
import {QUESTION_SET} from "../../../../helper/const";
import EditQuestionSetModal from "../../form/questions/modal/EditQuestionSetModal";
import helper from "../../../../helper/helper";
import SASubHeader from "../other/SASubHeader";
import SABody from "../other/SABody";
import QMCheckBox from "../../../other/form/QMCheckBox";

// This page is for list out all sets of evaluation questions
class SuperAdminQuestionSetPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false, // flag for modal open and close
            set: null,
            filter: 1
        };
        this.onOpenModal = this.onOpenModal.bind(this);
    }

    onOpenModal(set, type) {
        if (set != null) {
            this.setState({set: set, open: true}); // for edit
        }
        this.props.history.push(`/superadmin/master-data/evaluation-questions/add/${this.state.filter}`);
    }

    componentDidMount() {
        this.getAll(this.state.filter);
    }

    getAll = (filter) => {
        this.props.dispatch(evaluationQuestionAction.getAllowedMasterDataEQType({
            filter: filter
        }));
        this.props.dispatch(evaluationQuestionAction.readAllQuestionSets({
            filter: filter
        }));
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.props.isAllowed) {
            this.props.history.replace("/forbidden");
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.isDone) {
            this.setState({set: null, open: false});
        }
    }

    render() {
        const {loading, items, error, types} = this.props;
        const {open, set} = this.state;
        let content;
        if (items.length > 0) {
            content = (
                <Segment padded={"very"} basic style={{paddingTop: "0"}}>
                    <Table basic='very' selectable stackable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Question Set</Table.HeaderCell>
                                <Table.HeaderCell>Set Type</Table.HeaderCell>
                                <Table.HeaderCell></Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {items.map((item, index) => {
                                return (
                                    <Table.Row className={"row"} key={index}>
                                        <Table.Cell style={{paddingLeft: "16px"}}
                                                    onClick={() => this.props.history.push(`/superadmin/master-data/evaluation-questions/set/${item.id}?h=${item.name}`)}>
                                            <Icon name="list alternate outline" color={"grey"} size={"large"}/>
                                            <span><b>{item.name}</b></span>
                                        </Table.Cell>
                                        <Table.Cell>
                                            {helper.getStrTypeOfQS(item.type)}
                                        </Table.Cell>
                                        <Table.Cell collapsing>
                                            <Form>
                                                <Form.Group inline>
                                                    <Form.Field>
                                                        <Button icon={"pencil"} circular color={"grey"}
                                                                title={"Edit Question Set"} size={"mini"}
                                                                onClick={() => {
                                                                    this.onOpenModal(item)
                                                                }}/>
                                                    </Form.Field>
                                                    <Form.Field>
                                                        <QMCheckBox checked={item.status}
                                                                    onChange={checked => {
                                                                        this.props.dispatch(evaluationQuestionAction.changeStatus({
                                                                            ...item,
                                                                            code: QUESTION_SET,
                                                                            status: checked
                                                                        }));
                                                                    }}/>
                                                    </Form.Field>
                                                </Form.Group>
                                            </Form>
                                        </Table.Cell>
                                    </Table.Row>
                                );
                            })}
                        </Table.Body>
                    </Table>
                </Segment>
            );
        } else {
            content = (
                <Segment basic placeholder piled>
                    <Header icon>
                        <Icon name='file outline'/>
                        No evaluation question sets are listed.
                    </Header>
                </Segment>
            );
        }
        return (
            <SABody loading={loading}>
                <SASubHeader title={"Evaluation Questions"}>
                    <Button circular size={"medium"}
                            onClick={() => this.onOpenModal(null)}
                            floated="right" color={"teal"}>Add Question Set</Button>
                    {/*{!types.includes(EVALUATION_TYPE.SELF_EVALUATION) &&*/}
                    {/*<Button circular size={"medium"}*/}
                    {/*        onClick={() => this.onOpenModal(null, EVALUATION_TYPE.SELF_EVALUATION)}*/}
                    {/*        floated="right" color={"teal"}>Add*/}
                    {/*    Self Evaluation's Question Set</Button>}*/}
                    {/*{!types.includes(EVALUATION_TYPE.EXTERNAL_EVALUATION) &&*/}
                    {/*<Button circular size={"medium"}*/}
                    {/*        onClick={() => this.onOpenModal(null, EVALUATION_TYPE.EXTERNAL_EVALUATION)}*/}
                    {/*        floated="right" color={"teal"}>Add External Evaluation's Question Set</Button>}*/}
                </SASubHeader>
                <Segment basic style={{margin: "1em 3em 0 3em"}}>
                    <Form>
                        <Form.Group>
                            <Form.Dropdown selection value={this.state.filter}
                                           label={"Evaluation Category"}
                                           onChange={(e, {value}) => {
                                               this.setState({filter: value});
                                               this.getAll(value);
                                           }}
                                           name={"type"}
                                           options={[
                                               {text: "Matter and Evaluatee", value: 1},
                                               {text: "Firm/Client", value: 2}
                                           ]}/>
                        </Form.Group>
                    </Form>
                </Segment>
                {content}

                {open && <EditQuestionSetModal
                    isFromMaterData={true}
                    error={error}
                    loading={loading}
                    set={set}
                    onSave={(value) => {
                        this.props.dispatch(evaluationQuestionAction.update({
                            code: QUESTION_SET,
                            ...value
                        }));
                    }}
                    onClose={() => {
                        this.setState({open: false, set: null});
                    }}/>}
            </SABody>


        );
    }
}

function mapStateToProps(state) {
    return {...state.evaluation_question};
}

const cSuperAdminQuestionSetPage = connect(mapStateToProps)(SuperAdminQuestionSetPage);
export {cSuperAdminQuestionSetPage as SuperAdminQuestionSetPage};
