import React, {Component} from 'react';
import {connect} from "react-redux";
import {orgState} from "../../../../../helper/stateUtil";
import Body from "../../../../other/Body";
import EvaluationHeader from "./common/EvaluationHeader";
import {Header, Icon, Segment} from "semantic-ui-react";
import {EVALUATION_STYLE} from "../../../../../helper/const";
import evaluationAction from "../../../../../actions/evaluation.action";
import {SinglePageEvaluation} from "./style/SinglePageEvaluation";
import {MultiPageEvaluation} from "./style/MultiPageEvaluation";
import * as qs from "query-string";

class EvaluationPage extends Component {
    constructor(props) {
        super(props);
        this.params = qs.parse(props.location.search);
    }

    componentWillMount() {
        const {action, type} = this.params;
        this.props.dispatch(evaluationAction.startEvaluation({
            org_id: this.props.org.id,
            id: this.props.match.params.id,
            action: action === 'edit'
        }, type));
    }

    render() {
        const {loading, evaluation, questions} = this.props;
        let content;
        switch (evaluation.evaluation_style) {
            case EVALUATION_STYLE.SINGLE_PAGE:
                content = <SinglePageEvaluation/>;
                break;
            case EVALUATION_STYLE.MULTI_PAGE:
                content = <MultiPageEvaluation/>;
                break;
            default:
                content =
                    <Segment padded={'very'} basic>
                        <Segment placeholder>
                            <Header icon color={'red'}>
                                <Icon name='close'/>
                                Something went wrong!
                            </Header>
                        </Segment>
                    </Segment>;
                break;
        }
        return (
            <Body loading={(questions.length === 0 && loading)}>
                <EvaluationHeader
                    data={evaluation}/>
                {content}
            </Body>
        );
    }
}

function mapStateToProps(state) {
    let s = orgState(state);
    return {...s, ...state.evaluations};
}

const cEvaluationPage = connect(mapStateToProps)(EvaluationPage);
export {cEvaluationPage as EvaluationPage};
