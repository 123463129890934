import React, {Component, Fragment} from 'react';
import Body from "../../../../other/Body";
import {connect} from "react-redux";
import {Button, Divider, Form, Header, List, Segment} from "semantic-ui-react";
import ErrorMessage from "../../../../other/ErrorMessage";
import orgState from "../../../../../helper/stateUtil";
import {OrgMenuBar} from "../../other/OrgMenuBar";
import OrgSubHeader from "../../other/OrgSubHeader";
import {PriceTable} from '../../../../other/PriceTable'
import planAction from "../../../../../actions/plan.action";
import SuccessMessage from "../../../../other/SuccessMessage";

class OrgPlanPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            phone: '',
            address: '',
            plan: '',
            comments: '',
            po_number: ''
        };
    }

    componentDidMount() {
        this.props.dispatch(planAction.getCurrentPlan({org_id: this.props.org.id}));
    }

    handleChange = e => this.setState({[e.target.name]: e.target.value});

    componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any): void {
        const {currentPlan: {plan_id}, isDone, history} = nextProps;
        if (isDone)
            history.goBack();
        else if (plan_id) {
            this.setState({plan: plan_id});
        }
    }

    render() {
        const {error, loading, org, currentPlan: {plan_name, expired_at, no_users, sub_status}} = this.props;
        const {name, email, phone, address, comments, po_number} = this.state;
        return (
            <Body loading={loading}>

                <OrgMenuBar/>

                <OrgSubHeader title={"Plan & Billing"}/>

                <Segment basic padded='very'>
                    {sub_status === 2 ? <Fragment>
                        <SuccessMessage messages={[
                            <span>
                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                Your request is pending, We will get back to you soon. Need further help <a
                                style={{cursor: "pointer"}} onClick={() => this.props.history.push("/contact")}><u>Contact Us</u></a>
                            </span>
                        ]}/>
                    </Fragment> : <Fragment>
                        <Header as={'h3'}>
                            Current Plan Information:
                        </Header>
                        <List>
                            <List.Item>
                                <List horizontal>
                                    <List.Item><b>Plan Name: </b></List.Item>
                                    <List.Item>{plan_name || "No current plan"}</List.Item>
                                </List>
                            </List.Item>
                            <List.Item>
                                <List horizontal>
                                    <List.Item><b>Plan Renewal Date: </b></List.Item>
                                    <List.Item>{expired_at || "NA"}</List.Item>
                                </List>
                            </List.Item>
                        </List>
                        <Divider/>
                        <br/>

                        <Header as={'h3'}>
                            Plans:
                        </Header>
                        <br/>
                        <PriceTable onSelect={planId => this.setState({plan: planId})} planId={this.state.plan}
                                    currentUsers={no_users}/>
                        <br/>
                        <Divider/>
                        <Form>
                            <ErrorMessage errors={error}/>

                            <br/>
                            <Header as={'h3'}>
                                Invoice Information:
                            </Header>
                            <br/>
                            <Form.Field>
                                <Form.Input label='Name' placeholder='Full Name' width={8} name={"name"} required
                                            onChange={this.handleChange}
                                            value={name}/>
                            </Form.Field>
                            <Form.Field>
                                <Form.Input type='email' label='Email' placeholder='Email' width={8} name={"email"} required
                                            onChange={this.handleChange}
                                            value={email}/>
                            </Form.Field>
                            <Form.Field>
                                <Form.TextArea type='address' rows={3} label='Address' placeholder='Address' width={8} required
                                               name={"address"}
                                               onChange={this.handleChange}
                                               value={address}/>
                            </Form.Field>
                            <Form.Field>
                                <Form.Input type='tel' label='Phone' placeholder='Phone' width={8} name={"phone"} required
                                            onChange={this.handleChange}
                                            value={phone}/>
                            </Form.Field>
                            <Form.Field>
                                <Form.Input type='text' label='PO Number' width={8} name={"po_number"}
                                            onChange={this.handleChange}
                                            value={po_number}/>
                            </Form.Field>
                            <Form.Field>
                                <Form.TextArea label='Comments' width={8} name={"comments"}
                                               onChange={this.handleChange}
                                               value={comments}/>
                            </Form.Field>

                            <br/>
                            <Button type='submit' primary
                                    onClick={() => {
                                        this.props.dispatch(planAction.requestPlan({
                                            ...this.state,
                                            org_id: org.id
                                        }));
                                    }}
                                    circular
                                    disabled={!(name && address && phone && email && this.state.plan)}
                                    floated='right'>Request</Button>
                            <Button type='button' secondary inverted circular
                                    onClick={() => this.props.history.go(-1)}
                                    floated='right'>Cancel</Button>
                            <br/>
                        </Form>
                    </Fragment>}
                </Segment>

            </Body>
        );
    }
}

function mapStateToProps(state) {
    return {
        ...orgState(state),
        ...state.plan
    };
}

const cOrgSettingsPage = connect(mapStateToProps)(OrgPlanPage);
export {cOrgSettingsPage as OrgPlanPage};
