import React, {Component} from "react";
import PropTypes from "prop-types";
import {Button, Container, Icon, List, Segment} from "semantic-ui-react";
import AnswerSelector from "./AnswerSelector";
import {E_QUESTION_TYPE} from "../../helper/const";
import CommentBox from "../pages/org/my_evaluations/evaluation/components/CommentBox";

class QuestionBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props.data
        };
    }

    componentWillReceiveProps(nextProps) {
        const {data} = nextProps;
        this.setState({...data});
    }

    render() {
        const {onSubmit, onChange, onPrevious, isMultiPage, activeIndex, lastIndex, data, loading} = this.props;
        let submitButtonDisabled = false;
        if (loading) {
            submitButtonDisabled = true;
        }
        if (this.state.answer < 0 && !this.state.text_answer) {
            submitButtonDisabled = true;
        }
        if (submitButtonDisabled) {
            if (this.state.type === 3 || this.state.type === 4) {
                submitButtonDisabled = false;
            }
        }
        let ansTitle;
        switch (data.answer_type) {
            case E_QUESTION_TYPE.ANSWER_TYPE_TEXT_ONLY:
                ansTitle = "Write your answer:";
                break;
            case E_QUESTION_TYPE.ANSWER_TYPE_YES_NO:
                ansTitle = "";
                break;
            default:
                ansTitle = "Rating:";
                break;
        }
        let commentBox = {};
        if (E_QUESTION_TYPE.ANSWER_TYPE_TEXT_ONLY === this.state.answer_type || E_QUESTION_TYPE.ANSWER_TYPE_YES_NO === this.state.answer_type || this.state.type === 2 || this.state.type === 5) {
            commentBox = null;
        }
        return (
            <Container text>
                <Segment padded style={{margin: "1em 6em 0 6em"}} textAlign='left'
                         color={isMultiPage ? undefined : ((data.answer !== -1) || data.text_answer) ? "green" : "red"}>
                    <List>
                        <List.Item>
                            <div>
                                <div style={{marginBottom: "4px"}}>
                                    {data.type === 0 && <b> Question {data.order_id}:</b>}
                                </div>
                                <div>
                                    <span style={{fontSize: "16px"}}>{data.question}</span>
                                </div>
                            </div>
                        </List.Item>
                        <List.Item style={{margin: "1em 0 0 0"}}>
                            <List.Header>{ansTitle}</List.Header>
                        </List.Item>
                        <List.Item>
                            <Segment basic textAlign={"center"} style={{padding: "0"}}>
                                <AnswerSelector
                                    loading={loading}
                                    data={this.state}
                                    onChange={(ans) => {
                                        onChange && onChange(ans);
                                        this.setState({...ans});
                                    }}/>
                            </Segment>
                        </List.Item>
                        {(this.state.answer_type === E_QUESTION_TYPE.ANSWER_TYPE_U_ENA) && <List.Item>
                                            <span style={{cursor: "pointer"}}
                                                  onClick={() => onSubmit && onSubmit({...this.state, answer: 0})}><Icon
                                                name={"angle right"}/>Skip Question</span>
                        </List.Item>}
                        {commentBox &&
                        <List.Item>
                            <CommentBox value={this.state.comments}
                                        onChange={(value) => {
                                            const s = {
                                                ...this.state,
                                                comments: value
                                            };
                                            onChange && onChange(s);
                                            this.setState(s);
                                        }}/>
                        </List.Item>}
                        <List.Item>
                            {isMultiPage &&
                            <Button primary fluid
                                    loading={loading}
                                    disabled={submitButtonDisabled}
                                    onClick={() => {
                                        onSubmit(this.state);
                                    }}>{(activeIndex !== lastIndex) ? "Next Question" : "Submit"}</Button>}
                        </List.Item>
                        {(isMultiPage && data.type === 0 && activeIndex > 0) && <List.Item>
                                            <span style={{cursor: "pointer"}} onClick={() => onPrevious()}><Icon
                                                name={"angle left"}/>Previous Question</span>
                        </List.Item>}
                    </List>
                </Segment>
            </Container>
        );
    }
}

QuestionBox.propTypes = {
    data: PropTypes.object,
    onSubmit: PropTypes.func,
    onChange: PropTypes.func,
    onPrevious: PropTypes.func,
    isMultiPage: PropTypes.bool.isRequired,
    activeIndex: PropTypes.number,
    lastIndex: PropTypes.number,
    loading: PropTypes.bool
};

export default QuestionBox;
