import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Segment, Table} from "semantic-ui-react";

class DataTable extends Component {
    render() {
        const {headers, active, children} = this.props;
        return (
            active ?
                <Segment basic>
                    <Table basic={'very'} padded selectable stackable>
                        <Table.Header>
                            <Table.Row>
                                {headers.map((h, i) => <Table.HeaderCell key={i}>{h}</Table.HeaderCell>)}
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {children}
                        </Table.Body>
                    </Table>
                </Segment>
                :
                <div/>
        );
    }
}

DataTable.propTypes = {
    headers: PropTypes.array.isRequired,
    active: PropTypes.bool
};

export default DataTable;
