import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Form, Modal, Segment} from "semantic-ui-react";
import ErrorMessage from "../../../../../../other/ErrorMessage";

class RegionAreaOfficeModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: "",
            type: 1
        };
    }

    componentWillReceiveProps(nextProps) {
        const {office} = nextProps;
        if (office) {
            this.setState({...office});
        } else {
            this.setState({
                title: '',
                type: 1
            });
        }
    }

    render() {
        const {loading, errors, open, onSubmit, office, onClose} = this.props;
        const {title, type} = this.state;
        return (
            <Modal size={"tiny"} open={open}
                   closeOnDimmerClick={false}
                   onClose={() => onClose()}>
                <Modal.Header>{office ? "Edit" : "Add"} Office Location</Modal.Header>
                <Modal.Content>
                    <Segment basic>
                        <Form>
                            <ErrorMessage errors={errors}/>
                            <Form.Field>
                                <label>Office Location:</label>
                                <input placeholder={`Office Location`}
                                       onChange={(e) => this.setState({title: e.target.value})}
                                       type="text" value={title}/>
                            </Form.Field>
                            <Form.Dropdown label='Office Type:'
                                           selection value={type}
                                           onChange={(e, {value}) => {
                                               this.setState({type: value});
                                           }}
                                           options={[
                                               {text: "Associated/Alliance", value: 1},
                                               {text: "Office", value: 2}
                                           ]}/>
                        </Form>
                    </Segment>
                </Modal.Content>
                <Modal.Actions>
                    <Button secondary onClick={() => onClose()}
                            disabled={loading}>Cancel</Button>
                    <Button color={"teal"} content="Submit"
                            type="submit"
                            loading={loading}
                            onClick={() => {
                                onSubmit(this.state);
                            }}
                            disabled={(loading) || (!this.state.title)}/>
                </Modal.Actions>
            </Modal>
        );
    }
}

RegionAreaOfficeModal.propTypes = {
    open: PropTypes.bool.isRequired,
    errors: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    office: PropTypes.object
};

export default RegionAreaOfficeModal;
