import regionLocationService from "../services/region_location.service";

// common
function request() {
    return {type: REGION.REQUEST};
}

function error(reason) {
    reason = reason.response;
    switch (reason.status) {
        case 404:
            return {type: REGION.NOT_FOUND};
        case 403:
            return {type: REGION.NOT_ALLOWED};
        case 422:
            return {type: REGION.VALIDATION_ERROR, payload: reason.data};
        default:
            return {type: REGION.ERROR, payload: reason.data};
    }
}

// ########################## REGION ##########################
function createRegion(data) {

    return dispatch => {
        dispatch(request());
        regionLocationService.addRegion(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        if (res.status === 201) {
            return {type: REGION.ADD_SUCCESS, payload: res.data};
        }
        return error(res);
    }


}

function updateRegion(data) {
    return dispatch => {
        dispatch(request());
        regionLocationService.updateRegion(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        if (res.status === 202) {
            return {type: REGION.UPDATE_SUCCESS, payload: data};
        }
        return error(res);
    }
}

function readAllRegions(data) {
    return dispatch => {
        dispatch(request());
        regionLocationService.readAllRegions(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: REGION.READ_ALL_SUCCESS, payload: res.data};
    }
}

function changeStatusOfRegion(data) {
    return dispatch => {
        dispatch(request());
        regionLocationService.changeStatusOfRegion(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        if (res.status === 202) {
            return {type: REGION.CHANGE_STATUS_SUCCESS, payload: data};
        }
        return error(res);
    }
}

// ########################## REGION AREA ##########################
function createRegionArea(data) {

    return dispatch => {
        dispatch(request());
        regionLocationService.addRegionArea(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        if (res.status === 201) {
            return {type: REGION.ADD_SUCCESS, payload: res.data};
        }
        return error(res);
    }
}

function updateRegionArea(data) {
    return dispatch => {
        dispatch(request());
        regionLocationService.updateRegionArea(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        if (res.status === 202) {
            return {type: REGION.UPDATE_SUCCESS, payload: data};
        }
        return error(res);
    }
}

function readAllRegionAreas(data) {
    return dispatch => {
        dispatch(request());
        regionLocationService.readAllRegionAreas(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: REGION.READ_ALL_SUCCESS, payload: res.data};
    }
}

function changeStatusOfRegionArea(data) {
    return dispatch => {
        dispatch(request());
        regionLocationService.changeStatusOfRegionArea(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        if (res.status === 202) {
            return {type: REGION.CHANGE_STATUS_SUCCESS, payload: data};
        }
        return error(res);
    }
}

// ########################## REGION AREA >> OFFICE ##########################
function createOffice(data) {

    return dispatch => {
        dispatch(request());
        regionLocationService.addOffice(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        if (res.status === 201) {
            return {type: REGION.ADD_SUCCESS, payload: res.data};
        }
        return error(res);
    }
}

function updateOffice(data) {
    return dispatch => {
        dispatch(request());
        regionLocationService.updateOffice(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        if (res.status === 202) {
            return {type: REGION.UPDATE_SUCCESS, payload: data};
        }
        return error(res);
    }
}

function readAllRegionAreaOffices(data) {
    return dispatch => {
        dispatch(request());
        regionLocationService.readAllRegionAreaOffices(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: REGION.READ_ALL_SUCCESS, payload: res.data};
    }
}

function changeStatusOfOffice(data) {
    return dispatch => {
        dispatch(request());
        regionLocationService.changeStatusOfOffice(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        if (res.status === 202) {
            return {type: REGION.CHANGE_STATUS_SUCCESS, payload: data};
        }
        return error(res);
    }
}

const regionLocationAction = {
    // region
    createRegion,
    updateRegion,
    readAllRegions,
    changeStatusOfRegion,

    // region area
    createRegionArea,
    updateRegionArea,
    readAllRegionAreas,
    changeStatusOfRegionArea,

    // office
    createOffice,
    updateOffice,
    readAllRegionAreaOffices,
    changeStatusOfOffice,
};

// constants
export const REGION = {

    REQUEST: "REGION_LOCATION_REQUEST",
    ERROR: "REGION_LOCATION_ERROR",
    NOT_FOUND: "REGION_LOCATION_NOT_FOUND",
    VALIDATION_ERROR: "REGION_LOCATION_VALIDATION_ERROR ",
    NOT_ALLOWED: "REGION_LOCATION_NOT_ALLOWED",

    ADD_SUCCESS: "REGION_ADD_SUCCESS",
    UPDATE_SUCCESS: "REGION_UPDATE_SUCCESS",
    CHANGE_STATUS_SUCCESS: "REGION_CHANGE_STATUS_SUCCESS",
    READ_ALL_SUCCESS: "REGION_READ_ALL_SUCCESS"

    // ADD_SUCCESS_REGION: "ADD_SUCCESS_REGION",
    // ADD_SUCCESS_REGION_AREA: "ADD_SUCCESS_REGION_AREA",
    // ADD_SUCCESS_OFFICE: "ADD_SUCCESS_OFFICE",
    // ADD_SUCCESS_LOCATION: "ADD_SUCCESS_LOCATION",
    // ADD_SUCCESS_LOCATION_AREA: "ADD_SUCCESS_LOCATION_AREA",
    //
    // UPDATE_SUCCESS_REGION: "UPDATE_SUCCESS_REGION",
    // UPDATE_SUCCESS_REGION_AREA: "UPDATE_SUCCESS_REGION_AREA",
    // UPDATE_SUCCESS_OFFICE: "UPDATE_SUCCESS_OFFICE",
    // UPDATE_SUCCESS_LOCATION: "UPDATE_SUCCESS_LOCATION",
    // UPDATE_SUCCESS_LOCATION_AREA: "UPDATE_SUCCESS_LOCATION_AREA",
    //
    // READ_ALL_SUCCESS_REGION: "READ_ALL_SUCCESS_REGION",
    // READ_ALL_SUCCESS_LOCATION: "READ_ALL_SUCCESS_LOCATION",
    //
    // CHANGE_STATUS_SUCCESS_REGION: "CHANGE_STATUS_SUCCESS_REGION",
    // CHANGE_STATUS_SUCCESS_REGION_AREA: "CHANGE_STATUS_SUCCESS_REGION_AREA",
    // CHANGE_STATUS_SUCCESS_OFFICE: "CHANGE_STATUS_SUCCESS_OFFICE",
    // CHANGE_STATUS_SUCCESS_LOCATION: "CHANGE_STATUS_SUCCESS_LOCATION",
    // CHANGE_STATUS_SUCCESS_LOCATION_AREA: "CHANGE_STATUS_SUCCESS_LOCATION_AREA",

};

export default regionLocationAction;