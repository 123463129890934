import React, {Component} from 'react';
import ColoredBody from "../../other/ColoredBody";
import BoxHeader from "../../other/BoxHeader";
import {Header, Segment} from "semantic-ui-react";
import OrgSetupForm from "./components/OrgSetupForm";
import authAction from "../../../actions/auth.action";
import qs from "query-string";
import BoxFooter from "../../other/BoxFooter";
import {connect} from "react-redux";
import ErrorMessage from "../../other/ErrorMessage";

class NewOrgSetupPage extends Component {
    constructor(props) {
        super(props);
        this.params = qs.parse(this.props.location.search);
    }

    componentDidMount() {
        if (Object.keys(this.params).length !== 3) {
            alert("Invalid Request!");
            this.props.history.replace("/");
        }
    }

    componentWillReceiveProps(nextProps) {
        const {isDone, history, token} = nextProps;
        if (isDone) {
            history.replace(`/org-setup/done?token=${encodeURIComponent(token)}`);
        }
    }

    componentWillUnmount() {
        this.props.dispatch(authAction.unmount());
    }

    render() {
        const {loading, dispatch, error} = this.props;
        return (
            <ColoredBody boxWidth={"512px"} textAlign={'center'}>
                <BoxHeader/>
                <Segment textAlign={"left"} padded={'very'}>
                    <Header>
                        Step 3: Organization Setup
                    </Header>
                    <br/>
                    <ErrorMessage errors={error}/>
                    <OrgSetupForm
                        loading={loading}
                        onSubmit={(data) => {
                            dispatch(authAction.createOrg({...data, ...this.params}));
                        }}
                    />
                </Segment>
                <BoxFooter/>
            </ColoredBody>
        );
    }
}

function mapStateToProps(state) {
    return state.auth;
}

const cNewOrgSetupPage = connect(mapStateToProps)(NewOrgSetupPage);
export {cNewOrgSetupPage as NewOrgSetupPage};
