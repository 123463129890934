import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Grid, Header, Label, List, Progress, Segment} from "semantic-ui-react";
import helper from "../../../../helper/helper";
import {color} from "../../org/reports/components/barchart/utils";

class DiversityTable extends Component {
    render() {
        const {percentage: index, value} = this.props;
        return (
            <Segment basic>
                <Segment basic textAlign={'center'}>
                    <style>{`.ui.small.progress .bar { border-radius: 0.5em; background-color: ${color.dark_green} }`}</style>
                    <Header as={'h1'}>{index}
                        <small>
                            <small> %</small>
                        </small>
                    </Header>
                    <br/>
                    <Progress percent={index}
                              size={'small'}
                              style={{margin: "auto 0.75em", width: "100%", borderRadius: "0.5em"}}/>
                </Segment>
                <br/>
                <Grid columns='equal'>
                    {helper.diversityChart.map((item, index) =>
                        <Grid.Column style={{padding: "0"}} key={index}>
                            <Segment textAlign={'center'} basic={item.value !== value}
                                     color={item.value === value ? "teal" : undefined}>
                                <List relaxed={'very'}>
                                    <List.Item>
                                        <Label circular size={"huge"}
                                               style={{
                                                   backgroundColor: item.value === value ? color.dark_green : "transparent",
                                                   border: "solid 1.5px",
                                                   borderColor: item.value === value ? color.dark_green : color.grey_50,
                                                   color: item.value === value ? 'white' : color.dark_green
                                               }}>{item.value}</Label>
                                    </List.Item>
                                    <List.Item>
                                        {item.ratio}
                                    </List.Item>
                                    <List.Item>
                                        {item.title}
                                    </List.Item>
                                </List>
                            </Segment>
                        </Grid.Column>
                    )}
                </Grid>
            </Segment>
        );
    }
}

DiversityTable.propTypes = {
    percentage: PropTypes.number,
    value: PropTypes.number
};

export default DiversityTable;
