import React, {Component, Fragment} from 'react';
import Body from "../../../../other/Body";
import {Button, Divider, Form, Input, Label, Segment} from "semantic-ui-react";
import ErrorMessage from "../../../../other/ErrorMessage";
import orgAction from "../../../../../actions/org.action";
import {OrgMenuBar} from "../../other/OrgMenuBar";
import SuccessMessage from "../../../../other/SuccessMessage";
import {orgState} from "../../../../../helper/stateUtil";
import {connect} from "react-redux";
import OrgSubHeader from "../../other/OrgSubHeader";

class OrgSettingsSSOPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_update: false,
            entity_id: "",
            sso_end_point: "",
            slo_end_point: "",
            idp_certificate: "",
            status: false,
            metadata_file: ""
        };
        this.onChange = this.onChange.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onExtract = this.onExtract.bind(this);
        this.linkRef = React.createRef();
    }

    copyLink = () => {
        this.linkRef.current.select();
        document.execCommand('copy');
        alert("Copied!");
    };

    onChange(e, {name, value}) {
        this.setState({[name]: value, is_update: true});
    }

    componentWillReceiveProps(nextProps, nextContext) {
        const {is_sso, sso} = nextProps;
        if (Object.keys(sso).length > 0) {
            this.setState({
                ...sso,
                is_update: is_sso
            });
        }
    }

    onExtract = e => {
        e.preventDefault();
        this.props.dispatch(orgAction.parseMetadata({
            metadata_file: this.state.metadata_file
        }));
    };

    onSave(e) {
        e.preventDefault();
        this.props.dispatch(orgAction.updateSettingSSODetails({
            id: this.props.org.id,
            ...this.state
        }));
    }

    componentDidMount() {
        const {dispatch, org} = this.props;
        dispatch(orgAction.getSSODetails({id: org.id}));
    }

    render() {
        const {error, loading, isDone, is_sso} = this.props;
        const {entity_id, sso_end_point, slo_end_point, idp_certificate, hash, is_update, metadata_file} = this.state;
        return (
            <Body loading={loading}>

                <OrgMenuBar/>

                <OrgSubHeader title={"SSO(Single Sign On) Settings"} />

                <Segment basic padded='very'>
                    <ErrorMessage errors={error}/>
                    <SuccessMessage messages={isDone ? ["Successfully Updated!"] : []}/>
                    {!is_sso && <Fragment>
                        <Form onSubmit={this.onExtract} method={'post'}>
                            <Form.Group>
                                <Form.Field width={10}>
                                    <label>Upload Metadata file:</label>
                                    <input type={'file'}
                                           accept="text/xml"
                                           onChange={(e) => {
                                               this.setState({metadata_file: e.target.files[0]});
                                           }}/>
                                </Form.Field>
                                <Form.Field>
                                    <label>&nbsp;</label>
                                    <Button type='submit' primary circular
                                            disabled={!metadata_file}
                                            floated='right'>Extract Values</Button>
                                </Form.Field>
                            </Form.Group>
                            <br/>
                        </Form>
                        <Divider horizontal>Or</Divider>
                    </Fragment>
                    }
                    <Form onSubmit={this.onSave} method={'post'}>
                        <br/>

                        <Form.Input
                            label='Entity ID'
                            placeholder='Entity ID'
                            onChange={this.onChange}
                            name={"entity_id"}
                            value={entity_id}/>

                        <Form.Input
                            label='SSO End Point URL'
                            placeholder='SSO End Point URL'
                            onChange={this.onChange}
                            name={"sso_end_point"}
                            value={sso_end_point}/>

                        <Form.Input
                            label='SLO End Point URL'
                            placeholder='SLO End Point URL'
                            onChange={this.onChange}
                            name={"slo_end_point"}
                            value={slo_end_point}/>

                        <Form.TextArea
                            rows={12}
                            label='IDP Certificate'
                            placeholder='IDP Certificate'
                            onChange={this.onChange}
                            name={"idp_certificate"}
                            value={idp_certificate}/>

                        <Button type='submit' primary circular
                                disabled={!is_update}
                                floated='right'>Update</Button>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        {
                            (entity_id && sso_end_point && idp_certificate && hash) &&
                            <Form.Group>
                                <Form.Field width={12}>
                                    <label>Qualmet Login Link:</label>
                                    <Input labelPosition='right' type='text' readOnly
                                           value={process.env.REACT_APP_API_HOST_DOMAIN + "/saml/" + hash + "/sso"}
                                           ref={this.linkRef}>
                                        <input/>
                                        <Label as="a" onClick={() => this.copyLink()}>Copy Link</Label>
                                    </Input>
                                </Form.Field>
                                <Form.Field>
                                    <label>&nbsp;</label>
                                    <Button type='button' primary circular
                                            onClick={() => window.open(process.env.REACT_APP_API_HOST_DOMAIN + "/saml/" + hash + "/metadata.xml")}
                                            disabled={!(entity_id && sso_end_point && idp_certificate && hash)}>
                                        Download Metadata File
                                    </Button>
                                </Form.Field>
                            </Form.Group>
                        }
                        <br/>
                    </Form>
                </Segment>

            </Body>
        );
    }
}

function mapStateToProps(state) {
    let org = orgState(state);
    org.sso = state.org.sso;
    org.error = state.org.error;
    org.loading = state.org.loading;
    org.isDone = state.org.isDone;
    org.is_sso = state.org.is_sso;
    return {
        ...org
    };
}

const cOrgSettingsPage = connect(mapStateToProps)(OrgSettingsSSOPage);
export {cOrgSettingsPage as OrgSettingsSSOPage};
