import React, {Component} from 'react';
import authAction from "../../../actions/auth.action";
import {connect} from "react-redux";

class LogoutPage extends Component {

    componentDidMount() {
        this.props.dispatch(authAction.logout())
    }

    render() {
        return (
            <div/>
        );
    }
}

const cOrgHeader = connect(() => {
})(LogoutPage);
export {cOrgHeader as LogoutPage};
