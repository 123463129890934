import React, {Component} from 'react';
import PropTypes from 'prop-types';
import * as d3 from "d3";
import {max, scaleLinear} from "d3";

class BarChart extends Component {
    constructor(props) {
        super(props);
        this.chart = React.createRef();
    }

    componentDidMount() {
        this.draw();
    }

    draw = () => {
        const {data, maxX} = this.props;
        let {width, height} = this.props;
        const div = this.chart.current;
        if (!div) return;
        const maxScore = max(data);
        // if (data && data.length === 0) return;
        div.innerHTML = '';
        const margin = {
            left: 8,
            top: 12,
            bottom: 10,
            right: 4
        };
        if (!width) {
            width = 168;
        }
        if (!height) {
            height = 74;
        }
        const innerWidth = width - margin.left - margin.right;
        const innerHeight = height - margin.top - margin.bottom;
        // const leftMargin = 12;
        const labelHeight = height - 28;
        const barHeightMargin = 10;
        const barHeight = labelHeight - barHeightMargin;
        const barWidth = width / maxX;

        const step = (innerWidth) / maxX;
        const ticks = d3.range(0, innerWidth, step);

        const svg = d3.select(div)
            .append('svg')
            .attr('width', width)
            .attr('height', height);

        const xScale = scaleLinear()
            .domain([0, maxX])
            .range([0, width]);

        const container = svg.append('g')
            .attr('class', 'container')
            .attr("transform", `translate(${margin.left - 4},${margin.top})`);

        // const xLinesGroup = container.append("g")
        //     .attr('class', 'x-lines-group');

        const underline = container.append("g")
            .attr('class', 'underline');

        underline.append('rect')
            .attr('x', -2)
            .attr('y', innerHeight - 16)
            .attr('width', width)
            .attr('height', 1)
            .attr('fill', '#CCCCCC');

        const pers = container.append('g')
            .attr('class', 'pers');

        const labels = container.append('g')
            .attr('class', 'labels');

        const bars = container.append('g')
            .attr('class', 'bars');

        ticks.push(innerWidth);
        [...Array(maxX)].forEach((_, index) => {
            labels.append('text')
                .attr('x', ((barWidth / 2) + (barWidth * index) - 3))
                .attr('y', labelHeight + 2)
                .attr('text-anchor', 'middle')
                .attr('fill', '#888888')
                .attr('style', 'font-family: Roboto, sans-serif; font-size: 10px')
                .text(index + 1);
        });

        data.forEach((d, index) => {
            bars.append('rect')
                .attr('x', xScale(index))
                .attr('y', d <= 0 ? 0 : (((maxScore - d) / maxScore) * barHeight))
                .attr('width', barWidth - 6)
                .attr('height', d <= 0 ? 0 : (d / maxScore) * barHeight)
                .attr('fill', '#CCCCCC');
        });

        data.forEach((d, index) => {
            pers.append('text')
                .attr('x', ((barWidth / 2) + (barWidth * index) - 3))
                .attr('y', d <= 0 ? 0 : ((((maxScore - d) / maxScore) * barHeight) - 4))
                .attr('text-anchor', 'middle')
                .attr('fill', '#000')
                .attr('opacity', d > 0 ? 1 : 0)
                .attr('style', 'font-family: Roboto, sans-serif; font-size: 10px')
                .text(d);
        });

    };

    render() {
        return (
            <div ref={this.chart}/>
        );
    }
}

BarChart.propTypes = {
    data: PropTypes.array.isRequired,
    maxX: PropTypes.number.isRequired,
    height: PropTypes.number,
    width: PropTypes.number
};

export default BarChart;
