import React, {Component} from 'react';
import {connect} from "react-redux";
import Body from "../../../../other/Body";
import {orgState} from "../../../../../helper/stateUtil";
import {OrgMenuBar, reportsPath} from "../../other/OrgMenuBar";
import Report from "../components/other/Report";
import TabHeader from "../../other/TabHeader";
import {ReportLawyerSummary} from './components/ReportLawyerSummary';
import {ReportLawyerDetails} from './components/ReportLawyerDetails';
import {ReportLawyerComments} from './components/ReportLawyerComments';

class LawyerReportPage extends Component {
    constructor(props) {
        super(props);
        this.lawyerId = props.match.params.id;
        this.tabs = [
            {
                text: "Summary",
                value: "summary",
                render: <ReportLawyerSummary lawyerId={this.lawyerId}/>
            },
            {
                text: "Details",
                value: 'details',
                render: <ReportLawyerDetails lawyerId={this.lawyerId}/>
            },
            {
                text: "Comments",
                value: 'comments',
                render: <ReportLawyerComments lawyerId={this.lawyerId}/>
            }
        ];
    }

    render() {
        const {loadingFiltersAndSummary, match} = this.props;
        const type = match.params.type;
        let content;
        let activeIndex = 0;
        this.tabs.forEach((tab, index) => {
            if (tab.value === type) {
                activeIndex = index;
                content = tab.render;
            }
        });

        return (
            <Body loading={loadingFiltersAndSummary}>
                <OrgMenuBar/>
                <TabHeader
                    title={`Evalutees`}
                    backArrow
                    tabs={this.tabs}
                    activeIndex={activeIndex}
                    onTabChange={(tab) => {
                        this.props.history.replace(`${reportsPath}/lawyers/${this.lawyerId}/${tab.value}`);
                    }}/>
                <Report>
                    {content}
                </Report>
            </Body>
        );
    }
}

function mapStateToProps(state) {
    return {...orgState(state), ...state.report};
}

const cLawyerReportPage = connect(mapStateToProps)(LawyerReportPage);
export {cLawyerReportPage as LawyerReportPage};
