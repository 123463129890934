import React, {Component} from 'react';
import {orgState} from "../../../../../../helper/stateUtil";
import {connect} from "react-redux";
import {Button, Segment} from "semantic-ui-react";
import evaluationAction from "../../../../../../actions/evaluation.action";
import QuestionBox from "../../../../../other/QuestionBox";
import {withRouter} from "react-router-dom";
import * as qs from "query-string";

class SinglePageEvaluation extends Component {
    constructor(props) {
        super(props);
        this.params = qs.parse(props.location.search);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.isDone && nextProps.questions.length === 0) {
            this.props.history.go(-1);
        }
    }

    onSubmit = () => {
        let {questions, dispatch} = this.props;
        const {type} = this.params;
        let flag = true;
        questions.filter(q => q.type === 0)
            .forEach(({answer, text_answer}) => {
                if (answer === -1 && text_answer == null) {
                    flag = false;
                }
            });
        if (flag) {
            const ques = questions.filter(q => q.type !== 0);
            if (ques.length !== 0) {
                // yes / no
                if (ques[0].answer === -1 || ques[1].answer === -1 || ques[4].answer === -1) {
                    flag = false;
                }
                if (ques[1].answer > 8) {
                    // ans 9 or 10
                    if (!ques[3].text_answer) {
                        flag = false;
                    }
                } else {
                    // ans between 0 or 8
                    if (!ques[2].text_answer) {
                        flag = false;
                    }
                }
            }
        }
        // if (!flag) {
        //     alert("Incomplete submission!");
        //     return;
        // }
        dispatch(evaluationAction.evaluateAll(questions, type));
    };

    render() {
        let {loading, questions} = this.props;
        return (
            <Segment basic textAlign='center' padded>
                <Button type='submit' primary floated='right' circular
                        loading={loading} disabled={loading}
                        onClick={this.onSubmit}>Submit</Button>
                {questions.map((que, index) => {
                    if (!que.hidden) {
                        return <QuestionBox key={index}
                                            loading={loading}
                                            isMultiPage={false}
                                            activeIndex={index}
                                            lastIndex={questions.length - 1}
                                            style={{marginTop: "1em"}}
                                            data={que}
                                            onChange={(data) => {
                                                this.props.dispatch(evaluationAction.onChange(data, index));
                                            }}
                        />
                    } else {
                        return <div key={index}/>
                    }
                })}
                <Button type='submit' primary floated='right' circular
                        loading={loading} disabled={loading}
                        onClick={this.onSubmit}>Submit</Button>
                <br/>
                <br/>
            </Segment>
        );
    }
}

SinglePageEvaluation.propTypes = {};

function mapStateToProps(state) {
    let s = orgState(state);
    return {...s, ...state.evaluations};
}

const cSinglePageEvaluation = withRouter(connect(mapStateToProps)(SinglePageEvaluation));
export {cSinglePageEvaluation as SinglePageEvaluation};
