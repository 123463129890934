import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {orgState} from "../../../../../../helper/stateUtil";
import ReportHeader from "../../components/header/ReportHeader";
import {Form, Grid, List, Table} from "semantic-ui-react";
import DataFilter from "../../components/DataFilter";
import NoDataMessage from "../../components/other/NoDataMessage";
import reportAction from "../../../../../../actions/report.action";
import AnswerViewer from "../../components/answer/AnswerViewer";
import FullScreenLoader from "../../../../../other/FullScreenLoader";

class ReportMatterDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cycles: null,
            questions: null
        };
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        const {matterId} = this.props;
        this.props.dispatch(reportAction.getMatterDetailsFilter({
            matter_id: matterId
        }));
    }

    componentWillReceiveProps(nextProps) {
        const {loadingFiltersAndSummary, isDone} = nextProps;
        let s = {};
        if (!loadingFiltersAndSummary && isDone) {
            [
                'cycles',
                'questions',
            ].forEach((item) => {
                if (nextProps[item] && nextProps[item].length > 0) {
                    s[item] = nextProps[item][0].value;
                }
            });
            this.props.dispatch(reportAction.getMatterDetails({
                matter_id: this.props.matterId,
                questions: s['questions']
            }));
        }
        this.setState((state) => {
            return {...state, ...s};
        });
    }

    onChange = (_, {name, value}) => {
        this.setState({[name]: value});
        this.props.dispatch(reportAction.getMatterDetails({
            org_id: this.props.org.id,
            matter_id: this.props.matterId,
            ...this.state,
            [name]: value
        }));
    };

    render() {
        const {cycles, questions} = this.state;
        const {loadingData, header, org, data} = this.props;
        let que = this.props.questions.find((q) => q.value === questions) || {};
        return (
            <React.Fragment>
                <FullScreenLoader active={loadingData}/>
                <ReportHeader>
                    <Grid columns={'equal'} stackable>
                        <Grid.Column>
                            <List>
                                <List.Item>Matter Name: <b>{header.matter_name}</b></List.Item>
                                <List.Item>{org.str_client_firm} Name: <b>{header.client_name}</b></List.Item>
                            </List>
                        </Grid.Column>
                        <Grid.Column>
                            Status: <b>{header.status ? "Open" : `Closed on ${header.closed_at}`}</b>
                        </Grid.Column>
                    </Grid>
                </ReportHeader>
                <DataFilter>
                    <Form.Group>
                        <Form.Field width={6}>
                            <Form.Dropdown
                                label={`By Cycle:`}
                                selection name={"cycles"}
                                value={cycles}
                                onChange={this.onChange}
                                options={this.props.cycles}/>
                        </Form.Field>
                        <Form.Field width={10}>
                            <Form.Dropdown
                                label={`By Individual Question:`}
                                value={questions}
                                selection name={'questions'}
                                onChange={this.onChange}
                                options={this.props.questions}/>
                        </Form.Field>
                    </Form.Group>
                </DataFilter>

                <NoDataMessage active={data.length === 0}/>

                <b>Question: </b>{que.text}
                <br/>
                {data.map((d, di) => {
                        let c;
                        if (d.data) {
                            c = <Table key={di}>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>
                                            {d.cycle}
                                            &nbsp;&nbsp;
                                            Evaluator
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>Score</Table.HeaderCell>
                                        <Table.HeaderCell>Comment</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {d.data && d.data.map((dd, ddi) =>
                                        <Table.Row key={ddi}>
                                            <Table.Cell>
                                                {dd.evaluator_name}
                                            </Table.Cell>
                                            <Table.Cell>
                                                <AnswerViewer
                                                    answer_type={dd.answer_type}
                                                    answer={dd.answer}
                                                    text_answer={dd.text_answer}/>
                                            </Table.Cell>
                                            <Table.Cell>{dd.comments}</Table.Cell>
                                        </Table.Row>
                                    )}
                                </Table.Body>
                            </Table>
                        } else {
                            c = <div key={di}/>
                        }
                        return c;
                    }
                )}

            </React.Fragment>
        );
    }
}

ReportMatterDetails.propTypes = {
    matterId: PropTypes.string.isRequired
};

function mapStateToProps(state) {
    return {...orgState(state), ...state.report};
}

const cReportMatterDetails = connect(mapStateToProps)(ReportMatterDetails);
export {cReportMatterDetails as ReportMatterDetails};
