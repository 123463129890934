import React, {Component} from 'react';
import {connect} from "react-redux";
import {AddUserForm} from "../../form/users/AddUserForm";
import SABody from "../other/SABody";
import SASubHeader from "../other/SASubHeader";

class SuperAdminAddUserPage extends Component {
    render() {
        const {loading} = this.props;
        return (
            <SABody loading={loading}>
                <SASubHeader title={"Add User"} backArrow/>
                 <AddUserForm/>
            </SABody>
        );
    }
}

function mapStateToProps(state) {
    return {
        ...state.user
    };
}

const cSuperAdminAddUserPage = connect(mapStateToProps)(SuperAdminAddUserPage);
export {cSuperAdminAddUserPage as SuperAdminAddUserPage};