import {REGION} from "../actions/region_location.action";

const initState = {
    isAllowed: true,
    // list of regions or location state
    loading: false,
    items: [], // items or either regions or locations based on selection
    error: [],
    isDone: false
};

export default function regionLocationReducer(state = initState, action) {
    // reset
    state.error = [];
    state.loading = false;
    state.isAllowed = true;
    state.isDone = false;

    // filter
    switch (action.type) {

        // common
        case REGION.REQUEST:
            return {...state, loading: true};

        case REGION.VALIDATION_ERROR:
            return {...state, error: action.payload};

        case REGION.ERROR:
            return {...state, error: [...action.payload]};

        case REGION.NOT_FOUND:
            return {...state, not_found: true};

        // not allowed to access
        case REGION.NOT_ALLOWED:
            return {...state, isAllowed: false};

        // add
        case REGION.ADD_SUCCESS:
            return {...state, isDone: true, items: [...state.items, action.payload]};

        // update
        case REGION.UPDATE_SUCCESS:
            state.items[state.items.findIndex((item) => item.id === action.payload.id)] = action.payload;
            return {...state, isDone: true};

        // read all
        case REGION.READ_ALL_SUCCESS:
            return {...state, items: action.payload};

        case REGION.CHANGE_STATUS_SUCCESS:
            state.items[state.items.findIndex((item) => item.id === action.payload.id)].status = action.payload.status;
            return {...state};

        default:
            return state;
    }
}