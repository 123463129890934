import React, {Component} from 'react';
import ColoredBody from "../../other/ColoredBody";
import BoxFooter from "../../other/BoxFooter";
import {connect} from "react-redux";
import BoxHeader from "../../other/BoxHeader";
import {Header, Segment} from "semantic-ui-react";
import {AccountVerificationForm} from "./components/AccountVerificationForm";
import authAction from "../../../actions/auth.action";
import qs from 'query-string';

class VerifyUserAccount extends Component {
    constructor(props) {
        super(props);
        this.params = qs.parse(props.location.search);
    }

    componentDidMount() {
        const {history, dispatch} = this.props;
        if (Object.keys(this.params).length !== 3) {
            alert("Invalid Request!");
            history.replace("/");
        } else {
            dispatch(authAction.getEmailOfUser(this.params));
        }
    }

    render() {
        const {dispatch} = this.props;
        return (
            <ColoredBody boxWidth={"584px"} textAlign={'center'}>
                <BoxHeader/>
                <Segment textAlign={"left"} padded>
                    <Header>
                        Verify Email Address
                    </Header>
                    <br/>
                    <AccountVerificationForm
                        onSubmit={(code) => {
                            dispatch(authAction.validateAccount({
                                ...this.params,
                                code: code,
                                r_t: this.params.flag === "true" ? 'co' : 'cu'
                            }));
                        }}
                        onResendCode={() => {
                            dispatch(authAction.resendVerificationCode({...this.params}));
                        }}
                    />
                </Segment>
                <BoxFooter/>
            </ColoredBody>
        );
    }
}

function mapStateToProps(state) {
    return state.auth;
}

const cVerifyUserAccount = connect(mapStateToProps)(VerifyUserAccount);
export {cVerifyUserAccount as VerifyUserAccount};
