import planService from "../services/plan.service";

function request() {
    return {type: PLAN.REQUEST};
}

function error(reason) {
    reason = reason.response;
    // validation error
    if (reason.status === 422) {
        return {type: PLAN.VALIDATION_ERROR, payload: reason.data};
    }
    return {type: PLAN.ERROR, payload: reason.data};
}

function create(data) {

    return dispatch => {
        dispatch(request());
        planService.add(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        if (res.status === 201) {
            return {type: PLAN.ADD_SUCCESS, payload: res.data};
        }
        return error(res);
    }
}

function update(data) {

    return dispatch => {
        dispatch(request());
        planService.update(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        if (res.status === 202) {
            return {type: PLAN.UPDATE_SUCCESS, payload: res.data};
        }
        return error(res);
    }
}

function getAll(data) {

    return dispatch => {
        dispatch(request());
        planService.readAll(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: PLAN.READ_ALL_SUCCESS, payload: res.data};
    }
}

function get(data) {

    return dispatch => {
        dispatch(request());
        planService.read(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: PLAN.READ_SUCCESS, payload: res.data};
    }
}

function changeStatus(data) {

    return dispatch => {
        dispatch(request());
        planService.changeStatus(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        if (res.status === 202) {
            return {type: PLAN.CHANGE_STATUS_SUCCESS, payload: data};
        }
    }
}

function getCurrentPlan(data) {

    return dispatch => {
        dispatch(request());
        planService.getCurrentPlan(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: PLAN.GET_CURRENT_PLAN, payload: res.data};
    }
}

function getActivePlans(data) {

    return dispatch => {
        dispatch(request());
        planService.getActivePlans(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: PLAN.GET_ACTIVE_PLANS, payload: res.data};
    }
}

function getAllOrg(data) {

    return dispatch => {
        dispatch(request());
        planService.getAllOrg(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: PLAN.GET_ALL_ORGS, payload: res.data};
    }
}

function requestPlan(data) {

    return dispatch => {
        dispatch(request());
        planService.requestPlan(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: PLAN.REQUEST_PLAN_SUCCESS, payload: res.data};
    }
}

function unmount() {
    return dispatch => dispatch({type: PLAN.UNMOUNT});
}

const planAction = {
    create,
    update,
    getAll,
    get,
    changeStatus,
    getActivePlans,
    unmount,
    getCurrentPlan,
    getAllOrg,
    requestPlan
};

// constants
export const PLAN = {

    REQUEST: "PLAN_REQUEST",
    ERROR: "PLAN_ERROR",
    NOT_FOUND: "PLAN_NOT_FOUND",
    VALIDATION_ERROR: "PLAN_VALIDATION_ERROR ",
    NOT_ALLOWED: "PLAN_NOT_ALLOWED",

    ADD_SUCCESS: "PLAN_ADD_SUCCESS",
    UPDATE_SUCCESS: "PLAN_UPDATE_SUCCESS",
    READ_ALL_SUCCESS: "PLAN_READ_ALL_SUCCESS",
    READ_SUCCESS: "PLAN_READ_SUCCESS",
    CHANGE_STATUS_SUCCESS: "PLAN_CHANGE_STATUS_SUCCESS",

    GET_ACTIVE_PLANS: "GET_ACTIVE_PLANS",

    UNMOUNT: "PLAN_UNMOUNT",

    GET_CURRENT_PLAN: "PLAN_GET_CURRENT_PLAN",

    GET_ALL_ORGS: "PLAN_GET_ALL_ORGS",

    REQUEST_PLAN_SUCCESS: "REQUEST_PLAN_SUCCESS"
};

export default planAction;