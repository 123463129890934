import reportService from "../services/report.service";

// common
function error(reason) {
    reason = reason.response;
    switch (reason.status) {
        case 403:
            return {type: REPORT.NOT_ALLOWED};
        case 404:
            return {type: REPORT.NOT_FOUND};
        case 422:
            return {type: REPORT.VALIDATION_ERROR, payload: reason.data};
        default:
            return {type: REPORT.ERROR, payload: reason.data};
    }
}

//################# MATTER REPORT #################

function getAllMattersReport(data) {

    return dispatch => {
        dispatch({type: REPORT.REQUEST_DATA});
        reportService.getAllMattersReport(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: REPORT.ALL_MATTERS_REPORT_SUCCESS, payload: res.data};
    }
}

function getMatterReport(data) {

    return dispatch => {
        dispatch({type: REPORT.REQUEST_DATA});
        reportService.getMatterReport(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: REPORT.MATTER_REPORT_SUCCESS, payload: res.data};
    }
}

function getAllMattersSummaryAndFilters(data) {
    return dispatch => {
        dispatch({type: REPORT.REQUEST_FILTERS_SUMMARY});
        reportService.getAllMattersSummaryAndFilters(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: REPORT.ALL_MATTERS_REPORT_SUMMARY_AND_FILTERS, payload: {...res.data, ...data.org}};
    }
}

function getMatterSummaryAndFilters(data) {
    return dispatch => {
        dispatch({type: REPORT.REQUEST_FILTERS_SUMMARY});
        reportService.getMatterSummaryAndFilters(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: REPORT.MATTER_REPORT_SUMMARY_AND_FILTERS, payload: {...res.data, ...data.org}};
    }
}

function getMatterDetailsFilter(data) {
    return dispatch => {
        dispatch({type: REPORT.REQUEST_FILTERS_SUMMARY});
        reportService.getMatterDetailsFilter(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: REPORT.MATTER_DETAIL_FILTER_AND_SUMMARY, payload: {...res.data, ...data.org}};
    }
}

function getMatterDetails(data) {

    return dispatch => {
        dispatch({type: REPORT.REQUEST_DATA});
        reportService.getMatterDetails(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: REPORT.MATTER_DETAIL_SUCCESS, payload: res.data};
    }
}

function getMatterCommentsFilter(data) {
    return dispatch => {
        dispatch({type: REPORT.REQUEST_FILTERS_SUMMARY});
        reportService.getMatterCommentsFilter(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: REPORT.MATTER_COMMENTS_FILTER_AND_SUMMARY, payload: {...res.data, ...data.org}};
    }
}

function getMatterComments(data) {

    return dispatch => {
        dispatch({type: REPORT.REQUEST_DATA});
        reportService.getMatterComments(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: REPORT.MATTER_COMMENTS_SUCCESS, payload: res.data};

    }
}

//################# MATTER REPORT #################

//################# CLIENT REPORT #################

function getAllClientsReport(data) {
    return dispatch => {
        dispatch({type: REPORT.REQUEST_DATA});
        reportService.getAllClientsReport(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: REPORT.ALL_CLIENTS_REPORT_SUCCESS, payload: res.data};
    }
}

function getAllClientsSummaryAndFilters(data) {
    return dispatch => {
        dispatch({type: REPORT.REQUEST_FILTERS_SUMMARY});
        reportService.getAllClientsSummaryAndFilters(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: REPORT.ALL_CLIENTS_REPORT_SUMMARY_AND_FILTERS, payload: {...res.data, ...data.org}};
    }
}

function getClientSummaryAndFilters(data) {
    return dispatch => {
        dispatch({type: REPORT.REQUEST_FILTERS_SUMMARY});
        reportService.getClientSummaryAndFilters(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: REPORT.CLIENT_REPORT_SUMMARY_AND_FILTERS, payload: {...res.data, ...data.org}};
    }
}

function getClientReport(data) {

    return dispatch => {
        dispatch({type: REPORT.REQUEST_DATA});
        reportService.getClientReport(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: REPORT.CLIENT_REPORT_SUCCESS, payload: res.data};
    }
}

function getClientDetailsFilter(data) {
    return dispatch => {
        dispatch({type: REPORT.REQUEST_FILTERS_SUMMARY});
        reportService.getClientDetailsFilter(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: REPORT.CLIENT_DETAIL_FILTER_AND_SUMMARY, payload: {...res.data, ...data.org}};
    }
}

function getClientDetails(data) {

    return dispatch => {
        dispatch({type: REPORT.REQUEST_DATA});
        reportService.getClientDetails(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: REPORT.CLIENT_DETAIL_SUCCESS, payload: res.data};
    }
}

function getClientLawyersFilter(data) {
    return dispatch => {
        dispatch({type: REPORT.REQUEST_FILTERS_SUMMARY});
        reportService.getClientLawyersFilter(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: REPORT.CLIENT_LAWYERS_FILTER_AND_SUMMARY, payload: {...res.data, ...data.org}};
    }
}

function getClientLawyers(data) {

    return dispatch => {
        dispatch({type: REPORT.REQUEST_DATA});
        reportService.getClientLawyers(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: REPORT.CLIENT_LAWYERS_SUCCESS, payload: res.data};
    }
}

function getClientCommentsFilter(data) {
    return dispatch => {
        dispatch({type: REPORT.CLIENT_COMMENTS_REQUEST});
        reportService.getClientCommentsFilter(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: REPORT.CLIENT_COMMENTS_FILTER_AND_SUMMARY, payload: {...res.data, ...data.org}};
    }
}

function getClientCommentsQuesFilter(data) {
    return dispatch => {
        dispatch({type: REPORT.CLIENT_COMMENTS_REQUEST});
        reportService.getClientCommentsQuesFilter(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: REPORT.CLIENT_COMMENTS_FILTER_QUES, payload: {...res.data, ...data.org}};
    }
}

function getClientEvalComments(data) {
    return dispatch => {
        dispatch({type: REPORT.CLIENT_COMMENTS_REQUEST});
        reportService.getClientEvalComments(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: REPORT.CLIENT_COMMENTS_SUCCESS, payload: {...res.data, ...data.org}};
    }
}

//################# CLIENT REPORT #################


//################# LAWYER/EVALUATEE REPORT #################

function getAllLawyersReport(data) {
    return dispatch => {
        dispatch({type: REPORT.REQUEST_DATA});
        reportService.getAllLawyersReport(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: REPORT.ALL_LAWYERS_REPORT_SUCCESS, payload: res.data};
    }
}

function getAllLawyersSummaryAndFilters(data) {
    return dispatch => {
        dispatch({type: REPORT.REQUEST_FILTERS_SUMMARY});
        reportService.getAllLawyersSummaryAndFilters(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: REPORT.ALL_LAWYERS_REPORT_SUMMARY_AND_FILTERS, payload: {...res.data, ...data.org}};
    }
}

function getLawyerSummaryAndFilters(data) {
    return dispatch => {
        dispatch({type: REPORT.REQUEST_FILTERS_SUMMARY});
        reportService.getLawyerSummaryAndFilters(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: REPORT.LAWYER_REPORT_SUMMARY_AND_FILTERS, payload: {...res.data, ...data.org}};
    }
}

function getLawyerReport(data) {

    return dispatch => {
        dispatch({type: REPORT.REQUEST_DATA});
        reportService.getLawyerReport(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: REPORT.LAWYER_REPORT_SUCCESS, payload: res.data};
    }
}

function getLawyerDetailsFilter(data) {
    return dispatch => {
        dispatch({type: REPORT.REQUEST_FILTERS_SUMMARY});
        reportService.getLawyerDetailsFilter(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: REPORT.LAWYER_DETAIL_FILTER_AND_SUMMARY, payload: {...res.data, ...data.org}};
    }
}

function getLawyerDetails(data) {

    return dispatch => {
        dispatch({type: REPORT.REQUEST_DATA});
        reportService.getLawyerDetails(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: REPORT.LAWYER_DETAIL_SUCCESS, payload: res.data};
    }
}

function getLawyerCommentsFilter(data) {
    return dispatch => {
        dispatch({type: REPORT.LAWYER_COMMENTS_REQUEST});
        reportService.getLawyerCommentsFilter(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: REPORT.LAWYER_COMMENTS_FILTER_AND_SUMMARY, payload: {...res.data, ...data.org}};
    }
}

function getLawyerCommentsQuesFilter(data) {
    return dispatch => {
        dispatch({type: REPORT.LAWYER_COMMENTS_REQUEST});
        reportService.getLawyerCommentsQuesFilter(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: REPORT.LAWYER_COMMENTS_FILTER_QUES, payload: {...res.data, ...data.org}};
    }
}

function getLawyerEvalComments(data) {
    return dispatch => {
        dispatch({type: REPORT.LAWYER_COMMENTS_REQUEST});
        reportService.getLawyerEvalComments(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: REPORT.LAWYER_COMMENTS_SUCCESS, payload: {...res.data, ...data.org}};
    }
}

//################# LAWYER/EVALUATEE REPORT #################

//################# PRACTICE AREA REPORT #################

function getAllPracticeAreaReport(data) {
    return dispatch => {
        dispatch({type: REPORT.REQUEST_DATA});
        reportService.getAllPracticeAreaReport(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: REPORT.ALL_PRACTICE_REPORT_SUCCESS, payload: res.data};
    }
}

function getAllPracticeAreaSummaryAndFilters(data) {
    return dispatch => {
        dispatch({type: REPORT.REQUEST_FILTERS_SUMMARY});
        reportService.getAllPracticeAreaSummaryAndFilters(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: REPORT.ALL_PRACTICE_REPORT_SUMMARY_AND_FILTERS, payload: {...res.data, ...data.org}};
    }
}

//################# PRACTICE AREA REPORT #################

//################# REGION REPORT #################

function getAllRegionsReport(data) {
    return dispatch => {
        dispatch({type: REPORT.REQUEST_DATA});
        reportService.getAllRegionsReport(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: REPORT.ALL_REGIONS_REPORT_SUCCESS, payload: res.data};
    }
}

function getAllRegionsSummaryAndFilters(data) {
    return dispatch => {
        dispatch({type: REPORT.REQUEST_FILTERS_SUMMARY});
        reportService.getAllRegionsSummaryAndFilters(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: REPORT.ALL_REGIONS_REPORT_SUMMARY_AND_FILTERS, payload: {...res.data, ...data.org}};
    }
}

function getOfficeSummaryAndFilters(data) {
    return dispatch => {
        dispatch({type: REPORT.REQUEST_FILTERS_SUMMARY});
        reportService.getOfficeSummaryAndFilters(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: REPORT.OFFICE_REPORT_SUMMARY_AND_FILTERS, payload: {...res.data, ...data.org}};
    }
}

function getOfficeReport(data) {

    return dispatch => {
        dispatch({type: REPORT.REQUEST_DATA});
        reportService.getOfficeReport(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: REPORT.OFFICE_REPORT_SUCCESS, payload: res.data};
    }
}

function getOfficeDetailsFilter(data) {
    return dispatch => {
        dispatch({type: REPORT.REQUEST_FILTERS_SUMMARY});
        reportService.getOfficeDetailsFilter(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: REPORT.OFFICE_DETAIL_FILTER_AND_SUMMARY, payload: {...res.data, ...data.org}};
    }
}

function getOfficeDetails(data) {

    return dispatch => {
        dispatch({type: REPORT.REQUEST_DATA});
        reportService.getOfficeDetails(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: REPORT.OFFICE_DETAIL_SUCCESS, payload: res.data};
    }
}

function getDiversitySummaryAndFilters(data) {
    return dispatch => {
        dispatch({type: REPORT.REQUEST_FILTERS_SUMMARY});
        reportService.getDiversitySummaryAndFilters(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: REPORT.DIVERSITY_REPORT_SUMMARY_AND_FILTERS, payload: {...res.data, ...data.org}};
    }
}

function getDiversityReport(data) {

    return dispatch => {
        dispatch({type: REPORT.REQUEST_DATA});
        reportService.getDiversityReport(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: REPORT.DIVERSITY_REPORT_SUCCESS, payload: res.data};
    }
}

//################# REGION REPORT #################

const reportAction = {
    // matters report
    getAllMattersReport,
    getAllMattersSummaryAndFilters,
    getMatterReport,
    getMatterSummaryAndFilters,
    getMatterDetailsFilter,
    getMatterDetails,
    getMatterCommentsFilter,
    getMatterComments,

    // clients report
    getAllClientsReport,
    getAllClientsSummaryAndFilters,
    getClientSummaryAndFilters,
    getClientReport,
    getClientDetailsFilter,
    getClientDetails,
    getClientLawyers,
    getClientLawyersFilter,
    getClientCommentsFilter,
    getClientEvalComments,
    getClientCommentsQuesFilter,

    // lawyers/EVALUATEE
    getAllLawyersReport,
    getAllLawyersSummaryAndFilters,
    getLawyerSummaryAndFilters,
    getLawyerReport,
    getLawyerDetailsFilter,
    getLawyerDetails,
    getLawyerEvalComments,
    getLawyerCommentsFilter,
    getLawyerCommentsQuesFilter,

    // practice areas
    getAllPracticeAreaReport,
    getAllPracticeAreaSummaryAndFilters,

    // regions
    getAllRegionsReport,
    getAllRegionsSummaryAndFilters,
    getOfficeSummaryAndFilters,
    getOfficeReport,
    getOfficeDetailsFilter,
    getOfficeDetails,

    // diversity
    getDiversityReport,
    getDiversitySummaryAndFilters,

};

// constants
export const REPORT = {

    REQUEST_FILTERS_SUMMARY: "REPORT_REQUEST_FILTERS_SUMMARY",
    REQUEST_DATA: "REPORT_REQUEST_DATA",
    ERROR: "REPORT_ERROR",
    NOT_FOUND: "REPORT_NOT_FOUND",
    VALIDATION_ERROR: "REPORT_VALIDATION_ERROR ",
    NOT_ALLOWED: "REPORT_NOT_ALLOWED",

    // matters
    ALL_MATTERS_REPORT_SUCCESS: "REPORT_ALL_MATTERS_REPORT_SUCCESS",
    ALL_MATTERS_REPORT_SUMMARY_AND_FILTERS: "REPORT_ALL_MATTERS_REPORT_SUMMARY_AND_FILTERS",

    MATTER_REPORT_SUCCESS: "REPORT_MATTER_REPORT_SUCCESS",
    MATTER_REPORT_SUMMARY_AND_FILTERS: "REPORT_MATTER_REPORT_SUMMARY_AND_FILTERS",

    MATTER_DETAIL_SUCCESS: "REPORT_MATTER_DETAILS_SUCCESS",
    MATTER_DETAIL_FILTER_AND_SUMMARY: "REPORT_MATTER_DETAIL_FILTER_AND_SUMMARY",

    MATTER_COMMENTS_SUCCESS: "REPORT_MATTER_COMMENTS_SUCCESS",
    MATTER_COMMENTS_FILTER_AND_SUMMARY: "REPORT_MATTER_COMMENTS_FILTER_AND_SUMMARY",

    // clients
    ALL_CLIENTS_REPORT_SUCCESS: "REPORT_ALL_CLIENTS_REPORT_SUCCESS",
    ALL_CLIENTS_REPORT_SUMMARY_AND_FILTERS: "REPORT_ALL_CLIENTS_REPORT_SUMMARY_AND_FILTERS",

    CLIENT_REPORT_SUMMARY_AND_FILTERS: "REPORT_CLIENT_REPORT_SUMMARY_AND_FILTERS",
    CLIENT_REPORT_SUCCESS: "REPORT_CLIENT_REPORT_SUCCESS",

    CLIENT_DETAIL_SUCCESS: "REPORT_CLIENT_DETAILS_SUCCESS",
    CLIENT_DETAIL_FILTER_AND_SUMMARY: "REPORT_CLIENT_DETAIL_FILTER_AND_SUMMARY",

    CLIENT_LAWYERS_FILTER_AND_SUMMARY: 'REPORT_CLIENT_LAWYERS_FILTER_AND_SUMMARY',
    CLIENT_LAWYERS_SUCCESS: 'REPORT_CLIENT_LAWYERS_SUCCESS',

    CLIENT_COMMENTS_REQUEST: "REPORT_CLIENT_COMMENTS_REQUEST",
    CLIENT_COMMENTS_FILTER_AND_SUMMARY: 'REPORT_CLIENT_COMMENTS_FILTER_AND_SUMMARY',
    CLIENT_COMMENTS_FILTER_QUES: 'REPORT_CLIENT_COMMENTS_FILTER_QUES',
    CLIENT_COMMENTS_SUCCESS: 'REPORT_CLIENT_COMMENTS_SUCCESS',

    // lawyers/evaluatees
    ALL_LAWYERS_REPORT_SUCCESS: "REPORT_ALL_LAWYERS_REPORT_SUCCESS",
    ALL_LAWYERS_REPORT_SUMMARY_AND_FILTERS: "REPORT_ALL_LAWYERS_REPORT_SUMMARY_AND_FILTERS",

    LAWYER_REPORT_SUMMARY_AND_FILTERS: "REPORT_LAWYER_REPORT_SUMMARY_AND_FILTERS",
    LAWYER_REPORT_SUCCESS: "REPORT_LAWYER_REPORT_SUCCESS",

    LAWYER_DETAIL_SUCCESS: "REPORT_LAWYER_DETAILS_SUCCESS",
    LAWYER_DETAIL_FILTER_AND_SUMMARY: "REPORT_LAWYER_DETAIL_FILTER_AND_SUMMARY",

    LAWYER_COMMENTS_REQUEST: "REPORT_LAWYER_COMMENTS_REQUEST",
    LAWYER_COMMENTS_FILTER_AND_SUMMARY: 'REPORT_LAWYER_COMMENTS_FILTER_AND_SUMMARY',
    LAWYER_COMMENTS_FILTER_QUES: 'REPORT_LAWYER_COMMENTS_FILTER_QUES',
    LAWYER_COMMENTS_SUCCESS: 'REPORT_LAWYER_COMMENTS_SUCCESS',

    // Practice Area
    ALL_PRACTICE_REPORT_SUCCESS: "REPORT_ALL_PRACTICE_REPORT_SUCCESS",
    ALL_PRACTICE_REPORT_SUMMARY_AND_FILTERS: "REPORT_ALL_PRACTICE_REPORT_SUMMARY_AND_FILTERS",

    // regions
    ALL_REGIONS_REPORT_SUCCESS: "REPORT_ALL_REGIONS_REPORT_SUCCESS",
    ALL_REGIONS_REPORT_SUMMARY_AND_FILTERS: "REPORT_ALL_REGIONS_REPORT_SUMMARY_AND_FILTERS",

    OFFICE_REPORT_SUMMARY_AND_FILTERS: "REPORT_OFFICE_REPORT_SUMMARY_AND_FILTERS",
    OFFICE_REPORT_SUCCESS: "REPORT_OFFICE_REPORT_SUCCESS",

    OFFICE_DETAIL_SUCCESS: "REPORT_OFFICE_DETAIL_SUCCESS",
    OFFICE_DETAIL_FILTER_AND_SUMMARY: "REPORT_OFFICE_DETAIL_FILTER_AND_SUMMARY",

    // diversity
    DIVERSITY_REPORT_SUCCESS: "REPORT_DIVERSITY_REPORT_SUCCESS",
    DIVERSITY_REPORT_SUMMARY_AND_FILTERS: "REPORT_DIVERSITY_REPORT_SUMMARY_AND_FILTERS",
};

export default reportAction;