import settingService from "../services/setting.service";

function request() {
    return {type: SETTING.REQUEST};
}

function error(reason) {
    reason = reason.response;
    // validation error
    if (reason.status === 422) {
        return {type: SETTING.VALIDATION_ERROR, payload: reason.data};
    }
    return {type: SETTING.ERROR, payload: reason.data};
}

function save(data) {

    return dispatch => {
        dispatch(request());
        settingService.save(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        if (res.status === 202) {
            return {type: SETTING.SAVE_SUCCESS, payload: res.data};
        }
        return error(res);
    }
}

function getAll() {

    return dispatch => {
        dispatch(request());
        settingService.getAll().then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: SETTING.READ_ALL_SUCCESS, payload: res.data};
    }
}

function unmount() {
    return dispatch => dispatch({type: SETTING.UNMOUNT});
}

const settingsAction = {
    save,
    getAll,
    unmount
};

// constants
export const SETTING = {

    REQUEST: "SETTING_REQUEST",
    ERROR: "SETTING_ERROR",
    NOT_FOUND: "SETTING_NOT_FOUND",
    VALIDATION_ERROR: "SETTING_VALIDATION_ERROR ",
    NOT_ALLOWED: "SETTING_NOT_ALLOWED",

    SAVE_SUCCESS: "SETTING_SAVE_SUCCESS",
    READ_ALL_SUCCESS: "SETTING_READ_ALL_SUCCESS",

    UNMOUNT: "SETTING_UNMOUNT"
};

export default settingsAction;
