import React, {Component} from 'react';
import matterAction from "../../../../../actions/matter.action";
import {Confirm, Form, Header, Icon, Segment, Table} from "semantic-ui-react";
import helper, {log} from "../../../../../helper/helper";
import {EVALUATION_STATUS} from "../../../../../helper/const";
import RejectIcon from "../../../components/RejectIcon";
import ReminderIcon from "../../../components/ReminderIcon";
import SuccessMessage from "../../../../other/SuccessMessage";
import ErrorMessage from "../../../../other/ErrorMessage";
import ViewIcon from "../../../components/ViewIcon";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {orgState} from "../../../../../helper/stateUtil";
import socket from "../../../../../helper/socket";

class LawyerEvalEvaluations extends Component {
    constructor(props) {
        super(props);
        this.flag = {
            REJECT: 1,
            REMINDER: 2
        };
        this.state = {
            query: "",
            status: EVALUATION_STATUS.ALL,
            flag: null,
            index: -1
        };
    }

    componentDidMount() {
        const {match: {params: {eid}}, org: {id}} = this.props;
        this.props.dispatch(matterAction.getLawyerEvalEvaluations({
            ee_id: eid,
            org_id: id
        }));
        if (socket) {
            socket.channel(id)
                .listen(".event", (e) => {
                    log(e);
                    if (e.data === eid) {
                        this.props.dispatch(matterAction.getLawyerEvalEvaluations({
                            ee_id: eid,
                            org_id: id
                        }));
                    }
                });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.isDone && this.state.flag === this.flag.REJECT) {
            const {match: {params: {eid}}} = this.props;
            this.props.dispatch(matterAction.getLawyerEvalEvaluations({
                ee_id: eid
            }));
            this.setState({flag: null});
        }
    }

    componentWillUnmount() {
        if (socket) {
            socket.leave(this.props.org.id);
        }
        this.props.dispatch(matterAction.unmount());

    }

    render() {
        const {org, evaluations, error, success} = this.props;
        let items = evaluations;
        const {query, status, index} = this.state;
        if (status !== EVALUATION_STATUS.ALL) {
            items = items.filter((item) => item.status === status);
        }
        if (query.length > 0) {
            items = items.filter((item) => {
                const {lawyers_name, evalautor_name} = item;
                return ((lawyers_name && lawyers_name.toLowerCase().includes(query))
                    || (evalautor_name && evalautor_name.toLowerCase().includes(query))
                );
            });
        }
        let content;
        if (items.length > 0) {
            content = (
                <Segment basic style={{padding: "0 3em"}}>
                    <SuccessMessage messages={success}/>
                    <Table basic='very' selectable stackable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Cycle #</Table.HeaderCell>
                                <Table.HeaderCell>Start Date</Table.HeaderCell>
                                <Table.HeaderCell>Due Date</Table.HeaderCell>
                                <Table.HeaderCell>Status</Table.HeaderCell>
                                <Table.HeaderCell>Date Completed</Table.HeaderCell>
                                <Table.HeaderCell>Evaluatee</Table.HeaderCell>
                                <Table.HeaderCell>Evaluator</Table.HeaderCell>
                                <Table.HeaderCell>Reject</Table.HeaderCell>
                                <Table.HeaderCell>Remind</Table.HeaderCell>
                                <Table.HeaderCell>View</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {items.map((item, i) => {
                                return (
                                    <Table.Row key={i} className={"row"}>
                                        <Table.Cell>{item.cycle_no}</Table.Cell>
                                        <Table.Cell>{item.start_date}</Table.Cell>
                                        <Table.Cell>{item.due_date}</Table.Cell>
                                        <Table.Cell>{item.str_status}</Table.Cell>
                                        <Table.Cell>{item.completed_date}</Table.Cell>
                                        <Table.Cell>{item.lawyer_name}</Table.Cell>
                                        <Table.Cell>{item.evalautor_name}</Table.Cell>
                                        <Table.Cell>
                                            {((item.status === EVALUATION_STATUS.DUE || item.status === EVALUATION_STATUS.PENDING)
                                                && item.is_current_user) &&
                                            <RejectIcon onClick={() => {
                                                this.setState({index: i});
                                            }}/>}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {item.status === EVALUATION_STATUS.DUE &&
                                            <ReminderIcon onClick={() => {
                                                this.props.dispatch(matterAction.resendReminder({
                                                    id: item.id
                                                }, "lawyer"));
                                                this.setState({flag: this.flag.REMINDER});
                                            }}/>}
                                        </Table.Cell>
                                        <Table.Cell>
                                            <ViewIcon hidden={item.status !== EVALUATION_STATUS.COMPLETED}
                                                      onClick={() => {
                                                          this.props.history.push(`/evaluation/view/${item.id}?type=firm`);
                                                      }}/>
                                        </Table.Cell>
                                    </Table.Row>
                                );
                            })}

                        </Table.Body>
                    </Table>
                </Segment>
            );
        } else {
            content = (
                <Segment basic placeholder piled clearing>
                    <Header icon>
                        <Icon name='file outline'/>
                        No evaluations are listed.
                    </Header>
                </Segment>
            );
        }

        return (
            <div>
                <Segment basic style={{padding: "2em 3em 1em 3em"}}>
                    <Form>
                        <Form.Group>
                            <Form.Input placeholder='Search' value={this.state.query}
                                        onChange={(e) => this.setState({query: e.target.value.toLowerCase()})}/>
                            <Form.Dropdown selection value={status}
                                           onChange={(e, {value}) => this.setState({status: value})}
                                           options={helper.evaluationCycleStatus}/>
                        </Form.Group>
                    </Form>
                    <ErrorMessage errors={error}/>
                </Segment>
                <br/>
                {content}
                <Confirm
                    header={"Reject Evaluation"}
                    open={index > -1}
                    size={"mini"}
                    confirmButton={"Yes"}
                    cancelButton={"No"}
                    onConfirm={() => {
                        this.props.dispatch(matterAction.rejectEvaluation({
                            org_id: org.id,
                            id: items[index].id
                        }, "lawyer"));
                        this.setState({flag: this.flag.REJECT});
                    }}
                    onCancel={() => this.setState({index: -1})}/>
            </div>
        );
    }
}

function mapStateToProps(state) {
    let s = orgState(state);
    return {...s, ...state.matter};
}

const cClientEvalEvaluations = withRouter(connect(mapStateToProps)(LawyerEvalEvaluations));
export {cClientEvalEvaluations as LawyerEvalEvaluations};
