import React, {Component} from 'react';
import {Segment} from "semantic-ui-react";
import {connect} from "react-redux";
import {OrgMenuBar} from "../other/OrgMenuBar";
import Body from "../../../other/Body";
import {orgState} from "../../../../helper/stateUtil";
import TabHeader from "../other/TabHeader";
import qs from "query-string";
import {LawyerEvalSummary} from './components/LawyerEvalSummary';
import {LawyerEvalDiversity} from './components/LawyerEvalDiversity';
import {LawyerEvalEvaluations} from './components/LawyerEvalEvaluations';
import {LawyerEvalComments} from './components/LawyerEvalComments';

class LawyerSummaryPage extends Component {
    constructor(props) {
        super(props);
        this.params = qs.parse(props.location.search);
        this.tabs = [
            {text: "Summary", value: "summary", render: <LawyerEvalSummary/>}
        ];
        if (props.org.diversity_tracking) {
            this.tabs.push({text: "Diversity", value: "diversity", render: <LawyerEvalDiversity/>});
        }
        this.tabs.push({
            text: "Evaluations",
            value: "evaluations",
            render: <LawyerEvalEvaluations/>
        });
        this.tabs.push({text: "Comments", value: "comments", render: <LawyerEvalComments/>});
    }

    render() {
        const {loading, match: {params: {id: clientId, eid, type}}, location: {search: query}, history} = this.props;
        const {h: lawyerName, e: evalTitle} = this.params;
        let activeIndex = 0;
        let content;
        this.tabs.forEach((tab, index) => {
            if (tab.value === type) {
                activeIndex = index;
                content = tab.render;
            }
        });
        return (
            <Body loading={loading}>

                <OrgMenuBar/>

                <TabHeader
                    title={`${evalTitle} - ${lawyerName}`}
                    backArrow
                    tabs={this.tabs}
                    activeIndex={activeIndex}
                    onTabChange={(tab) => {
                        history.replace(`/lawyers/${clientId}/evaluation/${eid}/${tab.value}${query}`);
                    }}/>
                <Segment basic padded>
                    {content}
                </Segment>

            </Body>
        );
    }
}

function mapStateToProps(state) {
    let s = orgState(state);
    return {...s, ...state.matter};
}

const cClientSummaryPage = connect(mapStateToProps)(LawyerSummaryPage);
export {cClientSummaryPage as LawyerSummaryPage};