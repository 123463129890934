import api from "../helper/api";

function add(data) {
    return api.instance().post("qa/final-questions/store", data);
}

function update(data) {
    data._method = "PUT";
    return api.instance().put("qa/final-questions/update", data);
}

function readAll(data) {
    return api.instance().post("qa/final-questions/get-all", data);
}

function read(data) {
    return api.instance().post("qa/final-questions/get", data);
}

const finalQuestionsService = {
    add,
    update,
    readAll,
    read
};

export default finalQuestionsService;