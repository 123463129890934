import React, {Component} from 'react';
import {Button, Form, Segment} from "semantic-ui-react";
import {connect} from "react-redux";
import {OrgMenuBar} from "../../other/OrgMenuBar";
import clientAction from "../../../../../actions/client.action";
import {orgState} from "../../../../../helper/stateUtil";
import Body from "../../../../other/Body";
import OrgSubHeader from "../../other/OrgSubHeader";
import ErrorMessage from "../../../../other/ErrorMessage";
import QMCheckBox from "../../../../other/form/QMCheckBox";

/* clients and firms both are the same term*/
class OrgAddClientPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            status: true
        };
        this.onChange = this.onChange.bind(this);
        this.onSave = this.onSave.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.isDone) {
            this.props.history.go(-1);
        }
    }

    onSave(e) {
        e.preventDefault();
        this.props.dispatch(clientAction.create({
            org_id: this.props.org.id,
            name: this.state.name,
            status: this.state.status
        }));
    }

    onChange(e) {
        this.setState({name: e.target.value});
    }

    render() {
        const {error, loading, org} = this.props;
        return (
            <Body loading={loading}>

                <OrgMenuBar/>

                <OrgSubHeader title={`Add ${org.str_client_firm}`} backArrow/>

                <Segment basic padded='very'>
                    <Form>
                        <ErrorMessage errors={error}/>

                        <Form.Field>
                            <label>{org.str_client_firm} Name:</label>
                            <input placeholder={`${org.str_client_firm} Name`}
                                   onChange={this.onChange} type="text"/>
                        </Form.Field>
                        <Form.Field>
                            <label>Status:</label>
                            <QMCheckBox toggle onChange={(checked) => this.setState({status: checked})}
                                        checked={this.state.status}/>
                        </Form.Field>
                        <br/>
                        <br/>
                        <Button type='submit' primary onClick={this.onSave} floated='right' circular
                                disabled={!(this.state.name)}>Create</Button>
                        <Button type='button' secondary inverted floated='right' circular
                                onClick={() => this.props.history.go(-1)}>Cancel</Button>
                        <br/>
                    </Form>
                </Segment>

            </Body>
        );
    }
}

function mapStateToProps(state) {
    const s = orgState(state);
    return {
        ...s,
        ...state.client
    };
}

const connectedOrgAddClientPage = connect(mapStateToProps)(OrgAddClientPage);
export {connectedOrgAddClientPage as OrgAddClientPage};