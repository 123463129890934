import {EVALUATION_QUESTION} from "../actions/evaluation_question.action";

const initState = {
    isAllowed: true,
    loading: false,
    types: [],
    items: [], // items may be sets or questions
    groups: [],
    error: [],
    item: null,
    isDone: false
};

export default function evaluationQuestionReducer(state = initState, action) {
    // reset
    state.error = [];
    state.loading = false;
    state.isAllowed = true;
    state.isDone = false;

    // filter
    switch (action.type) {

        // common
        case EVALUATION_QUESTION.REQUEST:
            return {...state, loading: true};

        case EVALUATION_QUESTION.VALIDATION_ERROR:
            return {...state, error: action.payload};

        case EVALUATION_QUESTION.ERROR:
            return {...state, error: [...action.payload]};

        case EVALUATION_QUESTION.NOT_FOUND:
            return {...state, not_found: true};

        // not allowed to access
        case EVALUATION_QUESTION.NOT_ALLOWED:
            return {...state, isAllowed: false};

        case EVALUATION_QUESTION.READ_ALL_SUCCESS:
            return {...state, items: [...action.payload]};

        case EVALUATION_QUESTION.ADD_SUCCESS_QUESTION_DATA:
            return {...state, isDone: true};

        case EVALUATION_QUESTION.ADD_SUCCESS:
            return {...state, items: [...state.items, action.payload]};

        case EVALUATION_QUESTION.ADD_SUCCESS_QUESTION:
            return {...state, isDone: true, items: [...state.items, action.payload]};

        case EVALUATION_QUESTION.UPDATE_SUCCESS:
            state.items[state.items.findIndex((item) => item.id === action.payload.id)] = action.payload;
            return {...state, isDone: true};

        case EVALUATION_QUESTION.CHANGE_STATUS_SUCCESS:
            state.items[state.items.findIndex((item) => item.id === action.payload.id)].status = action.payload.status;
            return {...state};

        case EVALUATION_QUESTION.READ_QUESTION_SET_SUCCESS:
        case EVALUATION_QUESTION.READ_QUESTION_SET_GROUP_SUCCESS:
            return {...state, item: action.payload};

        case EVALUATION_QUESTION.SUCCESS_ALLOWED_TYPS:
            return {...state, types: action.payload};

        case EVALUATION_QUESTION.READ_ALL_FINAL_QUESTIONS:
            return {...state, items: action.payload};

        case EVALUATION_QUESTION.READ_ALL_QUESTION_GROUPS:
            return {...state, groups: action.payload};

        case EVALUATION_QUESTION.ADD_SUCCESS_FINAL_QUESTION:
            state.items.push(action.payload);
            return {...state, isDone: true};

        case EVALUATION_QUESTION.UPDATE_SUCCESS_FINAL_QUESTION:
            const index = state.items.findIndex((i) => i.id === action.payload.id);
            state.items[index] = action.payload;
            return {...state, isDone: true};

        default:
            return state;
    }
}