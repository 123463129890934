import React, {Component} from 'react';
import {Image, List, Segment} from "semantic-ui-react";
import logo from '../../imgs/qualmet-logo.jpg';


class BoxHeader extends Component {
    render() {
        return (
            <Segment textAlign={'left'} basic
                     style={{width: "100%", backgroundColor: "white", color: 'grey', fontWeight: "600"}}>
                <List>
                    <List.Item>
                        <Image src={logo} size='small'/>
                    </List.Item>
                    <List.Item>
                        <i>Creating Stronger Partners by Measuring Diversity and Performance</i>
                    </List.Item>
                </List>
            </Segment>
        );
    }
}

export default BoxHeader;
