import React, {Component} from 'react';
import {Button, Form, Header, Icon, Segment, Table} from "semantic-ui-react";
import {connect} from "react-redux";
import userAction from "../../../../../actions/user.action";
import TabHeader from "../../../org/other/TabHeader";
import SABody from "../../other/SABody";
import QMCheckBox from "../../../../other/form/QMCheckBox";

class SAOrgUsersPage extends Component {
    constructor(props) {
        super(props);
        this.tabs = [
            {text: "Summary", value: 1},
            {text: "Users", value: 2},
            {text: "SSO (Single Sign On)", value: 3},
        ];
    }

    componentDidMount() {
        this.props.dispatch(userAction.readAll({org_id: this.props.match.params.id}));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.props.user.isAllowed) {
            this.props.history.replace("forbidden");
        }
    }

    render() {
        const {match: {params}, history} = this.props;
        const {loading, users} = this.props.user;
        let content;
        if (users.length > 0) {
            content = (
                <Table basic='very' padded selectable stackable>
                    <Table.Body>
                        {users.map((user, index) => {
                            return (
                                <Table.Row className={"row"} key={index}>
                                    <Table.Cell style={{paddingLeft: "16px"}}
                                                onClick={() => this.props.history.push(`/superadmin/orgs/${this.props.match.params.id}/users/edit/${user.id}`)}>
                                        <Icon name="user" color={"grey"} size={"large"}/>
                                        <span><b>{user.name}</b> ({user.email})</span>
                                    </Table.Cell>
                                    <Table.Cell collapsing>
                                        <Form>
                                            <Form.Group inline>
                                                <Form.Field>
                                                    <QMCheckBox checked={user.status} onChange={checked =>
                                                        this.props.dispatch(userAction.changeStatus({
                                                            id: user.id,
                                                            org_id: this.props.match.params.id,
                                                            status: checked
                                                        }))}/>
                                                </Form.Field>
                                            </Form.Group>
                                        </Form>
                                    </Table.Cell>
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                </Table>
            );
        } else {
            content = (
                <Segment basic placeholder piled>
                    <Header icon>
                        <Icon name='file outline'/>
                        No users are listed.
                    </Header>
                    <Button color={"teal"} circular
                            onClick={() => this.props.history.push(`/superadmin/orgs/${params.id}/users/add`)}>
                        Add User
                    </Button>
                </Segment>
            );
        }
        return (
            <SABody loading={loading}>
                <TabHeader title={"Organizations"}
                           backArrow
                           tabs={this.tabs}
                           activeIndex={1}
                           onTabChange={({value}) => {
                               if (value === 1) {
                                   history.replace(`/superadmin/orgs/edit/${params.id}`);
                               } else if (value === 3) {
                                   history.replace(`/superadmin/orgs/${params.id}/sso`);
                               }
                           }}>
                    <Button circular size={"medium"}
                            onClick={() => this.props.history.push(`/superadmin/orgs/${params.id}/users/add`)}
                            title="Add User" floated="right" color={"teal"}>Add User</Button>
                </TabHeader>
                <Segment padded={"very"} basic>
                    {content}
                </Segment>
            </SABody>
        );
    }
}

function mapStateToProps(state) {
    return {user: state.user};
}

const cSAOrgUsersPage = connect(mapStateToProps)(SAOrgUsersPage);
export {cSAOrgUsersPage as SAOrgUsersPage};