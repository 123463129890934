import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Form, Modal, Segment} from "semantic-ui-react";
import {orgState} from "../../../../../helper/stateUtil";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import ErrorMessage from "../../../../other/ErrorMessage";
import userAction from "../../../../../actions/user.action";

class SendEmailToUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subject: '',
            message: ''
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.isDoneA) {
            this.props.onClose();
            alert("Email send successfully!");
            this.setState({subject: '', message: ''});
        }
    }

    render() {
        const {subject, message} = this.state;
        const {loadingA: loading, match, error, onClose, active} = this.props;
        return (
            <Modal size={"tiny"} open={active} onClose={() => onClose()}
                   closeOnDimmerClick={false}>
                <Modal.Header>Send Email to User</Modal.Header>
                <Modal.Content>
                    <Segment basic>
                        <Form>
                            <ErrorMessage errors={error}/>
                            <Form.Field label='Subject:' control={"input"}
                                        placeholder='Subject' value={subject}
                                        onChange={(e) => this.setState({subject: e.target.value})}/>
                            <Form.Field label='Message:' control={"textarea"}
                                        rows={6} placeholder='Message' value={message}
                                        onChange={(e) => this.setState({message: e.target.value})}/>

                        </Form>
                    </Segment>
                </Modal.Content>
                <Modal.Actions>
                    <Button secondary onClick={() => onClose()}
                            disabled={loading}>Cancel</Button>
                    <Button color={"teal"} content='Submit'
                            type="submit"
                            loading={loading}
                            onClick={() => {
                                this.props.dispatch(userAction.sendEmailToUser({
                                    to_user: match.params.id,
                                    ...this.state
                                }));
                            }}
                            disabled={(loading) || (!subject && !message)}/>
                </Modal.Actions>
            </Modal>
        );
    }
}

SendEmailToUser.propTypes = {
    active: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    const s = orgState(state);
    return {
        ...s,
        ...state.user
    };
}

const cSendEmailToUser = withRouter(connect(mapStateToProps)(SendEmailToUser));
export {cSendEmailToUser as SendEmailToUser};
