import {ORG} from "../actions/org.action";

const initState = {
    loading: false,
    isDone: false,
    orgs: [],
    org: null,
    sso: {},
    is_sso: false, //if success parse metadata it will be true
    domains: [],
    error: []
};

export default function orgReducer(state = initState, action) {
    // reset
    state.error = [];
    state.isDone = false;
    state.loading = false;
    state.is_sso = false;

    // filter
    switch (action.type) {

        // common
        case ORG.REQUEST:
            return {...state, loading: true};

        case ORG.VALIDATION_ERROR:
            return {...state, error: action.payload};

        case ORG.ERROR:
            return {...state, error: [...action.payload]};

        // add org
        case ORG.ADD_SUCCESS:
            return {...state, isDone: true};

        // update org
        case ORG.UPDATE_SUCCESS:
            // state.orgs[state.orgs.findIndex(org => org.id === action.payload.id)] = action.payload;
            return {...state, isDone: true};

        // read all orgs
        case ORG.READ_ALL_SUCCESS:
            return {...state, orgs: action.payload};

        case ORG.READ_SUCCESS:
            return {...state, org: action.payload};

        // delete org
        // case ORG.DELETE_SUCCESS:
        //     status.orgs.splice(status.orgs.findIndex(org => org.id === action.payload.id), 1);
        //     return {...status, orgs: [...status.orgs]};

        case ORG.ACTIVATE_ORG:
            return {...state, isDone: true};

        case ORG.SSO_GET_DETAILS:
            return {...state, sso: action.payload};

        case ORG.SSO_UPDATE_DETAILS:
            return {...state, sso: action.payload, isDone: true};

        case ORG.SSO_METADATA_PARSE:
            return {...state, sso: action.payload, is_sso: true};

        case ORG.DOMAINS_SUCCESS:
            return {...state, domains: action.payload, isDone: true};

        case ORG.UPDATE_DOMAINS_SUCCESS:
            return {...state, domains: action.payload, isDone: true};

        case ORG.GET_ACTIVE_PLANS:
            return {...state, domains: action.payload};

        case ORG.DELETE_ORG:
            return {...state, isDone: true};

        default:
            return state;
    }
}