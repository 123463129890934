import React, {Component} from 'react';
import {Header, Icon, Segment} from "semantic-ui-react";
import PropTypes from 'prop-types';
import {withRouter} from "react-router-dom";

const nos = [
    'one',
    'two',
    'three',
    'four',
    'five',
    'six',
    'seven',
    'eight',
    'nine',
    'ten',
    'eleven',
    'twelve',
    'thirteen',
    'fourteen',
    'fifteen',
    'sixteen',
];

class TabHeader extends Component {
    render() {
        const {title, backArrow, activeIndex, history, style, onTabChange, children} = this.props;
        let {tabs} = this.props;
        if (!tabs) {
            tabs = [];
        }
        let s = {backgroundColor: "#e5e5e5", paddingBottom: "0"};
        if (style) {
            s = {...s, ...style};
        }
        return (
            <Segment basic style={s} className={"mini padded"} id={'sub-header'}>
                {children}
                <Header as="h3" style={{margin: `0.1em auto`}}>
                    {backArrow && <Icon name="arrow left" size='tiny' link onClick={() => history.go(-1)}/>}
                    {title}
                </Header>
                <br/>
                <div className={`ui menu secondary stackable item ${nos[tabs.length - 1]}`}
                     style={{marginBottom: "0", minHeight: '0'}}>
                    {tabs.map((t, i) => {
                            let style = {};
                            const active = i === activeIndex;
                            if (active) {
                                style.backgroundColor = "rgb(208,208,208)";
                            }
                            // eslint-disable-next-line jsx-a11y/anchor-is-valid
                            return <a
                                style={{
                                    paddingTop: "0.665846em",
                                    paddingBottom: "0.665846em",
                                    fontSize: "1.1rem",
                                    color: "#606060", ...style
                                }}
                                key={i}
                                className={`${active ? "active" : ""} menu-item item`}
                                onClick={() => {
                                    if (!t.disabled)
                                        onTabChange(t, i)
                                }}><b>{t.text}</b>
                            </a>
                        }
                    )
                    }
                </div>
            </Segment>
        );
    }
}

TabHeader.defaultProps = {
    backArrow: true,
    activeIndex: 0
};

TabHeader.propTypes = {
    title: PropTypes.string.isRequired,
    backArrow: PropTypes.bool,
    style: PropTypes.object,
    activeIndex: PropTypes.number,
    tabs: PropTypes.array,
    onTabChange: PropTypes.func
};

export default withRouter(TabHeader);

