import {Dimmer, Image, Loader} from "semantic-ui-react";
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import qlogo from "../../imgs/qualmet-logo-new-white.png";

class FullScreenLoader extends Component {
    render() {
        const {message, active} = this.props;
        return (
            <Dimmer active={active} page={true}>
                <Loader size='large'>{message}
                    <Image src={qlogo} style={{
                        marginTop: "48px",
                        width: "auto",
                        height: "32px"
                    }}/>
                </Loader>
            </Dimmer>
        );
    }
}

FullScreenLoader.propTypes = {
    message: "Loading...",
    active: false
};

FullScreenLoader.propTypes = {
    message: PropTypes.string,
    active: PropTypes.bool
};

export default FullScreenLoader;