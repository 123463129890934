import React, {Component} from 'react';
import {Container, Dropdown, Image, Menu} from "semantic-ui-react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import authAction from "../../../../actions/auth.action";
import qlogo from "../../../../imgs/qualmet-logo.jpg";

class SuperAdminHeader extends Component {
    render() {
        let isTest;
        if (process.env.REACT_APP_RELEASE_STAGE !== "PRODUCTION") {
            isTest = true;
        }
        return (
            <div>
                <Menu secondary stackable
                      style={{borderRadius: "0px", borderTopLeftRadius: "2px", borderTopRightRadius: "2px"}}>
                    <Container>
                        <Menu.Item link header onClick={() => this.props.history.push("/")} className={"header-logo"}>
                            <Image size='small' src={qlogo} style={{marginRight: '1.5em', width: "210px"}}/>
                            <span style={{fontSize: "15px"}}><b>Qualmet Admin</b></span>
                            {isTest && <span style={{
                                borderRadius: "4px",
                                padding: "8px",
                                marginLeft: "16px",
                                fontSize: "15px",
                                backgroundColor: "red",
                                color: "white"
                            }}><b>{process.env.REACT_APP_RELEASE_STAGE} SITE</b></span>}
                        </Menu.Item>
                        <Menu.Menu position='right' style={{marginRight: "20px", fontSize: "15px"}}>
                            <Dropdown item pointing floating text={this.props.user_name}>
                                <Dropdown.Menu>
                                    <Dropdown.Item
                                        onClick={() => this.props.history.push("/profile")}>Profile</Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => this.props.dispatch(authAction.logout())}>Logout</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Menu.Menu>
                    </Container>
                </Menu>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const res = {user_name: state.auth.user.name};
    if (state.auth.user.org) {
        return {...res, org_name: state.auth.user.org.name};
    }
    return res;
}

const cSuperAdminHeader = withRouter(connect(mapStateToProps)(SuperAdminHeader));
export {cSuperAdminHeader as SuperAdminHeader};