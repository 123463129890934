import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Grid, Segment} from "semantic-ui-react";

class ColoredBody extends Component {
    render() {
        const {children, boxWidth, textAlign} = this.props;
        return (
            <Grid className="center-flex-container" textAlign={textAlign || 'left'}
                  style={{height: '100%'}}
                  verticalAlign='middle'>
                <Grid.Column style={{maxWidth: boxWidth || "512px"}}>
                    <Segment className={"colored-box"}>
                        {children}
                    </Segment>
                </Grid.Column>
            </Grid>
        );
    }
}

ColoredBody.propTypes = {
    boxWidth: PropTypes.string,
    textAlign: PropTypes.string
};

export default ColoredBody;
