import React, {Component} from 'react';
import ColoredBody from "../../other/ColoredBody";
import BoxFooter from "../../other/BoxFooter";
import BoxHeader from "../../other/BoxHeader";
import {Button, Embed, Grid, Header, Icon, Modal, Segment} from "semantic-ui-react";
import qs from "query-string";

class NewOrgSetupDonePage extends Component {
    constructor(props) {
        super(props);
        this.params = qs.parse(this.props.location.search);
    }

    render() {
        const {history} = this.props;
        return (
            <ColoredBody boxWidth={"768px"} textAlign={'center'}>
                <BoxHeader/>
                <Segment textAlign={"left"} padded>
                    <Grid verticalAlign={"middle"}>
                        <Grid.Column width={10}>
                            <Header>
                                All Done!
                            </Header>
                            <br/>
                            <p style={{fontSize: "16px", fontWeight: "600", textAlign: "justify"}}>
                                Your new organization has been setup in the Qualmet System.
                                Please view the Quick Start guide found on the home page and additional information in
                                the Help section to learn how to setup Practice Areas, Evaluations Questions, Users and
                                other system settings required before sending out your first evaluations.
                                <br/>
                                <br/>
                                Thanks!
                                <br/>
                                Qualmet
                            </p>
                            <Button
                                color={"teal"}
                                circular
                                onClick={() => {
                                    let token;
                                    if (this.params) {
                                        token = this.params.token;
                                    }
                                    if (token) {
                                        history.replace(`/login/callback?token=${encodeURIComponent(token)}`);
                                    } else {
                                        history.replace("/");
                                    }
                                }}>
                                <span style={{margin: "auto 2em"}}>Go to Home Page</span>
                            </Button>
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <Segment basic placeholder>
                                <Segment textAlign={'center'} raised compact
                                         style={{borderRadius: '8px', border: "solid grey 2px"}}>
                                        <span style={{fontSize: "18px", fontFamily: "sans-serif", lineHeight: "24px"}}>
                                            QUICK
                                            <br/>
                                            START
                                            <br/>
                                            GUIDE
                                        </span>
                                    <br/>
                                    <br/>
                                    <Modal closeIcon
                                           trigger={
                                               <Icon link name={"play circle"}
                                                     color={"red"} size={"huge"} title={'Play Video'}/>}>
                                        <Modal.Content>
                                            <Embed brandedUI
                                                   color={'red'}
                                                   id={"kTdDmebIksw"}
                                                   placeholder={`https://i1.ytimg.com/vi/kTdDmebIksw/0.jpg`}
                                                   source='youtube'/>
                                        </Modal.Content>
                                    </Modal>

                                </Segment>
                            </Segment>
                        </Grid.Column>
                    </Grid>
                </Segment>
                <BoxFooter/>
            </ColoredBody>
        );
    }
}

export default NewOrgSetupDonePage;