import React, {Component} from 'react';

class Report extends Component {
    render() {
        return (
            <div style={{margin: "0.8em 2.5em"}}>
                {this.props.children}
            </div>
        );
    }
}

Report.propTypes = {};

export default Report;
