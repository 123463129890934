import matterService from "../services/matter.service";

// common
function request() {
    return {type: MATTER.REQUEST};
}

function error(reason) {
    reason = reason.response;
    switch (reason.status) {
        case 403:
            return {type: MATTER.NOT_ALLOWED};
        case 404:
            return {type: MATTER.NOT_FOUND};
        case 422:
            return {type: MATTER.VALIDATION_ERROR, payload: reason.data};
        default:
            return {type: MATTER.ERROR, payload: reason.data};
    }
}

function unmount() {
    return dispatch => dispatch({type: MATTER.UNMOUNT});
}

function create(data) {

    return dispatch => {
        dispatch(request());
        matterService.add(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        if (res.status === 201) {
            return {type: MATTER.ADD_SUCCESS, payload: res.data};
        }
        return error(res);
    }
}

function update(data) {
    return dispatch => {
        dispatch(request());
        matterService.update(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        if (res.status === 202) {
            return {type: MATTER.UPDATE_SUCCESS, payload: res.data};
        }
        return error(res);
    }
}

function readAll(data) {
    return dispatch => {
        dispatch(request());
        matterService.readAll(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: MATTER.READ_ALL_SUCCESS, payload: res.data};
    }
}

function read(data) {
    return dispatch => {
        dispatch({type: MATTER.REQUEST_READ_MATTER});
        matterService.read(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: MATTER.READ_SUCCESS, payload: res.data};
    }
}

function readSummary(data) {
    return dispatch => {
        dispatch({type: MATTER.REQUEST_READ_MATTER});
        matterService.readSummary(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: MATTER.READ_SUMMARY_SUCCESS, payload: res.data};
    }
}

function changeStatus(data) {
    return dispatch => {
        dispatch(request());
        matterService.changeStatus(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        if (res.status === 202) {
            return {type: MATTER.CHANGE_STATUS_SUCCESS, payload: data};
        }
        return error(res);
    }
}

function readMasterData(data) {
    return dispatch => {
        dispatch(request());
        matterService.getMasterData(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: MATTER.READ_MASTER_DATA, payload: res.data};
    }
}

function addCommentSuccess(data) {
    return dispatch => {
        dispatch(request());
        matterService.addComments(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        if (res.status === 201) {
            return {type: MATTER.ADD_COMMENT_SUCCESS, payload: res.data};
        }
        return error(res);
    }
}

function getAllComments(data) {
    return dispatch => {
        dispatch(request());
        matterService.getComments(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: MATTER.READ_ALL_COMMENTS, payload: res.data};
    }
}

function getMatterEvaluations(data) {
    return dispatch => {
        dispatch(request());
        matterService.getEvaluations(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: MATTER.READ_ALL_EVALUATIONS_SUCCESS, payload: res.data};
    }
}

function getMyEvaluations(data) {
    return dispatch => {
        dispatch(request());
        matterService.getMyEvaluations(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: MATTER.READ_ALL_MY_EVALUATIONS_SUCCESS, payload: res.data};
    }
}

function rejectEvaluation(data, type) {
    return dispatch => {
        dispatch(request());
        matterService.rejectEvaluation(data, type).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        if (res.status === 202) {
            return {type: MATTER.REJECT_EVALUATION_SUCCESS, payload: res.data};
        }
        return error(res);
    }
}

function evaluate(data) {
    return dispatch => {
        dispatch(request());
        matterService.evaluate(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        if (res.status === 202) {
            return {type: MATTER.EVALUATE_SUCCESS, payload: res.data};
        }
        return error(res);
    }
}

function getAllMattersEvaluationsCycles(data) {
    return dispatch => {
        dispatch(request());
        matterService.getAllMattersEvaluationsCycles(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: MATTER.READ_ALL_MATTERS_EVALUATIONS_CYCLES, payload: res.data};
    }
}

function resendReminder(data, type) {
    return dispatch => {
        dispatch(request());
        matterService.resendReminder(data, type).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        if (res.status === 202)
            return {type: MATTER.RESEND_REMINDER_OF_EVALUATION_SUCCESS, payload: res.data};
        return error(res);
    }
}

function getDiversityData(data) {
    return dispatch => {
        dispatch(request());
        matterService.getDiversityData(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: MATTER.READ_DIVERSITY_DATA, payload: res.data};
    }
}

function updateDiversityData(data) {
    return dispatch => {
        dispatch(request());
        matterService.updateDiversityData(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        if (res.status === 202)
            return {type: MATTER.UPDATE_DIVERSITY_DATA_SUCCESS, payload: data};
        return error(res);
    }
}

// Client Evaluation
function getAllCommentsOfClientEval(data) {
    return dispatch => {
        dispatch(request());
        matterService.getAllCommentsOfClientEval(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: MATTER.READ_ALL_COMMENTS, payload: res.data};
    }
}

function addCommentOfClientEval(data) {
    return dispatch => {
        dispatch(request());
        matterService.addCommentOfClientEval(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        if (res.status === 201) {
            return {type: MATTER.ADD_COMMENT_SUCCESS, payload: res.data};
        }
        return error(res);
    }
}

function getDiversityDataOfClientEval(data) {
    return dispatch => {
        dispatch(request());
        matterService.getDiversityDataOfClientEval(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: MATTER.READ_DIVERSITY_DATA, payload: res.data};
    }
}

function updateDiversityDataOfClientEval(data) {
    return dispatch => {
        dispatch(request());
        matterService.updateDiversityDataOfClientEval(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        if (res.status === 202)
            return {type: MATTER.UPDATE_DIVERSITY_DATA_SUCCESS, payload: data};
        return error(res);
    }
}

function getClientEvalEvaluations(data) {
    return dispatch => {
        dispatch(request());
        matterService.getClientEvalEvaluations(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: MATTER.READ_ALL_EVALUATIONS_SUCCESS, payload: res.data};
    }
}

function readMasterDataOfClientEval(data) {
    return dispatch => {
        dispatch(request());
        matterService.readMasterDataOfClientEval(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: MATTER.READ_MASTER_DATA, payload: res.data};
    }
}

function createClientEval(data) {
    return dispatch => {
        dispatch(request());
        matterService.createClientEval(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        if (res.status === 201) {
            return {type: MATTER.ADD_SUCCESS, payload: res.data};
        }
        return error(res);
    }
}

function readOfClientEval(data) {
    return dispatch => {
        dispatch({type: MATTER.REQUEST_READ_MATTER});
        matterService.readOfClientEval(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: MATTER.READ_SUCCESS, payload: res.data};
    }
}

function updateOfClientEval(data) {
    return dispatch => {
        dispatch(request());
        matterService.updateOfClientEval(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        if (res.status === 202) {
            return {type: MATTER.UPDATE_SUCCESS, payload: res.data};
        }
        return error(res);
    }
}

function readSummaryOfClientEval(data) {
    return dispatch => {
        dispatch({type: MATTER.REQUEST_READ_MATTER});
        matterService.readSummaryOfClientEval(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: MATTER.READ_SUMMARY_SUCCESS, payload: res.data};
    }
}

// Lawyer Evaluation
function getAllCommentsOfLawyerEval(data) {
    return dispatch => {
        dispatch(request());
        matterService.getAllCommentsOfLawyerEval(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: MATTER.READ_ALL_COMMENTS, payload: res.data};
    }
}

function addCommentOfLawyerEval(data) {
    return dispatch => {
        dispatch(request());
        matterService.addCommentOfLawyerEval(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        if (res.status === 201) {
            return {type: MATTER.ADD_COMMENT_SUCCESS, payload: res.data};
        }
        return error(res);
    }
}

function getDiversityDataOfLawyerEval(data) {
    return dispatch => {
        dispatch(request());
        matterService.getDiversityDataOfLawyerEval(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: MATTER.READ_DIVERSITY_DATA, payload: res.data};
    }
}

function updateDiversityDataOfLawyerEval(data) {
    return dispatch => {
        dispatch(request());
        matterService.updateDiversityDataOfLawyerEval(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        if (res.status === 202)
            return {type: MATTER.UPDATE_DIVERSITY_DATA_SUCCESS, payload: data};
        return error(res);
    }
}

function getLawyerEvalEvaluations(data) {
    return dispatch => {
        dispatch(request());
        matterService.getLawyerEvalEvaluations(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: MATTER.READ_ALL_EVALUATIONS_SUCCESS, payload: res.data};
    }
}

function readMasterDataOfLawyerEval(data) {
    return dispatch => {
        dispatch(request());
        matterService.readMasterDataOfLawyerEval(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: MATTER.READ_MASTER_DATA, payload: res.data};
    }
}

function createLawyerEval(data) {
    return dispatch => {
        dispatch(request());
        matterService.createLawyerEval(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        if (res.status === 201) {
            return {type: MATTER.ADD_SUCCESS, payload: res.data};
        }
        return error(res);
    }
}

function readOfLawyerEval(data) {
    return dispatch => {
        dispatch({type: MATTER.REQUEST_READ_MATTER});
        matterService.readOfLawyerEval(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: MATTER.READ_SUCCESS, payload: res.data};
    }
}

function updateOfLawyerEval(data) {
    return dispatch => {
        dispatch(request());
        matterService.updateOfLawyerEval(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        if (res.status === 202) {
            return {type: MATTER.UPDATE_SUCCESS, payload: res.data};
        }
        return error(res);
    }
}

function readSummaryOfLawyerEval(data) {
    return dispatch => {
        dispatch({type: MATTER.REQUEST_READ_MATTER});
        matterService.readSummaryOfLawyerEval(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: MATTER.READ_SUMMARY_SUCCESS, payload: res.data};
    }
}

// close matter, firm evaluation, evaluatee evaluation
function close(data, type) {
    return dispatch => {
        dispatch({type: MATTER.REQUEST});
        matterService.close(data, type).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        if (res.status === 202) {
            return {type: MATTER.CLOSE_EVAL_SUCCESS};
        }
        return error(res);
    }
}

const matterAction = {

    unmount,

    create,
    update,
    readAll,
    read,
    readSummary,
    changeStatus,
    readMasterData,
    addCommentSuccess,
    getAllComments,
    rejectEvaluation,
    evaluate,
    getMyEvaluations,
    getMatterEvaluations,
    getAllMattersEvaluationsCycles,
    resendReminder,
    getDiversityData,
    updateDiversityData,

    // client evaluation
    getAllCommentsOfClientEval,
    addCommentOfClientEval,
    getDiversityDataOfClientEval,
    updateDiversityDataOfClientEval,
    getClientEvalEvaluations,
    readMasterDataOfClientEval,
    createClientEval,
    readOfClientEval,
    updateOfClientEval,
    readSummaryOfClientEval,

    // lawyer evaluation
    getAllCommentsOfLawyerEval,
    addCommentOfLawyerEval,
    getDiversityDataOfLawyerEval,
    updateDiversityDataOfLawyerEval,
    getLawyerEvalEvaluations,
    readMasterDataOfLawyerEval,
    createLawyerEval,
    readOfLawyerEval,
    updateOfLawyerEval,
    readSummaryOfLawyerEval,

    close
};

// constants
export const MATTER = {

    UNMOUNT: "MATTER_UNMOUNT",

    REQUEST: "MATTER_REQUEST",
    ERROR: "MATTER_ERROR",
    NOT_FOUND: "MATTER_NOT_FOUND",
    VALIDATION_ERROR: "MATTER_VALIDATION_ERROR ",
    NOT_ALLOWED: "MATTER_NOT_ALLOWED",

    ADD_SUCCESS: "MATTER_ADD_SUCCESS",
    UPDATE_SUCCESS: "MATTER_UPDATE_SUCCESS",
    READ_ALL_SUCCESS: "MATTER_READ_ALL_SUCCESS",

    REQUEST_READ_MATTER: "MATTER_REQUEST_READ_MATTER",

    READ_SUCCESS: "MATTER_READ_SUCCESS",
    READ_SUMMARY_SUCCESS: "MATTER_READ_SUMMARY_SUCCESS",
    CHANGE_STATUS_SUCCESS: "MATTER_CHANGE_STATUS_SUCCESS",
    READ_MASTER_DATA: "MATTER_READ_MASTER_DATA",

    ADD_COMMENT_SUCCESS: "MATTER_ADD_COMMENT_SUCCESS",
    READ_ALL_COMMENTS: "MATTER_READ_ALL_COMMENTS",
    READ_ALL_EVALUATIONS_SUCCESS: "MATTER_READ_ALL_EVALUATIONS_SUCCESS",

    READ_ALL_MY_EVALUATIONS_SUCCESS: "MATTER_READ_ALL_MY_EVALUATIONS_SUCCESS",

    REJECT_EVALUATION_SUCCESS: "MATTER_REJECT_EVALUATION_SUCCESS",

    EVALUATE_SUCCESS: "MATTER_EVALUATE_SUCCESS",

    READ_ALL_MATTERS_EVALUATIONS_CYCLES: "MATTER_READ_ALL_MATTERS_EVALUATIONS_CYCLES",

    RESEND_REMINDER_OF_EVALUATION_SUCCESS: "MATTER_RESEND_REMINDER_OF_EVALUATION_SUCCESS",

    // diversity
    READ_DIVERSITY_DATA: "MATTER_READ_DIVERSITY_DATA",
    UPDATE_DIVERSITY_DATA_SUCCESS: "MATTER_UPDATE_DIVERSITY_DATA_SUCCESS",

    CLOSE_EVAL_SUCCESS: "MATTER_CLOSE_EVAL_SUCCESS"
};

export default matterAction;