import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Form, Header, Input, Segment} from "semantic-ui-react";
import {connect} from "react-redux";
import userAction from "../../../actions/user.action";

class UserChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // old_password: "",
            new_password: "",
            confirm_password: ""
        };
    }

    render() {
        const {new_password, confirm_password} = this.state;
        const {loading} = this.props;
        return (
            <Segment loading={loading}>
                <Header dividing>Change Password:</Header>
                <br/>
                <Form>
                    {/*<Form.Group>*/}
                    {/*<Form.Field width={8}>*/}
                    {/*<label>Old Password:</label>*/}
                    {/*<Input value={old_password} type={'password'}*/}
                    {/*onChange={(e) => this.setState({old_password: e.target.value})}/>*/}
                    {/*</Form.Field>*/}
                    {/*</Form.Group>*/}
                    <Form.Group>
                        <Form.Field width={8}>
                            <label>New Password:</label>
                            <Input value={new_password} type={'password'}
                                   onChange={(e) => this.setState({new_password: e.target.value})}/>
                        </Form.Field>
                    </Form.Group>
                    <Form.Group>
                        <Form.Field width={8}>
                            <label>Confirm Password:</label>
                            <Input value={confirm_password} type={'password'}
                                   onChange={(e) => this.setState({confirm_password: e.target.value})}/>
                        </Form.Field>
                    </Form.Group>
                    <Button type='submit' primary
                            onClick={() => {
                                this.props.dispatch(userAction.changePassword({new_password: new_password}));
                            }} floated='right'
                            circular
                            disabled={!(new_password && (new_password === confirm_password))}>Change
                        Password</Button>
                    <br/>
                    <br/>
                </Form>
            </Segment>
        );
    }
}

UserChangePassword.propTypes = {
    data: PropTypes.object
};

function mapStateToProps(state) {
    return {...state.user};
}

const cUserChangePassword = connect(mapStateToProps)(UserChangePassword);
export {cUserChangePassword as UserChangePassword};
