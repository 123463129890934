import React, {Component} from 'react';
import {connect} from "react-redux";
import {Button, Icon, Modal} from "semantic-ui-react";
import {TermsOfUseContent} from "../auth/components/TermsOfUseContent";
import appAction from "../../../actions/app.action";
import authAction from "../../../actions/auth.action";

class TermsOfUseModal extends Component {
    constructor(props) {
        super(props);
        this.handleClose = this.handleClose.bind(this);
    }

    handleClose = () => {
        this.props.dispatch(appAction.closeTCModal());
        this.props.dispatch(authAction.logout());
    };

    render() {
        return (
            <Modal open={this.props.tc_modal} closeOnDimmerClick={false} onClose={this.handleClose}>
                <Modal.Header>Terms of Use</Modal.Header>
                <Modal.Content scrolling>
                    <Modal.Description>
                        <Modal.Description>
                            <TermsOfUseContent/>
                        </Modal.Description>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button secondary circular onClick={this.handleClose}>
                        Disagree
                    </Button>
                    <Button primary circular onClick={() => {
                        this.props.dispatch(appAction.acceptTermsOfUse());
                    }}>
                        Agree &nbsp;&nbsp;<Icon name='check'/>
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

export default connect(state => {
    return {...state.app};
})(TermsOfUseModal);
