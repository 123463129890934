import api from "../helper/api";

function add(data) {
    return api.instance().post("org/client/store", data);
}

function update(data) {
    data._method = "PUT";
    return api.instance().put("org/client/update", data);
}

function readAll(data) {
    return api.instance().post("org/client/get-all", data);
}

function read(data) {
    return api.instance().post("org/client/get", data);
}

function changeStatus(data) {
    return api.instance().put("org/client/change-status", data);
}

function readAllClientsFirm(data) {
    return api.instance().post("org/firm-evaluations/firms", data);
}

function readAllClientEvals(data) {
    return api.instance().post("org/firm-evaluations/list", data);
}

const clientService = {
    add,
    update,
    readAll,
    read,
    changeStatus,
    readAllClientsFirm,
    readAllClientEvals
};

export default clientService;