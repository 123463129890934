import React, {Component} from 'react';
import {Button, Divider, Form, Input, Label, Segment} from "semantic-ui-react";
import orgAction from "../../../../../actions/org.action";
import QMCheckBox from "../../../../other/form/QMCheckBox";
import TabHeader from "../../../org/other/TabHeader";
import SABody from "../../other/SABody";
import {connect} from "react-redux";
import ErrorMessage from "../../../../other/ErrorMessage";
import SuccessMessage from "../../../../other/SuccessMessage";

class SAOrgSSOPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_update: false,
            entity_id: "",
            sso_end_point: "",
            slo_end_point: "",
            idp_certificate: "",
            status: false,
            metadata_file: ""
        };
        this.tabs = [
            {text: "Summary", value: 1},
            {text: "Users", value: 2},
            {text: "SSO (Single Sign On)", value: 3}
        ];
        this.onChange = this.onChange.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onExtract = this.onExtract.bind(this);
        this.linkRef = React.createRef();
    }

    copyLink = () => {
        this.linkRef.current.select();
        document.execCommand('copy');
        alert("Copied!");
    };

    componentDidMount() {
        this.props.dispatch(orgAction.getSSODetails({id: this.props.match.params.id}));
    }

    componentWillReceiveProps(nextProps, nextContext) {
        const {sso, is_sso} = nextProps;
        if (Object.keys(sso).length > 0) {
            this.setState((state) => {
                let res = {
                    ...sso,
                    is_update: is_sso
                };
                if (!state.is_update) {
                    return res;
                } else
                    return {...res, status: state.status};
            });
        }
    }

    onSave(e) {
        e.preventDefault();
        this.props.dispatch(orgAction.updateSSODetails({
            id: this.props.match.params.id,
            ...this.state
        }));
    }

    onExtract = e => {
        e.preventDefault();
        this.props.dispatch(orgAction.parseMetadata({
            metadata_file: this.state.metadata_file
        }));
    };

    onChange(e, {name, value}) {
        this.setState({[name]: value, is_update: true});
    }

    render() {
        const {error, loading, history, match: {params: {id}}, is_sso, isDone} = this.props;
        const {entity_id, sso_end_point, slo_end_point, idp_certificate, status, is_update, hash, metadata_file} = this.state;
        return (
            <SABody loading={loading}>
                <TabHeader title={'Organizations'} backArrow onTabChange={({value}) => {
                    if (value === 2) {
                        history.replace(`/superadmin/orgs/${id}/users`);
                    } else if (value === 1) {
                        history.replace(`/superadmin/orgs/edit/${id}`);
                    }
                }} activeIndex={2} tabs={this.tabs}/>

                <Segment basic padded='very'>
                    <ErrorMessage errors={error}/>
                    <SuccessMessage messages={isDone ? ["Successfully Updated!"] : []}/>
                    {!is_sso && <React.Fragment>
                        <Form onSubmit={this.onExtract} method={'post'}>
                            <Form.Group>
                                <Form.Field width={10}>
                                    <label>Upload Metadata file:</label>
                                    <input type={'file'}
                                           accept="text/xml"
                                           onChange={(e) => {
                                               this.setState({metadata_file: e.target.files[0]});
                                           }}/>
                                </Form.Field>
                                <Form.Field>
                                    <label>&nbsp;</label>
                                    <Button type='submit' primary circular
                                            disabled={!metadata_file}
                                            floated='right'>Extract Values</Button>
                                </Form.Field>
                            </Form.Group>
                            <br/>
                        </Form>
                        <Divider horizontal>Or</Divider>
                    </React.Fragment>
                    }
                    <Form onSubmit={this.onSave} method={'post'}>

                        <br/>
                        <Form.Field>
                            <label>Status:</label>
                            <QMCheckBox toggle onChange={(checked) => this.setState({status: checked, is_update: true})}
                                        checked={status}/>
                        </Form.Field>
                        <br/>
                        <br/>

                        <Form.Input required
                                    label='Entity ID'
                                    placeholder='Entity ID'
                                    onChange={this.onChange}
                                    name={"entity_id"}
                                    value={entity_id}/>

                        < Form.Input required
                                     label='SSO End Point URL'
                                     placeholder='SSO End Point URL'
                                     onChange={this.onChange}
                                     name={"sso_end_point"}
                                     value={sso_end_point}/>

                        <Form.Input
                            label='SLO End Point URL'
                            placeholder='SLO End Point URL'
                            onChange={this.onChange}
                            name={"slo_end_point"}
                            value={slo_end_point}/>

                        <Form.TextArea required
                                       rows={12}
                                       label='IDP Certificate'
                                       placeholder='IDP Certificate'
                                       onChange={this.onChange}
                                       name={"idp_certificate"}
                                       value={idp_certificate}/>

                        <Button type='submit' primary circular
                                disabled={!is_update}
                                floated='right'>Submit</Button>
                        <Button type='button' secondary inverted circular
                                onClick={() => history.go(-1)}
                                floated='right'>Cancel</Button>
                        <br/>
                        <br/>
                        <br/>
                        {
                            (entity_id && sso_end_point && idp_certificate && hash) &&
                            <Form.Group>
                                <Form.Field width={12}>
                                    <label>Qualmet Login Link:</label>
                                    <Input labelPosition='right' type='text' readOnly
                                           value={process.env.REACT_APP_API_HOST_DOMAIN + "/saml/" + hash + "/sso"}
                                           ref={this.linkRef}>
                                        <input/>
                                        <Label as="a" onClick={() => this.copyLink()}>Copy Link</Label>
                                    </Input>
                                </Form.Field>
                                <Form.Field>
                                    <label>&nbsp;</label>
                                    <Button type='button' primary circular
                                            onClick={() => window.open(process.env.REACT_APP_API_HOST_DOMAIN + "/saml/" + hash + "/metadata.xml")}
                                            disabled={!(entity_id && sso_end_point && idp_certificate && hash)}>
                                        Download Metadata File
                                    </Button>
                                </Form.Field>
                            </Form.Group>
                        }
                        <br/>
                        <br/>
                    </Form>
                </Segment>
            </SABody>
        );
    }
}

function mapStateToProps(state) {
    return {
        ...state.org
    };
}

const connectedEditOrgPage = connect(mapStateToProps)(SAOrgSSOPage);
export {connectedEditOrgPage as SAOrgSSOPage};