import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Grid, Header, Segment, Table} from "semantic-ui-react";
import '../../css/price-table.css';
import {connect} from "react-redux";
import planAction from "../../actions/plan.action";
import orgState from "../../helper/stateUtil";
import {withRouter} from "react-router-dom";
import {PLAN_DURATION_TYPE} from "../../helper/const";

class PriceTable extends Component {
    componentDidMount() {
        this.props.dispatch(planAction.getActivePlans({org_id: this.props.org.id}));
    }

    getDurationLabel = (duration, duration_type, type) => {
        switch (duration_type) {
            case PLAN_DURATION_TYPE.DAY:
                return `${type === 2 ? "" : "Per "} ${duration > 1 ? duration : ""} ${duration === 1 ? "Day" : "Days"}`;
            case PLAN_DURATION_TYPE.MONTH:
                return `${type === 2 ? "" : "Per "} ${duration > 1 ? duration : ""} ${duration === 1 ? "Month" : "Months"}`;
            case PLAN_DURATION_TYPE.YEAR:
                return `${type === 2 ? "" : "Per "} ${duration > 1 ? duration : ""} ${duration === 1 ? "Year" : "Years"}`;
            default:
                return duration;
        }
    };

    getBtnLabel = (plan, currentUsers) => {
        if (plan.active) {
            return "Current Plan";
        } else if (plan.is_get_quote_plan) {
            return "Contact Us";
        } else if (plan.is_unlimited_users) {
            return "Upgrade";
        } else {
            if (plan.no_users < currentUsers) {
                return "Not Applicable";
            } else if (plan.flag === 1) {
                return "Upgrade";
            } else if (plan.flag === -1 && plan.no_users > currentUsers) {
                return "Downgrade";
            } else
                return "Upgrade";
        }
    };

    render() {
        let {items, onSelect, planId, currentUsers} = this.props;
        let noOfFe = 0;
        let active = false;
        let isActiveFreePlan = false;
        items = items.map((i) => {
            if (i.features.length > noOfFe) {
                noOfFe = i.features.length
            }
            if (i.active) {
                active = true;
                if (i.type === 2) {
                    isActiveFreePlan = true;
                }
            }
            if (active) {
                i.flag = 1;
            } else {
                i.flag = -1;
            }
            return i;
        });
        if (!isActiveFreePlan) {
            items = items.filter(item => item.type !== 2);
        }

        return (
            <Grid stackable columns={items.length > 4 ? items.length : 4} centered>
                {items.map((plan, index) => {
                        return <Grid.Column key={index} className={"price-table-column"}>
                            <Segment padded className="plan price-table-column-segment" textAlign={'center'}
                                     raised={!plan.active}>
                                <Segment attached={'top'} color={plan.active ? "teal" : "olive"} inverted
                                         className="plan-title">
                                    {plan.active && <span className="plan-ribbon">
                                    Current
                                </span>}
                                    <Header as={'h1'}>{plan.title}</Header>
                                </Segment>
                                <Segment attached className="feature">
                                    <Header as={'h2'}>{plan.is_get_quote_plan ? "  " : "$" + plan.price}</Header>
                                </Segment>
                                <Segment attached className="feature">
                                    <span
                                        style={{textTransform: "uppercase"}}>{this.getDurationLabel(plan.duration, plan.duration_type, plan.type)}</span>
                                </Segment>
                                <Segment attached className="feature">
                                    <span
                                        style={{textTransform: "uppercase"}}>{plan.is_unlimited_users ? "Unlimited Users" : `Up to ${plan.no_users} Users`}</span>
                                </Segment>
                                <Segment basic padded={'very'}>
                                    <Table basic='very'>
                                        <Table.Body>
                                            {[...Array(noOfFe)].map((_, index) =>
                                                <Table.Row textAlign='center' key={index}>
                                                    <Table.Cell>{plan.features[index] ? plan.features[index].text :
                                                        <span> &nbsp; </span>}</Table.Cell>
                                                </Table.Row>
                                            )}
                                        </Table.Body>
                                    </Table>
                                </Segment>
                                <Button circular basic={planId !== plan.id}
                                        color={planId === plan.id ? "teal" : "grey"}
                                        className="btn-plan"
                                        onClick={() => {
                                            if (plan.is_get_quote_plan) {
                                                this.props.history.push("/contact");
                                            } else {
                                                if (plan.no_users !== null && plan.no_users < currentUsers) {
                                                    return;
                                                }
                                                onSelect(plan.id);
                                            }
                                        }}>
                                    {this.getBtnLabel(plan, currentUsers)}
                                </Button>
                                <br/>
                                <br/>
                            </Segment>
                        </Grid.Column>
                    }
                )}
            </Grid>
        );
    }
}

PriceTable.propTypes = {
    planId: PropTypes.string.isRequired,
    currentUsers: PropTypes.number,
    onSelect: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        ...orgState(state),
        ...state.plan
    };
}

const c = withRouter(connect(mapStateToProps)(PriceTable));
export {c as PriceTable};
