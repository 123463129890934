import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Header, Image} from "semantic-ui-react";
import white from "../../imgs/qualmet-logo-new-white.png";
import dark from "../../imgs/qualmet-logo.jpg";

class HeaderLogo extends Component {
    render() {
        const {darkLogo} = this.props;
        return (
            <Header as='h1' style={{color: 'white'}} textAlign='center'>
                <Image style={{width: '10em', margin: "1.25em auto"}} src={darkLogo ? dark : white}/>
            </Header>
        );
    }
}

HeaderLogo.propTypes = {
    darkLogo: PropTypes.bool
};

export default HeaderLogo;
