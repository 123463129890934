import React, {Component} from 'react';
import PropTypes from 'prop-types';
import * as d3 from "d3";
import {axisTop, scaleLinear} from "d3";
import utils, {
    barContainerOffset,
    circleCxOffset,
    circleR,
    color,
    margin,
    reactHeight,
    separatorHeight,
    transitionDuration,
    xOffset,
    xTranslate
} from './utils';
import "../../../../../../css/chart/chart.css";
import {BAR_CHART_TYPE} from "../../../../../../helper/const";
import textwrap from "../../utils/textwrap";

class AvgHorizontalBarChart extends Component {
    onClick = (item) => {
        this.props.onClick(item);
    };

    onSelect = (item, yIndex) => {
        this.props.onSelect(item, yIndex);
    };

    draw = () => {
        const {data, xLabel, type, maxX, xTicks} = this.props;
        const div = document.getElementById("div-barchart");
        if (!div) return;
        // remove children
        div.innerHTML = '';
        let width = div.getBoundingClientRect().width;
        if (width >= 1200) {
            width = width - 65;
        }
        const compiledData = utils.compileData(data, type, maxX);
        const height = utils.calcHeight(compiledData);
        const isDesktop = utils.isDesktop(width);
        const innerWidth = width - margin.left - margin.right;
        const innerHeight = height - margin.top - margin.bottom;

        const svg = d3.select(div)
            .append("svg")
            .attr('display', data.length === 0 ? 'hidden' : 'visible')
            .attr('width', width)
            .attr('height', height);

        if (data && data.length === 0) {
            return;
        }

        const xScale = scaleLinear()
            .domain([0, maxX])
            .range([isDesktop ? 250 : 0, innerWidth]);

        const container = svg.append('g')
            .attr('class', 'container')
            .attr("transform", `translate(${margin.left},${margin.top})`);

        const xLinesGroup = container.append("g")
            .attr('class', 'x-lines-group');

        container.append("g")
            .attr('class', 'x-axis')
            .attr('transform', `translate(0,0)`)
            .call(axisTop(xScale).ticks(xTicks))
            .selectAll(".tick")
            .each((d) => {
                xLinesGroup
                    .append("line")
                    .attr('stroke', color.grey_95)
                    .attr('stroke-width', 1)
                    .attr('x1', xScale(d))
                    .attr('x2', xScale(d))
                    .attr('y1', 0)
                    .attr('y2', innerHeight - 8);
            });

        // centerline group
        const avg = utils.average(data);
        const xScaleAvg = xScale(avg);

        const centerLine = container.append('g')
            .attr('class', 'centerline')
            .attr('transform', `translate(${xScale(avg)}, 0)`);

        centerLine.append("line")
            .attr('stroke', color.brown)
            .attr('stroke-width', 2)
            .attr('x1', 0)
            .attr('x2', 0)
            .attr('y1', barContainerOffset - 8)
            .attr('y2', innerHeight - 8);

        const textContainer = centerLine.append('g')
            .attr('class', 'text-container');

        const description = textContainer.append('g')
            .attr('class', 'description');

        description.append('text')
            .attr('x', 0)
            .attr('y', 24)
            .attr('style', 'text-anchor:middle;')
            .text(`Average ${xLabel}`);

        description.append('text')
            .attr('x', 0)
            .attr('y', 42)
            .attr('style', 'text-anchor:middle;')
            .text(avg.toFixed(1));

        const barsContainer = container.append("g")
            .attr('class', 'bars-container')
            .attr('transform', `translate(0,${barContainerOffset})`);

        const zebraGroup = barsContainer.append("g")
            .attr("class", 'zebra-group');

        for (let i = 0, y = 0; i < compiledData.length; i++) {
            const item = compiledData[i];
            const isHeader = item.x === null;
            zebraGroup
                .append('rect')
                .attr('transform', `translate(-52,-8)`)
                .attr('fill', isHeader ? color.light_green : "white")
                .attr('opacity', isHeader ? 0.2 : 0.3)
                .attr('stroke', color.grey_70)
                .attr('height', isHeader ? separatorHeight : reactHeight)
                .attr('width', width - margin.right)
                .attr('y', y);
            y = y + (isHeader ? separatorHeight : reactHeight);
        }

        const tipGroup = barsContainer.append('g')
            .attr('class', 'tip-group');

        for (let i = 0, y = 16; i < compiledData.length; i++) {
            const item = compiledData[i];
            const isHeader = item.x === null;
            const isGreater = item.x > avg;
            // const cx = xScale(isGreater ? item.x - avg : avg - item.x);
            const node = tipGroup
                .append('circle')
                .attr('transform', `translate(${xScaleAvg},0)`)
                .attr('fill', item.is_selected ? color.brown : color.light_green)
                .attr('opacity', isHeader ? 0 : 1)
                .attr('r', circleR)
                .attr('cy', y)
                .attr('style', 'cursor: pointer');
            node.transition('duration', transitionDuration)
                .attr('cx', isHeader ? 0 : item.x !== avg ? isGreater ? xScale(item.x) - xScaleAvg - circleCxOffset : xScale(item.x) - xScaleAvg + circleCxOffset : 0);
            if (!isHeader) {
                node.on('click', () => {
                    // node.attr('fill', !item.is_selected ? color.brown : color.light_green);
                    this.onClick(compiledData[i]);
                })
            }
            y = y + (isHeader ? separatorHeight : reactHeight);
        }

        // bar group
        const barGroup = barsContainer.append("g")
            .attr('class', 'bar-group');

        let w = 0;
        for (let i = 0, y = 0; i < compiledData.length; i++) {
            const item = compiledData[i];
            const isHeader = item.x === null;
            const isGreater = item.x > avg;
            const cx = xScale(item.x) - xScaleAvg;
            w = 0;
            if (!isHeader) {
                if (isGreater) {
                    w = cx - circleCxOffset;
                } else {
                    w = -(cx + circleCxOffset)
                }
                if (w < 0) w = 0;
            }
            const node = barGroup
                .append('rect')
                .attr('transform', `translate(${xScaleAvg},0)`)
                .attr('fill', isHeader ? color.grey_95 : item.is_selected ? color.brown : color.light_green)
                .attr('y', y)
                .attr('height', 32)
                .attr('style', 'cursor: pointer');
            node.transition('duration', transitionDuration)
                .attr('width', w);
            if (!isGreater) {
                node.attr('x', (cx + circleCxOffset));
            }
            if (!isHeader) {
                node.on('click', () => {
                    // node.attr('fill', !item.is_selected ? color.brown : color.light_green);
                    this.onClick(item);
                });
            }
            y = y + (isHeader ? separatorHeight : reactHeight);
        }

        // text score
        const textScoreGroup = barsContainer.append('g')
            .attr('class', 'text-score-group');

        for (let i = 0, y = 22; i < compiledData.length; i++) {
            const x = compiledData[i].x;
            const isHeader = x === null;
            const isGreater = x > avg;
            let sc = xScale(x) - xScaleAvg;
            if (sc === 0) {
                sc = -16;
            }
            const node = textScoreGroup
                .append('text')
                .attr('transform', `translate(${xScaleAvg},0)`)
                .attr('fill', isHeader ? color.grey_95 : compiledData[i].is_selected ? color.brown : color.light_green)
                .attr('opacity', isHeader ? 0 : 1)
                .attr('y', y)
                .text(`${x}`)
                .attr('style', `font-size: 15; font-weight: 600; cursor: pointer; text-anchor:${isGreater ? "start" : "end"}`);
            node.transition('duration', transitionDuration)
                .attr('x', isHeader ? 0 : isGreater ? sc + 4 : sc - 4);
            if (!isHeader) {
                node.on('click', () => this.onClick(compiledData[i]));
            }
            y = y + (isHeader ? separatorHeight : reactHeight);
        }

        // circle group
        if (!isDesktop) {
            const circleGroup = barsContainer.append('g')
                .attr('class', 'circle-group');

            for (let i = 0, y = -5; i < compiledData.length; i++) {
                const isHeader = compiledData[i].x === null;
                circleGroup
                    .append('circle')
                    .attr('fill', compiledData[i].is_selected ? color.brown : color.dark_green)
                    .attr('opacity', isHeader ? 0 : 1)
                    .attr('r', circleR)
                    .attr('cx', 0)
                    .attr('cy', y)
                    .attr('transform', `translate(${xScale(0) - 28},${reactHeight / 2 - 2})`);
                y = y + (isHeader ? separatorHeight : reactHeight);
            }
        }

        // text-group
        if (isDesktop) {
            const textGroup = barsContainer.append('g')
                .attr('class', 'text-group');

            for (let i = 0, y = 0; i < compiledData.length; i++) {
                const item = compiledData[i];
                const isHeader = item.x === null;
                if (!isHeader && Array.isArray(compiledData[i].y)) {
                    const t = item.y.length;
                    let transY = 0;
                    item.y.forEach((d, yIndex) => {
                        if (t === 1) {
                            transY = reactHeight / 2 - 2;
                        } else if (t === 2) {
                            transY = transY + 10 * (yIndex + 1);
                        }
                        textGroup
                            .append('text')
                            .attr('fill', item.is_selected ? color.brown : color.dark_green)
                            .attr('transform', `translate(${xScale(0) - xTranslate},${transY})`)
                            .attr('y', y)
                            .attr('style', `cursor: pointer; text-anchor: end;`)
                            .attr('class','wrap')
                            .text(item.y[yIndex].text)
                            .on('click', () => this.onSelect(item, yIndex));
                    });
                } else {
                    const node = textGroup
                        .append('text')
                        .attr('fill', isHeader ? color.grey_20 : item.is_selected ? color.brown : color.dark_green)
                        .attr('transform', `translate(${xScale(0) - xTranslate},${isHeader ? separatorHeight / 2 - 2 : reactHeight / 2 - 2})`)
                        .attr('y', y)
                        .attr('class','wrap')
                        .attr('style', `cursor: pointer; text-anchor: end;`)
                        .text(isHeader ? item.y : item.y.text);

                    if (!isHeader) {
                        node.on('click', () => this.onSelect(item));
                    }
                }
                y = y + (isHeader ? separatorHeight : reactHeight);
            }
        }

        // text second group
        if (!isDesktop) {
            const text2Group = barsContainer.append('g')
                .attr('class', 'text2-group');

            for (let i = 0, temp = 1, y = 0; i < compiledData.length; i++) {
                const isHeader = compiledData[i].x === null;
                text2Group
                    .append('text')
                    .attr('opacity', isHeader ? 0 : 1)
                    .attr('fill', 'white')
                    .attr('transform', `translate(${xScale(0) - xOffset + 12},${reactHeight / 2 - 2})`)
                    .attr('y', y)
                    .attr('style', d => `cursor: pointer; text-anchor: ${isHeader ? "middle" : "end"};`)
                    .text(isHeader ? "" : temp++);
                y = y + (isHeader ? separatorHeight : reactHeight);
                temp = temp++;
            }
        }

        d3.selectAll('.wrap').call(textwrap.dotme);
    };

    componentDidMount() {
        window.addEventListener('resize', () => {
            this.draw();
        });
        this.draw();
    }

    componentDidUpdate(prevProps, prevState) {
        this.draw();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', null);
    }

    render() {
        return (
            <div style={{backgroundColor: 'white', width: '100%'}} id={"div-barchart"}/>
        );
    }
}

AvgHorizontalBarChart.propTypes = {
    data: PropTypes.array.isRequired,
    onClick: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    maxX: PropTypes.number.isRequired,
    xTicks: PropTypes.number.isRequired,
    type: PropTypes.oneOf([BAR_CHART_TYPE.ALL, BAR_CHART_TYPE.TOP_25, BAR_CHART_TYPE.MIDDLE_50, BAR_CHART_TYPE.BOTTOM_25]).isRequired,
    xLabel: PropTypes.string
};

export default AvgHorizontalBarChart;
