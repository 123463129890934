import React, {Component} from 'react';
import {connect} from "react-redux";
import {Button, Form, Header, Icon, Segment, Table} from "semantic-ui-react";
import SABody from "../other/SABody";
import SASubHeader from "../other/SASubHeader";
import helper from "../../../../helper/helper";
import helpAction from "../../../../actions/help.action";
import {HELP_CATS} from "../../../../helper/const";
import QMCheckBox from "../../../other/form/QMCheckBox";

class HelpDocsPage extends Component {
    constructor(props) {
        super(props);
        this.title = "Help";
        this.menuType = parseInt(props.match.params.key);
        this.type = {};
        helper.helpMenus.forEach((menu) => {
            if (this.menuType === menu.value) {
                this.type = menu;
                this.title = menu.text;
            }
        });
    }

    componentDidMount() {
        this.props.dispatch(helpAction.readAll({
            type: this.menuType
        }));
    }

    render() {
        const {items, loading} = this.props;
        const isNotVideosType = this.menuType !== HELP_CATS.VIDEOS;
        let content;
        if (items.length > 0) {
            content = (
                <Segment padded={"very"} basic>
                    <Table basic='very' padded selectable stackable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell></Table.HeaderCell>
                                <Table.HeaderCell>Document Name</Table.HeaderCell>
                                {isNotVideosType && <Table.HeaderCell>Type</Table.HeaderCell>}
                                {isNotVideosType && <Table.HeaderCell>Size</Table.HeaderCell>}
                                <Table.HeaderCell>Date Added</Table.HeaderCell>
                                <Table.HeaderCell>Added By</Table.HeaderCell>
                                <Table.HeaderCell>Actions</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {items.map((item, index) => {
                                return (
                                    <Table.Row className={"row"} key={index}>
                                        <Table.Cell>
                                            <Icon name={this.type.icon} color={"grey"} size={"large"}/>
                                        </Table.Cell>
                                        <Table.Cell>{item.title}</Table.Cell>
                                        {isNotVideosType && <Table.Cell>{item.doc_type}</Table.Cell>}
                                        {isNotVideosType && <Table.Cell>{helper.getStrSize(item.doc_size)}</Table.Cell>}
                                        <Table.Cell>{item.updated_at}</Table.Cell>
                                        <Table.Cell>{item.added_by}</Table.Cell>
                                        <Table.Cell>
                                            <Form>
                                                <Form.Group inline>
                                                    <Form.Field>
                                                        <Button icon={"pencil"} circular color={"teal"}
                                                                size={"mini"}
                                                                onClick={() => {
                                                                    this.props.history.push(`/superadmin/help/${this.menuType}/edit/${item.id}`)
                                                                }}/>
                                                    </Form.Field>
                                                    <Form.Field>
                                                        <Icon name={"eye"} color={"teal"}
                                                              size={"large"} title={'View'}
                                                              onClick={() => {
                                                                  const win = window.open(item.url, '_blank');
                                                                  win.focus();
                                                              }}/>
                                                    </Form.Field>
                                                    <Form.Field>
                                                        <QMCheckBox toggle
                                                                    onChange={(checked) => this.props.dispatch(helpAction.changeStatus({
                                                                        id: item.id,
                                                                        status: checked,
                                                                        index: index
                                                                    }))}
                                                                    checked={item.status}/>
                                                    </Form.Field>
                                                </Form.Group>
                                            </Form>
                                        </Table.Cell>
                                    </Table.Row>
                                );
                            })}
                        </Table.Body>
                    </Table>
                </Segment>
            );
        } else {
            content = (
                <Segment basic placeholder piled clearing>
                    <Header icon>
                        <Icon name='file outline'/>
                        No documents are listed.
                    </Header>
                    <Button color={"teal"} circular
                            onClick={() => this.props.history.push(`/superadmin/help/${this.menuType}/add`)}>
                        Add New
                    </Button>
                </Segment>
            );
        }

        return (
            <SABody loading={loading}>

                <SASubHeader title={this.title} backArrow>
                    <Button color={"teal"} circular floated={'right'}
                            onClick={() => this.props.history.push(`/superadmin/help/${this.menuType}/add`)}>
                        Add New
                    </Button>
                </SASubHeader>

                {content}
            </SABody>
        );
    }
}

HelpDocsPage.propTypes = {};

function mapStateToProps(state) {
    return {...state.help};
}

const cHelpDocsPage = connect(mapStateToProps)(HelpDocsPage);
export {cHelpDocsPage as HelpDocsPage};
