import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Checkbox, Form, Header, Segment} from "semantic-ui-react";
import {EVALUATION_STYLE, EVALUATION_TYPE} from "../../../../helper/const";

class EvaluationSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            interval_id: null,
            email_reminder_id: null,
            evaluatees: [],
            evaluators: [],
            question_set: null,
            evaluation_type: EVALUATION_TYPE.EXTERNAL_EVALUATION,
            evaluation_style: 1,
            ec_status: true,
            responsible_evaluator: null,
            email_reminder_status: true
        };
    }

    onTypeChange = (value) => {
        this.props.onChange({
            ...this.state,
            evaluation_type: value,
            evaluators: [],
            evaluatees: [],
            responsible_evaluator: null
        });
        this.setState({
            evaluation_type: value,
            question_set: null,
            evaluators: [],
            evaluatees: [],
            responsible_evaluator: null
        });
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.data) {
            this.setState({...nextProps.data});
        }
    }

    render() {
        const {master_data, onChange, isUpdate, type} = this.props;
        const {
            interval_id,
            email_reminder_id,
            evaluatees,
            evaluators,
            question_set,
            evaluation_type,
            evaluation_style,
            ec_status,
            responsible_evaluator,
            email_reminder_status
        } = this.state;

        let lawyers = master_data.lawyers;
        if (this.state.client_id) {
            lawyers = lawyers.filter(l => l.client_id === this.state.client_id);
        }
        let evalTypes = [
            {
                text: "External Evaluations",
                value: EVALUATION_TYPE.EXTERNAL_EVALUATION
            }
        ];

        if (type !== 'lawyer') {
            evalTypes.push({
                    text: "Self Evaluations",
                    value: EVALUATION_TYPE.SELF_EVALUATION
                },
                {
                    text: "Self Evaluations With Responsible Evaluator",
                    value: EVALUATION_TYPE.REP_EVAL
                });
        }

        let group;
        switch (evaluation_type) {
            case EVALUATION_TYPE.SELF_EVALUATION:
                master_data.question_sets = [...master_data.question_sets_self_evals];
                group = <Form.Group>
                    <Form.Dropdown label={`Evaluators:`} width={8}
                                   multiple search scrolling selection
                                   value={evaluators}
                                   onChange={(e, {value}) => {
                                       onChange({
                                           ...this.state,
                                           evaluators: value
                                       });
                                       this.setState({
                                           evaluators: value
                                       });
                                   }}
                                   options={master_data.evaluators.filter((e) => responsible_evaluator !== e.value)}/>
                </Form.Group>;
                break;
            case EVALUATION_TYPE.REP_EVAL:
                master_data.question_sets = [...master_data.question_sets_self_evals];
                group = <Form.Group>
                    <Form.Dropdown label='Responsible Evaluator:' width={8}
                                   search scrolling selection
                                   value={responsible_evaluator}
                                   onChange={(e, {value}) => {
                                       onChange({...this.state, responsible_evaluator: value, evaluators: []});
                                       this.setState({responsible_evaluator: value, evaluators: []});
                                   }}
                                   options={master_data.evaluators}/>
                    <Form.Dropdown label={`Evaluators:`} width={8}
                                   multiple search scrolling selection
                                   disabled={!responsible_evaluator}
                                   value={evaluators}
                                   onChange={(e, {value}) => {
                                       onChange({
                                           ...this.state,
                                           evaluators: value
                                       });
                                       this.setState({
                                           evaluators: value
                                       });
                                   }}
                                   options={master_data.evaluators.filter((e) => responsible_evaluator !== e.value)}/>
                </Form.Group>;
                break;
            case EVALUATION_TYPE.EXTERNAL_EVALUATION:
                master_data.question_sets = [...master_data.question_sets_ext_evals];
                group = <Form.Group>
                    <Form.Dropdown label={`Evaluator(s):`} width={8}
                                   multiple search scrolling selection
                                   value={evaluators}
                                   onChange={(e, {value}) => {
                                       onChange({...this.state, evaluators: value});
                                       this.setState({evaluators: value});
                                   }}
                                   options={master_data.evaluators}/>
                    {type === 'matter' &&
                    <Form.Dropdown label={`Evaluatee(s): `} width={8}
                                   multiple search scrolling selection
                                   value={evaluatees}
                                   onChange={(e, {value}) => {
                                       onChange({
                                           ...this.state,
                                           evaluatees: value
                                       });
                                       this.setState({
                                           evaluatees: value
                                       });
                                   }}
                                   disabled={!this.state.client_id}
                                   options={lawyers}/>}
                </Form.Group>;
                break;
            default:
                break;
        }
        return (
            <div>
                <Header style={{marginBottom: "10px"}}> Evaluation Settings: </Header>
                <Segment>
                    <Form>
                        <Form.Group>
                            <Form.Dropdown label='Evaluation Interval:' width={8}
                                           selection value={interval_id} search
                                           disabled={isUpdate}
                                           onChange={(e, {value}) => {
                                               onChange({...this.state, interval_id: value});
                                               this.setState({interval_id: value});
                                           }}
                                           options={master_data.intervals}/>
                            <Form.Dropdown label='Email Reminder:' width={8}
                                           selection value={email_reminder_id}
                                           disabled={!email_reminder_status}
                                           onChange={(e, {value}) => {
                                               onChange({...this.state, email_reminder_id: value});
                                               this.setState({email_reminder_id: value});
                                           }}
                                           options={master_data.email_reminders}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Dropdown label='Evaluation Style:' width={8}
                                           selection value={evaluation_style} search
                                           onChange={(e, {value}) => {
                                               onChange({...this.state, evaluation_style: value});
                                               this.setState({evaluation_style: value});
                                           }}
                                           options={[
                                               {text: "Multi Page", value: EVALUATION_STYLE.MULTI_PAGE},
                                               {text: "Single Page", value: EVALUATION_STYLE.SINGLE_PAGE}
                                           ]}/>
                            <Form.Field width={8}>
                                <label>Email Reminder Status:</label>
                                <Checkbox toggle label={email_reminder_status ? "On" : "Off"}
                                          checked={email_reminder_status}
                                          onChange={(e, {checked}) => {
                                              onChange({...this.state, email_reminder_status: checked});
                                              this.setState({email_reminder_status: checked});
                                          }}/>
                            </Form.Field>
                        </Form.Group>
                        <Form.Group>
                            <Form.Dropdown label='Evaluation Type:' width={8}
                                           disabled={isUpdate}
                                           selection value={evaluation_type} search
                                           onChange={(e, {value}) => {
                                               this.onTypeChange(value);
                                           }}
                                           options={evalTypes}/>
                            <Form.Field width={8}>
                                <label>Evaluation Cycle Status:</label>
                                <Checkbox toggle label={ec_status ? "On" : "Off"}
                                          checked={ec_status}
                                          onChange={(e, {checked}) => {
                                              onChange({...this.state, ec_status: checked});
                                              this.setState({ec_status: checked});
                                          }}/>
                            </Form.Field>
                        </Form.Group>
                        <Form.Group>
                            <Form.Dropdown label='Evaluation Question Set:' width={8}
                                           selection search
                                           value={question_set}
                                           onChange={(e, {value}) => {
                                               onChange({...this.state, question_set: value});
                                               this.setState({question_set: value});
                                           }}
                                           options={master_data.question_sets}/>
                        </Form.Group>
                        {group}
                    </Form>
                </Segment>
            </div>
        );
    }
}

EvaluationSettings.propTypes = {
    type: PropTypes.oneOf(["lawyer", 'matter', 'firm']),
    data: PropTypes.object,
    org: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    master_data: PropTypes.object.isRequired,
    isUpdate: PropTypes.bool.isRequired,
    limit: PropTypes.number
};

export default EvaluationSettings;
