import React, {Component} from 'react';
import ColoredBody from "../../other/ColoredBody";
import {Button, Header, Segment} from "semantic-ui-react";
import BoxHeader from "../../other/BoxHeader";
import BoxFooter from "../../other/BoxFooter";
import {TermsOfUseContent} from "./components/TermsOfUseContent";
import {connect} from "react-redux";

class TermsAndConditionPage extends Component {
    render() {
        const {history, loading} = this.props;
        return (
            <ColoredBody boxWidth={"768px"} textAlign={"center"}>
                <BoxHeader/>
                <Segment basic padded>
                    <Header style={{fontSize: '18px', margin: "auto auto 1em auto"}}>
                        Terms of Use
                    </Header>
                    <div style={{
                        maxHeight: "320px",
                        minHeight: "320px",
                        border: "solid lightgrey 1px",
                        padding: "1em",
                        textAlign: "justify",
                        overflow: "auto",
                        marginBottom: "1em",
                        fontFamily: "Arial"
                    }}>
                        <TermsOfUseContent/>
                    </div>
                    <Button circular color={"teal"} disabled={loading} floated='right'
                            onClick={() => {
                                history.replace(`/register/form${this.props.location.search}`);
                            }}>
                        <span style={{margin: "auto 2.5em"}}>I Agree</span>
                    </Button>
                    <Button circular color={"grey"} floated='right' onClick={() => {
                        history.replace("/");
                    }}>
                        <span style={{margin: "auto 2.5em"}}>Cancel</span>
                    </Button>
                    <br/>
                    <br/>
                </Segment>
                <BoxFooter/>
            </ColoredBody>
        );
    }
}

export default connect(state => {
    return {...state.app};
})(TermsAndConditionPage);
