import React, {Component} from 'react';
import {Button, Form, Header, Icon, Modal, Segment, Table} from "semantic-ui-react";
import {connect} from "react-redux";
import {OrgMenuBar} from "../../other/OrgMenuBar";
import userAction from "../../../../../actions/user.action";
import {orgState} from "../../../../../helper/stateUtil";
import Body from "../../../../other/Body";
import helper from "../../../../../helper/helper";
import TabHeader from "../../other/TabHeader";
import OrgSubHeader from "../../other/OrgSubHeader";

class OrgUsersPage extends Component {
    constructor(props) {
        super(props);
        this.tabs = [
            {text: "Users", value: 1}
        ];
        if (props.org.sso_status) {
            this.tabs.push({text: "SSO Users", value: 2});
        }
        this.state = {
            pending: false,
            roles: [],
            item: null // as selected user for activation
        };
        this.onChange = this.onChange.bind(this);
        this.onActivate = this.onActivate.bind(this);
        this.userRoles = helper.roles.map((role) => {
            return {text: role.title, value: role.id};
        });
    }

    componentDidMount() {
        this.loadUsers(this.state.pending);
    }

    loadUsers = (pending) => {
        const {dispatch, org} = this.props;
        if (pending) {
            dispatch(userAction.readAllPendingUsers({org_id: org.id}));
        } else {
            dispatch(userAction.readAll({org_id: org.id}));
        }
    };

    componentWillReceiveProps(nextProps) {
        const {isDone, isAllowed, history} = nextProps;
        if (!isAllowed) {
            history.replace("/forbidden");
        }
        if (isDone) {
            this.setState({item: null, roles: []});
            this.loadUsers(this.state.pending);
        }
    }

    onChange = () => {
        const {pending} = this.state;
        this.loadUsers(!pending);
        this.setState({pending: !pending});
    };

    onActivate = user => this.setState({item: user});

    render() {
        const {loading, users, dispatch, history, org, pending_users} = this.props;
        const {pending, item, roles} = this.state;
        let content;
        if (users.length > 0) {
            content = (
                <Segment basic style={{padding: "1em 3em 3em 3em"}}>
                    <Table basic='very' padded selectable stackable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Name & Email</Table.HeaderCell>
                                {/*{org.sso_status && <Table.HeaderCell>SSO Login Status</Table.HeaderCell>}*/}
                                <Table.HeaderCell>Status</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {users.map((user, index) => {
                                return (
                                    <Table.Row className={"row"} key={index}>
                                        <Table.Cell style={{paddingLeft: "16px"}}
                                                    onClick={() => this.props.history.push(`/orgadmin/users/manage/${user.id}`)}>
                                            <Icon name="user" color={"grey"} size={"large"}/>
                                            <span><b>{user.name}</b> ({user.email})</span>
                                        </Table.Cell>
                                        {/*{org.sso_status && <Table.Cell>*/}
                                        {/*    {user.is_sso_enable ? "Active" : "Inactive"}*/}
                                        {/*</Table.Cell>}*/}
                                        <Table.Cell collapsing>
                                            {!pending && <Form>
                                                <Form.Group inline>
                                                    <Form.Field>
                                                        <Form.Checkbox toggle checked={user.status}
                                                                       label={user.status ? "Active" : "Inactive"}
                                                                       onChange={(e, {checked}) => {
                                                                           this.props.dispatch(userAction.changeStatus({
                                                                               id: user.id,
                                                                               org_id: this.props.org.id,
                                                                               status: checked
                                                                           }));
                                                                       }}/>
                                                    </Form.Field>
                                                </Form.Group>
                                            </Form>}
                                            {pending &&
                                            <Button circular color={'teal'}
                                                    onClick={() => this.onActivate(user)}>Activate</Button>}
                                        </Table.Cell>
                                    </Table.Row>
                                );
                            })}
                        </Table.Body>
                    </Table>
                </Segment>
            );
        } else {
            content = (
                <Segment basic placeholder piled>
                    <Header icon>
                        <Icon name='file outline'/>
                        No {pending ? "pending" : ""} users are listed.
                    </Header>
                    {!pending &&
                    <Button color={"teal"} circular onClick={() => this.props.history.push("/orgadmin/users/add")}>
                        Add User
                    </Button>}
                </Segment>
            );
        }

        let btn = <Button circular size={"medium"}
                          onClick={() => history.push("/orgadmin/users/add")}
                          floated="right" color={"teal"}>Add User</Button>;
        return (
            <Body loading={loading}>

                <OrgMenuBar/>

                {org.sso_status ?
                    <TabHeader title={`Users - ${org.name}`} onTabChange={({value}) => {
                        if (value === 2) {
                            history.replace(`/orgadmin/users/import`);
                        }
                    }} tabs={this.tabs}>
                        {btn}
                    </TabHeader>
                    :
                    <OrgSubHeader title={`Users - ${org.name}`}>
                        {btn}
                    </OrgSubHeader>
                }
                <Segment basic style={{padding: "1em 3em 0 4em"}}>
                    <Form>
                        <Form.Group>
                            <Button circular primary={pending}
                                    onClick={this.onChange}>{pending ? "Go Back" : `Pending Users${pending_users > 0 ? ` (${pending_users})` : ""}`}</Button>
                        </Form.Group>
                    </Form>
                </Segment>

                {content}

                {item != null &&
                <Modal size={"tiny"} open>
                    <Modal.Header>Activate User</Modal.Header>
                    <Modal.Content>
                        <Header as={'h3'}>Are you sure you want to activate this user?</Header>
                        <br/>
                        <p>User Name: {item.name} <br/> Email: {item.email}</p>
                        <Form>
                            <Form.Dropdown
                                label={`Assign User Roles:`}
                                multiple search scrolling selection
                                onChange={(e, {value}) => {
                                    this.setState({roles: value});
                                }}
                                options={this.userRoles}/>
                        </Form>
                        <br/>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button circular onClick={() => this.setState({item: null, roles: []})}
                                disabled={loading}>No</Button>
                        <Button color={'teal'} circular content='Yes' disabled={loading || roles.length === 0}
                                loading={loading}
                                onClick={() => {
                                    dispatch(userAction.activatePendingUser({
                                        user_id: item.id,
                                        status: true,
                                        roles: roles
                                    }));
                                }}/>
                    </Modal.Actions>
                </Modal>}

            </Body>
        );
    }
}

function mapStateToProps(state) {
    let s = orgState(state);
    return {...s, ...state.user};
}

const connectedOrgUsersPage = connect(mapStateToProps)(OrgUsersPage);
export {connectedOrgUsersPage as OrgUsersPage};