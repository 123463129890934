import React, {Component} from 'react';
import subAction from "../../../../actions/sub.action";
import {Button, Grid, Header, Icon, Label, List, Segment, Table} from "semantic-ui-react";
import {ORG_SUB_STATUS} from "../../../../helper/const";
import {connect} from "react-redux";
import SABody from "../../qualmetadmin/other/SABody";
import SASubHeader from "../../qualmetadmin/other/SASubHeader";

class SAOrgSubsPage extends Component {
    componentDidMount() {
        this.props.dispatch(subAction.getAllSubs({id: this.props.match.params.id}));
    }

    getStatusTitle = status => {
        switch (status) {
            case ORG_SUB_STATUS.PENDING:
                return "Pending";
            case ORG_SUB_STATUS.INVOICED:
                return "Invoiced";
            case ORG_SUB_STATUS.PAID:
                return "Paid";
            case ORG_SUB_STATUS.CANCELED:
                return "Canceled";
            case ORG_SUB_STATUS.DENIED:
                return "Denied";
            case ORG_SUB_STATUS.REFUNDED:
                return "Refunded";
            default:
                return "Unknown";
        }
    };

    componentWillUnmount() {
        this.props.dispatch(subAction.unmount());
    }

    render() {
        const {loading, items, item: headers} = this.props;
        let content;
        if (items.length > 0) {
            const last = items[0];
            content = (
                <Segment padded={"very"} basic>
                    <Segment basic padded>
                        <Grid columns='equal'>
                            <Grid.Column>
                                <Header as={"h3"}>
                                    {headers.org_name} ({headers.status ? "Active" : "Inactive"})
                                </Header>
                                <br/>
                                <b> Last invoice information</b>
                                <b>Name: </b>{last.name} <br/>
                                <b>Address: </b>{last.address} <br/>
                                <b>Email: </b>{last.email} <br/>
                                <b>Phone: </b>{last.phone} <br/>
                            </Grid.Column>
                            <Grid.Column>
                                <b>Current Plan Type:</b> {headers.plan_title}<br/>
                                {/*Date Created: {last.pay_date}<br/>*/}
                                <b>Last Change: </b>{last.pay_date}<br/>
                            </Grid.Column>
                        </Grid>
                    </Segment>
                    <List horizontal>
                        <List.Item>
                            <b>Status:</b>
                        </List.Item>
                        <List.Item>
                            <Label circular color={"red"} empty/> Pending
                        </List.Item>
                        <List.Item>
                            <Label circular color={"yellow"} empty/> Other
                        </List.Item>
                        <List.Item>
                            <Label circular color={"green"} empty/> Paid
                        </List.Item>
                    </List>
                    <Table basic='very' padded selectable stackable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Invoice Number</Table.HeaderCell>
                                <Table.HeaderCell>Status</Table.HeaderCell>
                                <Table.HeaderCell>Plan</Table.HeaderCell>
                                <Table.HeaderCell>Plan Amount</Table.HeaderCell>
                                <Table.HeaderCell>Payment Due Date</Table.HeaderCell>
                                <Table.HeaderCell>Renewal Date</Table.HeaderCell>
                                <Table.HeaderCell>Amount Paid</Table.HeaderCell>
                                <Table.HeaderCell textAlign={"center"}>Action</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {items.map((item, index) => {
                                return (
                                    <Table.Row className={"row"} key={index}
                                               warning={item.osstatus && (item.osstatus < 7 && item.osstatus > 2)}
                                               negative={item.osstatus === 2}
                                               positive={item.osstatus === 7}>
                                        <Table.Cell>{item.invoice_id}</Table.Cell>
                                        <Table.Cell>{this.getStatusTitle(item.osstatus)}</Table.Cell>
                                        <Table.Cell>{item.plan_title}</Table.Cell>
                                        <Table.Cell>${item.p_amount}</Table.Cell>
                                        <Table.Cell>{item.pay_date}</Table.Cell>
                                        <Table.Cell>{item.expiry_date}</Table.Cell>
                                        <Table.Cell>${item.os_amount}</Table.Cell>
                                        <Table.Cell textAlign={"center"}>
                                            <Button circular
                                                    color={"teal"}
                                                    size={"tiny"}
                                                    onClick={() => {
                                                        this.props.history.push(`/superadmin/payment/billing/org/${this.props.match.params.id}/manage-payment/${item.id}?isFirst=${index === 0 ? true : false}&title=${encodeURIComponent(headers ? headers.org_name : "Unknown")}`)
                                                    }}>
                                                {index === 0 ? "Manage" : "View"}
                                            </Button>
                                        </Table.Cell>
                                    </Table.Row>
                                );
                            })}
                        </Table.Body>
                    </Table>
                </Segment>
            );
        } else {
            content = (
                <Segment basic placeholder piled>
                    <Header icon>
                        <Icon name='file outline'/>
                        No payments are listed.
                    </Header>
                </Segment>
            );
        }
        return (
            <SABody loading={loading}>

                <SASubHeader title={"Billing Plans"} backArrow>
                    <Button color={"teal"} circular floated={'right'}
                            onClick={() => this.props.history.push(`/superadmin/payment/billing/org/${this.props.match.params.id}/add-payment?title=${encodeURIComponent(headers ? headers.org_name : "Unknown")}`)}>
                        Add Payment
                    </Button>
                    <Button color={"teal"} circular floated={'right'}
                            onClick={() => this.props.history.push(`/superadmin/orgs/edit/${this.props.match.params.id}`)}>
                        Manage Organization
                    </Button>
                </SASubHeader>

                {content}
            </SABody>
        );
    }
}

export default connect(state => {
    return state.sub;
})(SAOrgSubsPage);
