import React, {Component} from 'react';
import {connect} from "react-redux";
import Body from "../../../../other/Body";
import {orgState} from "../../../../../helper/stateUtil";
import {OrgMenuBar, reportsPath} from "../../other/OrgMenuBar";
import Report from "../components/other/Report";
import {ReportClientSummary} from './components/ReportClientSummary';
import {ReportClientDetails} from './components/ReportClientDetails';
import {ReportClientComments} from './components/ReportClientComments';
import TabHeader from "../../other/TabHeader";

class ClientReportPage extends Component {
    constructor(props) {
        super(props);
        this.clientId = props.match.params.id;
        this.tabs = [
            {
                text: "Summary",
                value: "summary",
                render: <ReportClientSummary clientId={this.clientId}/>
            },
            {
                text: "Details",
                value: 'details',
                render: <ReportClientDetails clientId={this.clientId}/>
            },
            {
                text: "Comments",
                value: 'comments',
                render: <ReportClientComments clientId={this.clientId}/>
            }
        ];
    }

    render() {
        const {loadingFiltersAndSummary, match, org} = this.props;
        const type = match.params.type;
        let content;
        let activeIndex = 0;
        this.tabs.forEach((tab, index) => {
            if (tab.value === type) {
                activeIndex = index;
                content = tab.render;
            }
        });

        return (
            <Body loading={loadingFiltersAndSummary}>
                <OrgMenuBar/>
                <TabHeader
                    title={`${org.str_client_firm}s`}
                    backArrow
                    tabs={this.tabs}
                    activeIndex={activeIndex}
                    onTabChange={(tab) => {
                        this.props.history.replace(`${reportsPath}/clients-firms/${this.clientId}/${tab.value}`);
                    }}/>
                <Report>
                    {content}
                </Report>
            </Body>
        );
    }
}

function mapStateToProps(state) {
    return {...orgState(state), ...state.report};
}

const cClientReportPage = connect(mapStateToProps)(ClientReportPage);
export {cClientReportPage as ClientReportPage};
