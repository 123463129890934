import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {orgState} from "../../../../../../helper/stateUtil";
import ReportHeader from "../../components/header/ReportHeader";
import {Form, Grid, List, Table} from "semantic-ui-react";
import DataFilter from "../../components/DataFilter";
import NoDataMessage from "../../components/other/NoDataMessage";
import reportAction from "../../../../../../actions/report.action";
import BarChart from "../../components/barchart/BarChart";
import FullScreenLoader from "../../../../../other/FullScreenLoader";

class ReportLawyerDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            matters: null,
            cycles: null,
            status: null
        };
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        const {lawyerId} = this.props;
        this.props.dispatch(reportAction.getLawyerDetailsFilter({
            lawyer_id: lawyerId
        }));
    }

    componentWillReceiveProps(nextProps) {
        const {loadingFiltersAndSummary, isDone} = nextProps;
        let s = {};
        if (!loadingFiltersAndSummary && isDone) {
            [
                'matters',
                'cycles',
                'status',
            ].forEach((item) => {
                if (nextProps[item] && nextProps[item].length > 0) {
                    s[item] = nextProps[item][0].value;
                }
            });
            this.props.dispatch(reportAction.getLawyerDetails({
                lawyer_id: this.props.lawyerId
            }));
        }
        this.setState((state) => {
            return {...state, ...s};
        });
    }

    onChange = (_, {name, value}) => {
        this.setState({[name]: value});
        this.props.dispatch(reportAction.getLawyerDetails({
            org_id: this.props.org.id,
            lawyer_id: this.props.lawyerId,
            ...this.state,
            [name]: value
        }));
    };

    render() {
        const {cycles, status, matters} = this.state;
        const {loadingData, header, org, data} = this.props;
        return (
            <React.Fragment>
                <FullScreenLoader active={loadingData}/>
                <ReportHeader>
                    <Grid columns={'equal'} stackable>
                        <Grid.Column>
                            {header && <List>
                                <List.Item>Evaluatee Name: <b>{header.lawyer_name}</b></List.Item>
                                <List.Item>{org.str_client_firm} Name: <b>{header.client_name}</b></List.Item>
                            </List>}
                        </Grid.Column>
                    </Grid>
                </ReportHeader>
                <DataFilter>
                    <Form.Group>
                        <Form.Field width={6}>
                            <Form.Dropdown
                                label={`By Evaluations:`}
                                value={matters}
                                selection name={'matters'}
                                onChange={this.onChange}
                                options={this.props.matters}/>
                        </Form.Field>
                        <Form.Field width={6}>
                            <Form.Dropdown
                                label={`By Status:`}
                                selection name={"status"}
                                value={status}
                                onChange={this.onChange}
                                options={this.props.status}/>
                        </Form.Field>
                        <Form.Field width={6}>
                            <Form.Dropdown
                                label={`By Cycle:`}
                                selection name={"cycles"}
                                value={cycles}
                                onChange={this.onChange}
                                options={this.props.cycles}/>
                        </Form.Field>
                    </Form.Group>
                </DataFilter>

                <NoDataMessage active={data.length === 0}/>

                <br/>
                {(data && data.length > 0) && <Table basic={'very'}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>
                                Question
                            </Table.HeaderCell>
                            <Table.HeaderCell>Distribution</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {data.map((d, index) =>
                            <Table.Row key={index}>
                                <Table.Cell>
                                    {d.question}
                                </Table.Cell>
                                <Table.Cell>
                                    {d.is_text ?
                                        <List bulleted>
                                            {d.answers.map((c, i) => <List.Item key={i}>{c}</List.Item>)}
                                        </List> :
                                        <BarChart data={d.answers || []} maxX={d.answers ? d.answers.length : 5}/>}
                                </Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>
                </Table>}
            </React.Fragment>
        );
    }
}

ReportLawyerDetails.propTypes = {
    lawyerId: PropTypes.string.isRequired
};

function mapStateToProps(state) {
    return {...orgState(state), ...state.report};
}

const cReportLawyerDetails = connect(mapStateToProps)(ReportLawyerDetails);
export {cReportLawyerDetails as ReportLawyerDetails};
