import 'core-js';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import bugsnagReact from '@bugsnag/plugin-react';
import * as serviceWorker from './serviceWorker';
import {Provider} from "react-redux";
import store from "./store";
import {App} from "./App";
import helper from "./helper/helper";
import errTracker from './helper/tracker';
import './index.css';
import QualmetErrorBoundary from "./components/other/QualmetErrorBoundary";

// setup Google Analytics
if (process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID) {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID, {debug: process.env.NODE_ENV !== "production"});
}

// check is user loggedin, if yes then validate login else redirect to login page
helper.init();

// setup bugsnag
errTracker.use(bugsnagReact, React);

// wrap your entire app tree in the ErrorBoundary provided
const ErrorBoundary = errTracker.getPlugin('react');

window.onload = () => {
    ReactDOM.render(
        <ErrorBoundary>
            <QualmetErrorBoundary>
                <Provider store={store}>
                    <App/>
                </Provider>
            </QualmetErrorBoundary>
        </ErrorBoundary>
        , document.getElementById('content')
    );
    document.body.className = "";
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// if (process.env.NODE_ENV === 'production') {
//     serviceWorker.register();
// } else {
//     serviceWorker.unregister();
// }

serviceWorker.unregister();
