import React, {Component} from 'react';
import {connect} from "react-redux";
import {orgState} from "../../../../../helper/stateUtil";
import {OrgMenuBar} from "../../other/OrgMenuBar";
import {AddUserForm} from "../../../form/users/AddUserForm";
import Body from "../../../../other/Body";
import OrgSubHeader from "../../other/OrgSubHeader";

class OrgAddUserPage extends Component {
    render() {
        const {loading, org} = this.props;
        return (
            <Body loading={loading}>

                <OrgMenuBar/>

                <OrgSubHeader title={"Add User"} backArrow/>

                <AddUserForm orgid={org.id}/>

            </Body>
        );
    }
}

function mapStateToProps(state) {
    const s = orgState(state);
    return {
        ...s,
        ...state.user
    };
}

const connectedOrgAddUserPage = connect(mapStateToProps)(OrgAddUserPage);
export {connectedOrgAddUserPage as OrgAddUserPage};