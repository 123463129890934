import React, {Component} from 'react';
import {Redirect, Route} from "react-router-dom";
import ReactGA from 'react-ga';
import helper from "../../helper/helper";
import {orgState} from "../../helper/stateUtil";
import {connect} from "react-redux";
import {MATTER, ORG_ADMIN, REPORT_VIEWER, SUPER_ADMIN} from "../../helper/const";

class PrivateRoute extends Component {
    render() {
        const {component: Component, role, org, ...rest} = this.props;
        return (
            <Route {...rest} render={props => {
                if (helper.isLoggedIn()) {
                    if (helper.hasPermission(role)) {
                        if ([ORG_ADMIN, MATTER, REPORT_VIEWER].includes(role)) {
                            if (!org) {
                                return <Redirect to={{pathname: '/', state: {from: props.location}}}/>;
                            }
                        }
                        if ([SUPER_ADMIN].includes(role)) {
                            if (org) {
                                return <Redirect to={{pathname: '/orgadmin', state: {from: props.location}}}/>;
                            }
                        }
                        ReactGA.pageview(rest.path);
                        return <Component {...props} />;
                    } else {
                        ReactGA.pageview("/forbidden");
                        return <Redirect to={{pathname: '/forbidden', state: {from: props.location}}}/>;
                    }
                } else {
                    ReactGA.pageview("/login");
                    return <Redirect to={{pathname: '/login', state: {from: props.location}}}/>
                }
            }}/>
        );
    }
}

function mapStateToProps(state) {
    const s = orgState(state);
    return {...s};
}

export default connect(mapStateToProps)(PrivateRoute);