import React, {Component} from 'react';
import {Button, Header, Icon, List, Segment} from "semantic-ui-react";
import PropTypes from 'prop-types';
import {withRouter} from "react-router-dom";
import qs from "query-string";

class OrgSubHeader extends Component {
    render() {
        const {title, backArrow, children, history, bottomSide, style, location} = this.props;
        this.params = qs.parse(location.search);
        let s = {backgroundColor: "#e5e5e5"};
        if (style) {
            s = {...s, ...style};
        }
        return (
            <Segment basic style={s} className={"mini padded"} id={'sub-header'}>
                {!bottomSide && children}
                {this.params.checklist ? <List horizontal>
                        <List.Item>
                            <Button content='Back to checklist' icon='left arrow' labelPosition='left' primary onClick={() => history.goBack()}/>
                        </List.Item>
                        <List.Item>
                            <Header as="h3" style={{margin: `${bottomSide ? "0.65em" : "0.1em"} auto`}}>
                                {title}
                            </Header>
                        </List.Item>
                    </List> :
                    <Header as="h3" style={{margin: `${bottomSide ? "0.65em" : "0.1em"} auto`}}>
                        {backArrow && <Icon name="arrow left" size='tiny' link onClick={() => history.goBack()}/>}
                        {title}
                    </Header>
                }
                {bottomSide && children}
            </Segment>
        );
    }
}

OrgSubHeader.propTypes = {
    title: PropTypes.string.isRequired,
    backArrow: PropTypes.bool,
    bottomSide: PropTypes.bool,
    style: PropTypes.object
};

OrgSubHeader.defaultProps = {
    backArrow: true
};

export default withRouter(OrgSubHeader);

