import React, {Component} from 'react';
import Body from "../../../../other/Body";
import {connect} from "react-redux";
import {Button, Form, Header, Input, Label, Segment, Table} from "semantic-ui-react";
import ErrorMessage from "../../../../other/ErrorMessage";
import orgState from "../../../../../helper/stateUtil";
import orgAction from "../../../../../actions/org.action";
import {OrgMenuBar} from "../../other/OrgMenuBar";
import OrgSubHeader from "../../other/OrgSubHeader";

const isValidDomain = require('is-valid-domain');

class OrgSettingsDomainsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            domains: [],
            domain: '',
            is_update: false
        };
    }

    notIn = () => {
        const {domains, domain} = this.state;
        let flag = false;
        domains.forEach((d) => {
            if (d.text === domain) {
                if (!d.is_deleted)
                    flag = true;
            }
        });
        return flag;
    };

    componentDidMount() {
        this.value = 1;
        const {dispatch, org} = this.props;
        dispatch(orgAction.getDomains({org_id: org.id}));
    }

    componentWillReceiveProps(nextProps) {
        const {isDone, domains} = nextProps;
        if (isDone) {
            this.setState({is_update: false})
        } else {
            this.setState({domains: [...domains], is_update: false});
        }
    }

    render() {
        const {error, loading, org,} = this.props;
        let {domains, domain, is_update} = this.state;
        domains = domains.filter((d) => !d.is_deleted);
        return (
            <Body loading={loading}>

                <OrgMenuBar/>

                <OrgSubHeader title={"Organization Domains"}/>

                <Segment basic padded='very'>
                    <Form>
                        <ErrorMessage errors={error}/>

                        <Header>
                            Organization Domains:
                        </Header>
                        <br/>
                        <Table basic='very'>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>#</Table.HeaderCell>
                                    <Table.HeaderCell>Domain Name</Table.HeaderCell>
                                    <Table.HeaderCell></Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {domains
                                    .map((d, index) =>
                                        <Table.Row key={index}>
                                            <Table.Cell>{index + 1}</Table.Cell>
                                            <Table.Cell>{d.text}</Table.Cell>
                                            <Table.Cell>
                                                <Label as={'a'} circular color={"red"}
                                                       onClick={() => this.setState((state) => {
                                                           d.is_deleted = true;
                                                           state.domains[index] = d;
                                                           return {domains: [...state.domains], is_update: true};
                                                       })}>
                                                    Delete
                                                </Label>
                                            </Table.Cell>
                                        </Table.Row>
                                    )}
                            </Table.Body>
                        </Table>

                        <br/>
                        <Form.Field width={8}>
                            <label>Add domain:</label>
                            <Input
                                action={<Button disabled={!isValidDomain(domain) || this.notIn()}
                                                onClick={() => this.setState((state) => {
                                                    return {
                                                        domains: [...state.domains, {
                                                            text: domain,
                                                            value: this.value++
                                                        }],
                                                        domain: '',
                                                        is_update: true
                                                    }
                                                })}>Add</Button>}
                                placeholder='example.com' value={domain}
                                onChange={(_, {value}) => this.setState({domain: value})}/>
                        </Form.Field>

                        <br/>
                        <Button type='submit' primary
                                onClick={() => {
                                    this.props.dispatch(orgAction.updateDomains({
                                        ...this.state,
                                        org_id: org.id
                                    }));
                                }}
                                circular
                                disabled={domains < 1 || !is_update}
                                floated='right'>Update</Button>
                        <Button type='button' secondary inverted circular
                                onClick={() => this.props.history.go(-1)}
                                floated='right'>Cancel</Button>
                        <br/>
                    </Form>
                </Segment>

            </Body>
        );
    }
}

function mapStateToProps(state) {
    let org = orgState(state);
    org.domains = state.org.domains;
    return {
        ...org,
        ...state.auth
    };
}

const cOrgSettingsPage = connect(mapStateToProps)(OrgSettingsDomainsPage);
export {cOrgSettingsPage as OrgSettingsDomainsPage};
