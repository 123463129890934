import React, {Component} from "react";
import PropTypes from "prop-types";
import {Button, Grid, Header, Icon, Segment, Table} from "semantic-ui-react";
import matterAction from "../../../../../actions/matter.action";
import {connect} from "react-redux";
import {orgState} from "../../../../../helper/stateUtil";
import DiversityTable from "../../other/DiversityTable";

class MatterDiversity extends Component {
    constructor(props) {
        super(props);
        this.state = {
            team_total: 0,
            racially_diverse: 0,
            diversity_total: 0,
            lgbt: 0,
            females: 0,
            is_update: false
        };
        this.onChange = this.onChange.bind(this);
        this.varToString = this.varToString.bind(this);
    }


    onChange = (key, value) => {
        this.setState({[key]: this.state[key] + value, is_update: true});
    };

    varToString = (varObj) => Object.keys(varObj)[0];


    componentWillMount() {
        this.props.dispatch(matterAction.getDiversityData({
            org_id: this.props.org.id,
            id: this.props.matterId
        }));
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.diversity) {
            this.setState(() => {
                return {...nextProps.diversity, is_update: false}
            });
        }
    }

    componentWillUnmount() {
        this.props.dispatch(matterAction.unmount());
    }

    render() {
        const {team_total, diversity_total, racially_diverse, lgbt, females, is_update} = this.state;
        // const total = (racially_diverse + lgbt + females + diversity_total);
        let index = (diversity_total * 100) / team_total;
        // const per = index ? (index * 4) < 100 ? (index * 4) : 100 : 0;
        index = parseInt(index.toFixed(0)) || 0;
        const taddable = ((diversity_total) < team_total);
        // const addable = ((racially_diverse + lgbt + females) < diversity_total);
        let imgPath = 0;
        if (index <= 10) {
            imgPath = 1;
        } else if (index <= 30) {
            imgPath = 2;
        } else if (index <= 45) {
            imgPath = 3;
        } else if (index <= 60) {
            imgPath = 4
        } else if (index <= 100) {
            imgPath = 5;
        }
        return (
            <Segment basic padded>
                <Button floated='right' color={"teal"} circular disabled={!is_update}
                        onClick={() => this.props.dispatch(matterAction.updateDiversityData({
                            org_id: this.props.org.id,
                            id: this.props.matterId,
                            ...this.state,
                            diversity_score: index || 0,
                            diversity_index: index || 0
                        }))}>Update</Button>
                <Header>Diversity Information:</Header>
                <br/>
                <br/>
                <Grid stackable columns={2}>
                    <Grid.Column width={5}>
                        <Table celled>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell textAlign='center'>Group</Table.HeaderCell>
                                    <Table.HeaderCell textAlign='center' colSpan={3}>Totals</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                <Table.Row active>
                                    <Table.Cell><b>Team Total</b></Table.Cell>
                                    <Table.Cell textAlign='center'>
                                        {taddable &&
                                        <Icon circular name='minus' link color={"teal"} inverted size='small'
                                              onClick={() => this.onChange(this.varToString({team_total}), -1)}/>}
                                    </Table.Cell>
                                    <Table.Cell textAlign='center'>
                                        <p style={{fontSize: "16px"}}><b>{team_total}</b></p>
                                    </Table.Cell>
                                    <Table.Cell textAlign='center'>
                                        <Icon circular name='plus' link color={"teal"} inverted size='small'
                                              onClick={() => this.onChange(this.varToString({team_total}), 1)}/>
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>Diversity Total</Table.Cell>
                                    <Table.Cell textAlign='center'>
                                        {diversity_total > 0 &&
                                        <Icon circular name='minus' link color={"teal"} inverted size='small'
                                              onClick={() => this.onChange(this.varToString({diversity_total}), -1)}/>}
                                    </Table.Cell>
                                    <Table.Cell textAlign='center'>
                                        <p style={{fontSize: "16px"}}><b>{diversity_total}</b></p>
                                    </Table.Cell>
                                    <Table.Cell textAlign='center'>
                                        {taddable &&
                                        <Icon circular name='plus' link color={"teal"} inverted size='small'
                                              onClick={() => this.onChange(this.varToString({diversity_total}), 1)}/>}
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>Racially Diverse</Table.Cell>
                                    <Table.Cell textAlign='center'>
                                        {racially_diverse > 0 &&
                                        <Icon circular name='minus' link color={"teal"} inverted size='small'
                                              onClick={() => this.onChange(this.varToString({racially_diverse}), -1)}
                                        />}
                                    </Table.Cell>
                                    <Table.Cell textAlign='center'>
                                        <p style={{fontSize: "16px"}}><b>{racially_diverse}</b></p>
                                    </Table.Cell>
                                    <Table.Cell textAlign='center'>
                                        {racially_diverse < diversity_total &&
                                        <Icon circular name='plus' link color={"teal"} inverted size='small'
                                              onClick={() => this.onChange(this.varToString({racially_diverse}), 1)}/>}
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>LGBT</Table.Cell>
                                    <Table.Cell textAlign='center'>
                                        {lgbt > 0 &&
                                        <Icon circular name='minus' link color={"teal"} inverted size='small'
                                              onClick={() => this.onChange(this.varToString({lgbt}), -1)}/>}
                                    </Table.Cell>
                                    <Table.Cell textAlign='center'>
                                        <p style={{fontSize: "16px"}}><b>{lgbt}</b></p>
                                    </Table.Cell>
                                    <Table.Cell textAlign='center'>
                                        {lgbt < diversity_total &&
                                        <Icon circular name='plus' link color={"teal"} inverted size='small'
                                              onClick={() => this.onChange(this.varToString({lgbt}), 1)}/>}
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>Females</Table.Cell>
                                    <Table.Cell textAlign='center'>
                                        {females > 0 &&
                                        <Icon circular name='minus' link color={"teal"} inverted size='small'
                                              onClick={() => this.onChange(this.varToString({females}), -1)}/>}
                                    </Table.Cell>
                                    <Table.Cell textAlign='center'>
                                        <p style={{fontSize: "16px"}}><b>{females}</b></p>
                                    </Table.Cell>
                                    <Table.Cell textAlign='center'>
                                        {females < diversity_total &&
                                        <Icon circular name='plus' link color={"teal"} inverted size='small'
                                              onClick={() => this.onChange(this.varToString({females}), 1)}/>}
                                    </Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    </Grid.Column>
                    <Grid.Column width={11}
                                 style={{display: "flex", justifyContent: "center"}}>
                        <DiversityTable percentage={index} value={imgPath}/>
                    </Grid.Column>
                </Grid>
            </Segment>
        );
    }
}

MatterDiversity.propTypes = {
    matterId: PropTypes.string.isRequired
};

function mapStateToProps(state) {
    let s = orgState(state);
    return {...s, ...state.matter};
}

const cMatterDiversity = connect(mapStateToProps)(MatterDiversity);
export {cMatterDiversity as MatterDiversity};
