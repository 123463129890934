import React, {Component} from 'react';
import {Button} from "semantic-ui-react";
import {connect} from "react-redux";
import {OrgMenuBar} from "../other/OrgMenuBar";
import Body from "../../../other/Body";
import OrgSubHeader from "../other/OrgSubHeader";
import MatterList from "../../list/matter/MatterList";
import matterAction from "../../../../actions/matter.action";
import {orgState} from "../../../../helper/stateUtil";

class MattersPage extends Component {
    componentDidMount() {
        this.props.dispatch(matterAction.readAll({org_id: this.props.org.id}));
    }

    render() {
        const {loading, org, matters} = this.props;
        return (
            <Body loading={loading}>

            <OrgMenuBar/>

            <OrgSubHeader title={"Matters"} backArrow={false}>
                <Button floated='right' color={"teal"} circular
                        onClick={() => this.props.history.push(`matters/add`)}>Add Matter</Button>

            </OrgSubHeader>

            <MatterList
                matters={matters}
                addMatterPath={'matters/add'}
                strClientFirm={org.str_client_firm}
                onClick={(matter) => {
                    this.props.history.push(`/matters/${matter.id}/summary?h=${matter.name}`);
                }}/>

            </Body>
        );
    }
}

function mapStateToProps(state) {
    let s = orgState(state);
    return {...s, ...state.matter};
}

const cMattersPage = connect(mapStateToProps)(MattersPage);
export {cMattersPage as MattersPage};