import React, {Component} from 'react';
import {Button, Confirm, Segment} from "semantic-ui-react";
import {orgState} from "../../../../../helper/stateUtil";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {LawyerEvalViewSummary} from '../../other/LawyerEvalViewSummary'
import matterAction from "../../../../../actions/matter.action";

class LawyerEvalSummary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false
        };
    }

    componentWillReceiveProps(nextProps) {
        const {isDone} = nextProps;
        if (isDone) {
            this.setState({open: false});
        }
    }

    render() {
        const {match: {params: {id, eid}}, history, summary: matter} = this.props;
        return (
            <Segment basic padded>
                {(matter && matter.is_open) && <Button circular size={"medium"}
                                   onClick={() => history.push(`/lawyers/${id}/edit-evaluation/${eid}`)}
                                   floated="right" color={"teal"}>Edit Evaluation</Button>}
                {(matter && matter.is_open) &&
                <Button circular size={"medium"}
                        onClick={() => this.setState({open: true})}
                        floated="right" color={"red"}>Close Evaluation</Button>}
                <br/>
                <br/>
                <br/>
                <LawyerEvalViewSummary/>
                <Confirm
                    open={this.state.open}
                    size={"tiny"}
                    confirmButton={"Yes"}
                    cancelButton={"No"}
                    header={"Close Evaluation Confirmation"}
                    content={"This will close the evaluation and delete all evaluations that have not yet been completed. Do you wish to continue?"}
                    onCancel={() => this.setState({open: false})}
                    onConfirm={() => this.props.dispatch(matterAction.close({id: eid}, "lawyer"))}
                />
            </Segment>
        );
    }
}

function mapStateToProps(state) {
    let s = orgState(state);
    return {...s, ...state.matter};
}

const cClientEvalSummary = withRouter(connect(mapStateToProps)(LawyerEvalSummary));
export {cClientEvalSummary as LawyerEvalSummary};
