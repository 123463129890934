import {APP} from "../actions/app.action";

const initState = {
    tc_modal: false,
    content: "",
    loading: false,
    isDone: false
};

export default function appReducer(state = initState, {type, payload}) {
    state.loading = false;
    state.isDone = false;
    switch (type) {
        case APP.LOADING:
            return {...state, loading: true};
        case APP.RESPONSE_TERMS_OF_USE:
            return {...state, tc_modal: !payload.tc_status};
        case APP.ACCEPT_TERMS_OF_USE:
            return {...state, tc_modal: false};
        case APP.CLOSE_TERMS_OF_USE_MODAL:
            return {...state, tc_modal: false};
        case APP.GET_TERMS_OF_USE:
            return {...state, content: payload.tc_content};
        case APP.UPDATE_TERMS_OF_USE:
            return {...state, isDone: true};
        default:
            return {...state};
    }
}