import React, {Component} from 'react';
import {Button, Container, Dropdown, Image, List, Menu, Segment} from "semantic-ui-react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import authAction from "../../../../actions/auth.action";
import qlogo from "../../../../imgs/qualmet-logo.jpg";

class OrgHeader extends Component {
    render() {
        const {user_name, org_name, history, from_qa} = this.props;
        let isTest;
        if (process.env.REACT_APP_RELEASE_STAGE !== "PRODUCTION") {
            isTest = true;
        }
        return (
            <div>
                {from_qa && <div
                    id={'return-to-admin-bar'}
                    style={{
                        backgroundColor: "#606060",
                        borderRadius: "0px",
                        borderTopLeftRadius: "4px",
                        borderTopRightRadius: "4px"
                    }}>
                    <Segment basic style={{padding: "0.35em 1em"}} textAlign='center'>
                        <List horizontal>
                            <List.Item>
                                <p style={{color: "white"}}>
                                    You are currently on the <b>{org_name}</b> Organization as an administrator
                                </p>
                            </List.Item>
                            <List.Item>
                                <Button inverted size='tiny' onClick={(e) => {
                                    e.preventDefault();
                                    history.push("/");
                                }}>Return to Qualmet Admin Site</Button>
                            </List.Item>
                        </List>
                    </Segment>
                </div>}
                <Menu secondary stackable style={{
                    borderRadius: "0px",
                    borderTopLeftRadius: from_qa ? "0" : "4px",
                    borderTopRightRadius: from_qa ? "0" : "4px"
                }}>
                    <Container>
                        <Menu.Item link header onClick={() => history.push("/")} className={"header-logo"}>
                            <Image size='small' src={qlogo} style={{marginRight: '1.5em', width: "210px"}}/>
                            <span style={{fontSize: "15px"}}><b>{org_name}</b></span>
                            {isTest && <span style={{
                                borderRadius: "4px",
                                padding: "8px",
                                marginLeft: "16px",
                                fontSize: "15px",
                                backgroundColor: "red",
                                color: "white"
                            }}><b>{process.env.REACT_APP_RELEASE_STAGE} SITE</b></span>}
                        </Menu.Item>
                        <Menu.Menu position='right' style={{marginRight: "20px", fontSize: "15px"}}>
                            <Dropdown item pointing floating text={user_name}>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => history.push("/profile")}>
                                        Profile
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={() => this.props.dispatch(authAction.logout())}>
                                        Logout
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Menu.Menu>
                    </Container>
                </Menu>
            </div>
        );
    }
}

function mapStateToProps({auth: {user}}) {
    if (user) {
        const res = {user_name: user.name};
        if (user.org) {
            return {...res, org_name: user.org.name, from_qa: user.org.from_qa};
        }
        return res;
    }
    return {};
}

const cOrgHeader = withRouter(connect(mapStateToProps)(OrgHeader));
export {cOrgHeader as OrgHeader};