import React, {Component} from 'react';
import {connect} from "react-redux";
import {AddUserForm} from "../../../form/users/AddUserForm";
import SASubHeader from "../../other/SASubHeader";
import SABody from "../../other/SABody";

class SAOrgAddUserPage extends Component {
    render() {
        const {loading, match} = this.props;
        return (
            <SABody loading={loading}>
                <SASubHeader title={"Add User"}/>

                <AddUserForm orgid={match.params.id}/>
            </SABody>
        );
    }
}

function mapStateToProps(state) {
    return {
        ...state.user
    };
}

const cSAOrgAddUserPage = connect(mapStateToProps)(SAOrgAddUserPage);
export {cSAOrgAddUserPage as SAOrgAddUserPage};