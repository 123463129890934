import React, {Component} from 'react';
import {Button, Label, List, Rating} from "semantic-ui-react";
import PropTypes from 'prop-types';
import {E_QUESTION_TYPE} from "../../../../../../helper/const";
import helper from "../../../../../../helper/helper";
import {color} from "../barchart/utils";

class AnswerViewer extends Component {
    render() {
        const {answer_type, answer, text_answer} = this.props;
        const options = helper.getAnsOptions(answer_type);
        let ans_label;
        const al = options.find((ans) => ans && answer === ans.value);
        if (al) {
            ans_label = al.quote;
        }
        let content;
        switch (answer_type) {
            case E_QUESTION_TYPE.ANSWER_TYPE_U_ENA:
            case E_QUESTION_TYPE.ANSWER_TYPE_U_E:
                content = (
                    <List>
                        <List.Item>
                            <List horizontal>
                                {/*<List.Item>*/}
                                {/*    <List.Header>*/}
                                {/*        POOR*/}
                                {/*    </List.Header>*/}
                                {/*</List.Item>*/}
                                <Rating maxRating={5} rating={answer}
                                        clearable
                                        style={{fontSize: "2rem"}}/>
                                {/*<List.Item>*/}
                                {/*    <List.Header>*/}
                                {/*        EXCELLENT*/}
                                {/*    </List.Header>*/}
                                {/*</List.Item>*/}
                            </List>
                        </List.Item>
                        <List.Item>{ans_label || "Select Rating"}</List.Item>
                    </List>
                );
                break;
            case E_QUESTION_TYPE.ANSWER_TYPE_NS_S:
                content = (
                    <List>
                        <List.Item>
                            <List horizontal>
                                {options.map((ans, index) =>
                                    <List.Item key={index} as='a'>
                                        <Label circular size={"huge"}
                                               style={{
                                                   backgroundColor: answer === ans.value ? color.dark_green : "transparent",
                                                   border: "solid 1.5px",
                                                   borderColor: answer === ans.value ? color.dark_green : color.grey_50,
                                                   color: answer === ans.value ? 'white' : color.dark_green
                                               }}>{ans.i_code}</Label>
                                        {/*<LabelCircle text={ans.value} active={answer === ans.value} size={"huge"}/>*/}
                                        {/*<Image*/}
                                        {/*src={`/imgs/emoji/${answer === ans.value ? "active" : "grey"}/${ans.i_code}.png`}*/}
                                        {/*size='mini'/>*/}
                                    </List.Item>
                                )}
                            </List>
                        </List.Item>
                        <List.Item>{ans_label || "No Rating"}</List.Item>
                    </List>
                );
                break;
            case E_QUESTION_TYPE.ANSWER_TYPE_TEXT_ONLY:
                content = (
                    <span>{text_answer}</span>
                );
                break;
            case E_QUESTION_TYPE.ANSWER_TYPE_LV_HV:
            case E_QUESTION_TYPE.ANSWER_TYPE_F_NL_EL:
                content = (
                    <List>
                        <List.Item>
                            <List horizontal>
                                {options.map((_, index) =>
                                    <List.Item key={index} as='a' style={{margin: "0 0.5em"}}>
                                        <Label circular color={answer === index ? "teal" : "grey"} size={"large"}>
                                            {index}
                                        </Label>
                                    </List.Item>
                                )}
                            </List>
                        </List.Item>
                    </List>
                );
                break;
            case E_QUESTION_TYPE.ANSWER_TYPE_YES_NO:
                content = (
                    <List>
                        <List.Item>
                            <List horizontal>
                                <Button circular secondary
                                        style={{paddingLeft: "48px", paddingRight: "48px"}}>No</Button>
                                <Button circular primary
                                        style={{paddingLeft: "48px", paddingRight: "48px"}}>Yes</Button>
                            </List>
                        </List.Item>
                        <List.Item>{text_answer}</List.Item>
                    </List>
                );
                break;
            default:
                content = (<div>Invalid answer type!</div>);
                break;
        }
        return (content);
    }
}

AnswerViewer.propTypes = {
    answer_type: PropTypes.number.isRequired,
    answer: PropTypes.number,
    text_answer: PropTypes.string
};

export default AnswerViewer;
