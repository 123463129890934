import React, {Component} from 'react';
import {Comment, Header, Label, Segment} from "semantic-ui-react";
import ErrorMessage from "../../../../other/ErrorMessage";
import {orgState} from "../../../../../helper/stateUtil";
import {connect} from "react-redux";
import matterAction from "../../../../../actions/matter.action";
import {withRouter} from "react-router-dom";
import {ClientEvalCommentForm} from '../components/ClientEvalCommentForm';

class ClientEvalComments extends Component {

    componentDidMount() {
        const {match: {params: {eid}}} = this.props;
        this.props.dispatch(matterAction.getAllCommentsOfClientEval({
            fe_id: eid
        }));
    }

    render() {
        const {comments, loading, error, match: {params: {eid}}} = this.props;
        return (
            <Segment basic padded={"very"}>
                <Comment.Group>
                    <Header as='h3' dividing>
                        Evaluation Comments:
                    </Header>

                    <ErrorMessage errors={error}/>

                    {comments.map((comment, index) =>
                        <Comment key={index}>
                            <Comment.Content>
                                <Comment.Author as='a'>{comment.comment_by}</Comment.Author>
                                <Comment.Metadata>
                                    <div>{comment.commented_on}</div>
                                </Comment.Metadata>
                                <Comment.Text>{comment.comment}</Comment.Text>
                            </Comment.Content>
                        </Comment>
                    )}

                    {
                        (comments.length === 0) &&
                        <React.Fragment>
                            <br/>
                            <Label>No comments...</Label>
                        </React.Fragment>
                    }

                    <ClientEvalCommentForm loading={loading} onSubmit={(data) => {
                        this.props.dispatch(matterAction.addCommentOfClientEval({
                            fe_id: eid,
                            ...data
                        }));
                    }}/>
                </Comment.Group>
            </Segment>
        );
    }
}

function mapStateToProps(state) {
    let s = orgState(state);
    return {...s, ...state.matter};
}

const cClientEvalComments = withRouter(connect(mapStateToProps)(ClientEvalComments));
export {cClientEvalComments as ClientEvalComments};
