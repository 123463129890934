import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Grid, Header, Label, List, Popup} from "semantic-ui-react";
import {color} from "../../../reports/components/barchart/utils";

class OnHoverPopUp extends Component {
    get = rating => {
        return this.props.answers.find((ans) => ans.value === rating) || {};
    };

    render() {
        const {rating, element} = this.props;
        const d = this.get(rating);
        return (
            <Popup position={'bottom center'}
                   trigger={element} wide on='hover'>
                <Popup.Content>
                    <Grid columns={2} verticalAlign='middle'>
                        <Grid.Column width={4} textAlign='center'>
                            <List>
                                <List.Item>
                                    <Label circular size={"huge"}
                                           style={{
                                               backgroundColor: "transparent",
                                               border: "solid 1.5px",
                                               borderColor: color.dark_green,
                                               color: color.dark_green
                                           }}>{d.i_code}</Label>
                                </List.Item>
                                <List.Item>
                                    <Header as={'h5'}>RATING</Header>
                                </List.Item>
                            </List>
                        </Grid.Column>
                        <Grid.Column width={12} textAlign='left'
                                     style={{fontFamily: "Arial"}}>
                            <b><i>"{d.quote}"</i></b> <br/>
                            <span dangerouslySetInnerHTML={{__html: d.desc}}/>
                        </Grid.Column>
                    </Grid>
                </Popup.Content>
            </Popup>
        );
    }
}

OnHoverPopUp.propTypes = {
    answers: PropTypes.array.isRequired,
    rating: PropTypes.number.isRequired,
    element: PropTypes.element.isRequired
};

export default OnHoverPopUp;
