import {SETTING} from "../actions/settings.action";

const initState = {
    loading: false,
    isDone: false,
    error: [],
    item: {}
};

export default function settingReducer(state = initState, action) {
    // reset
    state.error = [];
    state.isDone = false;
    state.loading = false;

    // filter
    switch (action.type) {

        // common
        case SETTING.REQUEST:
            return {...state, loading: true};

        case SETTING.VALIDATION_ERROR:
            return {...state, error: action.payload};

        case SETTING.ERROR:
            return {...state, error: [...action.payload]};

        // not allowed to access
        case SETTING.NOT_ALLOWED:
            return {...state, isAllowed: false};

        case SETTING.SAVE_SUCCESS:
            return {...state, loading: false, isDone: true};

        case SETTING.READ_ALL_SUCCESS:
            return {...state, item: action.payload};

        case SETTING.UNMOUNT:
            return {...initState};

        default:
            return state;
    }
}
