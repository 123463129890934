import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Icon} from "semantic-ui-react";

class ViewIcon extends Component {
    render() {
        if (this.props.hidden) {
            return <div/>;
        }
        return (
            <Icon {...this.props} link name={"eye"} size='large' onClick={() => this.props.onClick()}/>
        );
    }
}

ViewIcon.propTypes = {
    onClick: PropTypes.func,
    hidden: PropTypes.bool
};

export default ViewIcon;
