import api from "../helper/api";

function add(data) {
    return api.instance().post("qa/plan/store", data);
}

function update(data) {
    data._method = "PUT";
    return api.instance().put("qa/plan/update", data);
}

function readAll(data) {
    return api.instance().post("qa/plan/get-all", data);
}

function getActivePlans(data) {
    return api.instance().post("qa/plan/get-active-plans", data);
}

function read(data) {
    return api.instance().post("qa/plan/get", data);
}

function changeStatus(data) {
    return api.instance().post("qa/plan/change-status", data);
}

function getCurrentPlan(data) {
    return api.instance().post("org/get-current-plan", data);
}

function getAllOrg() {
    return api.instance().post("qa/plan/get-all-orgs");
}

function requestPlan(data) {
    return api.instance().post("org/request-plan", data);
}

const planService = {
    add,
    update,
    readAll,
    read,
    changeStatus,
    getActivePlans,
    getCurrentPlan,
    getAllOrg,
    requestPlan
};

export default planService;