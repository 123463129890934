import React, {Component} from "react";
import AvgHorizontalBarChart from "../components/barchart/AvgHorizontalBarChart";
import {connect} from "react-redux";
import {BAR_CHART_TYPE} from "../../../../../helper/const";
import Body from "../../../../other/Body";
import {Form, Header, Table} from "semantic-ui-react";
import LabelCircle from "../components/LabelCircle";
import DataTable from "../components/DataTable";
import DataFilter from "../components/DataFilter";
import ReportHeader from "../components/header/ReportHeader";
import ReportSummary from "../components/header/ReportSummary";
import {orgState} from "../../../../../helper/stateUtil";
import {OrgMenuBar, reportsPath} from "../../other/OrgMenuBar";
import OrgSubHeader from "../../other/OrgSubHeader";
import NoDataMessage from "../components/other/NoDataMessage";
import reportAction from "../../../../../actions/report.action";
import Report from "../components/other/Report";
import {sort} from "../utils";

class AllLawyersReportPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            by_matrix: true, // true - by qualmet score, false - by indivisual question
            clients: null,
            score: null,
            practice_areas: null,
            questions: null
        };
        this.onSelect = this.onSelect.bind(this);
        this.onClick = this.onClick.bind(this);
        this.compileData = this.compileData.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onSwitchMatrixToQuestion = this.onSwitchMatrixToQuestion.bind(this);
    }

    componentDidMount() {
        const {org} = this.props;
        this.props.dispatch(reportAction.getAllLawyersSummaryAndFilters({org_id: org.id, org}));
        this.props.dispatch(reportAction.getAllLawyersReport({
            org_id: org.id,
            by_matrix: true
        }));
    }

    componentWillReceiveProps(nextProps) {
        const {data, loadingData, loadingFiltersAndSummary, isDone} = nextProps;
        let s = {};
        if (!loadingData) {
            s.data = this.compileData(data);
        }
        if (!loadingFiltersAndSummary && isDone) {
            [
                'score',
                'clients',
                'practice_areas',
                'questions'
            ].forEach((item) => {
                if (nextProps[item] && nextProps[item].length > 0) {
                    s[item] = nextProps[item][0].value;
                }
            });
        }
        this.setState((state) => {
            return {...state, ...s};
        });
    }

    onSwitchMatrixToQuestion = () => {
        this.setState((state) => ({by_matrix: !state.by_matrix}));
        this.props.dispatch(reportAction.getAllLawyersReport({
            org_id: this.props.org.id,
            ...this.state,
            by_matrix: false
        }));
    };

    onChange = (_, {name, value}) => {
        this.setState({[name]: value});
        this.props.dispatch(reportAction.getAllLawyersReport({
            org_id: this.props.org.id,
            ...this.state,
            [name]: value
        }));
    };

    onClick = (item) => {
        this.setState((state) => {
            const {data} = state;
            const i = data.findIndex((d) => d.id === item.id);
            data[i].is_selected = !data[i].is_selected;
            return data;
        });
    };

    onSelect = (item, yIndex) => {
        const {history} = this.props;
        switch (yIndex) {
            case 0:
                history.push(`${reportsPath}/lawyers/${item.y[yIndex].value}/summary`);
                break;
            case 1:
                history.push(`${reportsPath}/clients-firms/${item.y[yIndex].value}/summary`);
                break;
            default:
                break;
        }
    };

    compileData = (data) => {
        sort('score', data);
        data.forEach((item, index) => {
            item.id = index + 1;
            item.x = item.score;
            item.y = [];
            item.y[0] = {text: item.lawyer_name, value: item.lawyer_id};
            item.y[1] = {text: item.client_name, value: item.client_id};
        });
        return data;
    };

    render() {
        const {by_matrix, score, clients, practice_areas, data} = this.state;
        const {totals, summary, responses, org, loadingFiltersAndSummary, loadingData} = this.props;
        let chart;
        if (!loadingData) {
            chart = <AvgHorizontalBarChart
                data={data}
                type={BAR_CHART_TYPE.ALL}
                onClick={this.onClick}
                onSelect={this.onSelect}
                maxX={by_matrix ? 100 : 5}
                xTicks={by_matrix ? 10 : 5}
                xLabel={"Score"}
            />;
        }
        return (
            <Body loading={loadingFiltersAndSummary}>
                <OrgMenuBar/>
                <OrgSubHeader title={`Evaluatees`} backArrow={false}/>
                <Report>
                    <ReportHeader>
                        <Header>Summary: All Evaluatees</Header>
                    </ReportHeader>
                    <ReportSummary qualmetScore={summary.qualmet_score}
                                   overallSatisfaction={summary.overall_satisfaction}
                                   valueForMoney={summary.value_for_money}
                                   wouldRecommend={summary.would_recommend}
                                   totals={totals}
                                   diversityTracking={org.diversity_tracking ? summary.diversity_tracking : null}
                                   responses={responses}/>

                    <DataFilter>
                        <Form.Group widths='equal'>
                            {by_matrix && <Form.Field>
                                <Form.Dropdown
                                    label={`By Score:`}
                                    selection name={"score"}
                                    value={score}
                                    onChange={this.onChange}
                                    options={this.props.score}/>
                            </Form.Field>}
                            <Form.Field>
                                <Form.Dropdown
                                    label={`By Practice Area:`}
                                    selection name={'practice_areas'}
                                    value={practice_areas}
                                    onChange={this.onChange}
                                    options={this.props.practice_areas}/>
                            </Form.Field>
                            <Form.Field>
                                <Form.Dropdown
                                    label={`By ${org.str_client_firm}s:`}
                                    selection name={'clients'}
                                    value={clients}
                                    onChange={this.onChange}
                                    options={this.props.clients}/>
                            </Form.Field>
                        </Form.Group>
                        {/*<Form.Field>*/}
                        {/*{!by_matrix && <Form.Dropdown*/}
                        {/*label={`By Evaluation Question:`}*/}
                        {/*selection name={'questions'}*/}
                        {/*value={questions}*/}
                        {/*onChange={this.onChange}*/}
                        {/*options={this.props.questions}/>}*/}
                        {/*<label style={{color: color.light_green, cursor: "pointer"}}*/}
                        {/*onClick={this.onSwitchMatrixToQuestion}>*/}
                        {/*{!by_matrix ? "Ranking by Qualmet Metrics" : "Ranking by Individual Questions"}</label>*/}
                        {/*</Form.Field>*/}
                    </DataFilter>

                    <NoDataMessage active={data.length === 0}/>

                    {chart}

                    {!loadingData && <DataTable headers={['ID', `Evaluatee`, 'Evaluations', 'Score']}
                                                active={data.length > 0}>
                        {
                            data.map((d, i) =>
                                <Table.Row key={i} className={'row'} onClick={() => this.onClick(d)}>
                                    <Table.Cell>
                                        <LabelCircle text={d.id} active={d.is_selected}/>
                                    </Table.Cell>
                                    <Table.Cell>{d.client_name}</Table.Cell>
                                    <Table.Cell>{d.evaluations}</Table.Cell>
                                    <Table.Cell>{d.score}</Table.Cell>
                                </Table.Row>)
                        }
                    </DataTable>}
                </Report>
            </Body>
        );
    }
}

function mapStateToProps(state) {
    return {...orgState(state), ...state.report};
}

const cAllLawyersReportPage = connect(mapStateToProps)(AllLawyersReportPage);
export {cAllLawyersReportPage as AllLawyersReportPage};
