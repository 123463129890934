import React, {Component} from 'react';
import {Button} from "semantic-ui-react";
import {connect} from "react-redux";
import {OrgMenuBar} from "../../other/OrgMenuBar";
import OrgSubHeader from "../../other/OrgSubHeader";
import matterAction from "../../../../../actions/matter.action";
import MatterList from "../../../list/matter/MatterList";
import {orgState} from "../../../../../helper/stateUtil";
import Body from "../../../../other/Body";

class OrgMattersPage extends Component {
    componentDidMount() {
        this.props.dispatch(matterAction.readAll({org_id: this.props.org.id}));
    }

    render() {
        const {loading, org, matters} = this.props;
        return (
            <Body loading={loading}>

            <OrgMenuBar/>

            <OrgSubHeader title={"Matters"}>
                <Button floated='right' color={"teal"} circular
                        onClick={() => this.props.history.push("/orgadmin/matters/add")}>Add Matter</Button>

            </OrgSubHeader>

            <MatterList
                matters={matters}
                addMatterPath={"/orgadmin/matters/add"}
                strClientFirm={org.str_client_firm}
                onClick={(matter) => {
                    this.props.history.push(`/orgadmin/matters/view/${matter.id}`);
                }}/>

            </Body>
        );
    }
}

function mapStateToProps(state) {
    let s = orgState(state);
    return {...s, ...state.matter};
}

const cOrgMattersPage = connect(mapStateToProps)(OrgMattersPage);
export {cOrgMattersPage as OrgMattersPage};