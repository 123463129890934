import {SUB} from "../actions/sub.action";

const initState = {
    loading: false,
    isDone: false,
    items: [],
    error: [],
    item: {}
};

export default function subReducer(state = initState, action) {
    // reset
    state.error = [];
    state.isDone = false;
    state.loading = false;

    // filter
    switch (action.type) {

        // common
        case SUB.REQUEST:
            return {...state, loading: true};

        case SUB.VALIDATION_ERROR:
            return {...state, error: action.payload};

        case SUB.ERROR:
            return {...state, error: [...action.payload]};

        // not allowed to access
        case SUB.NOT_ALLOWED:
            return {...state, isAllowed: false};

        // add client
        case SUB.ADD_SUCCESS:
            return {...state, isDone: true};

        // update client
        case SUB.UPDATE_SUCCESS:
            return {...state, isDone: true};

        // read all clients
        case SUB.READ_ALL_SUCCESS:
            return {...state, items: action.payload};

        // read single client
        case SUB.READ_SUCCESS:
            return {...state, item: action.payload};

        case SUB.NOT_FOUND:
            return {...state, not_found: true};

        case SUB.CHANGE_STATUS_SUCCESS:
            state.items[action.payload.index].status = action.payload.status;
            return {...state};

        case SUB.GET_ACTIVE_PLANS:
            return {...state, items: action.payload};

        case SUB.READ_ALL_SUBS_SUCCESS:
            return {...state, items: action.payload.subs, item: {...action.payload}};

        case SUB.SUB_ADD_PAYMENT_SUCCESS:
            return {...state, isDone: true};

        case SUB.UNMOUNT:
            return {...initState};

        default:
            return state;
    }
}