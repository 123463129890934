import React, {Component} from 'react';
import Body from "../../../../other/Body";
import {connect} from "react-redux";
import {Button, Form, Segment} from "semantic-ui-react";
import ErrorMessage from "../../../../other/ErrorMessage";
import QMCheckBox from "../../../../other/form/QMCheckBox";
import orgState from "../../../../../helper/stateUtil";
import orgAction from "../../../../../actions/org.action";
import {OrgMenuBar} from "../../other/OrgMenuBar";
import helper from "../../../../../helper/helper";
import OrgSubHeader from "../../other/OrgSubHeader";

class OrgSettingsPage extends Component {
    constructor(props) {
        super(props);
        const {org} = props;
        this.state = {
            name: org.name,
            type: org.type,
            diversity_tracking: org.diversity_tracking,
            location_tracking: org.location_tracking,
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.isDone) {
            nextProps.history.go(-1);
        }
    }

    render() {
        const {error, loading, org} = this.props;
        const {name, type, diversity_tracking, location_tracking} = this.state;
        return (
            <Body loading={loading}>

                <OrgMenuBar/>

                <OrgSubHeader title={"Organization Information"}/>

                <Segment basic padded='very'>
                    <Form>
                        <ErrorMessage errors={error}/>
                        <Form.Field>
                            <Form.Input label='Organization Name' placeholder='Organization Name' width={8}
                                        onChange={(e) => this.setState({name: e.target.value})} value={name}/>
                        </Form.Field>
                        <Form.Field>
                            <Form.Dropdown label='Organization Type' placeholder='Organization Type' width={8}
                                           selection onChange={(e, {value}) => this.setState({type: value})}
                                           value={type} options={helper.orgTypes}/>
                        </Form.Field>
                        <Form.Field>
                            <label>Diversity Tracking:</label>
                            <QMCheckBox toggle
                                        onChange={(checked) => this.setState({diversity_tracking: checked})}
                                        checked={diversity_tracking}/>
                        </Form.Field>

                        <br/>

                        <Form.Field>
                            <label>Location Tracking:</label>
                            <QMCheckBox toggle
                                        onChange={(checked) => this.setState({location_tracking: checked})}
                                        checked={location_tracking}/>
                        </Form.Field>

                        <br/>

                        <Button type='submit' primary
                                onClick={() => {
                                    this.props.dispatch(orgAction.updateSettings({
                                        ...this.state,
                                        id: org.id
                                    }));
                                }}
                                circular
                                disabled={!(name)}
                                floated='right'>Update</Button>
                        <Button type='button' secondary inverted circular
                                onClick={() => this.props.history.go(-1)}
                                floated='right'>Cancel</Button>
                        <br/>
                    </Form>
                </Segment>

            </Body>
        );
    }
}

function mapStateToProps(state) {
    return {
        ...orgState(state),
        ...state.auth
    };
}

const cOrgSettingsPage = connect(mapStateToProps)(OrgSettingsPage);
export {cOrgSettingsPage as OrgSettingsPage};
