import React, {Component} from 'react';
import {Button, Divider, Form, Header, Input, Segment, TextArea} from "semantic-ui-react";
import ColoredBody from "../other/ColoredBody";
import BoxHeader from "../other/BoxHeader";
import BoxFooter from "../other/BoxFooter";
import ErrorMessage from "../other/ErrorMessage";
import {connect} from "react-redux";
import authAction from "../../actions/auth.action";
import SuccessMessage from "../other/SuccessMessage";

class ContactPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            title: '',
            company: '',
            comments: ''
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange = e => this.setState({[e.target.name]: e.target.value});

    handleSubmit = () => {
        this.props.dispatch(authAction.contactUs(this.state));
    };

    componentWillReceiveProps(nextProps) {
        const {isDone} = nextProps;
        if (isDone) {
            this.setState({
                name: '',
                email: '',
                title: '',
                company: '',
                comments: ''
            });
        }
    }

    render() {
        const {history, loading, error, isDone} = this.props;
        const {name, email, title, company, comments} = this.state;
        return (
            <ColoredBody textAlign={"center"} boxWidth={"768px"}>
                <BoxHeader/>
                <Segment basic padded style={{fontFamily: "Roboto, sans-serif", fontSize: "14px"}}>
                    <Header as={'h1'} style={{color: '#01d0fa'}}>CONTACT US</Header>
                    <br/>
                    <p> Please <b>call us</b> with your questions.</p>
                    <a href={"tel:+1 (502) 234-1433"} style={{color: 'white'}}>
                        <Button circular primary style={{letterSpacing: "0.15em"}}>
                            +1 (502) 234-1433
                        </Button>
                    </a>
                    <br/>
                    <br/>
                    <Divider horizontal>Or</Divider>
                    <p> You can <b>email us</b> using the form below and let us know how we can help you.</p>
                    <Segment basic padded textAlign={'left'}>
                        <SuccessMessage messages={isDone ? [
                            "Thank you for contacting Qualmet Team, our support team will get back to you soon!"
                        ] : []}/>
                        <ErrorMessage errors={error}/>
                        <Form>
                            <Form.Group widths='equal'>
                                <Form.Field required>
                                    <label>Full Name</label>
                                    <Input placeholder={'Full Name'} value={name} name={'name'}
                                           onChange={this.handleChange}/>
                                </Form.Field>
                                <Form.Field required>
                                    <label>Email</label>
                                    <Input placeholder={'Email'} type={'email'} value={email} name={'email'}
                                           onChange={this.handleChange}/>
                                </Form.Field>
                            </Form.Group>
                            <Form.Group widths='equal'>
                                <Form.Field required>
                                    <label>Company</label>
                                    <Input placeholder={'Company'} name={'company'} value={company}
                                           onChange={this.handleChange}/>
                                </Form.Field>
                                <Form.Field required>
                                    <label>Title</label>
                                    <Input placeholder={'Title'} name={'title'}
                                           onChange={this.handleChange}/>
                                </Form.Field>
                            </Form.Group>
                            <Form.Field control={TextArea} label='Comments or Questions'
                                        placeholder='Comments or Questions' onChange={this.handleChange}
                                        name={'comments'} value={comments}/>
                            <Button circular color={'teal'} floated={'right'} loading={loading}
                                    disabled={loading || !(name && company && title && email)}
                                    onClick={this.handleSubmit}>Send</Button>
                            <Button circular color={'grey'} floated={'right'} onClick={() => {
                                history.goBack();
                            }}>Cancel</Button>
                            <br/>
                            <br/>
                        </Form>
                    </Segment>
                </Segment>
                <BoxFooter/>
            </ColoredBody>
        );
    }
}

function mapStateToProps(state) {
    return state.auth;
}

const cContactPage = connect(mapStateToProps)(ContactPage);
export {cContactPage as ContactPage};