import React, {Component} from 'react';
import ColoredBody from "../../other/ColoredBody";
import BoxFooter from "../../other/BoxFooter";
import {Button, Grid, Header, Image, Segment} from "semantic-ui-react";
import BoxHeader from "../../other/BoxHeader";
import img1 from '../../../imgs/track-value.png';
import img2 from '../../../imgs/collaboration.png';
import img3 from '../../../imgs/communications.png';
import img4 from '../../../imgs/diversity.png';

class NewOrgGetStartedPage extends Component {
    render() {
        return (
            <ColoredBody boxWidth={"768px"} textAlign={'center'}>
                <BoxHeader/>
                <Segment padded={'very'}>
                    <Header>Setup new company account</Header>
                    <br/>
                    <p>
                        Create a new company account for <b>FREE</b> today for 30 days and get started
                        tracking
                        performance
                        management and diversity for your clients or firms.
                        <br/>
                        Upgrade to the paid version once you are ready to lunch!
                    </p>
                    <br/>
                    <br/>
                    <Button circular color={"teal"} onClick={() => {
                        this.props.history.replace("/register?flag=co");
                    }}>
                        <span style={{margin: "auto 2em"}}>Get Started</span>
                    </Button>
                    <br/>
                    <br/>
                    <br/>
                    <Segment basic textAlign={"center"}>
                        <Grid columns='equal' verticalAlign='middle' centered>
                            <Grid.Column textAlign='center'>
                                <Image src={img1} size='tiny' title={"Track Value"} className={"center-img"}/>
                            </Grid.Column>
                            <Grid.Column>
                                <Image src={img2} size='tiny' title={"Collaboration"} className={"center-img"}/>
                            </Grid.Column>
                            <Grid.Column>
                                <Image src={img3} size='tiny' title={"Communications"} className={"center-img"}/>
                            </Grid.Column>
                            <Grid.Column>
                                <Image src={img4} size='tiny' title={"Diversity"} className={"center-img"}/>
                            </Grid.Column>
                        </Grid>
                    </Segment>
                </Segment>
                <BoxFooter/>
            </ColoredBody>
        );
    }
}

export default NewOrgGetStartedPage;