import React, {Component} from 'react';
import {orgState} from "../../../../helper/stateUtil";
import {connect} from "react-redux";
import Body from "../../../other/Body";
import {OrgMenuBar, reportsPath} from "../other/OrgMenuBar";
import OrgSubHeader from "../other/OrgSubHeader";
import {Button} from "semantic-ui-react";
import clientAction from "../../../../actions/client.action";
import ListClients from "../../list/clients/ListClients";

class ClientsPage extends Component {
    componentDidMount() {
        this.props.dispatch(clientAction.readAllClientsFirm({org_id: this.props.org.id}));
    }

    render() {
        const {loading, org, clients, history} = this.props;
        return (
            <Body loading={loading}>

                <OrgMenuBar/>

                <OrgSubHeader title={`${org.str_client_firm}s`} backArrow={false}>
                    <Button floated='right' color={"teal"} circular
                            onClick={() => history.push(`${reportsPath}/clients-firms`)}>
                        {org.str_client_firm} Rankings</Button>

                </OrgSubHeader>

                <ListClients
                    clients={clients}
                    org={org}
                    onClick={(client) => {
                        history.push(`/clients-firms/${client.id}/evaluations?h=${encodeURIComponent(client.name)}`);
                    }}/>

            </Body>
        );
    }
}

function mapStateToProps(state) {
    let s = orgState(state);
    return {...s, ...state.client};
}

const cClientsPage = connect(mapStateToProps)(ClientsPage);
export {cClientsPage as ClientsPage};
