import React, {Component} from 'react';
import helper from "../../helper/helper";
import {EVALUATOR, MATTER, ORG_ADMIN, REPORT_VIEWER, SUPER_ADMIN} from "../../helper/const";
import {Header, Segment} from "semantic-ui-react";
import authAction from "../../actions/auth.action";
import {connect} from "react-redux";
import appAction from "../../actions/app.action";

class RedirectPage extends Component {

    componentDidMount() {
        this.props.dispatch(appAction.checkTermsOfUse());
        let c = "/not-found";
        const roles = helper.userRoles();
        if (roles.includes(SUPER_ADMIN)) {
            this.props.dispatch(authAction.removeOrganization());
            c = "/superadmin/orgs";
        } else if (roles.includes(EVALUATOR)) {
            c = "/my-evaluations";
        } else if (roles.includes(MATTER)) {
            c = "/matters";
        } else if (roles.includes(REPORT_VIEWER)) {
            c = "/reports/matters";
        } else if (roles.includes(ORG_ADMIN)) {
            c = '/orgadmin'
        }
        this.props.history.replace(c);
    }

    render() {
        return (
            <Segment basic color={"red"} padded='very'>
                <Header> Something went wrong! </Header>
            </Segment>
        );
    }
}

function mapStateToProps(state) {
    return {};
}

const connectedRedirectPage = connect(mapStateToProps)(RedirectPage);
export {connectedRedirectPage as RedirectPage};