import bugsnag from '@bugsnag/js'
// error tracker

const errTracker = bugsnag({
    apiKey: process.env.REACT_APP_BUGSNAG_KEY,
    releaseStage: process.env.REACT_APP_RELEASE_STAGE,
    collectUserIp: true,
    autoBreadcrumbs: true,
    maxBreadcrumbs: 40,
    interactionBreadcrumbsEnabled: true,
    navigationBreadcrumbsEnabled: true,
    networkBreadcrumbsEnabled: true,
    consoleBreadcrumbsEnabled: true
});

const request = (data) => {
    errTracker.leaveBreadcrumb("REQUEST", data, "API_CALL", new Date().toString());
};

const response = (data) => {
    errTracker.leaveBreadcrumb("RESPONSE", data, "API_CALL", new Date().toString());
};

const reqError = (data) => {
    errTracker.leaveBreadcrumb("REQ_ERROR", data, "API_CALL", new Date().toString());
};

const resError = (data) => {
    errTracker.leaveBreadcrumb("RES_ERROR", data, "API_CALL", new Date().toString());
};

export const bug = {
    request, response, reqError, resError
};

export default errTracker;