import React, {Component} from 'react';
import {Icon, Segment, Table} from "semantic-ui-react";
import {connect} from "react-redux";
import Body from "../../../../other/Body";
import OrgSubHeader from "../../other/OrgSubHeader";
import {OrgMenuBar} from '../../other/OrgMenuBar';
import {orgState} from "../../../../../helper/stateUtil";

class OrgPrefsPage extends Component {

    componentWillReceiveProps(nextProps) {
        if (!nextProps.org) {
            nextProps.history.replace("/");
        }
    }

    render() {
        const {org} = this.props;
        const menus = [
            {title: "Organization Information", icon: "building", path: "/orgadmin/settings/info"},
            {title: "Domains", icon: "at", path: "/orgadmin/settings/domains"},
        ];
        if (org.sso_status) {
            menus.push({title: "SSO(Single Sign On) Settings", icon: "key", path: "/orgadmin/settings/sso"});
        }
        menus.push({title: "Plan & Billing", icon: "payment", path: "/orgadmin/settings/plan"});

        return (
            <Body loading={false}>

                <OrgMenuBar/>

                <OrgSubHeader title={org.name + " - Settings"} backArrow={org.from_qa}/>

                <Segment padded={"very"} basic>
                    <Table basic='very' padded selectable stackable>
                        <Table.Body>
                            {menus.map((menu, index) => {
                                return (
                                    <Table.Row className={"row"} key={index}
                                        // disabled={[8].includes(index + 1)}
                                               onClick={() => this.props.history.push(menu.path)}>
                                        <Table.Cell style={{paddingLeft: "16px"}}>
                                            <Icon name={menu.icon} color={"grey"} size={"large"}/>
                                            <span>{menu.title}</span>
                                        </Table.Cell>
                                    </Table.Row>
                                );
                            })}
                        </Table.Body>
                    </Table>
                </Segment>
            </Body>
        );
    }
}

function mapStateToProps(state) {
    return orgState(state);
}

const connectedOrgAdminPage = connect(mapStateToProps)(OrgPrefsPage);
export {connectedOrgAdminPage as OrgPrefsPage};