import {applyMiddleware, combineReducers, compose, createStore} from "redux";
import thunkMiddleware from 'redux-thunk';
import authReducer from "./reducers/auth.reducer";
import orgReducer from "./reducers/org.reducer";
import userReducer from "./reducers/user.reducer";
import clientReducer from "./reducers/client.reducer";
import lawyerReducer from "./reducers/lawyer.reducer";
import practiceReducer from "./reducers/practice.reducer";
import regionLocationReducer from "./reducers/region_location.reducer";
import evaluationQuestionReducer from "./reducers/evaluation_question.reducer";
import matterReducer from "./reducers/matter.reducer";
import evaluationReducer from "./reducers/evaluation.reducer";
import reportReducer from "./reducers/report.reducer";
import helpReducer from "./reducers/help.reducer";
import planReducer from "./reducers/plan.reducer";
import appReducer from "./reducers/app.reducer";
import subReducer from "./reducers/sub.reducer";
import exportReducer from "./reducers/export.reducer";
import {createLogger} from "redux-logger/src";
import checklistReducer from "./reducers/checklist.reducer";
import settingReducer from "./reducers/setting.reducer";

let middlewares = [thunkMiddleware];

if (process.env.REACT_APP_RELEASE_STAGE === 'TEST' || process.env.REACT_APP_RELEASE_STAGE === "LOCAL") {
    middlewares.push(
        createLogger({
            level: "warn",
            diff: true,
            duration: true
        })
    );
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    combineReducers({
        app: appReducer,

        auth: authReducer,

        matter: matterReducer,

        org: orgReducer,
        user: userReducer,
        client: clientReducer,
        lawyer: lawyerReducer,
        practice: practiceReducer,
        region_location: regionLocationReducer,
        evaluation_question: evaluationQuestionReducer,
        evaluations: evaluationReducer,
        report: reportReducer,
        help: helpReducer,
        export: exportReducer,
        plan: planReducer,
        sub: subReducer,
        checklist: checklistReducer,
        setting: settingReducer
    }),
    composeEnhancers(applyMiddleware(...middlewares))
);

export default store;
