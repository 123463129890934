import api from "../helper/api";

function add(data) {
    return api.instance().post("qa/plan/store", data);
}

function update(data) {
    data._method = "PUT";
    return api.instance().put("qa/plan/update", data);
}

function readAll() {
    return api.instance().post("qa/payment/get-all-orgs");
}

function readAllSubs(data) {
    return api.instance().post("qa/payment/get-org-billing", data);
}

function getActivePlans(data) {
    return api.instance().post("qa/plan/get-active-plans", data);
}

function getAddPaymentData(data) {
    return api.instance().post("qa/payment/get-add-payment-data", data);
}

function getManagePaymentData(data) {
    return api.instance().post("qa/payment/get-manage-payment-data", data);
}

function read(data) {
    return api.instance().post("qa/plan/get", data);
}

function changeStatus(data) {
    return api.instance().post("qa/plan/change-status", data);
}

function addPayment(data) {
    return api.instance().post("qa/billing/add-payment", data);
}

function managePayment(data) {
    return api.instance().post("qa/billing/manage-payment", data);
}

const subService = {
    add,
    update,
    readAll,
    readAllSubs,
    getAddPaymentData,
    read,
    changeStatus,
    getActivePlans,
    addPayment,
    managePayment,
    getManagePaymentData
};

export default subService;