import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Form, Header, Segment} from "semantic-ui-react";
import DatePicker from "react-datepicker";

class MatterInformation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            number: "",
            client_id: null,
            practice_area_id: null,
            office_id: null,
            sdate: null,
            edate: null,
            client_estimated_spend: "",
            estimated_spend: "",
            actual_spend: ""
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.data) {
            this.setState({...nextProps.data});
        }
    }

    render() {
        const {master_data, onChange, org, isUpdate} = this.props;
        const {
            name,
            number,
            client_id,
            sdate,
            edate,
            office_id,
            client_estimated_spend,
            estimated_spend,
            actual_spend,
            practice_area_id,
        } = this.state;
        return (
            <div style={{marginBottom: "18px"}}>
                <Header style={{marginBottom: "10px"}}> Matter Information: </Header>
                <Segment>
                    <Form>
                        <Form.Group>
                            <Form.Input label={"Matter name:"} placeholder={"Matter name"} width={10}
                                        value={name}
                                        onChange={(e) => {
                                            onChange({...this.state, name: e.target.value});
                                            this.setState({name: e.target.value});
                                        }}/>
                            <Form.Field width={6} disabled={isUpdate}>
                                <label>Evaluation Start Date:</label>
                                <DatePicker
                                    className={"ui input fluid"}
                                    placeholderText={"01/02/2019"}
                                    selected={sdate}
                                    onChange={(date) => {
                                        onChange({...this.state, sdate: date, edate: null});
                                        this.setState({sdate: date, edate: null});
                                    }}
                                    peekNextMonth
                                    dateFormat={"MM/dd/yyyy"}
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                />
                            </Form.Field>
                        </Form.Group>
                        <Form.Group>
                            <Form.Input label={"Matter Number:"} placeholder={"Matter number"} width={10}
                                        value={number || ""}
                                        onChange={(e) => {
                                            onChange({...this.state, number: e.target.value});
                                            this.setState({number: e.target.value});
                                        }}/>
                            <Form.Field width={6}>
                                <label>Evaluation End Date:</label>
                                <DatePicker
                                    disabled={!sdate}
                                    placeholderText={"01/03/2019"}
                                    selected={edate}
                                    onChange={(date) => {
                                        onChange({...this.state, edate: date});
                                        this.setState({edate: date});
                                    }}
                                    minDate={sdate}
                                    peekNextMonth
                                    dateFormat={"MM/dd/yyyy"}
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                />
                            </Form.Field>
                        </Form.Group>
                        <Form.Group>
                            <Form.Dropdown label={`${org.str_client_firm}:`} width={10}
                                           selection value={client_id} search
                                           onChange={(e, {value}) => {
                                               onChange({...this.state, client_id: value});
                                               this.setState({client_id: value});
                                           }}
                                           options={master_data.clients}/>
                            <Form.Input label={"Client Estimated Spend:"} placeholder={"Client Estimated Spend"}
                                        width={6} value={client_estimated_spend} iconPosition='left' icon={'dollar'}
                                        onChange={(e) => {
                                            onChange({...this.state, client_estimated_spend: e.target.value});
                                            this.setState({client_estimated_spend: e.target.value});
                                        }}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Dropdown label='Practice Area:' width={10}
                                           selection value={practice_area_id} search
                                           onChange={(e, {value}) => {
                                               onChange({...this.state, practice_area_id: value});
                                               this.setState({practice_area_id: value});
                                           }}
                                           options={master_data.practices}/>
                            <Form.Input label={"Estimated Spend:"} placeholder={"Estimated Spend"}
                                        width={6} value={estimated_spend} iconPosition='left' icon={'dollar'}
                                        onChange={(e) => {
                                            onChange({...this.state, estimated_spend: e.target.value});
                                            this.setState({estimated_spend: e.target.value});
                                        }}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Dropdown label='Office Location:' width={10}
                                           disabled={!org.location_tracking}
                                           selection value={office_id} search
                                           onChange={(e, {value}) => {
                                               onChange({...this.state, office_id: value});
                                               this.setState({office_id: value});
                                           }}
                                           options={master_data.offices}
                            />
                            <Form.Input label={"Actual Spend:"} placeholder={"Actual Spend"}
                                        width={6} value={actual_spend} iconPosition='left' icon={'dollar'}
                                        onChange={(e) => {
                                            onChange({...this.state, actual_spend: e.target.value});
                                            this.setState({actual_spend: e.target.value});
                                        }}/>
                        </Form.Group>
                    </Form>
                </Segment>
            </div>
        );
    }
}

MatterInformation.propTypes = {
    data: PropTypes.object,
    org: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    master_data: PropTypes.object.isRequired,
    isUpdate: PropTypes.bool.isRequired
};

export default MatterInformation;
