import React, {Component} from 'react';
import {Button, Form, Segment} from "semantic-ui-react";
import ColoredBody from "../other/ColoredBody";
import BoxHeader from "../other/BoxHeader";
import BoxFooter from "../other/BoxFooter";
import api from "../../helper/api";
import storage from "../../helper/storage";
import {log} from "../../helper/helper";

class TestPage extends Component {
    constructor(props) {
        super(props);
        let url = storage.get("TEMP_API");log(url);
        const has = url;
        if(!has)
            url = process.env.REACT_APP_API_HOST_DOMAIN;
        api.baseURL(url);
        this.state = {
            url: url,
            set: has || false
        };
    }

    render() {
        const {url, set} = this.state;
        return (
            <ColoredBody textAlign={"center"} boxWidth={"512px"}>
                <BoxHeader/>
                <Segment>
                    <br/>
                    <br/>
                    <span style={{fontSize: "34px"}}>Test Config Page</span>
                    <br/>
                    <br/>
                    <b>Original API URL: {process.env.REACT_APP_API_HOST_DOMAIN}</b>
                    <br/>
                    <b>Current API URL: {url}</b>
                    <br/>
                    <br/>
                    <Form>
                        {!set && <Form.Input label={"API URL:"} value={url}
                                             onChange={(e) => this.setState({url: e.target.value})}/>}
                        <Button primary={!set} onClick={() => {
                            if (this.state.set) {
                                this.setState({set: false});
                                storage.remove("TEMP_API");
                                api.baseURL();
                            } else {
                                this.setState({set: true});
                                storage.set("TEMP_API",this.state.url);
                                api.baseURL(this.state.url);
                            }
                        }}>{!set ? "Set" : "Remove"}</Button>
                    </Form>
                    <br/>
                    <br/>
                    <br/>
                </Segment>
                <BoxFooter/>
            </ColoredBody>
        );
    }
}

export default TestPage;