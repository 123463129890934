import React, {Component} from 'react';
import {OrgMenuBar} from "../../other/OrgMenuBar";
import OrgSubHeader from "../../other/OrgSubHeader";
import matterAction from "../../../../../actions/matter.action";
import Body from "../../../../other/Body";
import {Form, Header, Icon, Segment, Table} from "semantic-ui-react";
import {orgState} from "../../../../../helper/stateUtil";
import {connect} from "react-redux";
import helper from "../../../../../helper/helper";
import {EVALUATION_STATUS} from "../../../../../helper/const";

class OrgEvaluationsCyclesPage extends Component {
    constructor(props) {
        super(props);
        this.flag = {
            REJECT: 1,
            REMINDER: 2
        };
        this.state = {
            query: "",
            evalType: -1,
            flag: null,
            status: EVALUATION_STATUS.ALL
        };
    }

    getStrTypeOfEval = item => {
        switch (item.diff_id) {
            case "fe":
                return this.props.org.str_client_firm;
            case "me":
                return "Matter";
            case "ee":
                return "Evaluatee";
            default:
                return "Unknown";
        }
    };

    componentDidMount() {
        this.props.dispatch(matterAction.getAllMattersEvaluationsCycles({
            org_id: this.props.org.id
        }));
    }

    // componentWillReceiveProps(nextProps) {
    //     if (nextProps.isDone && this.state.flag === this.flag.REJECT) {
    //         this.props.dispatch(matterAction.getAllMattersEvaluationsCycles({
    //             org_id: this.props.org.id
    //         }));
    //         this.setState({flag: null});
    //     }
    // }

    render() {
        const {loading, org, evaluations} = this.props;
        let items = evaluations;
        let {query, status, evalType} = this.state;
        query = query.toLowerCase();
        if (evalType !== EVALUATION_STATUS.ALL) {
            items = items.filter((item) => item.diff_id === evalType);
        }
        if (status !== EVALUATION_STATUS.ALL) {
            items = items.filter((item) => item.status === status);
        }
        if (query.length > 0) {
            items = items.filter((item) => {
                const {name, lawyer_name, client_name} = item;
                return (
                    name.toLowerCase().includes(query)
                    || (lawyer_name && lawyer_name.toLowerCase().includes(query))
                    || client_name.toLowerCase().includes(query)
                );
            });
        }
        let content;
        if (items.length > 0) {
            content = (
                <Segment basic style={{padding: "0 3em"}}>
                    <Table basic='very' selectable stackable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Due Date</Table.HeaderCell>
                                <Table.HeaderCell>Cycle No</Table.HeaderCell>
                                <Table.HeaderCell>Evaluation Category</Table.HeaderCell>
                                <Table.HeaderCell>Name</Table.HeaderCell>
                                <Table.HeaderCell>{org.str_client_firm} Name</Table.HeaderCell>
                                <Table.HeaderCell>Evaluatee</Table.HeaderCell>
                                <Table.HeaderCell>Status</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {items.map((item, index) => {
                                return (
                                    <Table.Row key={index} className={"row"}>
                                        <Table.Cell>{item.due_date}</Table.Cell>
                                        <Table.Cell>{item.cycle_no}</Table.Cell>
                                        <Table.Cell>{this.getStrTypeOfEval(item)}</Table.Cell>
                                        <Table.Cell>{item.name}</Table.Cell>
                                        <Table.Cell>{item.client_name}</Table.Cell>
                                        <Table.Cell>{item.lawyer_name}</Table.Cell>
                                        <Table.Cell>{item.str_status}</Table.Cell>
                                    </Table.Row>
                                );
                            })}
                        </Table.Body>
                    </Table>
                </Segment>
            );
        } else {
            content = (
                <Segment basic placeholder piled clearing>
                    <Header icon>
                        <Icon name='file outline'/>
                        No evaluations cycles are listed.
                    </Header>
                </Segment>
            );
        }

        return (
            <Body loading={loading}>
                <OrgMenuBar/>

                <OrgSubHeader title={"Evaluations Cycles"} backArrow/>

                <Segment basic style={{padding: "2em 3em 1em 3em"}}>
                    <Form>
                        <Form.Group>
                            <Form.Input placeholder='Search' value={this.state.query}
                                        onChange={(e) => this.setState({query: e.target.value})}/>
                            <Form.Dropdown selection value={evalType}
                                           onChange={(e, {value}) => this.setState({evalType: value})}
                                           options={helper.evaluationTypes(org)}/>
                            <Form.Dropdown selection value={status}
                                           onChange={(e, {value}) => this.setState({status: value})}
                                           options={helper.evaluationCycleStatus}/>
                        </Form.Group>
                    </Form>
                </Segment>
                <br/>
                {content}

            </Body>
        );
    }
}

function mapStateToProps(state) {
    let s = orgState(state);
    return {...s, ...state.matter};
}

const cOrgEvaluationsCyclesPage = connect(mapStateToProps)(OrgEvaluationsCyclesPage);
export {cOrgEvaluationsCyclesPage as OrgEvaluationsCyclesPage};