import api from "../helper/api";

const save = data => api.instance().post("qa/save-settings", data);

const getAll = () => api.instance().post("qa/get-settings");

const settingService = {
    save,
    getAll
};

export default settingService;
