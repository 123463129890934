import React, {Component} from 'react';
import {Button, Form, Message, Segment} from "semantic-ui-react";
import {connect} from "react-redux";
import {OrgMenuBar} from "../../other/OrgMenuBar";
import lawyerAction from "../../../../../actions/lawyer.action";
import {orgState} from "../../../../../helper/stateUtil";
import Body from "../../../../other/Body";
import OrgSubHeader from "../../other/OrgSubHeader";
import QMCheckBox from "../../../../other/form/QMCheckBox";

class OrgManageLawyerPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "",
            status: true,
            client_id: null
        };
        this.onChange = this.onChange.bind(this);
        this.onSave = this.onSave.bind(this);
    }

    componentDidMount() {
        this.props.dispatch(lawyerAction.read({org_id: this.props.org.id, id: this.props.match.params.id}));
        this.props.dispatch(lawyerAction.getClients({org_id: this.props.org.id}));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.isDone) {
            this.props.history.go(-1);
        }
        if (this.props.not_found) {
            this.props.history.replace("/not-found");
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.lawyer) {
            this.setState({
                name: nextProps.lawyer.name,
                email: nextProps.lawyer.email,
                status: nextProps.lawyer.status,
                client_id: nextProps.lawyer.client_id
            });
        }
    }

    onSave(e) {
        e.preventDefault();
        this.props.dispatch(lawyerAction.update({
            org_id: this.props.org.id,
            id: this.props.match.params.id,
            name: this.state.name,
            email: this.state.email,
            status: this.state.status,
            client: this.state.client_id
        }));
    }

    onChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    render() {
        const {error, loading, clients, org} = this.props;
        return (
            <Body loading={loading}>

                <OrgMenuBar/>

                <OrgSubHeader title={'Edit Evaluatee'} backArrow/>

                <Segment basic padded='very'>
                    <Form>
                        <Message color='red' hidden={error.length === 0}>
                            <Message.List>
                                {error.map((item, index) => <Message.Item key={index}>{item}</Message.Item>)}
                            </Message.List>
                        </Message>
                        <br/>
                        <Form.Group>
                            <Form.Field width={8}>
                                <label>Evaluatee Name:</label>
                                <input placeholder='Evaluatee Name' onChange={this.onChange} type="text"
                                       name={"name"}
                                       value={this.state.name}/>
                            </Form.Field>
                            <Form.Field width={8}>
                                <label>Evaluatee Email:</label>
                                <input placeholder='Evaluatee Email' onChange={this.onChange} type="email"
                                       name={"email"}
                                       value={this.state.email}/>
                            </Form.Field>
                        </Form.Group>
                        <Form.Field>
                            <Form.Dropdown label={`${org.str_client_firm}:`} width={8}
                                           selection value={this.state.client_id}
                                           onChange={(e, {value}) => this.setState({client_id: value})}
                                           options={clients}/>
                        </Form.Field>
                        <Form.Field>
                            <label>Status:</label>
                            <QMCheckBox toggle onChange={(checked) => this.setState({status: checked})}
                                        checked={this.state.status}/>
                        </Form.Field>
                        <br/>
                        <br/>
                        <Button type='submit' primary onClick={this.onSave} floated='right' circular
                                disabled={!(this.state.name && this.state.email)}>Update</Button>
                        <Button type='button' secondary inverted floated='right' circular
                                onClick={() => this.props.history.go(-1)}>Cancel</Button>
                        <br/>
                    </Form>
                </Segment>
            </Body>
        );
    }
}

function mapStateToProps(state) {
    const s = orgState(state);
    return {
        ...s,
        ...state.lawyer
    };
}

const connectedOrgManageLawyerPage = connect(mapStateToProps)(OrgManageLawyerPage);
export {connectedOrgManageLawyerPage as OrgManageLawyerPage};