import api from "../helper/api";

// question set
function addQuestionSet(data) {
    return api.instance().post(`${data.org_id ? "org" : "qa"}/questions/store`, data);
}

function addQuestionSetGroup(data) {
    return api.instance().post(`${data.org_id ? "org" : "qa"}/questions/add-question-group`, data);
}

function addQuestionSetGroupQuestion(data) {
    return api.instance().post(`${data.org_id ? "org" : "qa"}/questions/add-question`, data);
}

// common
function update(data) {
    data._method = "PUT";
    return api.instance().put(`${data.org_id ? "org" : "qa"}/questions/update`, data);
}

function changeStatus(data) {
    data._method = "PUT";
    return api.instance().put(`${data.org_id ? "org" : "qa"}/questions/change-status`, data);
}

function readAllQuestionSet(data) {
    return api.instance().post(`${data.org_id ? "org" : "qa"}/questions/get-all-sets`, data);
}

function readAllQuestionGroup(data) {
    return api.instance().post(`${data.org_id ? "org" : "qa"}/questions/get-qs-group`, data);
}

function readAllQuestions(data) {
    return api.instance().post(`${data.org_id ? "org" : "qa"}/questions/get-qs-question`, data);
}

function readQuestionSet(data) {
    return api.instance().post(`${data.org_id ? "org" : "qa"}/questions/get-question-set`, data);
}

function readQuestionSetGroup(data) {
    return api.instance().post(`${data.org_id ? "org" : "qa"}/questions/get-question-set-group`, data);
}

function getAllowedMasterDataEQType(data) {
    return api.instance().post(`qa/questions/get-allowed-master-data-eq-types`, data);
}

const evaluationQuestionService = {

    addQuestionSet,
    addQuestionSetGroup,
    addQuestionSetGroupQuestion,
    update,
    changeStatus,
    readAllQuestionSet,
    readAllQuestionGroup,
    readAllQuestions,
    readQuestionSet,
    readQuestionSetGroup,
    getAllowedMasterDataEQType
};

export default evaluationQuestionService;
