import api from "../helper/api";

const add = data => api.instance().post("org/create-export-request", data);

const readAll = data => api.instance().post("org/get-all-export-requests", data);

const exportService = {
    add,
    readAll
};

export default exportService;