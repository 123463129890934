import React, {Component} from 'react';
import {Button, Segment} from "semantic-ui-react";
import {connect} from "react-redux";
import {OrgMenuBar} from "../../other/OrgMenuBar";
import "./../../../../../react-datepicker.css";
import OrgSubHeader from "../../other/OrgSubHeader";
import {orgState} from "../../../../../helper/stateUtil";
import Body from "../../../../other/Body";
import ErrorMessage from "../../../../other/ErrorMessage";
import {MatterViewSummary} from "../../other/MatterViewSummary";

class OrgViewMatterPage extends Component {
    render() {
        const {error, loading} = this.props;
        return (
            <Body loading={loading}>

            <OrgMenuBar/>

            <OrgSubHeader backArrow title={"Matter"}>
                <Button floated='right' color={"teal"} circular
                        onClick={() => this.props.history.push(`/orgadmin/matters/edit/${this.props.match.params.id}`)}>Edit
                    Matter</Button>
            </OrgSubHeader>

            <Segment basic padded='very'>
                <ErrorMessage errors={error}/>
                <MatterViewSummary matterId={this.props.match.params.id}/>
            </Segment>
            </Body>
        );
    }
}

function mapStateToProps(state) {
    const s = orgState(state);
    return {
        ...s,
        ...state.matter
    };
}

const cOrgViewMatterPage = connect(mapStateToProps)(OrgViewMatterPage);
export {cOrgViewMatterPage as OrgViewMatterPage};