import {HELP} from "../actions/help.action";

const initState = {
    isAllowed: true,
    loading: false,
    isDone: false,
    items: [],
    error: [],
    item: null
};

export default function helpReducer(state = initState, action) {
    // reset
    state.error = [];
    state.isDone = false;
    state.loading = false;
    state.isAllowed = true;

    // filter
    switch (action.type) {

        // common
        case HELP.REQUEST:
            return {...state, loading: true};

        case HELP.VALIDATION_ERROR:
            return {...state, error: action.payload};

        case HELP.ERROR:
            return {...state, error: [...action.payload]};

        // not allowed to access
        case HELP.NOT_ALLOWED:
            return {...state, isAllowed: false};

        case HELP.ADD_SUCCESS:
            return {...state, isDone: true};

        case HELP.UPDATE_SUCCESS:
            return {...state, isDone: true};

        case HELP.READ_ALL_SUCCESS:
            return {...state, items: action.payload};

        case HELP.READ_SUCCESS:
            return {...state, item: action.payload};

        case HELP.NOT_FOUND:
            return {...state, not_found: true};

        case HELP.CHANGE_STATUS_SUCCESS:
            state.items[action.payload.index].status = action.payload.status;
            return {...state};

        default:
            return state;
    }
}