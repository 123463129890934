import React, {Component} from 'react';
import {connect} from "react-redux";
import {Button, Embed, Header, Icon, Modal, Segment, Table} from "semantic-ui-react";
import helpAction from "../../actions/help.action";
import Body from "../other/Body";
import OrgSubHeader from "./org/other/OrgSubHeader";
import {OrgMenuBar} from "./org/other/OrgMenuBar";
import helper from "../../helper/helper";
import {HELP_CATS} from "../../helper/const";

class HelpPage extends Component {

    componentDidMount() {
        this.props.dispatch(helpAction.readAllHelp({}));
    }

    openLink = item => {
        let link = document.createElement("a");
        document.body.appendChild(link);
        link.download = item.title;
        link.href = item.url;
        link.target = "_blank";
        link.click();
        document.body.removeChild(link);
    };

    getContent = item => {
        if (item.type === HELP_CATS.VIDEOS) {
            if (item.url.includes("youtu")) {
                let id;
                if (item.url.includes("youtu.be")) {
                    id = item.url.substring(item.url.lastIndexOf("/") + 1);
                } else
                    id = item.url.substring(item.url.indexOf("=") + 1);
                return <Modal closeIcon
                              trigger={
                                  <Icon name={"eye"} color={"teal"}
                                        size={"large"} title={'View'}
                                  />}>
                    <Modal.Content>
                        <Embed brandedUI
                               color={'red'}
                               id={id}
                               placeholder={`https://i1.ytimg.com/vi/${id}/0.jpg`}
                               source='youtube'/>
                    </Modal.Content>
                </Modal>;
            }
        }
        return <Icon name={"download"} color={"teal"} link
                     size={"large"} title={'Download'}
                     onClick={() => this.openLink(item)}/>;
    };

    render() {
        const {items, loading} = this.props;
        let rows = [];
        helper.helpMenus.forEach((menu) => {
            const temp = items.filter((item) => item.type === menu.value);
            if (temp.length > 0) {
                rows.push({
                    icon: menu.icon,
                    title: menu.text,
                    header: true
                });
                rows = [...rows, ...temp];
            }
        });
        let content;
        if (items.length > 0) {
            content = (
                <Segment basic style={{padding: "0.5em 3em"}}>
                    <Table basic='very' padded stackable compact striped>
                        <Table.Header>
                            <Table.Row>
                                <Table.Cell></Table.Cell>
                                <Table.Cell>Document Name</Table.Cell>
                                <Table.Cell>Type</Table.Cell>
                                <Table.Cell>Size</Table.Cell>
                                <Table.Cell>Date Added</Table.Cell>
                                <Table.Cell></Table.Cell>
                            </Table.Row>
                        </Table.Header>
                        {rows.map((item, index) => {
                            return (
                                item.header ?
                                    <Table.Header key={index}>
                                        <Table.Row>
                                            <Table.HeaderCell>
                                                <Icon name={item.icon} color={"grey"} size={"large"}/>
                                            </Table.HeaderCell>
                                            <Table.HeaderCell colSpan={6}>
                                                {item.title}
                                            </Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    :
                                    <Table.Body key={index}>
                                        <Table.Row className={"row no-pointer"} key={index}>
                                            <Table.Cell></Table.Cell>
                                            <Table.Cell>{item.title}</Table.Cell>
                                            <Table.Cell>{item.type !== HELP_CATS.VIDEOS ? item.doc_type : ""}</Table.Cell>
                                            <Table.Cell>{item.type !== HELP_CATS.VIDEOS ? helper.getStrSize(item.doc_size) : ""}</Table.Cell>
                                            <Table.Cell>{item.updated_at}</Table.Cell>
                                            <Table.Cell>
                                                {this.getContent(item)}
                                            </Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                            );
                        })}
                    </Table>
                </Segment>
            );
        } else {
            content = (
                <Segment basic placeholder piled clearing>
                    <Header icon>
                        <Icon name='file outline'/>
                        No help documents are listed.
                    </Header>
                </Segment>
            );
        }

        return (
            <Body loading={loading}>
                <OrgMenuBar/>
                <OrgSubHeader title={"Help"} backArrow={false}>
                    <Button color={"teal"} circular floated={'right'}
                            onClick={() => this.props.history.push("/contact")}>
                        Contact Us
                    </Button>
                </OrgSubHeader>

                {content}
            </Body>
        );
    }
}

HelpPage.propTypes = {};

function mapStateToProps(state) {
    return {...state.help};
}

const cHelpPage = connect(mapStateToProps)(HelpPage);
export {cHelpPage as HelpPage};
