import React, {Component} from 'react';
import {Message, Segment} from "semantic-ui-react";
import {connect} from "react-redux";
import evaluationQuestionAction from "../../../../../actions/evaluation_question.action";
import AddQuestionForm from "../../../form/questions/AddQuestionForm";
import AddQuestionSetForm from "../../../form/questions/AddQuestionSetForm";
import SABody from "../../other/SABody";
import SASubHeader from "../../other/SASubHeader";

class SuperAdminAddQuestionSetPage extends Component {

    // data structure
    // {
    //     "name":"First",
    //     "status":false,
    //     "question_groups":[
    //         {
    //             "name":"Group 1",
    //             "status":true,
    //             "questions":[
    //                 {
    //                     "title":"What is a questions?",
    //                     "answer_type":1,
    //                     "status":false
    //                 }
    //                 ]
    //         }
    //         ]
    // }

    constructor(props) {
        super(props);
        this.state = {
            data: {},
            step: 1, // 1 - set, 2 - group, 3 - question
            set: null, // title of set
            group: null
        };
        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit() {
        const {data} = this.state;
        this.props.dispatch(evaluationQuestionAction.createQuestionData(data));
    }

    componentDidMount() {
        this.props.dispatch(evaluationQuestionAction.getAllActiveQueGroups());
    }

    componentDidUpdate() {
        const {isAllowed, isDone} = this.props.eq;
        if (!isAllowed) {
            this.props.history.replace("/forbidden");
        }
        if (isDone) {
            this.props.history.go(-1);
        }
    }

    render() {
        const {loading, error, groups} = this.props.eq;
        const {set, step, data} = this.state;
        let content;
        switch (step) {
            case 1: // question set
                content = (
                    <AddQuestionSetForm
                        defaultEvalType={parseInt(this.props.match.params.filter)}
                        isFromMaterData={true}
                        onSave={(value) => {
                            this.setState({
                                data: value,
                                set: value,
                                step: 2
                            });
                        }}/>
                );
                break;
            // case 2: // question set's group
            //     content = (
            //         <AddQuestionSetGroupForm status={set.status} setName={set.name} onSave={(value) => {
            //             data.question_groups.push(value);
            //             this.setState({
            //                 data: data,
            //                 group: value,
            //                 step: 3
            //             });
            //         }}/>
            //     );
            //     break;
            case 2:
                content = (
                    <AddQuestionForm
                        groups={groups}
                        status={set.status}
                        setName={set.name}
                        onSave={(value) => {
                            data.questions.push(value);
                            this.setState({data: data});
                        }}
                        onSubmit={(value) => {
                            data.questions.push(value);
                            this.setState({data: data});
                            this.onSubmit();
                        }}/>
                );
                break;
            default:
                content = (<div>Something went wrong!</div>);
        }

        return (
            <SABody loading={loading}>
                <SASubHeader backArrow title={"Add Evaluation Question Data"}/>
                <Segment basic>
                    <Message color='red' hidden={error.length === 0}>
                        <Message.List>
                            {error.map((item, index) => <Message.Item key={index}>{item}</Message.Item>)}
                        </Message.List>
                    </Message>
                </Segment>
                {content}
            </SABody>
        );
    }
}

function mapStateToProps(state) {
    return {eq: state.evaluation_question};
}

const cSuperAdminAddQuestionSetPage = connect(mapStateToProps)(SuperAdminAddQuestionSetPage);
export {cSuperAdminAddQuestionSetPage as SuperAdminAddQuestionSetPage};
