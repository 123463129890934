import api from "../helper/api";

function add(data) {
    return api.instance().post(`${data.org_id ? "org" : "qa"}/practice-areas/store`, data);
}

function update(data) {
    data._method = "PUT";
    return api.instance().put(`${data.org_id ? "org" : "qa"}/practice-areas/update`, data);
}

function readAll(data) {
    return api.instance().post(`${data.org_id ? "org" : "qa"}/practice-areas/get-all`, data);
}

function read(data) {
    return api.instance().post(`${data.org_id ? "org" : "qa"}/practice-areas/get`, data);
}

function changeStatus(data) {
    data._method = "PUT";
    return api.instance().put(`${data.org_id ? "org" : "qa"}/practice-areas/change-status`, data);
}

const practiceService = {
    add,
    update,
    readAll,
    read,
    changeStatus
};

export default practiceService;