import React, {Component} from 'react';
import {Button, Form, Header, Icon, Segment, Table} from "semantic-ui-react";
import {connect} from "react-redux";
import {OrgMenuBar} from "../../other/OrgMenuBar";
import OrgSubHeader from "../../other/OrgSubHeader";
import practiceAction from "../../../../../actions/practice.action";
import {orgState} from "../../../../../helper/stateUtil";
import Body from "../../../../other/Body";
import QMCheckBox from "../../../../other/form/QMCheckBox";

class OrgPracticeAreasPage extends Component {

    componentDidMount() {
        this.props.dispatch(practiceAction.readAll({org_id: this.props.org.id}));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.props.practice.isAllowed) {
            this.props.history.replace("/forbidden");
        }
    }

    render() {
        const {org} = this.props;
        const {loading, practices} = this.props.practice;
        let content;
        if (practices.length > 0) {
            content = (
                <Segment padded={"very"} basic>
                    <Table basic='very' selectable stackable>
                        <Table.Body>
                            {practices.map((practice, index) => {
                                return (
                                    <Table.Row className={"row"} key={index}>
                                        <Table.Cell style={{paddingLeft: "16px"}}>
                                            <Icon name="file alternate" color={"grey"} size={"large"}/>
                                            <span><b>{practice.title}</b></span>
                                        </Table.Cell>
                                        <Table.Cell collapsing>
                                            <Form>
                                                <Form.Group inline>
                                                    {!practice.is_master && <Form.Field>
                                                        <Button icon={"pencil"} circular color={"grey"}
                                                                size={"mini"} onClick={() => {
                                                            this.props.history.push(`/orgadmin/practice-areas/manage/${practice.id}`);
                                                        }}/>
                                                    </Form.Field>}
                                                    <Form.Field>
                                                        <QMCheckBox checked={practice.status}
                                                                    onChange={checked => {
                                                                        this.props.dispatch(practiceAction.changeStatus({
                                                                            org_id: org.id,
                                                                            id: practice.id,
                                                                            status: checked
                                                                        }));
                                                                    }}/>
                                                    </Form.Field>
                                                </Form.Group>
                                            </Form>
                                        </Table.Cell>
                                    </Table.Row>
                                );
                            })}
                        </Table.Body>
                    </Table>
                </Segment>
            );
        } else {
            content = (
                <Segment basic placeholder piled>
                    <Header icon>
                        <Icon name='file outline'/>
                        No practice areas are listed.
                    </Header>
                    <Button color={"teal"} circular
                            onClick={() => this.props.history.push("/orgadmin/practice-areas/add")}>
                        Add Practice Area
                    </Button>
                </Segment>
            );
        }
        return (
            <Body loading={loading}>

                <OrgMenuBar/>

                <OrgSubHeader title={`Practice areas - ${org.name}`} backArrow>
                    <Button circular size={"medium"}
                            onClick={() => this.props.history.push("/orgadmin/practice-areas/add")}
                            title="Add Practice Area" floated="right" color={"teal"}>Add Practice Area</Button>
                </OrgSubHeader>

                {content}
            </Body>
        );
    }
}

function mapStateToProps(state) {
    let s = orgState(state);
    return {...s, practice: state.practice};
}

const connectedOrgPracticeAreasPage = connect(mapStateToProps)(OrgPracticeAreasPage);
export {connectedOrgPracticeAreasPage as OrgPracticeAreasPage};