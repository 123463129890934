import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Form, Modal, Segment} from "semantic-ui-react";
import ErrorMessage from "../../../../../../other/ErrorMessage";

class RegionAreaModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: ""
        };
    }

    componentWillReceiveProps(nextProps) {
        const {area} = nextProps;
        if (area) {
            this.setState({
                title: area.title
            });
        } else {
            this.setState({
                title: ''
            })
        }
    }

    render() {
        const {loading, errors, open, onSubmit, area, onClose} = this.props;

        return (
            <Modal size={"tiny"} open={open}
                   closeOnDimmerClick={false}
                   onClose={() => onClose()}>
                <Modal.Header>{area ? "Edit" : "Add"} Region Area</Modal.Header>
                <Modal.Content>
                    <Segment basic>
                        <Form>
                            <ErrorMessage errors={errors}/>
                            <Form.Field>
                                <label>Region Area Title:</label>
                                <input placeholder={`Region Area Title`}
                                       onChange={(e) => this.setState({title: e.target.value})}
                                       type="text" value={this.state.title}/>
                            </Form.Field>
                        </Form>
                    </Segment>
                </Modal.Content>
                <Modal.Actions>
                    <Button secondary onClick={() => onClose()}
                            disabled={loading}>Cancel</Button>
                    <Button color={"teal"} content="Submit"
                            type="submit"
                            loading={loading}
                            onClick={() => {
                                onSubmit(this.state, area);
                            }}
                            disabled={(loading) || (!this.state.title)}/>
                </Modal.Actions>
            </Modal>
        );
    }
}

RegionAreaModal.propTypes = {
    open: PropTypes.bool.isRequired,
    errors: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    area: PropTypes.object
};

export default RegionAreaModal;
