import React, {Component} from 'react';
import {Button, Form, Header, Icon, Segment, Table} from "semantic-ui-react";
import {connect} from "react-redux";
import evaluationQuestionAction from "../../../../actions/evaluation_question.action";
import QuestionGroupFormModal from "./QuestionGroupFormModal";
import SABody from "../other/SABody";
import SASubHeader from "../other/SASubHeader";

// This page is for list out all questions of evaluation questions's set's group
class SAListQuestionGroupsPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false, // flag for modal open and close
            group: null,
            filter: 1
        };
        this.onOpenModal = this.onOpenModal.bind(this);
    }

    onOpenModal(group) {
        if (group != null) {
            this.setState({group: group, open: true}); // for edit
        } else {
            this.setState({group: null, open: true}); // for add
        }
    }

    componentDidMount() {
        this.props.dispatch(evaluationQuestionAction.getAllQueGroups({}));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.props.eq.isAllowed) {
            this.props.history.replace("/forbidden");
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.eq.isDone) {
            this.setState({group: null, open: false});
        }
    }

    render() {
        let {loading, items, error} = this.props.eq;
        const {open, group} = this.state;
        // items = items.filter((item) => item.eval_type === filter);
        let content;
        if (items.length > 0) {
            content = (
                <Segment padded={'very'} basic>
                    <Table basic='very' selectable stackable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Group name</Table.HeaderCell>
                                <Table.HeaderCell>Status</Table.HeaderCell>
                                <Table.HeaderCell>Actions</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {items.map((item, index) => {
                                return (
                                    <Table.Row className={"row"} key={index}>
                                        <Table.Cell>
                                            <span><b>{item.title}</b></span>
                                        </Table.Cell>
                                        <Table.Cell>
                                            {item.status ? "Active" : "Inactive"}
                                        </Table.Cell>
                                        <Table.Cell collapsing>
                                            <Form>
                                                <Form.Group inline>
                                                    <Form.Field>
                                                        <Button icon={"pencil"} circular color={"teal"}
                                                                title={"Edit Question Set"} size={"mini"}
                                                                onClick={() => {
                                                                    this.onOpenModal(item)
                                                                }}/>
                                                    </Form.Field>
                                                </Form.Group>
                                            </Form>
                                        </Table.Cell>
                                    </Table.Row>
                                );
                            })}
                        </Table.Body>
                    </Table>
                </Segment>
            );
        } else {
            content = (
                <Segment basic placeholder piled>
                    <Header icon>
                        <Icon name='file outline'/>
                        No question groups are listed.
                    </Header>
                    <Button color={"teal"} circular
                            onClick={() => this.onOpenModal(null)}>
                        Add Group
                    </Button>
                </Segment>
            );
        }
        return (
            <SABody loading={loading}>
                <SASubHeader title={"Question Groups"} backArrow>
                    <Button circular size={"medium"}
                            onClick={() => this.onOpenModal(null)}
                            title="Add User" floated="right" color={"teal"}>Add Group</Button>
                </SASubHeader>
                {/*<Segment basic padded>*/}
                {/*    <Form>*/}
                {/*        <Form.Group>*/}
                {/*            <Form.Dropdown selection value={this.state.filter}*/}
                {/*                           label={"Final Question Type"}*/}
                {/*                           onChange={(e, {value}) => this.setState({filter: value})}*/}
                {/*                           name={"type"}*/}
                {/*                           options={[*/}
                {/*                               {text: "Matter and Evaluatee", value: 1},*/}
                {/*                               {text: "Firm/Client", value: 2}*/}
                {/*                           ]}/>*/}
                {/*        </Form.Group>*/}
                {/*    </Form>*/}
                {/*</Segment>*/}
                {content}

                {open && <QuestionGroupFormModal
                    group={group}
                    error={error}
                    ignoreTypes={items.map((item) => item.type)}
                    loading={loading}
                    onSave={(value) => {
                        if (group) {
                            this.props.dispatch(evaluationQuestionAction.updateQueGroup({
                                ...value
                            }));
                        } else {
                            this.props.dispatch(evaluationQuestionAction.addQueGroup({
                                ...value
                            }));
                        }
                    }}
                    onClose={() => {
                        this.setState({open: false, group: null});
                    }}
                />}
            </SABody>
        );
    }
}

function mapStateToProps(state) {
    return {eq: state.evaluation_question};
}

const cSAListQuestionGroupsPage = connect(mapStateToProps)(SAListQuestionGroupsPage);
export {cSAListQuestionGroupsPage as SAListQuestionGroupsPage};