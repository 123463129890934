import React, {Component} from 'react';
import ColoredBody from "../../other/ColoredBody";
import BoxHeader from "../../other/BoxHeader";
import {RegisterUserAccountForm} from "./components/RegisterUserAccountForm";
import {Confirm, Header, Segment} from "semantic-ui-react";
import authAction from "../../../actions/auth.action";
import ErrorMessage from "../../other/ErrorMessage";
import qs from "query-string";
import BoxFooter from "../../other/BoxFooter";
import {connect} from "react-redux";

class NewUserAccountPage extends Component {
    constructor(props) {
        super(props);
        this.params = qs.parse(this.props.location.search);
        this.state = {
            open: false
        };
    }

    componentDidMount() {
        if (Object.keys(this.params).length !== 1) {
            alert("Invalid Request!");
            this.props.history.replace("/");
        }
    }

    componentWillReceiveProps(nextProps) {
        const {is_first_time, isDone, history, i_t, h_id, code} = nextProps;
        if (isDone) {
            history.replace(`/register/verify?i_t=${i_t}&flag=${is_first_time}&h_id=${h_id}`);
        }
        if (code === 'rt') {
            this.setState({open: true});
        }
    }

    componentWillUnmount() {
        this.props.dispatch(authAction.unmount());
    }

    render() {
        const {loading, error, history, dispatch, message} = this.props;
        const {open} = this.state;
        return (
            <React.Fragment> {open}
                <ColoredBody boxWidth={"584px"} textAlign={'center'}>
                    <BoxHeader/>
                    <Segment textAlign={"left"} padded>
                        <Header>
                            Create New User Account
                        </Header>
                        <br/>
                        <ErrorMessage errors={error}/>
                        <RegisterUserAccountForm
                            loading={loading}
                            onBlurEmail={(email) => {
                                if (!loading) {
                                    dispatch(authAction.isFirstUser({email, r_t: this.params.flag}));
                                }
                            }}
                            onSubmit={(data) => {
                                dispatch(authAction.register(data));
                            }}
                            onCancel={() => {
                                history.replace("/");
                            }}/>
                    </Segment>
                    <BoxFooter/>
                </ColoredBody>
                <Confirm open={open}
                         content={message || ""}
                         cancelButton={"NO"}
                         confirmButton={"YES"}
                         onCancel={() => history.replace("/")}
                         onConfirm={() => {
                             dispatch(authAction.deleteCode());
                             this.setState({open: false});
                         }}/>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return state.auth;
}

const cNewUserAccountPage = connect(mapStateToProps)(NewUserAccountPage);
export {cNewUserAccountPage as NewUserAccountPage};
