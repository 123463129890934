import React, {Component} from 'react';
import {Dropdown, Menu} from "semantic-ui-react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {EVALUATOR, MATTER, ORG_ADMIN, REPORT_VIEWER, SUPER_ADMIN} from "../../../../helper/const";
import {orgState, roles} from "../../../../helper/stateUtil";
import "./../../../../css/menu-bar.css";

export const reportsPath = '/reports';

class OrgMenuBar extends Component {

    constructor(props) {
        super(props);
        this.onMenuSelect = this.onMenuSelect.bind(this);
    }

    onMenuSelect(e, {value}) {
        this.props.history.push(value);
    }

    render() {
        const {path} = this.props.match;
        const {roles, org, history} = this.props;
        // const report = "reports";
        const matterMenu = {title: "Matters", path: "/matters"};
        const clientMenu = {title: org.str_client_firm + "s", path: "/clients-firms"};
        const evaluateeMenu = {title: "Evaluatees", path: "/lawyers"};
        const helpMenu = {title: "Help", path: '/help'};
        const adminMenu = {title: "Admin", path: "/orgadmin"};
        const isReportPath = path.includes(reportsPath);
        const reportsMenu = {
            title: "Reports",
            type: 'reports',
            content: <Dropdown item floating text='Reports' as={"h5"} key={100}
                               className={`${path.includes('reports') ? "active-item" : ""}`}>
                <Dropdown.Menu>
                    <Dropdown.Item text='Matters'
                                   active={isReportPath && path.includes('matters')}
                                   onClick={() => history.push(`${reportsPath}/matters`)}/>
                    <Dropdown.Item text={`${org.str_client_firm}s`}
                                   onClick={() => history.push(`${reportsPath}/clients-firms`)}
                                   active={isReportPath && path.includes('clients-firms')}/>
                    <Dropdown.Item text='Evaluatees'
                                   onClick={() => history.push(`${reportsPath}/lawyers`)}
                                   active={isReportPath && path.includes('lawyers')}/>
                    {/*<Dropdown.Item text='Practice Areas'*/}
                    {/*               onClick={() => history.push(`${reportsPath}/practice-areas`)}*/}
                    {/*               active={isReportPath && path.includes('practice-areas')}/>*/}
                    {/*<Dropdown.Item text='Regions' disabled*/}
                    {/*               onClick={() => history.push(`${reportsPath}/regions`)}*/}
                    {/*               active={isReportPath && path.includes('regions')}/>*/}
                    {/*<Dropdown.Item text='Diversity' disabled*/}
                    {/*               onClick={() => history.push(`${reportsPath}/diversity`)}*/}
                    {/*               active={isReportPath && path.includes('diversity')}/>*/}
                </Dropdown.Menu>
            </Dropdown>
        };
        let menus = [];

        // super admin
        if (roles.includes(SUPER_ADMIN)) {
            menus = [
                matterMenu,
                clientMenu,
                evaluateeMenu,
                reportsMenu,
                helpMenu,
                adminMenu
            ];
        } else {

            // evaluator
            if (roles.includes(EVALUATOR)) {
                menus = [...menus, {title: "My Evaluations", path: "/my-evaluations"}];
            }

            // matter role
            if (roles.includes(MATTER)) {
                menus = [...menus,
                    matterMenu,
                    clientMenu,
                    evaluateeMenu
                ];
            }

            // report viewer
            if (roles.includes(REPORT_VIEWER)) {
                menus = [
                    ...menus,
                    reportsMenu
                ];
            }

            menus = [...menus, helpMenu];

            // org admin
            if (roles.includes(ORG_ADMIN)) {
                menus = [...menus, adminMenu];
            }
        }
        return (
            <Menu pointing secondary stackable id={'menu-bar'}>
                {
                    menus.map((menu, index) => {
                        if (menu.content)
                            return menu.content;
                        else
                            return (<Menu.Item name={menu.title} as="h5" link value={menu.path} key={index}
                                               onClick={this.onMenuSelect} active={path.indexOf(menu.path) === 0}/>)
                    })
                }
            </Menu>
        );
    }
}

function mapStateToProps(state) {
    const r = roles(state);
    const s = orgState(state);
    return {...r, ...s};
}

const cOrgMenuBar = withRouter(connect(mapStateToProps)(OrgMenuBar));
export {cOrgMenuBar as OrgMenuBar};