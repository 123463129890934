import React, {Component} from 'react';
import {Segment} from "semantic-ui-react";
import appAction from "../../../../actions/app.action";
import {connect} from "react-redux";

class TermsOfUseContent extends Component {

    componentDidMount() {
        this.props.dispatch(appAction.getTermsOfUse());
    }

    render() {
        let {loading, content} = this.props;
        return (
            <Segment basic style={{whiteSpace: "pre-wrap", minHeight: "320px"}} loading={loading}>
                {content}
            </Segment>
        );
    }
}

const a = connect(state => {
    return {...state.app};
})(TermsOfUseContent);
export {a as TermsOfUseContent};
