import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Icon, Popup} from "semantic-ui-react";

class HelpPopup extends Component {
    render() {
        const {header, message} = this.props;
        return (
            <Popup
                position='top center'
                trigger={
                    <Icon name={"help circle"} color={"orange"} inverted link/>
                }
                header={header}
                content={message}
            />
        );
    }
}

HelpPopup.propTypes = {
    header: PropTypes.string,
    message: PropTypes.string.isRequired
};

export default HelpPopup;
