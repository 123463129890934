import React, {Component} from 'react';
import SABody from "../other/SABody";
import SASubHeader from "../other/SASubHeader";
import {Form, Segment} from "semantic-ui-react";
import QMCheckBox from "../../../other/form/QMCheckBox";
import {connect} from "react-redux";
import settingsAction from "../../../../actions/settings.action";

class AdminSettingsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            master_email_reminder_status: false
        };
        this.isFetched = false;
    }

    componentDidMount() {
        this.props.dispatch(settingsAction.getAll());
    }

    componentWillReceiveProps(nextProps) {
        const {loading, item} = nextProps;
        if (!loading) {
            if (Object.keys(item).length > 0 && !this.isFetched) {
                this.setState({...item});
                this.isFetched = true;
            }
        }
    }

    render() {
        const {loading, dispatch} = this.props;
        return (
            <SABody loading={loading}>
                <SASubHeader title={"App Settings"} backArrow={false}/>
                <Segment basic style={{padding: "2em 3em 1em 3em"}}>
                    <Form>
                        <Form.Field>
                            <label>Master Email Reminder Status:</label>
                            <QMCheckBox toggle onChange={(checked) => {
                                dispatch(settingsAction.save({
                                    key: "master_email_reminder_status",
                                    value: checked
                                }));
                                this.setState({master_email_reminder_status: checked});
                            }} type={1} checked={this.state.master_email_reminder_status}/>
                        </Form.Field>
                    </Form>
                </Segment>
            </SABody>
        );
    }
}

export default connect(
    state => state.setting
)(AdminSettingsPage);
