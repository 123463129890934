import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Container} from "semantic-ui-react";
import {SuperAdminHeader} from "./SuperAdminHeader";
import {SuperAdminMenuBar} from "./SuperAdminMenuBar";
import FullScreenLoader from "../../../other/FullScreenLoader";
import PageFooter from "../../../other/PageFooter";
import TermsOfUserModal from "../../app/TermsOfUseModal";

class SABody extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const {loading, children} = this.props;
        return (
            <div style={{backgroundColor: "#f1f2f3"}}>
                <Container id={"container"}>

                    <div style={{minHeight: "92vh"}}>

                        <FullScreenLoader active={loading} page={true}/>

                        <SuperAdminHeader/>

                        <SuperAdminMenuBar/>

                        <div style={{marginBottom: "1em"}}>
                            {children}
                        </div>
                    </div>
                    <PageFooter/>
                </Container>
                <TermsOfUserModal/>
            </div>
        );
    }
}

SABody.propTypes = {
    loading: PropTypes.bool.isRequired
};

export default SABody;
