import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Label} from "semantic-ui-react";
import {color} from "./barchart/utils";

class LabelCircle extends Component {
    render() {
        const {text, active} = this.props;
        return (
            <Label circular
                   style={{
                       backgroundColor: active ? color.brown : color.dark_green,
                       borderColor: active ? color.brown : color.dark_green,
                       color: 'white'
                   }}>{text}</Label>
        );
    }
}

LabelCircle.propTypes = {
    active: PropTypes.bool,
    text: PropTypes.any.isRequired
};

export default LabelCircle;
