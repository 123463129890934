import React, {Component} from 'react';
import {Segment} from "semantic-ui-react";
import {connect} from "react-redux";
import {OrgMenuBar} from "../other/OrgMenuBar";
import Body from "../../../other/Body";
import {orgState} from "../../../../helper/stateUtil";
import {MatterSummary} from "./components/MatterSummary";
import {MatterDiversity} from "./components/MatterDiversity";
import {MatterEvaluations} from "./components/MatterEvaluations";
import {MatterComments} from "./components/MatterComments";
import TabHeader from "../other/TabHeader";
import qs from "query-string";

class MatterSummaryPage extends Component {
    constructor(props) {
        super(props);
        this.params = qs.parse(props.location.search);
        const matterId = props.match.params.id;
        this.tabs = [
            {text: "Summary", value: "summary", render: <MatterSummary matterId={matterId}/>}
        ];
        if (props.org.diversity_tracking) {
            this.tabs.push({text: "Diversity", value: "diversity", render: <MatterDiversity matterId={matterId}/>});
        }
        this.tabs.push({text: "Evaluations", value: "evaluations", render: <MatterEvaluations matterId={matterId}/>});
        this.tabs.push({text: "Comments", value: "comments", render: <MatterComments matterId={matterId}/>});
    }

    render() {
        const {loading, match} = this.props;
        const matterId = match.params.id;
        const type = match.params.type;
        let activeIndex = 0;
        let content;
        this.tabs.forEach((tab, index) => {
            if (tab.value === type) {
                activeIndex = index;
                content = tab.render;
            }
        });
        return (
            <Body loading={loading}>

                <OrgMenuBar/>

                <TabHeader
                    title={`Matter(${this.params.h})`}
                    backArrow
                    tabs={this.tabs}
                    activeIndex={activeIndex}
                    onTabChange={(tab) => {
                        this.props.history.replace(`/matters/${matterId}/${tab.value}?h=${encodeURIComponent(this.params.h)}`)
                    }}/>
                <Segment basic padded>
                    {content}
                    {/*<Tab panes={this.panes}*/}
                    {/*onTabChange={(tab, {activeIndex}) => this.props.history.replace(`/matters/${matterId}/${this.panes[activeIndex].value}`)}*/}
                    {/*defaultActiveIndex={this.panes.findIndex((pane) => pane.value === type)}/>*/}
                </Segment>

            </Body>
        );
    }
}

function mapStateToProps(state) {
    let s = orgState(state);
    return {...s, ...state.matter};
}

const cMatterSummaryPage = connect(mapStateToProps)(MatterSummaryPage);
export {cMatterSummaryPage as MatterSummaryPage};