import helper from "../helper/helper";
import {AUTH} from "../actions/auth.action";
import storage from "../helper/storage";
import {AUTH_KEY, ORG_KEY} from "../helper/const";
import {ORG} from "../actions/org.action";
import errTracker from "../helper/tracker";
import ReactGA from 'react-ga';
import {USER} from "../actions/user.action";

const initState = {
    user: helper.user(),
    loading: false,
    error: [],
    orgs: [],
    is_first_time: false,
    create_org: false,
    isDone: false // use for forgot password request submission and password reset done
};

if (initState.user) {
    // init organization from localStorage
    if (!initState.user.org)
        initState.user.org = storage.get(ORG_KEY, true);

    try {
        errTracker.user = initState.user;
    } catch (e) {
        errTracker.notify(e);
    }
}

export default function authReducer(state = initState, action) {

    //assign user to bugsnag
    if (state.user) {
        errTracker.user = {
            name: state.user.name,
            status: state.user.status,
            hash: state.user.hash,
            roles: state.user.roles
        };
        ReactGA.set({
            name: state.user.name,
            status: state.user.status,
        });
    }

    // assign org to bugsnag
    if (state.user && state.user.org) {
        errTracker.metaData = state.user.org;
    }

    // reset
    state.loading = false;
    state.error = [];
    state.isDone = false;
    state.isCodeSend = false;

    switch (action.type) {

        // common
        case AUTH.REQUEST:
            return {...state, loading: true, isSSOChecked: false};

        case AUTH.IS_REQUEST_IS_FIRST_USER:
            return {...state, is_first_time: false, loading: true};

        case AUTH.VALIDATION_ERROR:
            return {...state, error: action.payload};

        case AUTH.ERROR:
            return {...state, error: [...action.payload]};

        case AUTH.ACTION:
            return {...state, ...action.payload};

        // login
        case AUTH.LOGIN_SUCCESS:
            return {...state, user: action.payload, isDone: true};

        case AUTH.IS_SSO_USER_SUCCESS:
            return {...state, ...action.payload, isSSOChecked: true};

        // forgot password
        case AUTH.FORGOT_PASSWORD_SUCCESS:
            return {...state, isDone: true};

        // reset password
        case AUTH.RESET_PASSWORD_SUCCESS:
            return {...state, isDone: true};

        case AUTH.ASSIGN_ORGANIZATION: {
            state.user.org = action.payload;
            return {...state, isDone: true};
        }

        case AUTH.REMOVE_ORGANIZATION: {
            if (state.user)
                state.user.org = null;
            return {...state, isDone: true};
        }

        case ORG.UPDATE_SETTING_SUCCESS:
            if (state.user) {
                const {name, type, diversity_tracking, location_tracking} = action.payload;
                const {org} = state.user;
                org.name = name;
                org.type = type;
                org.diversity_tracking = diversity_tracking;
                org.location_tracking = location_tracking;
                switch (type) {
                    case 1: // client => firm
                        org.str_client_firm = "Firm";
                        break;
                    case 2: // firm => client
                        org.str_client_firm = "Client";
                        break;
                    default:
                        break;
                }
                if (storage.get(ORG_KEY, true)) {
                    storage.set(ORG_KEY, org);
                } else {
                    let temp = storage.get(AUTH_KEY, true);
                    if (temp) {
                        temp.org = org;
                        storage.set(AUTH_KEY, temp);
                    }
                }
                state.user.org = org;
            }
            return {...state, isDone: true};

        case ORG.UPDATE_ORG_TYPE_SUCCESS:
            if (state.user) {
                const {type} = action.payload;
                const {org} = state.user;
                org.type = type;
                switch (type) {
                    case 1: // client => firm
                        org.str_client_firm = "Firm";
                        break;
                    case 2: // firm => client
                        org.str_client_firm = "Client";
                        break;
                    default:
                        break;
                }
                if (storage.get(ORG_KEY, true)) {
                    storage.set(ORG_KEY, org);
                } else {
                    let temp = storage.get(AUTH_KEY, true);
                    if (temp) {
                        temp.org = org;
                        storage.set(AUTH_KEY, temp);
                    }
                }
                state.user.org = org;
            }
            return {...state, isDone: true};

        case AUTH.JOIN_QUALMET_SUCCESS:
            return {...state, isDone: true};

        case AUTH.IS_FIRST_TIME_USER_SUCCESS:
            return {
                ...state,
                message: undefined,
                orgs: [],
                is_first_time: false,
                create_org: false,
                code: undefined, ...action.payload
            };

        case AUTH.VALIDATE_ACCOUNT_SUCCESS:
            return {...state, isDone: true, token: action.payload.token};

        case AUTH.REGISTER_SUCCESS:
            return {...state, ...action.payload, isDone: true};

        case AUTH.GET_USER_EMAIL_SUCCESS:
            return {...state, email: action.payload.email};

        case AUTH.CREATE_ORG_SUCCESS:
            return {...state, isDone: true, token: action.payload.token};

        case AUTH.EMAIL_VERIFICATION_CODE_SENT_SUCCESS:
            return {...state, isCodeSend: true};

        case AUTH.CONTACT_US_SUCCESS:
            return {...state, isDone: true};

        case AUTH.UNMOUNT_COMPONENT:
            return {
                ...state,
                loading: false,
                error: [],
                is_first_time: false,
                isDone: false,
                message: undefined,
                code: undefined,
                isCodeSend: false,
                create_org: false,
                orgs: [],
                token: undefined,
                isSSOChecked: false
            };

        case USER.CHANGE_INFO_SUCCESS:
            if (state.user) {
                state.user.name = action.payload.name;
            }
            return {...state};

        default:
            return state;
    }

}
