import React, {Component} from 'react';
import {Button, Form, Header, Icon, Segment, Table} from "semantic-ui-react";
import PropTypes from "prop-types";
import HelpPopup from "../../other/HelpPopup";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import checklistAction from "../../../actions/checklist.action";
import {roles} from "../../../helper/stateUtil";
import {ORG_ADMIN} from "../../../helper/const";
import helper from "../../../helper/helper";
import orgAction from "../../../actions/org.action";

class SetupCheckList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: props.org.type
        };
    }

    componentDidMount() {
        this.props.dispatch(checklistAction.read({
            org_id: this.props.org.id
        }));
    }

    save(data) {
        if (this.props.data) {
            this.props.dispatch(checklistAction.update({
                org_id: this.props.org.id,
                data: {
                    ...this.props.data,
                    ...data
                }
            }));
        }
    }

    changeOrgType = () => {
        if (this.props.org.type !== this.state.type) {
            this.props.dispatch(orgAction.updateOrgType({
                ...this.state,
                id: this.props.org.id
            }));
        }
    };

    push = path => this.props.history.push(`${path}?checklist=true`);

    render() {
        const {org, data, loading, roles} = this.props;
        const {type} = this.state;
        const {open, firm, evaluatees, users, regions, practice_areas, questions, org_type} = data;
        if (!open || !roles.includes(ORG_ADMIN))
            return <div/>;
        return (
            <Segment style={{margin: "0 3em"}} loading={loading}>
                <Header>
                    Quick Setup Checklist:
                </Header>
                <br/>
                <b>
                    Welcome to Qualmet Performance Management System. Let's get you
                    started in less than 5 minutes. Setup each item below by clicking on the "Setup" button.
                    Some items may be skipped, but you can always use them later by going to the Admin menu. For
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    additional help, go to the <a style={{cursor: "pointer"}}
                                                  onClick={() =>
                                                      this.push("/help")
                                                  }><u>Help</u></a> section.
                </b>
                <br/>
                <Table basic='very' stackable border={0} color={"teal"}>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell collapsing>
                                <Icon name='check circle' size='big' color={org_type ? "teal" : "grey"}/>
                            </Table.Cell>
                            <Table.Cell collapsing>
                                <Icon name='options' size='large'/>
                            </Table.Cell>
                            <Table.Cell collapsing>
                                Organization Type
                            </Table.Cell>
                            <Table.Cell collapsing>
                                <Form>
                                    <Form.Group inline>
                                        <Form.Dropdown placeholder='Organization Type'
                                                       selection onChange={(e, {value}) => this.setState({type: value})}
                                                       value={type} options={helper.orgTypes}/>
                                        <Button circular primary size='tiny'
                                                onClick={() => {
                                                    this.changeOrgType();
                                                    this.save({org_type: true});
                                                }}>
                                            Change
                                        </Button>
                                    </Form.Group>
                                </Form>
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell collapsing>
                                <Icon name='check circle' size='big' color={firm ? "teal" : "grey"}/>
                            </Table.Cell>
                            <Table.Cell collapsing>
                                <Icon name='group' size='large'/>
                            </Table.Cell>
                            <Table.Cell collapsing>
                                {org && org.str_client_firm}s <HelpPopup message={`
                                Add your Law Firms/Outside Counsel to the system so you can add them to evaluations and track results. Add your Clients to the system so you can add them to evaluations and track results. This checklist item help you create your “master” list that can then be selected from when creating any type of evaluation.
                                `}/>
                            </Table.Cell>
                            <Table.Cell collapsing>
                                <Button circular primary size='tiny'
                                        onClick={() => {
                                            this.push("/orgadmin/client_firm");
                                            this.save({firm: true});
                                        }}>
                                    Setup
                                </Button>
                                &nbsp;
                                &nbsp;
                                &nbsp;
                                Required
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell collapsing>
                                <Icon name='check circle' size='big' color={evaluatees ? "teal" : "grey"}/>
                            </Table.Cell>
                            <Table.Cell collapsing>
                                <Icon name='user' size='large'/>
                            </Table.Cell>
                            <Table.Cell collapsing>
                                Evaluatees <HelpPopup message={`
                                Add your Clients to the system so you can add them to evaluations and track results. This checklist item help you create your “master” list that can then be selected from when creating any type of evaluation.
                                `}/>
                            </Table.Cell>
                            <Table.Cell collapsing>
                                <Button circular primary size='tiny' onClick={() => {
                                    this.save({evaluatees: true});
                                    this.push("/orgadmin/lawyers");
                                }}>
                                    Setup
                                </Button>
                                &nbsp;
                                &nbsp;
                                &nbsp;
                                <Button circular primary basic size='tiny' disabled={evaluatees}
                                        onClick={() => this.save({evaluatees: true})}>
                                    Skip
                                </Button>
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell collapsing>
                                <Icon name='check circle' size='big' color={users ? "teal" : "grey"}/>
                            </Table.Cell>
                            <Table.Cell collapsing>
                                <Icon name='group' size='large'/>
                            </Table.Cell>
                            <Table.Cell collapsing>
                                Users <HelpPopup message={`
                                Add additional users to the system. Each user can be given access to one or more security roles based on what that user will be doing in the system. Email invites will be sent to allow the user to complete the account setup and enter their password.
                                `}/>
                            </Table.Cell>
                            <Table.Cell collapsing>
                                <Button circular primary size='tiny' onClick={() => {
                                    this.save({users: true});
                                    this.push("/orgadmin/users");
                                }}>
                                    Setup
                                </Button>
                                &nbsp;
                                &nbsp;
                                &nbsp;
                                <Button circular primary basic size='tiny' disabled={users}
                                        onClick={() => this.save({users: true})}>
                                    Skip
                                </Button>
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell collapsing>
                                <Icon name='check circle' size='big' color={regions ? "teal" : "grey"}/>
                            </Table.Cell>
                            <Table.Cell collapsing>
                                <Icon name='map marker alternate' size='large'/>
                            </Table.Cell>
                            <Table.Cell collapsing>
                                Regions <HelpPopup message={`
                                Add your office locations so you can track evaluation results by Region, Area, and Office. The system gives you report options to sort results by your office locations so you can view evaluation results in multiple ways.
                                `}/>
                            </Table.Cell>
                            <Table.Cell collapsing>
                                <Button circular primary size='tiny' onClick={() => {
                                    this.save({regions: true});
                                    this.push("/orgadmin/regions");
                                }}>
                                    Setup
                                </Button>
                                &nbsp;
                                &nbsp;
                                &nbsp;
                                <Button circular primary basic size='tiny' disabled={regions}
                                        onClick={() => this.save({regions: true})}>
                                    Skip
                                </Button>
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell collapsing>
                                <Icon name='check circle' size='big' color={practice_areas ? "teal" : "grey"}/>
                            </Table.Cell>
                            <Table.Cell collapsing>
                                <Icon name='group' size='large'/>
                            </Table.Cell>
                            <Table.Cell collapsing>
                                Practice Areas <HelpPopup message={`
                                The system comes with the most common practice areas, but you can add or remove practice areas to only the ones you use for your business.
                                `}/>
                            </Table.Cell>
                            <Table.Cell collapsing>
                                <Button circular primary size='tiny'
                                        onClick={() => {
                                            this.save({practice_areas: true});
                                            this.push("/orgadmin/practice-areas");
                                        }}>
                                    Setup
                                </Button>
                                &nbsp;
                                &nbsp;
                                &nbsp;
                                <Button circular primary basic size='tiny' disabled={practice_areas}
                                        onClick={() => this.save({practice_areas: true})}>
                                    Use Defaults
                                </Button>
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell collapsing>
                                <Icon name='check circle' size='big' color={questions ? "teal" : "grey"}/>
                            </Table.Cell>
                            <Table.Cell collapsing>
                                <Icon name='question circle' size='big'/>
                            </Table.Cell>
                            <Table.Cell collapsing>
                                Evaluation Questions <HelpPopup message={`
                                Use our recommend evaluation questions or add your own. Just select from the list and make inactive any question you don’t want to use. Add new questions if needed. You can also start from scratch and create all new question sets based on your companies needs.
                                `}/>
                            </Table.Cell>
                            <Table.Cell collapsing>
                                <Button circular primary size='tiny'
                                        onClick={() => {
                                            this.save({questions: true});
                                            this.push("/orgadmin/evaluation-questions");
                                        }}>
                                    Setup
                                </Button>
                                &nbsp;
                                &nbsp;
                                &nbsp;
                                <Button circular primary basic size='tiny' disabled={questions}
                                        onClick={() => this.save({questions: true})}>
                                    Use Defaults
                                </Button>
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
                <br/>
                <Button circular secondary floated={"right"} onClick={() => this.save({open: false})}
                        disabled={!(firm && evaluatees && users && regions && practice_areas && questions && org_type)}>
                    Setup Completed. Close Checklist.
                </Button>
                <Button circular primary floated={"right"} basic onClick={() => this.save({
                    firm: true,
                    evaluatees: true,
                    users: true,
                    regions: true,
                    practice_areas: true,
                    questions: true,
                    open: false
                })}>
                    Skip Checklist. I will setup on my own.
                </Button>
                <br/>
                <br/>
            </Segment>
        );
    }
}

SetupCheckList.propTypes = {
    org: PropTypes.object.isRequired
};

export default withRouter(connect(state => ({...state.checklist, ...roles(state)}))(SetupCheckList));
