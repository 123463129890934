import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Form, Input} from "semantic-ui-react";
import {ORG_TYPES} from "../../../../helper/const";
import helper from "../../../../helper/helper";

class OrgSetupForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            type: ORG_TYPES.CLIENT,
            diversity_tracking: true,
            location_tracking: true
        };
        this.onChange = this.onChange.bind(this);
    }

    onChange = e => {
        this.setState({[e.target.name]: e.target.value});
    };

    render() {
        const {loading, onSubmit} = this.props;
        const {name, type, diversity_tracking, location_tracking} = this.state;
        return (
            <Form style={{minHeight: "248px"}}>
                <Form.Field>
                    <label>Organization Name</label>
                    <Input name={'name'} value={name} onChange={this.onChange}/>
                </Form.Field>
                <Form.Dropdown label='Organization Type'
                               selection
                               onChange={(_, {value}) => this.setState({type: value})}
                               name={"type"}
                               value={type}
                               options={helper.orgTypes}/>
                <Form.Dropdown label='Diversity Tracking'
                               selection onChange={(_, {value}) => this.setState({diversity_tracking: value})}
                               name={"diversity_tracking"}
                               value={diversity_tracking}
                               options={[
                                   {
                                       text: "Yes, I want to include diversity tracking",
                                       value: true
                                   },
                                   {
                                       text: "Not Now (This can be changed later)",
                                       value: false
                                   }
                               ]}/>
                <Form.Dropdown label='Location Type'
                               selection onChange={(_, {value}) => this.setState({location_tracking: value})}
                               name={"location_tracking"}
                               value={location_tracking}
                               options={[
                                   {
                                       text: "Yes, I want to include reporting for my office locations",
                                       value: true
                                   },
                                   {
                                       text: "Not Now (This can be changed later)",
                                       value: false
                                   }
                               ]}/>
                <Button circular color={"teal"} floated='right'
                        disabled={loading || !name}
                        loading={loading}
                        onClick={() => onSubmit(this.state)}>
                    <span style={{margin: "auto 2.5em"}}>Next</span>
                </Button>
                <br/>
                <br/>
            </Form>
        );
    }
}

OrgSetupForm.propTypes = {
    loading: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired
};

export default OrgSetupForm;
