import React, {Component} from 'react';
import {orgState} from "../../../../helper/stateUtil";
import {connect} from "react-redux";
import Body from "../../../other/Body";
import {OrgMenuBar, reportsPath} from "../other/OrgMenuBar";
import OrgSubHeader from "../other/OrgSubHeader";
import {Button} from "semantic-ui-react";
import ListLawyers from "../../list/lawyers/ListLawyers";
import lawyerAction from "../../../../actions/lawyer.action";

class LawyersPage extends Component {
    componentDidMount() {
        this.props.dispatch(lawyerAction.readAllEvaluatees({org_id: this.props.org.id}));
    }

    render() {
        const {loading, lawyers, org, history} = this.props;
        return (
            <Body loading={loading}>

            <OrgMenuBar/>

            <OrgSubHeader title={`Evaluatees`} backArrow={false}>
                <Button floated='right' color={"teal"} circular
                        onClick={() => this.props.history.push(`${reportsPath}/lawyers`)}>
                    Evaluatee Rankings</Button>

            </OrgSubHeader>

            <ListLawyers
                lawyers={lawyers}
                org={org}
                onClick={(lawyer) => {
                    history.push(`/lawyers/${lawyer.id}/evaluations?h=${lawyer.name}`);
                }}/>

            </Body>
        );
    }
}

function mapStateToProps(state) {
    let s = orgState(state);
    return {...s, ...state.lawyer};
}

const cLawyersPage = connect(mapStateToProps)(LawyersPage);
export {cLawyersPage as LawyersPage};