import React, {Component} from 'react';
import helper from "../../../../helper/helper";
import {Button, Form, Header, Icon, Message, Segment} from "semantic-ui-react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import userAction from "../../../../actions/user.action";
import {SUPER_ADMIN} from "../../../../helper/const";
import QMCheckBox from "../../../other/form/QMCheckBox";

class AddUserForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            name: "",
            status: true,
            roles: this.props.orgid ? [] : [SUPER_ADMIN]
        };
        this.onChange = this.onChange.bind(this);
        this.onSelectPlan = this.onSelectPlan.bind(this);
        this.onSave = this.onSave.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.isDone) {
            this.props.history.go(-1);
        }
    }

    onSave(e) {
        e.preventDefault();
        const {roles, name, email, status} = this.state;
        this.props.dispatch(userAction.create({
            org_id: this.props.orgid,
            email: email, status: status, name: name, roles: roles,
        }));
    }

    onChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    onSelectPlan(e, {id}) {
        if (this.state.roles.includes(id)) {
            this.setState((prevState) => ({roles: [...prevState.roles.filter(role => role !== id)]}));
        } else {
            this.setState((preState) => ({roles: [...preState.roles, id]}));
        }
    }

    render() {
        const {error, orgid} = this.props;
        const {roles, name, email} = this.state;
        return (
            <Segment basic padded='very'>
                <Form>
                    <Message color='red' hidden={error.length === 0}>
                        <Message.List>
                            {error.map((item, index) => <Message.Item key={index}>{item}</Message.Item>)}
                        </Message.List>
                    </Message>
                    <br/>
                    <Form.Group>
                        <Form.Field width={8}>
                            <label>Name</label>
                            <input placeholder='Name' onChange={this.onChange} name={"name"}/>
                        </Form.Field>
                        <Form.Field width={8}>
                            <label>Email</label>
                            <input placeholder='Email' onChange={this.onChange} type="email" name={"email"}/>
                        </Form.Field>
                    </Form.Group>
                    <Form.Field>
                        <label>Status</label>
                        <QMCheckBox toggle onChange={(checked) => this.setState({status: checked})}
                                    checked={this.state.status}/>
                    </Form.Field>
                    <Header as="h3">Roles</Header>
                    <br/>

                    {
                        orgid ? helper.roles.map((role, index) => {
                                const active = roles.includes(role.id);
                                return <Button circular size='huge' basic={!active}
                                               style={{textAlign: "left", marginTop: "4px"}} key={index}
                                               secondary={active} onClick={this.onSelectPlan}
                                               id={role.id}>
                                    {active && <Icon name="check circle" color={"white"}/>} {role.title}
                                </Button>
                            }
                        ) : <Button size='huge' circular basic={!roles.includes(SUPER_ADMIN)}
                                    style={{textAlign: "left", marginTop: "4px"}}
                                    secondary={roles.includes(SUPER_ADMIN)} onClick={this.onSelectPlan}
                                    id={SUPER_ADMIN}>
                            <Icon name="check circle"/> Qualmet Administrator
                        </Button>
                    }

                    <br/>
                    <br/>
                    <Button type='submit' primary onClick={this.onSave} floated='right' circular
                            disabled={!(email && name && roles.length > 0)}>Create</Button>
                    <Button type='button' secondary inverted floated='right' circular
                            onClick={() => this.props.history.go(-1)}>Cancel</Button>
                    <br/>
                </Form>
            </Segment>
        );
    }
}

function mapStateToProps(state) {
    return {
        ...state.user
    };
}

const cAddUserForm = withRouter(connect(mapStateToProps)(AddUserForm));
export {cAddUserForm as AddUserForm};
