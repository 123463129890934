import React, {Component} from 'react';
import {Button, Form, Modal, Segment} from "semantic-ui-react";
import helper from "../../../../../helper/helper";
import PropTypes from "prop-types";
import ErrorMessage from "../../../../other/ErrorMessage";

class ExportRequestForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: null
        };
    }

    render() {
        const {type} = this.state;
        const {error, loading, org} = this.props;
        return (
            <Modal size={"tiny"} open
                   closeOnDimmerClick={false}>
                <Modal.Header>Create Request</Modal.Header>
                <Modal.Content>
                    <Segment basic>
                        <Form>
                            <ErrorMessage errors={error}/>
                            <br/>
                            <Form.Dropdown label='Evaluation Type:'
                                           selection value={type}
                                           onChange={(e, {value}) => this.setState({type: value})}
                                           name={"type"}
                                           options={helper.evaluationTypes(org).filter(e => e.value !== -1)}/>
                        </Form>
                    </Segment>
                </Modal.Content>
                <Modal.Actions>
                    <Button secondary circular onClick={() => this.props.onClose()}
                            disabled={loading}>Cancel</Button>
                    <Button color={"teal"} circular content='Submit'
                            type="submit"
                            loading={loading}
                            onClick={() => {
                                this.props.onSave({
                                    ...this.state
                                });
                            }}
                            disabled={(loading) || (!type)}/>
                </Modal.Actions>
            </Modal>
        );
    }
}

ExportRequestForm.propTypes = {
    org: PropTypes.object.isRequired,
    onSave: PropTypes.func.isRequired,
};

export default ExportRequestForm;
