import React, {Component} from 'react';
import ColoredBody from "../../other/ColoredBody";
import BoxFooter from "../../other/BoxFooter";
import {Button, Header, Segment} from "semantic-ui-react";
import HeaderLogo from "../../other/HeaderLogo";
import authAction from "../../../actions/auth.action";
import {connect} from "react-redux";
import qs from "query-string";
import ErrorMessage from "../../other/ErrorMessage";
import FullScreenLoader from "../../other/FullScreenLoader";

class LoginCallbackPage extends Component {
    constructor(props) {
        super(props);
        this.params = qs.parse(props.location.search);
    }

    componentDidMount() {
        const {token} = this.params;
        if (token) {
            this.props.dispatch(authAction.loginViaToken({token}));
        }
    }

    componentWillUnmount() {
        this.props.dispatch(authAction.unmount());
    }

    componentWillReceiveProps(nextProps) {
        const {loading, isDone, history} = nextProps;
        if (loading) return;
        if (isDone)
            history.replace("/");
    }

    render() {
        const {loading, error, history} = this.props;
        const {token} = this.params;
        return (
            <ColoredBody>
                <FullScreenLoader active={loading} message={"processing..."}/>
                <Segment padded>
                    <HeaderLogo darkLogo/>
                    <Segment basic textAlign={'center'}>

                        <ErrorMessage errors={error}/>

                        {!token && <Header as={'h1'}>
                            Invalid Request!
                            <br/>
                            <br/>
                            <small>
                                Please try again or login with email and password.
                            </small>
                        </Header>}

                        {loading ?
                            <Header as={'h1'}>
                                Do not press back or refresh!
                            </Header>
                            :
                            <Button type='submit' color='teal' circular size='large'
                                    style={{marginTop: "2em"}}
                                    onClick={() => history.replace("/login")}
                                    loading={loading}
                                    disabled={loading}>
                                Login
                            </Button>
                        }
                        <br/>
                    </Segment>
                </Segment>
                <BoxFooter/>
            </ColoredBody>
        );
    }
}

function mapStateToProps(state) {
    return state.auth;
}

const cLoginCallbackPage = connect(mapStateToProps)(LoginCallbackPage);
export {cLoginCallbackPage as LoginCallbackPage};
