import React from 'react';
import {Redirect, Route} from "react-router-dom";
import helper from "../../helper/helper";
import ReactGA from "react-ga";

export const PublicRoute = ({component: Component, role, ...rest}) => (
    <Route {...rest} render={props => {
        if (helper.isLoggedIn()) {
            ReactGA.pageview("/");
            return <Redirect to={{pathname: '/', state: {from: props.location}}}/>;
        } else {
            ReactGA.pageview(rest.path);
            return <Component {...props} />;
        }
    }}/>
);