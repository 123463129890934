import React, {Component} from 'react';
import {connect} from "react-redux";
import {EditPracticeAreaForm} from "../../form/practiceareas/EditPracticeAreaForm";
import SABody from "../other/SABody";
import SASubHeader from "../other/SASubHeader";

class SuperAdminManagePracticeAreaPage extends Component {
    render() {
        const {loading} = this.props;
        return (
            <SABody loading={loading}>
                <SASubHeader title={"Edit Practice Area"}/>
                <EditPracticeAreaForm pid={this.props.match.params.id}/>
            </SABody>


        );
    }
}

function mapStateToProps(state) {
    return {
        ...state.practice
    };
}

const cSuperAdminManagePracticeAreaPage = connect(mapStateToProps)(SuperAdminManagePracticeAreaPage);
export {cSuperAdminManagePracticeAreaPage as SuperAdminManagePracticeAreaPage};