import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {ProgressBar, Step} from "react-step-progress-bar";
import './../../css/eval-progress-bar.css';
import "react-step-progress-bar/styles.css";
import {Container} from "semantic-ui-react";
import themeConfig from "../../helper/themeConfig";

class EvalProgressBar extends Component {
    constructor(props) {
        super(props);
        this.getStepPosition = this.getStepPosition.bind(this);
    }

    getStepPosition = (steps, step) => {
        return (100 / (steps - 1)) * (step - 1);
    };

    render() {
        const {total, current, onSelect} = this.props;
        return (
            <Container text>
                <style>{`
                    .RSPBprogressBar .RSPBstep {
                        cursor: ${onSelect ? "pointer" : "default"}
                    }
                `}</style>
                <ProgressBar percent={this.getStepPosition(total, current)}
                             filledBackground={themeConfig.primary} unfilledBackground={"#cacbcd"}>
                    {[...Array(total)].map((_, index) => (
                        <Step transition="scale" key={index}>
                            {({accomplished, index}) => (
                                <div className={`indexedStep ${accomplished ? "accomplished" : null}`}
                                     onClick={() => onSelect && onSelect(current > index ? index : -1)}>
                                    {index + 1}
                                </div>
                            )}
                        </Step>
                    ))}
                </ProgressBar>
            </Container>
        );
    }
}

EvalProgressBar.propTypes = {
    total: PropTypes.number.isRequired,
    current: PropTypes.number.isRequired,
    onSelect: PropTypes.func
};

export default EvalProgressBar;
