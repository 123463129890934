import api from "../helper/api";

function validate() {
    return api.instance().post("validate");
}

function login(user) {
    return api.instance().post("login", user);
}

function loginWithToken(data) {
    return api.instance().post("login-with-token", data);
}

function loginWithID(data) {
    return api.instance().post("login-with-id", data);
}

function loginViaToken(data) {
    return api.instance().post("login-via-token", data);
}

function isSSOUser(data) {
    return api.instance().post("is-sso-user", data);
}

function logout() {
    return api.instance().post("logout");
}

function forgotPassword(data) {
    return api.instance().post("initiate-reset", data);
}

function resetPassword(data) {
    return api.instance().post("reset-password", data);
}

function join(data) {
    return api.instance().post('user/verify-update', data);
}

// for self registration
function register(data) {
    return api.instance().post('register', data);
}

function validateAccount(data) {
    return api.instance().post('validate-account', data);
}

function isFirstUser(data) {
    return api.instance().post('is-fu', data);
}

function getEmailOfUser(data) {
    return api.instance().post('get-email-of-user', data);
}

function createOrg(data) {
    return api.instance().post('register-organization', data);
}

function resendVerificationCode(data) {
    return api.instance().post('resend-email-verification-code', data);
}

function contactUs(data) {
    return api.instance().post('contact', data);
}

const authService = {
    validate,
    login,
    loginWithToken,
    loginWithID,
    isSSOUser,
    logout,
    forgotPassword,
    resetPassword,
    join,
    register,
    validateAccount,
    isFirstUser,
    getEmailOfUser,
    createOrg,
    resendVerificationCode,
    contactUs,
    loginViaToken
};

export default authService;
