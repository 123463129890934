import React, {Component} from 'react';
import {Button, Form, Header, Icon, Segment, Table} from "semantic-ui-react";
import {connect} from "react-redux";
import evaluationQuestionAction from "../../../../actions/evaluation_question.action";
import helper from "../../../../helper/helper";
import FinalQuestionFormModal from "./FinalQuestionFormModal";
import SABody from "../other/SABody";
import SASubHeader from "../other/SASubHeader";

// This page is for list out all questions of evaluation questions's set's group
class SAListFinalQuestionsPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false, // flag for modal open and close
            question: null,
            filter: 1
        };
        this.onOpenModal = this.onOpenModal.bind(this);
    }

    onOpenModal(question) {
        if (question != null) {
            this.setState({question: question, open: true}); // for edit
        } else {
            this.setState({question: null, open: true}); // for add
        }
    }

    componentDidMount() {
        this.props.dispatch(evaluationQuestionAction.getAllFinalQuestions({
            id: this.props.match.params.id
        }));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.props.eq.isAllowed) {
            this.props.history.replace("/forbidden");
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.eq.isDone) {
            this.setState({question: null, open: false});
        }
    }

    render() {
        let {loading, items, error} = this.props.eq;
        const {open, question, filter} = this.state;
        items = items.filter((item) => item.eval_type === filter);
        let content;
        const types = helper.getFinalQueType();
        if (items.length > 0) {
            content = (
                <Segment padded basic>
                    <Table basic='very' selectable stackable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Quesiton</Table.HeaderCell>
                                <Table.HeaderCell></Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {items.map((item, index) => {
                                let t = types.find((t) => t.value === item.type) || {};
                                return (
                                    <Table.Row className={"row"} key={index}>
                                        <Table.Cell style={{paddingLeft: "16px"}}>
                                            <Icon name="question circle" color={"grey"} size={"large"}/>
                                            <span><b>{item.question}</b></span>
                                            <br/>
                                            <span style={{paddingLeft: "68px"}}>
                                                <small>
                                                    Question Type: {t.text}
                                                </small>
                                            </span>
                                        </Table.Cell>
                                        <Table.Cell collapsing>
                                            <Form>
                                                <Form.Group inline>
                                                    <Form.Field>
                                                        <Button icon={"pencil"} circular color={"teal"}
                                                                title={"Edit Question Set"} size={"mini"}
                                                                onClick={() => {
                                                                    this.onOpenModal(item)
                                                                }}/>
                                                    </Form.Field>
                                                </Form.Group>
                                            </Form>
                                        </Table.Cell>
                                    </Table.Row>
                                );
                            })}
                        </Table.Body>
                    </Table>
                </Segment>
            );
        } else {
            content = (
                <Segment basic placeholder piled>
                    <Header icon>
                        <Icon name='file outline'/>
                        No questions are listed.
                    </Header>
                    <Button color={"teal"} circular
                            onClick={() => this.onOpenModal(null)}>
                        Add Question
                    </Button>
                </Segment>
            );
        }
        return (
            <SABody loading={loading}>
                <SASubHeader title={"Final questions"} backArrow>
                    <Button circular size={"medium"} disabled={items.length === 5}
                            onClick={() => this.onOpenModal(null)}
                            title="Add User" floated="right" color={"teal"}>Add Question</Button>
                </SASubHeader>
                <Segment basic padded>
                    <Form>
                        <Form.Group>
                            <Form.Dropdown selection value={this.state.filter}
                                           label={"Final Question Type"}
                                           onChange={(e, {value}) => this.setState({filter: value})}
                                           name={"type"}
                                           options={[
                                               {text: "Matter and Evaluatee", value: 1},
                                               {text: "Firm/Client", value: 2}
                                           ]}/>
                        </Form.Group>
                    </Form>
                </Segment>
                {content}

                {open && <FinalQuestionFormModal
                    type={this.state.filter === 2 ? "firm" : "matter-evaluatee"}
                    question={question}
                    error={error}
                    ignoreTypes={items.map((item) => item.type)}
                    loading={loading}
                    onSave={(value) => {
                        if (question) {
                            this.props.dispatch(evaluationQuestionAction.updateFinalQuestion({
                                ...value,
                                eval_type: filter
                            }));
                        } else {
                            this.props.dispatch(evaluationQuestionAction.addFinalQuestion({
                                qs_id: this.props.match.params.id,
                                ...value,
                                eval_type: filter
                            }));
                        }
                    }}
                    onClose={() => {
                        this.setState({open: false, question: null});
                    }}
                />}
            </SABody>
        );
    }
}

function mapStateToProps(state) {
    return {eq: state.evaluation_question};
}

const cSAListFinalQuestionsPage = connect(mapStateToProps)(SAListFinalQuestionsPage);
export {cSAListFinalQuestionsPage as SAListFinalQuestionsPage};