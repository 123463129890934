import api from "../helper/api";

function add(data) {
    return api.instance().post("org/store", data);
}

function update(data) {
    data._method = "PUT";
    return api.instance().put("org/update", data);
}

function updateSettings(data) {
    data._method = "PUT";
    return api.instance().put("org/update-settings", data);
}

function updateOrgType(data) {
    data._method = "PUT";
    return api.instance().put("org/update-org-type", data);
}

function deleteOrg(data) {
    data._method = "PUT";
    return api.instance().put("org/destroy", data);
}

function readAll(flag) {
    return api.instance().post(flag ? "pending-organization" : "org/get-all");
}

function read(data) {
    return api.instance().post("org/get", data);
}

function activateOrg(data) {
    return api.instance().post("update-pos", data);
}

function getSSODetails(data) {
    let form = new FormData();
    Object.entries(data).forEach((d) => {
        form.append(d[0], d[1]);
    });
    return api.instance().post("org/sso", form);
}

function updateSSODetails(data) {
    // let form = new FormData();
    // Object.entries(data).forEach((d) => {
    //     form.append(d[0], d[1]);
    // });
    return api.instance().post("org/sso/update", data);
}

function updateSettingSSODetails(data) {
    // let form = new FormData();
    // Object.entries(data).forEach((d) => {
    //     form.append(d[0], d[1]);
    // });
    return api.instance().post("org/settings/sso/update", data);
}

function getDomains(data) {
    return api.instance().post("org/get-domains", data);
}

function getAllPlans(data) {
    return api.instance().post("org/get-active-plans", data);
}

function updateDomains(data) {
    data._method = "PUT";
    return api.instance().put("org/update-domains", data);
}

function parseMetadata(data) {
    let form = new FormData();
    Object.entries(data).forEach((d) => {
        form.append(d[0], d[1]);
    });
    return api.instance().post("sso/metadata-parse", form);
}

const orgService = {
    add,
    update,
    readAll,
    read,
    // archive,
    updateSettings,
    updateOrgType,
    activateOrg,
    getSSODetails,
    updateSSODetails,
    updateSettingSSODetails,
    getDomains,
    updateDomains,
    parseMetadata,
    getAllPlans,
    deleteOrg
};

export default orgService;
