/* logging into browser */
import storage from "./storage";
import {
    ANY_ROLE,
    AUTH_KEY,
    E_QUESTION_TYPE,
    EVALUATION_STATUS,
    EVALUATION_TYPE,
    EVALUATOR,
    FINAL_QUESTION_TYPE,
    HELP_CATS,
    MATTER,
    ORG_ADMIN,
    ORG_PLANS,
    ORG_SUB_STATUS,
    ORG_TYPES,
    PLAN_DURATION_TYPE,
    PLAN_TYPE,
    REPORT_VIEWER,
    SUB_USER,
    SUPER_ADMIN
} from "./const";
import api from "./api";
import moment from "moment/moment";

// const authRoles = storage.get(ROLE_KEY, true);

export function log(...value) {
    if (typeof value === "undefined") {
        throw new Error("value can not undefined!");
    }
    if (process.env.NODE_ENV !== "production") {
        console.log(...value);
    }
}

function isLoggedIn() {
    try {
        return storage.has(AUTH_KEY);
    } catch (e) {
        log(e);
    }
    return false;
}

function hasPermission(type) {
    if (type === undefined) {
        throw new Error("role must be required");
    }
    const roles = userRoles();
    if (roles.includes(SUPER_ADMIN))
        return true;
    if (Array.isArray(type)) {
        for (let i = 0; type.length > i; i = i + 1) {
            if (type[i] === ANY_ROLE)
                return true;
            if (roles.includes(type[i]))
                return true;
        }
    } else {
        if (type === ANY_ROLE)
            return true;
        if (roles.includes(SUPER_ADMIN))
            return true;
        return roles.includes(type);
    }
    return false;
}

function logout() {
    try {
        storage.reset();
        api.init();
        if (window.location.pathname !== "/login") {
            window.location = "/login";
        }
    } catch (e) {
        log(e);
    }
}

function logoutSubUser() {
    try {
        storage.remove(SUB_USER);
    } catch (e) {
        log(e);
    }
}

function user() {
    try {
        const value = storage.get(AUTH_KEY, true);
        if (value) {
            return value;
        }
    } catch (e) {
        log(e);
    }
    return null;
}

function subUser() {
    try {
        const value = storage.get(SUB_USER, true);
        if (value) return value;
    } catch (e) {
        log(e);
    }
    return null;
}

function userRoles() {
    try {
        const subUser = storage.get(SUB_USER, true);
        if (subUser) {
            return subUser.roles;
        }
        const user = storage.get(AUTH_KEY, true);
        if (user) {
            return user.roles;
        }
    } catch (e) {
        log(e);
    }
    return [];
}

function storeLogin(user) {
    try {
        if (typeof user === 'undefined') {
            throw new Error("User can not undefined!");
        }
        if (typeof user !== 'object') {
            throw new Error("user must be object!");
        }
        api.init(user);
        storage.set(AUTH_KEY, user);
    } catch (e) {
        log(e);
    }
}

function init() {
    log("####################################### Qualmet App #######################################");
    api.init();
    storage.migrations();
}

// function validateEmail(email) {
//     const re = new RegExp('/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/');
//     return re.test(String(email).toLowerCase());
// }
//
// function validatePassword(str) {
//     const re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
//     return re.test(str);
// }

// if path is login or forgot-password or reset-password are considered as unauth path because this path not need loggedin
function isUnAuthPath() {
    const path = window.location.pathname;
    if (path.includes("reset-password")
        || path.includes("join-qualmet")) {
        return false;
    } else
        switch (path) {
            case "/login":
            case "/forgot-password":
                return false;
            default:
                return true;
        }
}

function dateFormat(date) {
    return moment(date).format("Y-MM-DD");
}

function formatToDate(formattedDate) {
    return moment(formattedDate, "Y-MM-DD").toDate();
}

const downloadFile = function (sUrl) {

    //iOS devices do not support downloading. We have to inform user about this.
    if (/(iP)/g.test(navigator.userAgent)) {
        //alert('Your device does not support files downloading. Please try again in desktop browser.');
        window.open(sUrl, '_blank');
        return false;
    }

    const isChrome = navigator.userAgent.toLowerCase().indexOf('chrome') > -1;
    const isSafari = navigator.userAgent.toLowerCase().indexOf('safari') > -1;

    //If in Chrome or Safari - download via virtual link click
    if (isChrome || isSafari) {
        //Creating new link node.
        let link = document.createElement('a');
        link.href = sUrl;
        link.setAttribute('target', '_blank');

        if (link.download !== undefined) {
            //Set HTML5 download attribute. This will prevent file from opening if supported.
            link.download = sUrl.substring(sUrl.lastIndexOf('/') + 1, sUrl.length);
        }

        //Dispatching click event.
        if (document.createEvent) {
            let e = document.createEvent('MouseEvents');
            e.initEvent('click', true, true);
            link.dispatchEvent(e);
            return true;
        }
    }

    // Force file download (whether supported by server).
    if (sUrl.indexOf('?') === -1) {
        sUrl += '?download';
    }

    window.open(sUrl, '_blank');
    return true;
};

function getAnsOptions(type) {
    let common = [
        {
            title: "Significantly Deficient at Best",
            i_code: 1,
            value: 1,
            quote: "Terrible",
            desc: "Overall performance unsatisfactory – significant improvement <u>required</u>"
        },
        {
            title: "So-So",
            i_code: 2,
            value: 2,
            quote: "Poor",
            desc: "Overall performance does not meet expectations - <u>some</u> improvement needed in multiple areas"
        },
        {
            title: "Expectations Met",
            i_code: 3,
            value: 3,
            quote: "Fair",
            desc: "Performance <u>consistently</u> meets expectations but may exceed expectations in one or more areas"
        },
        {
            title: "Shined",
            i_code: 4,
            value: 4,
            quote: "Good",
            desc: "Performance consistently <u>exceeds</u> expectations in multiple areas"
        },
        {
            title: "Best In Class",
            i_code: 5,
            value: 5,
            quote: "Excellent",
            desc: "Top performer/ROle models for others"
        }
    ];
    switch (type) {
        case E_QUESTION_TYPE.ANSWER_TYPE_NS_S: // "Not at all Satisfied to Completely Satisfied"
        case E_QUESTION_TYPE.ANSWER_TYPE_U_E: // "Unsatisfactory to Exceptional"
            return common;
        case E_QUESTION_TYPE.ANSWER_TYPE_U_ENA: // "Unsatisfactory to Exceptional with N/A"
            common.push({
                title: "NA",
                i_code: "NA",
                value: 0,
                quote: "Not Applicable",
                desc: ""
            });
            return common;
        case E_QUESTION_TYPE.ANSWER_TYPE_F_NL_EL: // 0 to 10 rating
        case E_QUESTION_TYPE.ANSWER_TYPE_LV_HV: // LV to HV rating
            return [...Array(11)];
        default:
            return [];
    }
}

function getListOfAnswerType() {
    return [
        {
            text: "Rating 1 to 5 with N/A",
            value: E_QUESTION_TYPE.ANSWER_TYPE_U_ENA
        }, {
            text: "Rating 1 to 5 ",
            value: E_QUESTION_TYPE.ANSWER_TYPE_U_E
        }
        // , {
        //     text: "Write in answer",
        //     value: E_QUESTION_TYPE.ANSWER_TYPE_TEXT_ONLY
        // }
    ];
}

function getStrAnsType(type) {
    switch (type) {
        case E_QUESTION_TYPE.ANSWER_TYPE_U_E:
            return "Rating 1 to 5";
        case E_QUESTION_TYPE.ANSWER_TYPE_U_ENA:
            return "Rating 1 to 5 with N/A";
        // case E_QUESTION_TYPE.ANSWER_TYPE_NS_S:
        //     return "Not at all Satisfied to Very Satisfied";
        case E_QUESTION_TYPE.ANSWER_TYPE_F_NL_EL:
            return "Not at all likely to Extremely likely - 0 to 10";
        case E_QUESTION_TYPE.ANSWER_TYPE_TEXT_ONLY:
            return "Write in answer";
        default:
            return "Unknown Type!";
    }
}

function getStrTypeOfQS(type) {
    switch (type) {
        case EVALUATION_TYPE.SELF_EVALUATION:
            return "Self Evaluation";
        case EVALUATION_TYPE.EXTERNAL_EVALUATION:
            return "External Evaluation";
        default:
            return "Unknown Type";
    }
};

function getFinalQueType() {
    return [
        {
            text: "Confirmation Question",
            value: FINAL_QUESTION_TYPE.QUE_CONFIRMATION
        },
        {
            text: "Would Recommend Question",
            value: FINAL_QUESTION_TYPE.QUE_WOULD_RECOMMEND
        },
        {
            text: "Suggestion Question: If Answer is in between 0 & 8",
            value: FINAL_QUESTION_TYPE.QUE_SUGGESTION_0_8
        },
        {
            text: "Suggestion Question: If Answer is 9 or 10",
            value: FINAL_QUESTION_TYPE.QUE_SUGGESTION_9_10
        },
        {
            text: "Value for Money Question",
            value: FINAL_QUESTION_TYPE.QUE_VALUE
        }
    ];
}

function getStrSize(size) {
    let temp = 0;
    if (size < 1024) {
        return size + " Bytes";
    } else if ((temp = size / 1024) < 1024) {
        return Math.round(temp) + " KB";
    } else if ((temp = (size / (1024 * 1024))) < 1024) {
        return Math.round(temp) + " MB";
    } else if ((temp = (size / (1024 * 1024 * 1024))) < 1024) {
        return Math.round(temp) + " GB";
    }
    return size;
}

// const plans = ["Extra-lite Plan", "Lite Plan", "Full Plan", "Mass Mutual", "FedEx", "Denton's"];
const plans = [{text: "Level 1", title: "Level 1", value: ORG_PLANS.LEVEL_1}];

const orgTypes = [
    {text: "Client - Our company uses Law Firm services", value: ORG_TYPES.CLIENT},
    {text: "Firm - Our company is a Law Firm", value: ORG_TYPES.FIRM}
];

const roles = [
    {title: "Report viewer", id: REPORT_VIEWER},
    {title: "Evaluator", id: EVALUATOR},
    {title: "Add/Edit Evaluations", id: MATTER},
    {title: "Organization Administrator", id: ORG_ADMIN}
];

const evaluationCycleStatus = [
    {text: "All", value: EVALUATION_STATUS.ALL},
    {text: "Pending", value: EVALUATION_STATUS.PENDING},
    {text: "Due", value: EVALUATION_STATUS.DUE},
    {text: "Started", value: EVALUATION_STATUS.STARTED},
    {text: "Completed", value: EVALUATION_STATUS.COMPLETED},
    {text: "Rejected", value: EVALUATION_STATUS.REJECTED}
];

const helpMenus = [
    {icon: 'book', text: 'User Guides', value: HELP_CATS.USER_GUIDES},
    {icon: 'video play', text: 'Videos', value: HELP_CATS.VIDEOS},
    {icon: 'list ul', text: 'FAQs', value: HELP_CATS.FAQS},
];

const matterOrClientStatus = [
    {text: "All", value: -1},
    {text: "Open", value: true},
    {text: "Close", value: false}
];

const diversityChart = [
    {ratio: "0% to 10%", value: 1, title: "Needs Improvement"},
    {ratio: "11% to 30%", value: 2, title: "Can do better"},
    {ratio: "31% to 45%", value: 3, title: "Trending in the right direction"},
    {ratio: "46% to 60%", value: 4, title: "Now we are talking"},
    {ratio: "61% to 100%", value: 5, title: "Industry Leaders"},
];

const pricePlanTypes = [
    {text: 'Normal Plan', value: PLAN_TYPE.NORMAL},
    {text: 'Free Trial Plan', value: PLAN_TYPE.TRIAL},
    // {text: 'Get Quote Plan', value: PLAN_TYPE.QUOTE},
];

const planDurationTypes = [
    {text: "Day(s)", value: PLAN_DURATION_TYPE.DAY},
    {text: "Month(s)", value: PLAN_DURATION_TYPE.MONTH},
    {text: "Year(s)", value: PLAN_DURATION_TYPE.YEAR},
];

const subStatus = [
    {text: "Pending", value: ORG_SUB_STATUS.PENDING},
    {text: "Canceled", value: ORG_SUB_STATUS.CANCELED},
    {text: "Denied", value: ORG_SUB_STATUS.DENIED},
    {text: "Refunded", value: ORG_SUB_STATUS.REFUNDED},
    {text: "Invoiced", value: ORG_SUB_STATUS.INVOICED},
    {text: "Paid", value: ORG_SUB_STATUS.PAID},
];

const evaluationTypes = (org) => [
    {text: "All Evaluation", value: -1},
    {text: "Matter Evaluation", value: "me"},
    {text: org.str_client_firm + " Evaluation", value: "fe"},
    {text: "Evaluatee Evaluation", value: "ee"},
];

const getStatusOfExportData = status => {
    switch (status) {
        case 1:
            return "Requested";
        case 2:
            return "In Process";
        case 3:
            return "Completed";
        case 4:
            return "Insufficient Data";
        case 0:
            return "Something while exporting data.";
        default:
            return "Unknown";
    }
};

const copyright = " 2019 QUALMET LEGAL";

const helper = {
    init,
    isLoggedIn,
    hasPermission,
    downloadFile,
    logout,
    logoutSubUser,
    userRoles,
    user,
    subUser,
    storeLogin,
    getAnsOptions,
    isUnAuthPath,
    dateFormat,
    formatToDate,
    getListOfAnswerType,
    getStrTypeOfQS,
    getStrAnsType,
    getFinalQueType,
    getStrSize,
    getStatusOfExportData,
    copyright,
    plans,
    roles,
    evaluationCycleStatus,
    helpMenus,
    orgTypes,
    matterOrClientStatus,
    diversityChart,
    pricePlanTypes,
    planDurationTypes,
    subStatus,
    evaluationTypes
};

export default helper;
