import React, {Component} from 'react';
import {Icon, Segment, Table} from "semantic-ui-react";
import {connect} from "react-redux";
import {orgState} from "../../../../helper/stateUtil";
import {OrgMenuBar} from "../other/OrgMenuBar";
import Body from "../../../other/Body";
import OrgSubHeader from "../other/OrgSubHeader";

class OrgAdminPage extends Component {

    componentWillReceiveProps(nextProps) {
        if (!nextProps.org) {
            nextProps.history.replace("/");
        }
    }

    render() {
        const {org, history} = this.props;
        const menus = [
            {title: org.str_client_firm + "s", icon: "group", path: "/orgadmin/client_firm"},
            {title: "Evaluatees", icon: "user", path: "/orgadmin/lawyers"},
            {title: "Users", icon: "users", path: "/orgadmin/users"},
            {title: "Regions", icon: "map marker alternate", path: "/orgadmin/regions"},
            {title: "Practice Areas", icon: "file alternate", path: "/orgadmin/practice-areas"},
            {title: "Matters", icon: "balance", path: "/orgadmin/matters"},
            {title: "Evaluation Questions", icon: "question circle", path: "/orgadmin/evaluation-questions"},
            {title: "Evaluation Cycles", icon: "refresh", path: "/orgadmin/evaluation-cycles"},
            {title: "Export", icon: "database", path: "/orgadmin/export", is_premium: true},
            {title: "Organization Settings", icon: "setting", path: "/orgadmin/settings"},
        ];

        return (
            <Body loading={false}>

                <OrgMenuBar/>

                <OrgSubHeader title={org.name} backArrow={false}/>

                <Segment padded={"very"} basic>
                    <Table basic='very' padded selectable stackable>
                        <Table.Body>
                            {menus.map((menu, index) => {
                                return (
                                    <Table.Row className={"row"} key={index}
                                               disabled={menu.disabled}
                                               onClick={() => {
                                                   if (menu.is_premium) {
                                                       if (org.is_free_plan) {
                                                           alert("This feature is not available for free trial plan, Please upgrade your plan.");
                                                           return;
                                                       }
                                                   }
                                                   history.push(menu.path);
                                               }}>
                                        <Table.Cell style={{paddingLeft: "16px"}}>
                                            <Icon name={menu.icon} color={"grey"} size={"large"}/>
                                            <span>{menu.title}</span>
                                        </Table.Cell>
                                    </Table.Row>
                                );
                            })}
                        </Table.Body>
                    </Table>
                </Segment>
            </Body>
        );
    }
}

function mapStateToProps(state) {
    return orgState(state);
}

const connectedOrgAdminPage = connect(mapStateToProps)(OrgAdminPage);
export {connectedOrgAdminPage as OrgAdminPage};