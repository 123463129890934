export const color = {
    blue: "#00b0eb",
    red: "#d52bte",
    brown: "#d47b35",
    green: "#34b233",
    light_green: "#68a6a6",
    dark_green: "#4e8381",
    grey_98: "#fafafa",
    grey_95: "#f2f2f2",
    grey_90: "#e5e5e5",
    grey_80: "#cccccc",
    grey_70: "#b2b2b2",
    grey_50: "#7f7f7f",
    grey_20: "#333333"
};

export const margin = {
    top: 16,
    right: 50,
    bottom: 50,
    left: 72
};

export const circleR = 16;

export const transitionDuration = 750;

const funXAvg = data => {
    let total = 0;
    data.forEach((d) => {
        total += d.x;
    });
    return total / data.length;
};

const funYAvg = data => {
    let total = 0;
    data.forEach((d) => {
        total += d.y;
    });
    return total / data.length;
};

const utils = {
    funXAvg,
    funYAvg
};

export default utils;