// common
import helpService from "../services/help.service";

function request() {
    return {type: HELP.REQUEST};
}

function error(reason) {
    reason = reason.response;
    switch (reason.status) {
        case 422:
            return {type: HELP.VALIDATION_ERROR, payload: reason.data};
        case 404:
            return {type: HELP.NOT_FOUND};
        case 403:
            return {type: HELP.NOT_ALLOWED};
        default:
            return {type: HELP.ERROR, payload: reason.data};
    }
}

function create(data) {

    return dispatch => {
        dispatch(request());
        helpService.add(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        if (res.status === 201) {
            return {type: HELP.ADD_SUCCESS, payload: res.data};
        }
        return error(res);
    }

}

function update(data) {
    return dispatch => {
        dispatch(request());
        helpService.update(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        if (res.status === 202) {
            return {type: HELP.UPDATE_SUCCESS, payload: res.data};
        }
        return error(res);
    }
}

function readAll(data) {
    return dispatch => {
        dispatch(request());
        helpService.readAll(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: HELP.READ_ALL_SUCCESS, payload: res.data};
    }
}

function readAllHelp(data) {
    return dispatch => {
        dispatch(request());
        helpService.readAllHelp(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: HELP.READ_ALL_SUCCESS, payload: res.data};
    }
}

function read(data) {
    return dispatch => {
        dispatch(request());
        helpService.read(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: HELP.READ_SUCCESS, payload: res.data};
    }
}

function changeStatus(data) {
    return dispatch => {
        dispatch(request());
        helpService.changeStatus(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        if (res.status === 202) {
            return {type: HELP.CHANGE_STATUS_SUCCESS, payload: data};
        }
        return error(res);
    }
}

const helpAction = {
    create,
    update,
    readAll,
    readAllHelp,
    read,
    changeStatus
};

// constants
export const HELP = {

    REQUEST: "HELP_REQUEST",
    ERROR: "HELP_ERROR",
    NOT_FOUND: "HELP_NOT_FOUND",
    VALIDATION_ERROR: "HELP_VALIDATION_ERROR ",
    NOT_ALLOWED: "HELP_NOT_ALLOWED",

    ADD_SUCCESS: "HELP_ADD_SUCCESS",
    UPDATE_SUCCESS: "HELP_UPDATE_SUCCESS",
    READ_ALL_SUCCESS: "HELP_READ_ALL_SUCCESS",
    READ_SUCCESS: "HELP_READ_SUCCESS",
    CHANGE_STATUS_SUCCESS: "HELP_CHANGE_STATUS_SUCCESS"
};

export default helpAction;