import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Form, Segment} from "semantic-ui-react";
import QMCheckBox from "../../../other/form/QMCheckBox";
import {EVALUATION_TYPE} from "../../../../helper/const";

// props - onSave
class AddQuestionSetForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            status: true,
            type: props.defaultType || EVALUATION_TYPE.EXTERNAL_EVALUATION,
            eval_type: props.defaultEvalType || 1
        };
    }

    render() {
        const {firmClientName} = this.props;
        const {name, status, type, eval_type} = this.state;
        return (
            <Segment basic padded='very'>
                <br/>
                <Form>
                    <Form.Field width={10}>
                        <label>Question Set Title:</label>
                        <input placeholder={'Question Set Title'}
                               onChange={(e) => this.setState({name: e.target.value})} type="text"/>
                    </Form.Field>
                    <Form.Field width={10}>
                        <Form.Dropdown label='Evaluation Category:'
                                       selection value={eval_type}
                                       onChange={(e, {value}) => this.setState({eval_type: value})}
                                       options={[
                                           {text: "Matter and Evaluatee", value: 1},
                                           {text: `${firmClientName}`, value: 2}
                                       ]}/>
                    </Form.Field>
                    <Form.Field width={10}>
                        <Form.Dropdown label='Question Set Type:'
                                       selection value={type}
                                       onChange={(e, {value}) => this.setState({type: value})}
                                       options={[
                                           {text: "External Evaluation", value: EVALUATION_TYPE.EXTERNAL_EVALUATION},
                                           {text: "Self Evaluation", value: EVALUATION_TYPE.SELF_EVALUATION}
                                       ]}/>
                    </Form.Field>
                    <Form.Field>
                        <label>Status:</label>
                        <QMCheckBox toggle onChange={(checked) => this.setState({status: checked})}
                                    checked={this.state.status}/>
                    </Form.Field>
                    <Button type='submit' primary
                            onClick={() => this.props.onSave({
                                eval_type: eval_type,
                                name: name,
                                status: status,
                                type: type,
                                questions: []
                            })}
                            floated='right'
                            circular
                            disabled={!(name)}>Next</Button>
                    <br/>
                </Form>
            </Segment>
        );
    }
}

AddQuestionSetForm.defaultProps = {
    firmClientName: "Firm/Client"
};

AddQuestionSetForm.propTypes = {
    firmClientName: PropTypes.string,
    isFromMaterData: PropTypes.bool,
    defaultType: PropTypes.oneOf([EVALUATION_TYPE.EXTERNAL_EVALUATION, EVALUATION_TYPE.SELF_EVALUATION]),
    defaultEvalType: PropTypes.oneOf([1, 2]),
};

export default AddQuestionSetForm;
