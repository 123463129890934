import * as d3 from 'd3';

const wrap = (text) => {
    text.each(function () {
        let text = d3.select(this);
        let words = text.text().split(/\s+/).reverse();
        let lineHeight = 20;
        let width = parseFloat(text.attr('width'));
        let y = parseFloat(text.attr('y'));
        let x = text.attr('x');
        let anchor = text.attr('text-anchor');

        let tspan = text.text(null).append('tspan').attr('x', x).attr('y', y).attr('text-anchor', anchor);
        let lineNumber = 0;
        let line = [];
        let word = words.pop();

        while (word) {
            line.push(word);
            tspan.text(line.join(' '));
            if (tspan.node().getComputedTextLength() > width) {
                lineNumber += 1;
                line.pop();
                tspan.text(line.join(' '));
                line = [word];
                tspan = text.append('tspan').attr('x', x).attr('y', y + lineNumber * lineHeight).attr('anchor', anchor).text(word);
            }
            word = words.pop();
        }

        tspan.append("svg:title").text(text.text());
    });
};

const dotme = (text) => {
    text.each(function () {
        let text = d3.select(this);
        let words = text.text().split(/\s+/);
        let ellipsis = text.text('').append('tspan').attr('class', 'elip').text('...');
        let width = parseFloat(text.attr('width') || 250) - ellipsis.node().getComputedTextLength();
        let numWords = words.length;

        let tspan = text.insert('tspan', ':first-child').text(words.join(' '));

        // Try the whole line
        // While it's too long, and we have words left, keep removing words
        while (tspan.node().getComputedTextLength() > width && words.length) {
            words.pop();
            tspan.text(words.join(' '));
        }

        if (words.length === numWords) {
            ellipsis.remove();
        }

        tspan.append("svg:title").text(text.text());
    });
};

const textwrap = {
    dotme,
    wrap
};

export default textwrap;