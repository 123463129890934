import React, {Component} from 'react';
import {Button, Form, Header, Icon, Segment, Table} from "semantic-ui-react";
import {connect} from "react-redux";
import userAction from "../../../../actions/user.action";
import SABody from "../other/SABody";
import SASubHeader from "../other/SASubHeader";
import QMCheckBox from "../../../other/form/QMCheckBox";

class SuperAdminUsersPage extends Component {

    componentDidMount() {
        this.props.dispatch(userAction.readAll({}));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.props.user.isAllowed) {
            this.props.history.replace("forbidden");
        }
    }

    render() {
        const {loading, users} = this.props.user;
        let content;
        if (users.length > 0) {
            content = (
                <Segment padded={"very"} basic>
                    <Table basic='very' padded selectable stackable>
                        <Table.Body>
                            {users.map((user, index) => {
                                return (
                                    <Table.Row className={"row"} key={index}>
                                        <Table.Cell style={{paddingLeft: "16px"}}
                                                    onClick={() => this.props.history.push(`/superadmin/users/manage/${user.id}`)}>
                                            <Icon name="user" color={"grey"} size={"large"}/>
                                            <span><b>{user.name}</b> ({user.email})</span>
                                        </Table.Cell>
                                        <Table.Cell collapsing>
                                            <Form>
                                                <Form.Group inline>
                                                    <Form.Field>
                                                        <QMCheckBox checked={user.status}
                                                                    onChange={checked => {
                                                                        this.props.dispatch(userAction.changeStatus({
                                                                            id: user.id,
                                                                            status: checked
                                                                        }));
                                                                    }}/>
                                                    </Form.Field>
                                                </Form.Group>
                                            </Form>
                                        </Table.Cell>
                                    </Table.Row>
                                );
                            })}
                        </Table.Body>
                    </Table>
                </Segment>
            );
        } else {
            content = (
                <Segment basic placeholder piled>
                    <Header icon>
                        <Icon name='file outline'/>
                        No users are listed.
                    </Header>
                    <Button color={"teal"} circular onClick={() => this.props.history.push("/superadmin/users/add")}>
                        Add User
                    </Button>
                </Segment>
            );
        }
        return (

            <SABody loading={loading}>
                <SASubHeader title={"Admin Users"} backArrow>
                    <Button circular size={"medium"}
                            onClick={() => this.props.history.push("/superadmin/users/add")}
                            title="Add User" floated="right" color={"teal"}>Add User</Button>
                </SASubHeader>
                {content}
            </SABody>

        );
    }
}

function mapStateToProps(state) {
    return {user: state.user};
}

const cSuperAdminUsersPage = connect(mapStateToProps)(SuperAdminUsersPage);
export {cSuperAdminUsersPage as SuperAdminUsersPage};