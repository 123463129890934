import React, {Component} from 'react';
import {Button, Form, Header, Icon, Segment, Table} from "semantic-ui-react";
import {connect} from "react-redux";
import {OrgMenuBar} from "../../other/OrgMenuBar";
import evaluationQuestionAction from "../../../../../actions/evaluation_question.action";
import {QUESTION_SET_GROUP_QUESTION} from "../../../../../helper/const";
import EditQuestionModal from "../../../form/questions/modal/EditQuestionModal";
import {orgState} from "../../../../../helper/stateUtil";
import Body from "../../../../other/Body";
import OrgSubHeader from "../../other/OrgSubHeader";
import helper from "../../../../../helper/helper";
import qs from "query-string";
import QMCheckBox from "../../../../other/form/QMCheckBox";

// This page is for list out all questions of evaluation questions's set's group
class OrgQuestionPage extends Component {

    constructor(props) {
        super(props);
        this.params = qs.parse(props.location.search);
        this.state = {
            open: false, // flag for modal open and close
            question: null
        };
        this.onOpenModal = this.onOpenModal.bind(this);
    }

    onOpenModal(question) {
        if (question != null) {
            this.setState({question: question, open: true}); // for edit
        } else {
            this.setState({question: null, open: true}); // for add
        }
    }

    componentDidMount() {
        this.props.dispatch(evaluationQuestionAction.getAllActiveQueGroups());
        this.props.dispatch(evaluationQuestionAction.readAllQuestions({
            org_id: this.props.org.id,
            id: this.props.match.params.id
        }));
        this.props.dispatch(evaluationQuestionAction.readQuestionSet({
            org_id: this.props.org.id,
            id: this.props.match.params.id
        }));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.props.eq.isAllowed) {
            this.props.history.replace("/forbidden");
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.eq.isDone) {
            this.setState({question: null, open: false});
        }
    }

    render() {
        const {loading, items, error, item: group, groups} = this.props.eq;
        const {open, question} = this.state;
        let content;

        if (items.length > 0) {
            content = (
                <Segment padded={"very"} basic>
                    <Table basic='very' selectable stackable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Questions of "{this.params.h}"</Table.HeaderCell>
                                <Table.HeaderCell></Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {items.map((item, index) => {
                                return (
                                    <Table.Row className={"row"} key={index}>
                                        <Table.Cell style={{paddingLeft: "16px"}}>
                                            <Icon name="question circle outline" color={"grey"} size={"large"}/>
                                            <span><b>{item.title}</b></span>
                                            <br/>
                                            <span style={{paddingLeft: "68px"}}>
                                                <small>
                                                    Answer Type: {helper.getStrAnsType(item.answer_type)}
                                                </small>
                                            </span>
                                        </Table.Cell>
                                        <Table.Cell collapsing>
                                            <Form>
                                                <Form.Group inline>
                                                    <Form.Field>
                                                        <QMCheckBox checked={item.status}
                                                                    disabled={!(group && group.status)}
                                                                    onChange={checked => {
                                                                        this.props.dispatch(evaluationQuestionAction.changeStatus({
                                                                            ...item,
                                                                            code: QUESTION_SET_GROUP_QUESTION,
                                                                            org_id: this.props.org.id,
                                                                            status: checked
                                                                        }));
                                                                    }}/>
                                                    </Form.Field>
                                                    {!item.is_master && <Form.Field>
                                                        <Button icon={"pencil"} circular color={"grey"}
                                                                title={"Edit Question"} size={"mini"}
                                                                onClick={() => {
                                                                    this.onOpenModal(item)
                                                                }}/>
                                                    </Form.Field>}
                                                </Form.Group>
                                            </Form>
                                        </Table.Cell>
                                    </Table.Row>
                                );
                            })}
                        </Table.Body>
                    </Table>
                </Segment>
            );
        } else {
            content = (
                <Segment basic placeholder piled>
                    <Header icon>
                        <Icon name='file outline'/>
                        No questions are listed.
                    </Header>
                    <Button color={"teal"} circular
                            onClick={() => this.onOpenModal(null)}>
                        Add Question
                    </Button>
                </Segment>
            );
        }
        return (
            <div>
                <Body loading={!open && loading}>

                    <OrgMenuBar/>

                    <OrgSubHeader title={`Evaluation Questions - ${this.props.org.name}`} backArrow>
                        <Button circular size={"medium"}
                                onClick={() => this.onOpenModal(null)}
                                title="Add User" floated="right" color={"teal"}>Add Question</Button>
                    </OrgSubHeader>

                    {content}

                </Body>

                {/* add and edit question modal */}
                {open && <EditQuestionModal
                    groups={groups}
                    question={question}
                    error={error}
                    status={group && group.status}
                    loading={loading}
                    onSave={(value) => {
                        if (question) {
                            this.props.dispatch(evaluationQuestionAction.update({
                                code: QUESTION_SET_GROUP_QUESTION,
                                org_id: this.props.org.id,
                                ...value
                            }));
                        } else {
                            this.props.dispatch(evaluationQuestionAction.createQuestionSetGroupQuestion({
                                code: QUESTION_SET_GROUP_QUESTION,
                                org_id: this.props.org.id,
                                qs_id: this.props.match.params.id,
                                ...value
                            }));
                        }
                    }}
                    onClose={() => {
                        this.setState({open: false, question: null});
                    }}
                />}
            </div>
        );
    }
}

function mapStateToProps(state) {
    let s = orgState(state);
    return {...s, eq: state.evaluation_question};
}

const cOrgQuestionPage = connect(mapStateToProps)(OrgQuestionPage);
export {cOrgQuestionPage as OrgQuestionPage};