import React, {Component} from 'react';
import {Header, Segment} from "semantic-ui-react";
import ColoredBody from "../other/ColoredBody";
import BoxHeader from "../other/BoxHeader";
import BoxFooter from "../other/BoxFooter";

class PrivacyPage extends Component {
    render() {
        return (
            <ColoredBody textAlign={"center"} boxWidth={"768px"}>
                <BoxHeader/>
                <Segment>
                    <Header as={'h2'}>Qualmet Privacy Policy</Header>
                    <Segment basic padded>
                        <p style={{fontSize: "16px", fontWeight: "600", textAlign: "justify"}}>
                            Qualmet only collects information voluntarily provided on our “Contact Us” page, email,
                            phone, or other methods of electronic communication. We will not sell or provide this
                            information
                            to anyone outside of our organization. At any time if you wish us to remove your personal
                            information from our systems, please send your request
                            to <a href={"mailto:support@qualmetlegal.com"} style={{cursor: "pointer"}}>
                            support@qualmetlegal.com </a> and we will do so at our earliest convenience.
                            <br/>
                            <br/>
                            Qualmet will only contact you based on your request and the information you provided to us.
                            Unless you ask us not to, Qualmet may continue to reach out to you in the future regarding
                            our
                            products and services that you may be interested in.
                            <br/>
                            <br/>
                            We reserve the right to update this policy at any time.
                        </p>
                    </Segment>
                </Segment>
                <BoxFooter/>
            </ColoredBody>
        );
    }
}

export default PrivacyPage;