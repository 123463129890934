import React, {Component} from 'react';
import subAction from "../../../../actions/sub.action";
import SASubHeader from "../../qualmetadmin/other/SASubHeader";
import {Button, Form, Header, Segment, Table} from "semantic-ui-react";
import ErrorMessage from "../../../other/ErrorMessage";
import SABody from "../../qualmetadmin/other/SABody";
import {connect} from "react-redux";
import helper from "../../../../helper/helper";
import {ORG_SUB_STATUS} from "../../../../helper/const";
import DatePicker from "react-datepicker/es";
import * as ps from "query-string";

class SAOrgAddPayment extends Component {
    constructor(props) {
        super(props);
        this.params = ps.parse(props.location.search);
        this.state = {
            amount: 0,
            status: ORG_SUB_STATUS.PENDING,
            name: '',
            address: '',
            phone: '',
            email: '',
            current_plan_id: null,
            pdate: null,
            po_number: '',
            comments: ''
        };
        this.firstTime = true;
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        this.props.dispatch(subAction.getAddPaymentData({id: this.props.match.params.id}));
    }

    componentWillReceiveProps(nextProps, nextContext) {
        const {item, isDone, history} = nextProps;
        if (isDone)
            history.goBack();
        if (Object.keys(item).length > 0 && this.firstTime) {
            this.firstTime = false;
            this.setState({
                name: item.name || "",
                address: item.address || "",
                phone: item.phone || "",
                email: item.email || "",
                current_plan_id: item.current_plan_id || null,
                pdate: item.paid_at ? helper.formatToDate(item.paid_at) : null,
            });
        }
    }

    onChange = e => this.setState({[e.target.name]: e.target.value});

    onSave = e => {
        e.preventDefault();
        this.props.dispatch(subAction.addPayment({
            ...this.state,
            org_id: this.props.match.params.id,
            paid_at: this.state.pdate ? helper.dateFormat(this.state.pdate) : null,
        }));
    };

    componentWillUnmount() {
        this.props.dispatch(subAction.unmount());
    }

    render() {
        const {loading, error, item} = this.props;
        const {name, address, phone, email, current_plan_id, status, invoice_id, amount, po_number, comments, pdate} = this.state;
        let plans = [];
        // let plan = {};
        if (item && item.plans) {
            plans = item.plans;
            // plan = plans.find((p) => item && p.id === item.current_plan_id) || {};
        }
        return (
            <SABody loading={loading}>

                <SASubHeader title={`Add Payment - ${this.params.title}`} backArrow/>

                <Segment basic>
                    <Form>
                        <Segment basic padded>
                            <ErrorMessage
                                errors={error || (!item && ["Something went wrong, Please try again later.."])}/>
                            <Header as={'h3'}>
                                Plan Information:
                            </Header>
                            {/*<List>*/}
                            {/*    <List.Item>*/}
                            {/*        <b> Current Plan: </b> {plan.title} ({plan.price_title})*/}
                            {/*    </List.Item>*/}
                            {/*    <List.Item>*/}
                            {/*        <b>Active Date:</b> {plan.active_date}*/}
                            {/*    </List.Item>*/}
                            {/*    <List.Item>*/}
                            {/*        <b>Renewal Date:</b> {plan.expiry_date}*/}
                            {/*    </List.Item>*/}
                            {/*</List>*/}

                            <br/>
                            <Header as={'h2'}>
                                Plans:
                            </Header>
                            <br/>
                            <Segment>
                                <Table basic='very' padded selectable stackable>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Title</Table.HeaderCell>
                                            <Table.HeaderCell>Tag</Table.HeaderCell>
                                            <Table.HeaderCell>Amount</Table.HeaderCell>
                                            <Table.HeaderCell>No of Users</Table.HeaderCell>
                                            <Table.HeaderCell textAlign={'center'}>Current Selection</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {plans.map((p, index) => {
                                            return (
                                                <Table.Row className={"row"} key={index} onClick={() => {
                                                    if (p.is_unlimited_users || item.org_users <= p.no_users) {
                                                        this.setState({current_plan_id: p.id, amount: p.amount});
                                                    }
                                                }}>
                                                    <Table.Cell><b>{p.title}</b></Table.Cell>
                                                    <Table.Cell>{p.price_title}</Table.Cell>
                                                    <Table.Cell>${p.amount}</Table.Cell>
                                                    <Table.Cell>{p.is_unlimited_users ? "Unlimited Users" : p.no_users}</Table.Cell>
                                                    <Table.Cell textAlign={"center"}>
                                                        {p.id === current_plan_id &&
                                                        <Button icon={"check"} circular color={"teal"} size={"tiny"}/>
                                                        }
                                                    </Table.Cell>
                                                </Table.Row>
                                            );
                                        })}
                                    </Table.Body>
                                </Table>
                            </Segment>
                        </Segment>

                        <Segment basic padded>
                            <Form.Group>
                                <Form.Input width={8} label={"Invoice No"} value={invoice_id || ""} name={"invoice_id"}
                                            onChange={this.onChange} required/>
                                <Form.Input width={8} label={"Amount"} value={amount} name={"amount"}
                                            onChange={this.onChange} required/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Dropdown width={8}
                                               selection
                                               label={"Status"}
                                               value={status} name={"status"}
                                               options={helper.subStatus} required
                                               onChange={(_, {value}) => this.setState({status: value})}/>
                                {(status === ORG_SUB_STATUS.PAID) &&
                                <Form.Field width={8} required>
                                    <label>Paid Date:</label>
                                    <DatePicker
                                        className={"ui input fluid"}
                                        placeholderText={"01/02/2019"}
                                        selected={pdate}
                                        isClearable
                                        onChange={(date) => {
                                            this.setState({pdate: date});
                                        }}
                                        peekNextMonth
                                        dateFormat={"MM/dd/yyyy"}
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                    />
                                </Form.Field>}
                            </Form.Group>
                            <Form.Input width={8} label={"PO Number"} value={po_number} name={"po_number"}
                                        onChange={this.onChange}/>
                            <Form.TextArea width={8} label={"Comments"} value={comments} name={"comments"}
                                           onChange={this.onChange}/>
                            <br/>
                            <br/>
                            <br/>
                            <Header as={'h3'}>
                                Invoice Information:
                            </Header>
                            <Form.Input width={8} label={"Name"} value={name} name={"name"} required
                                        onChange={this.onChange}/>
                            <Form.Input width={8} type="email" label={"Email"} value={email} name={"email"} required
                                        onChange={this.onChange}/>
                            <Form.TextArea rows={2} width={8} label={"Address"} value={address} name={"address"}
                                           required
                                           onChange={this.onChange}/>
                            <Form.Input width={8} type="phone" label={"Phone"} value={phone} name={"phone"} required
                                        onChange={this.onChange}/>
                            <br/>
                            <Button type='submit' primary onClick={this.onSave} floated='right' circular
                                    disabled={!(name && email && address && phone && current_plan_id && invoice_id && amount)}>Add
                                Payment</Button>
                            <Button type='button' secondary inverted floated='right' circular
                                    onClick={() => this.props.history.go(-1)}>Cancel</Button>
                            <br/>
                        </Segment>
                    </Form>
                </Segment>
            </SABody>
        );
    }
}

export default connect(state => {
    return state.sub;
})(SAOrgAddPayment);
