import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Form, Header, Icon, Segment, Table} from "semantic-ui-react";
import {withRouter} from "react-router-dom";
import helper from "../../../../helper/helper";

class MatterList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            query: "",
            status: -1
        };
    }

    render() {
        let matters = this.props.matters;
        const {strClientFirm} = this.props;
        const {query, status} = this.state;
        if (status !== -1) {
            matters = matters.filter((matter) => matter.status === status);
        }
        if (query.length > 0) {
            matters = matters.filter((matter) => {
                const {name, practice_area, client_name} = matter;
                return (
                    name.toLowerCase().includes(query)
                    || practice_area.toLowerCase().includes(query)
                    || client_name.toLowerCase().includes(query)
                );
            });
        }
        let content;
        if (matters.length > 0) {
            content = (
                <Segment basic style={{padding: "0 3em"}}>
                    <Table basic='very' selectable stackable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Matter Name</Table.HeaderCell>
                                <Table.HeaderCell>Practice Areas</Table.HeaderCell>
                                <Table.HeaderCell>{strClientFirm}</Table.HeaderCell>
                                <Table.HeaderCell>Date Created</Table.HeaderCell>
                                <Table.HeaderCell>End Date</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {matters.map((matter, index) => {
                                return (
                                    <Table.Row key={index} className={"row"} onClick={() => this.props.onClick(matter)}>
                                        <Table.Cell><b>{matter.name}</b></Table.Cell>
                                        <Table.Cell>{matter.practice_area}</Table.Cell>
                                        <Table.Cell>{matter.client_name}</Table.Cell>
                                        <Table.Cell>{matter.start_date}</Table.Cell>
                                        <Table.Cell>{matter.end_date}</Table.Cell>
                                    </Table.Row>
                                );
                            })}
                        </Table.Body>
                    </Table>
                </Segment>
            );
        } else {
            content = (
                <Segment basic placeholder piled clearing>
                    <Header icon>
                        <Icon name='file outline'/>
                        No matters are listed.
                    </Header>
                    <Button color={"teal"} circular onClick={() => this.props.history.push(this.props.addMatterPath)}>
                        Add Matter
                    </Button>
                </Segment>
            );
        }

        return (
            <div>
                <Segment basic style={{padding: "2em 3em 1em 3em"}}>
                    <Form>
                        <Form.Group>
                            <Form.Input placeholder='Search' value={this.state.query}
                                        onChange={(e) => this.setState({query: e.target.value.toLowerCase()})}/>
                            <Form.Dropdown selection value={status}
                                           onChange={(e, {value}) => {
                                               this.setState({status: value});
                                           }}
                                           options={helper.matterOrClientStatus}/>
                        </Form.Group>
                    </Form>
                </Segment>
                <br/>
                {content}
            </div>
        );
    }
}

MatterList.propTypes = {
    matters: PropTypes.array,
    addMatterPath: PropTypes.string,
    strClientFirm: PropTypes.string.isRequired,
    onClick: PropTypes.func
};

export default withRouter(MatterList);
