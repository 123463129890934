import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Form, Input} from "semantic-ui-react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

class RegisterUserAccountForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "",
            password: "",
            confirm_password: "",
            org: null,
            org_name: ""
        };
        this.onChange = this.onChange.bind(this);
        this.onClick = this.onClick.bind(this);
        this.is_set = 0;
    }

    componentWillReceiveProps(nextProps) {
        const {orgs} = nextProps;
        if (orgs.length > 1 && orgs.length !== this.is_set) {
            this.setState({org: "create"});
            this.is_set = orgs.length;
        }
    }

    onChange = e => {
        this.setState({[e.target.name]: e.target.value});
    };

    onClick = () => {
        const {is_first_time, code, history, i_t, h_id} = this.props;
        switch (code) {
            case 'rtl':
                history.replace("/");
                break;
            case 'rtvp':
                history.replace(`/register/verify?i_t=${i_t}&flag=${is_first_time}&h_id=${h_id}`);
                break;
            case 'rtop':
                history.replace(`/org-setup/form?i_t=${i_t}&flag=${is_first_time}&h_id=${h_id}`);
                break;
            case 'rt':
                history.replace("/");
                break;
            default:
                break;
        }
    };

    render() {
        const {onCancel, onSubmit, onBlurEmail, loading, message, code, orgs} = this.props;
        const {name, email, password, confirm_password, org, org_name} = this.state;
        let isInValid = !(name && email && password && confirm_password && org_name);
        if (!isInValid) {
            if (orgs.length > 0) {
                if (!org) {
                    isInValid = true;
                }
            }
        }
        let msg;
        if (message != null) {
            msg = (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <p style={{color: "maroon"}}>{message} <a href="#" style={{cursor: "pointer"}} onClick={this.onClick}>Click
                    here</a></p>);
        }
        return (
            <Form>
                <Form.Field width={16} required>
                    <label>Name</label>
                    <Input placeholder='Your Full Name' name={'name'} value={name || ""} onChange={this.onChange}/>
                </Form.Field>
                <Form.Field width={16} required>
                    <label>Organization Name</label>
                    <Input placeholder='Organization Name' name={'org_name'} value={org_name || ""} required
                           onChange={this.onChange}/>
                </Form.Field>
                <Form.Field width={16} required>
                    <label>Email</label>
                    <Input type={'email'} placeholder='Email' name={'email'} onChange={this.onChange}
                           onBlur={(e) => {
                               onBlurEmail(e.target.value);
                               this.setState({org: null});
                           }}/>
                    {msg}
                </Form.Field>
                <Form.Field width={16} required>
                    <label>Password</label>
                    <Input type={'password'} placeholder='Password' name={'password'} onChange={this.onChange}
                           autoComplete={'new-password'}/>
                </Form.Field>
                <Form.Field width={16} required>
                    <label>Confirm Password</label>
                    <Input type={'password'} placeholder='Confirm Password' name={'confirm_password'}
                           autoComplete={"nope"}
                           onChange={this.onChange}/>
                </Form.Field>
                <Button type='submit'
                        floated={"right"}
                        color={"teal"}
                        circular
                        loading={loading}
                        disabled={loading || isInValid || (message && code !== 'rt')}
                        onClick={(e) => {
                            e.preventDefault();
                            if (password !== confirm_password) {
                                alert("Password and confirm password does not match!");
                            } else
                                onSubmit(this.state);
                        }}>
                    <span style={{margin: "auto 2.5em"}}>Next</span>
                </Button>
                <Button color={"grey"}
                        floated={"right"}
                        disabled={loading || (message && code !== 'rt')}
                        circular
                        onClick={(e) => {
                            e.preventDefault();
                            onCancel();
                        }}>
                    <span style={{margin: "auto 2.5em"}}>Cancel</span>
                </Button>
                <br/>
                <br/>
            </Form>
        );
    }
}

RegisterUserAccountForm.propTypes = {
    loading: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onBlurEmail: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return state.auth;
}

const cRegisterUserAccountForm = withRouter(connect(mapStateToProps)(RegisterUserAccountForm));
export {cRegisterUserAccountForm as RegisterUserAccountForm};
