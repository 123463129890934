import React, {Component} from 'react';
import {connect} from "react-redux";
import {AddPracticeAreaForm} from "../../form/practiceareas/AddPracticeAreaForm";
import SASubHeader from "../other/SASubHeader";
import SABody from "../other/SABody";

class SuperAdminAddPracticeAreaPage extends Component {
    render() {
        const {loading} = this.props;
        return (
            <SABody loading={loading}>
                <SASubHeader backArrow title={"Add Practice Area"}/>

                <AddPracticeAreaForm/>
            </SABody>
        );
    }
}

function mapStateToProps(state) {
    return {
        ...state.practice
    };
}

const cSuperAdminAddPracticeAreaPage = connect(mapStateToProps)(SuperAdminAddPracticeAreaPage);
export {cSuperAdminAddPracticeAreaPage as SuperAdminAddPracticeAreaPage};