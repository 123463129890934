import React, {Component} from 'react';
import {Button, Form, Header, Icon, Segment, Table} from "semantic-ui-react";
import {connect} from "react-redux";
import {OrgMenuBar} from "../../other/OrgMenuBar";
import lawyerAction from "../../../../../actions/lawyer.action";
import {orgState} from "../../../../../helper/stateUtil";
import Body from "../../../../other/Body";
import OrgSubHeader from "../../other/OrgSubHeader";
import QMCheckBox from "../../../../other/form/QMCheckBox";

class OrgLawyersPage extends Component {

    componentDidMount() {
        this.props.dispatch(lawyerAction.readAll({org_id: this.props.org.id}));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.props.lawyer.isAllowed) {
            this.props.history.replace("/forbidden");
        }
    }

    render() {
        const {loading, lawyers} = this.props.lawyer;
        let content;
        if (lawyers.length > 0) {
            content = (
                <Segment padded={"very"} basic>
                    <Table basic='very' selectable stackable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Evaluatee</Table.HeaderCell>
                                <Table.HeaderCell>{this.props.org.str_client_firm}</Table.HeaderCell>
                                <Table.HeaderCell></Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {lawyers.map((lawyer, index) => {
                                return (
                                    <Table.Row className={"row"} key={index}>
                                        <Table.Cell style={{paddingLeft: "16px"}}
                                                    onClick={() => this.props.history.push(`/orgadmin/lawyers/manage/${lawyer.id}`)}>
                                            <Icon name="group" color={"grey"} size={"large"}/>
                                            <span><b>{lawyer.name}</b>&nbsp;({lawyer.email})</span>
                                        </Table.Cell>
                                        <Table.Cell>{lawyer.client_name}</Table.Cell>
                                        <Table.Cell collapsing>
                                            <Form>
                                                <Form.Group inline>
                                                    <Form.Field>
                                                        <QMCheckBox checked={lawyer.status}
                                                                    onChange={checked => {
                                                                        this.props.dispatch(lawyerAction.changeStatus({
                                                                            org_id: this.props.org.id,
                                                                            id: lawyer.id,
                                                                            status: checked
                                                                        }));
                                                                    }}/>
                                                    </Form.Field>
                                                </Form.Group>
                                            </Form>
                                        </Table.Cell>
                                    </Table.Row>
                                );
                            })}
                        </Table.Body>
                    </Table>
                </Segment>
            );
        } else {
            content = (
                <Segment basic placeholder piled>
                    <Header icon>
                        <Icon name='file outline'/>
                        No Evaluatees are listed.
                    </Header>
                    <Button color={"teal"} circular onClick={() => this.props.history.push("/orgadmin/lawyers/add")}>
                        Add Evaluatee
                    </Button>
                </Segment>
            );
        }

        return (
            <Body loading={loading}>

                <OrgMenuBar/>

                <OrgSubHeader title={`Evaluatees - ${this.props.org.name}`} backArrow>
                    <Button circular size={"medium"}
                            onClick={() => this.props.history.push("/orgadmin/lawyers/add")}
                            floated="right" color={"teal"}>Add Evaluatee</Button>
                </OrgSubHeader>
                {content}
            </Body>
        );
    }
}

function mapStateToProps(state) {
    let s = orgState(state);
    return {...s, lawyer: state.lawyer};
}

const connectedOrgLawyersPage = connect(mapStateToProps)(OrgLawyersPage);
export {connectedOrgLawyersPage as OrgLawyersPage};