import userService from "../services/user.service";

function request() {
    return {type: USER.REQUEST};
}

function error(reason) {
    reason = reason.response;
    // validation error
    if (reason.status === 422) {
        return {type: USER.VALIDATION_ERROR, payload: reason.data};
    } else if (reason.status === 404) {
        return {type: USER.NOT_FOUND};
    } else if (reason.status === 403) {
        return {type: USER.NOT_ALLOWED};
    }
    return {type: USER.ERROR, payload: reason.data};
}

function create(data) {
    return dispatch => {
        dispatch(request());
        userService.add(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        if (res.status === 201) {
            return {type: USER.ADD_SUCCESS, payload: res.data};
        }
        return {type: USER.ERROR, payload: ["Something went wrong!"]};
    }
}

function update(data) {
    return dispatch => {
        dispatch(request());
        userService.update(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        if (res.status === 202) {
            return {type: USER.UPDATE_SUCCESS, payload: res.data};
        }
        return error(res);
    }
}

function readAll(data) {
    return dispatch => {
        dispatch(request());
        userService.readAll(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: USER.READ_ALL_SUCCESS, payload: res.data};
    }
}

function read(data) {
    return dispatch => {
        dispatch(request());
        userService.read(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: USER.READ_SUCCESS, payload: res.data};
    }
}

function getUserInfo(data) {
    return dispatch => {
        dispatch(request());
        userService.getUserInfo(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: USER.READ_SUCCESS, payload: res.data};
    }
}

function changeStatus(data) {
    return dispatch => {
        dispatch(request());
        userService.changeStatus(data).then(() => dispatch(success()), reason => dispatch(error(reason)));
    };

    function success() {
        return {type: USER.CHANGE_STATUS_SUCCESS, payload: data};
    }
}

function changeInfo(data) {
    return dispatch => {
        dispatch(request());
        userService.changeInfo(data).then(() => dispatch(success()), reason => dispatch(error(reason)));
    };

    function success() {
        return {type: USER.CHANGE_INFO_SUCCESS, payload: data};
    }
}

function changePassword(data) {
    return dispatch => {
        dispatch(request());
        userService.changePassword(data).then(() => dispatch(success()), reason => dispatch(error(reason)));
    };

    function success() {
        return {type: USER.CHANGE_PASSWORD_SUCCESS};
    }
}

function sendEmailToUser(data) {
    return dispatch => {
        dispatch({type: USER.REQUEST_SU});
        userService.sendEmailToUser(data).then((res) => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        if (res.status === 202) {
            return {type: USER.SU_SUCCESS};
        }
        return error(res);
    }
}

function readAllPendingUsers(data) {
    return dispatch => {
        dispatch(request());
        userService.readAllPendingUsers(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: USER.READ_ALL_SUCCESS, payload: res.data};
    }
}

function activatePendingUser(data) {
    return dispatch => {
        dispatch(request());
        userService.activatePendingUser(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        if (res.status === 202) {
            return {type: USER.ACTIVATE_PENDING_USER, payload: res.data};
        }
        return error(res);
    }
}

function getAllImportUsers(data) {
    return dispatch => {
        dispatch(request());
        userService.getAllImportUsers(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: USER.READ_ALL_SUCCESS, payload: res.data};
    }
}

function uploadImportUsersFile(data) {
    return dispatch => {
        dispatch(request());
        userService.uploadImportUsersFile(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        if (res.status === 202) {
            return {type: USER.IMPORTED_USER_SUCCESS, payload: res.data};
        }
    }
}

function deleteImportUser(data) {
    return dispatch => {
        dispatch(request());
        userService.deleteImportUser(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        if (res.status === 202) {
            return {type: USER.DELETE_IMPORT_USER_SUCCESS, payload: res.data};
        }
    }
}

function deleteUser(data) {
    return dispatch => {
        dispatch(request());
        userService.deleteUser(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        if (res.status === 202) {
            return {type: USER.DELETE_IMPORT_USER_SUCCESS, payload: res.data};
        }
    }
}

function addImportUser(data) {
    return dispatch => {
        dispatch(request());
        userService.addImportUser(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        if (res.status === 201) {
            return {type: USER.ADD_IMPORT_USER_SUCCESS, payload: res.data};
        }
    }
}

function activateImportUsers(data) {
    return dispatch => {
        dispatch(request());
        userService.activateImportUsers(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        if (res.status === 202) {
            return {type: USER.ACTIVATE_IMPORT_USER_SUCCESS, payload: res.data};
        }
    }
}

const userAction = {
    create,
    update,
    readAll,
    read,
    changeStatus,
    getUserInfo,
    changeInfo,
    changePassword,
    sendEmailToUser,
    readAllPendingUsers,
    activatePendingUser,
    getAllImportUsers,
    uploadImportUsersFile,
    deleteImportUser,
    deleteUser,
    addImportUser,
    activateImportUsers
};

// constants
export const USER = {

    REQUEST: "REQUEST",
    ERROR: "ERROR",
    NOT_FOUND: "NOT_FOUND",
    VALIDATION_ERROR: "VALIDATION_ERROR ",
    NOT_ALLOWED: "NOT_ALLOWED",

    ADD_SUCCESS: "ADD_SUCCESS",
    UPDATE_SUCCESS: "UPDATE_SUCCESS",
    READ_ALL_SUCCESS: "READ_ALL_SUCCESS",
    READ_SUCCESS: "READ_SUCCESS",
    CHANGE_STATUS_SUCCESS: "USER_CHANGE_STATUS_SUCCESS",

    CHANGE_INFO_SUCCESS: "USER_CHANGE_INFO_SUCCESS",
    CHANGE_PASSWORD_SUCCESS: "USER_CHANGE_PASSWORD_SUCCESS",

    // sending mail to user
    REQUEST_SU: "USER_REQUEST_SU",
    SU_SUCCESS: "USER_SU_SUCCESS",

    // activate pending user
    ACTIVATE_PENDING_USER: "USER_ACTIVATE_PENDING_USER",

    IMPORTED_USER_SUCCESS: "USER_IMPORTED_USER_SUCCESS",

    DELETE_IMPORT_USER_SUCCESS: "USER_DELETE_IMPORT_USER_SUCCESS",

    ADD_IMPORT_USER_SUCCESS: "USER_ADD_IMPORT_USER_SUCCESS",

    ACTIVATE_IMPORT_USER_SUCCESS: "USER_ACTIVATE_IMPORT_USER_SUCCESS",
};

export default userAction;
