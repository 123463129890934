import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from "react-router-dom";

class Link extends Component {
    render() {
        const {style, children, history, to, onClick} = this.props;
        return (
            <span style={{cursor: "pointer", ...style}} onClick={() => {
                to ? history.push(to) : onClick();
            }}>
                {children}
            </span>
        );
    }
}

Link.propTypes = {
    to: PropTypes.string,
    onClick: PropTypes.func
};

export default withRouter(Link);
