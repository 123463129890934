import React, {Component, Fragment} from 'react';
import ColoredBody from "../../other/ColoredBody";
import BoxFooter from "../../other/BoxFooter";
import {Button, Form, Header, Icon, Label, Segment} from "semantic-ui-react";
import HeaderLogo from "../../other/HeaderLogo";
import authAction from "../../../actions/auth.action";
import {connect} from "react-redux";
import qs from "query-string";
import ErrorMessage from "../../other/ErrorMessage";
import SuccessMessage from "../../other/SuccessMessage";
import {Link} from "react-router-dom";

class NewLoginPage extends Component {
    constructor(props) {
        super(props);
        this.params = qs.parse(props.location.search);
        this.state = {
            email: '',
            password: '',
            step: false // step - false for enter email & step true for submit with password
        };
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onClickToContinue = this.onClickToContinue.bind(this);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        const {isDone, is_sso_user, sso_url, error, history, loading, isSSOChecked} = nextProps;
        if (loading) return;
        if (isDone)
            history.replace("/");
        if (is_sso_user)
            window.open(sso_url, "_top");
        else if (error.length === 0 && isSSOChecked)
            this.setState({step: true});
    }

    onChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    onSubmit(e) {
        e.preventDefault();
        const {email, password, step} = this.state;
        if (step) {
            this.props.dispatch(authAction.login(email, password));
        } else {
            this.props.dispatch(authAction.isSSOUser(email));
        }
    }

    onClickToContinue = () => {
        const {code, h_id, i_t, is_first_time, history} = this.props;
        switch (code) {
            case 'rtvp':
                history.replace(`/register/verify?i_t=${i_t}&flag=${is_first_time}&h_id=${h_id}`);
                break;
            case 'rtop':
                history.replace(`/org-setup/form?i_t=${i_t}&flag=${is_first_time}&h_id=${h_id}`);
                break;
            default:
                break;
        }
    };

    componentWillUnmount() {
        this.props.dispatch(authAction.unmount());
    }

    render() {
        const {loading, error, code, message} = this.props;
        const {email, password, step} = this.state;
        let {verified} = this.params;
        verified = verified === "true";
        return (
            <ColoredBody>
                <Segment padded>
                    <HeaderLogo darkLogo/>
                    <Segment basic>
                        <Header style={{fontSize: '18px', margin: "auto auto 1em auto", textAlign: "center"}}>
                            Log-in to your account
                        </Header>
                        <ErrorMessage errors={error}/>
                        {!step &&
                        <SuccessMessage messages={verified ? ["Your email has been successfully verified!"] : []}/>}
                        <br/>
                        {step && <div style={{textAlign: "center"}}>
                            <Label size={'big'}>
                                <Icon name='user'/> {email}
                            </Label>
                            <br/>
                            <br/>
                            <span style={{cursor: "pointer", textDecoration: "underline"}} onClick={() => {
                                this.setState({step: false, password: ""});
                                this.props.dispatch(authAction.unmount());
                            }}>
                                Change email
                            </span>
                            <br/>
                        </div>}
                        <Form size={"large"} onSubmit={this.onSubmit}>
                            {step ?
                                <Fragment>
                                    <Form.Input
                                        icon='lock'
                                        label={"Password:"}
                                        iconPosition='left'
                                        placeholder='Password'
                                        type='password'
                                        value={password}
                                        name='password'
                                        focus
                                        onChange={this.onChange}
                                    />
                                    <Form.Input name="email" value={email} type={'hidden'}/>
                                </Fragment>
                                :
                                <Form.Input label={"Email:"}
                                            icon='user' iconPosition='left' value={email}
                                            placeholder='E-mail address' type='email' name='email'
                                            onChange={this.onChange} focus/>
                            }
                        </Form>
                        {(code === "rtvp" || code === "rtop") &&
                        <span style={{color: "maroon"}}>
                            {message}
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <a href={"#"} style={{cursor: "pointer"}}
                               onClick={this.onClickToContinue}> Click here to Continue</a>
                        </span>}
                        <br/>
                        <br/>
                        <br/>
                        <Button type='submit' color='teal' floated={'right'} circular size='large'
                                loading={loading}
                                onClick={this.onSubmit}
                                disabled={loading}>
                            {step ? "Login" : "Next"}
                        </Button>
                        {step ? <Link to={"/forgot-password"}>
                            <span style={{
                                color: "#606060 ",
                                cursor: "pointer",
                                textDecoration: "underline",
                                textAlign: "left"
                            }}>
                                    Forgot Password?
                            </span>
                            </Link> :
                            <Link to={"/register?flag=cu"}>
                            <span style={{
                                color: "#606060 ",
                                cursor: "pointer",
                                textDecoration: "underline",
                                textAlign: "left"
                            }}>
                                    Create account
                            </span>
                            </Link>
                        }
                        <br/>
                        <br/>
                    </Segment>
                </Segment>
                <BoxFooter/>
            </ColoredBody>
        );
    }
}

function mapStateToProps(state) {
    return state.auth;
}

const cNewLoginPage = connect(mapStateToProps)(NewLoginPage);
export {cNewLoginPage as NewLoginPage};
