import {BAR_CHART_TYPE} from "../../../../../../helper/const";

export const color = {
    blue: "#00b0eb",
    red: "#d52bte",
    brown: "#d47b35",
    green: "#34b233",
    light_green: "#0674FD",
    dark_green: "#0674FD",
    grey_98: "#fafafa",
    grey_95: "#f2f2f2",
    grey_90: "#e5e5e5",
    grey_80: "#cccccc",
    grey_70: "#b2b2b2",
    grey_50: "#7f7f7f",
    grey_20: "#333333"
};

export const barContainerOffset = 74;
export const xOffset = 36;
export const xTranslate = xOffset + 16;
export const circleCy = 16;
export const circleCyOffset = -16;
export const circleCxOffset = 16;
export const circleR = 16;
export const transitionDuration = 750;
export const reactHeight = 48;
export const reactSepration = 16;
export const separatorHeight = 28;
export const centerLineDescriptionOffset = 24;
export const centerLineLabelOffset = 42;
export const centerLineLabelheight = 58;
export const margin = {top: 32, right: 40, bottom: 16, left: 80};

const centerLine = data => {
    let total = 0;
    data.forEach((d) => total = total + d.x);
    return total / data.length;
};

const average = data => {
    let total = 0;
    data.forEach((d) => total = total + d.x);
    return total / data.length;
};

const compileData = (data, type, maxX) => {
    let cData = [];
    if (type === BAR_CHART_TYPE.ALL || type === BAR_CHART_TYPE.TOP_25) {
        const top = data.filter((d) => {
            return d.x >= (maxX * 0.75);
        });
        if (top.length > 0) {
            cData.push({x: null, y: "Top 25%"});
            top.forEach((d) => cData.push(d));
        }
    }
    if (type === BAR_CHART_TYPE.ALL || type === BAR_CHART_TYPE.MIDDLE_50) {
        const middle = data.filter((d) => {
            return d.x > (maxX * 0.25) && d.x < (maxX * 0.75);
        });
        if (middle.length > 0) {
            cData.push({x: null, y: "Middle 50%"});
            middle.forEach((d) => cData.push(d));
        }
    }
    if (type === BAR_CHART_TYPE.ALL || type === BAR_CHART_TYPE.BOTTOM_25) {
        const bottom = data.filter((d) => {
            return d.x <= (maxX * 0.25);
        });
        if (bottom.length > 0) {
            cData.push({x: null, y: "Bottom 25%"});
            bottom.forEach((d) => cData.push(d));
        }
    }
    return cData;
};

const isDesktop = width => width > 767 || width === 723;

const calcHeight = data => {
    let temp = 16;
    for (let i = 0; i < data.length; i++) {
        temp = temp + (data[i].x === null ? separatorHeight : reactHeight);
    }
    return temp + margin.top + margin.bottom + centerLineLabelheight;
};

const utils = {
    centerLine,
    average,
    calcHeight,
    compileData,
    isDesktop
};

export default utils;