import React, {Component} from 'react';
import {PriceTable} from "../../../other/PriceTable";
import {Segment} from "semantic-ui-react";
import SABody from "../../qualmetadmin/other/SABody";

class OrgPlanAndBiliing extends Component {
    render() {
        return (
            <SABody loading={false}>
                <Segment padded={'very'} basic>
                    <PriceTable/>
                </Segment>
            </SABody>
        );
    }
}

OrgPlanAndBiliing.propTypes = {};

export default OrgPlanAndBiliing;
