import React, {Component} from 'react';
import PropTypes from 'prop-types';
import utils, {margin} from "./utils";
import * as d3 from "d3";
import {axisBottom, axisLeft, format, max, min, scaleLinear} from "d3";
import {circleR, color} from "../barchart/utils";
import "./../../../../../../css/chart/chart.css";

class ScatterPlotChart extends Component {
    componentWillReceiveProps() {
        this.draw();
    }

    componentDidMount() {
        window.addEventListener('resize', () => {
            this.draw();
        });
        this.draw();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', () => {
        });
    }

    mouseOver = (popup, d) => {
        const {xLabel, yLabel, popupKeys, popupLabels} = this.props;
        popup.text("");
        if (popupKeys.length > 0 && popupLabels.length > 0 && popupLabels.length === popupKeys.length) {
            for (let i = 0; i < popupKeys.length; i++) {
                popup.append("span")
                    .text(`${popupLabels[i]}: ${d[popupKeys[i]]}`);
                popup.append("br");
            }
        }
        popup.append("span")
            .text(`${xLabel || ""}: ${d.x}`);
        popup.append("br");
        popup.append("span")
            .text(`${yLabel || ""}: ${format("$,.0f")(d.y)}`);
        // popup.text(`${xLabel || ""}: ${d.x}, ${yLabel || ""}: ${format("$,.02f")(d.y.toFixed(2))}`);
        popup.style('visibility', 'visible');
    };

    mouseMove = (popup) => {
        popup.style('top', `${d3.event.pageY - 10}px`);
        popup.style('left', `${d3.event.pageX + 10}px`);
    };

    mouseOut = (popup) => {
        popup.style('visibility', 'hidden');
    };

    componentDidUpdate(prevProps) {
        this.draw();
    }

    draw = () => {
        const {data, maxX, xTicks, onClick} = this.props;

        const div = document.getElementById("scatter-plot-chart");
        if (!div) return;
        // remove children
        div.innerHTML = '';
        let width = div.getBoundingClientRect().width;
        if (width >= 1200) {
            width = width - 65;
        }
        const height = width * 0.7 < 650 ? width * 0.7 : 650;
        const innerWidth = width - margin.left - margin.right;
        const innerHeight = height - margin.top - margin.bottom;

        const xValue = d => d.x;
        const yValue = d => d.y;

        const xScale = scaleLinear()
            .domain([0, maxX])
            .range([0, innerWidth]);

        const yScale = scaleLinear()
            .domain([max(data, d => yValue(d)), min(data, d => yValue(d))])
            .range([0, innerHeight]);

        const svg = d3.select(div)
            .append("svg")
            .attr('display', data.length === 0 ? 'hidden' : 'visible')
            .attr('width', width)
            .attr('height', height);

        if (data.length === 0) return;

        const popup = d3.select(div)
            .append("div")
            .attr('id', 'chart-popup');

        const container = svg.append('g')
            .attr('class', 'container')
            .attr("transform", `translate(${margin.left},${margin.top})`);

        // lines group

        const xLinesGroup = container.append("g")
            .attr('class', 'x-lines-group');

        const yLinesGroup = container.append("g")
            .attr('class', 'y-lines-group');

        const xAxis = axisBottom(xScale).ticks(xTicks);

        container.append("g")
            .attr('class', 'x-axis')
            .attr('transform', `translate(0,${innerHeight})`)
            .call(xAxis)
            .selectAll(".tick")
            .each((d) => {
                xLinesGroup
                    .append("line")
                    .attr('stroke', color.grey_95)
                    .attr('stroke-width', 1)
                    .attr('x1', xScale(d))
                    .attr('x2', xScale(d))
                    .attr('y1', 0)
                    .attr('y2', innerHeight);
            });

        const yAxis = axisLeft(yScale).tickFormat(format("$,"));

        container.append("g")
            .attr('class', 'y-axis')
            .attr('transform', `translate(0,0)`)
            .call(yAxis)
            .selectAll(".tick")
            .each((d) => {
                yLinesGroup
                    .append("line")
                    .attr('stroke', color.grey_90)
                    .attr('stroke-width', 1)
                    .attr('x1', 0)
                    .attr('x2', innerWidth)
                    .attr('y1', yScale(d))
                    .attr('y2', yScale(d));
            });

        // center lines group
        const xAvg = utils.funXAvg(data);
        const yAvg = utils.funYAvg(data);

        const centerLineX = container.append("g")
            .attr('class', 'centerline-x');

        const xLine1 = centerLineX.append("g")
            .attr('class', "line1");

        xLine1.append("line")
            .attr('stroke', color.brown)
            .attr('stroke-width', 2)
            .attr('x1', xScale(xAvg))
            .attr('x2', xScale(xAvg))
            .attr('y1', 0)
            .attr('y2', innerHeight);

        const textContainerX = centerLineX.append('g')
            .attr('class', 'text-container');

        textContainerX
            .append("text")
            .style('text-anchor', 'start')
            .attr('x', 16)
            .attr('y', yScale(yAvg) + 16)
            .text(`Average ${this.props.yLabel || ""}`);

        textContainerX
            .append("text")
            .style('text-anchor', 'start')
            .attr('x', 16)
            .attr('y', yScale(yAvg) + 16 * 2 + 4)
            .text(`${format("$,.02f")(yAvg.toFixed(2))}`);

        const centerLineY = container.append("g")
            .attr('class', 'centerline-y');

        const yline1 = centerLineY.append("g")
            .attr('class', "line1");

        yline1.append("line")
            .attr('stroke', color.brown)
            .attr('stroke-width', 2)
            .attr('x1', 0)
            .attr('x2', innerWidth)
            .attr('y1', yScale(yAvg))
            .attr('y2', yScale(yAvg));

        const textContainerY = centerLineX.append('g')
            .attr('class', 'text-container');

        textContainerY
            .append("text")
            .style('text-anchor', 'end')
            .attr('x', xScale(xAvg) - 14)
            .attr('y', 16)
            .text(`Average ${this.props.xLabel || ""}`);

        textContainerY
            .append("text")
            .style('text-anchor', 'end')
            .attr('x', xScale(xAvg) - 14)
            .attr('y', 34)
            .text(`${xAvg.toFixed(1)}`);

        // circle container
        const circleContainer = container.append("g")
            .attr('class', 'circles-container');

        // circles
        circleContainer.selectAll('circle')
            .data(data)
            .enter()
            .append('circle')
            .on('mouseover', d => this.mouseOver(popup, d))
            .on('mousemove', d => this.mouseMove(popup, d))
            .on('mouseout', () => this.mouseOut(popup))
            .on('click', (d) => onClick(d))
            .attr('cx', d => xScale(xValue(d)))
            .attr('cy', d => yScale(yValue(d)))
            .transition('duration', 750)
            .attr('cx', d => xScale(xValue(d)))
            .attr('r', circleR)
            .style('cursor', 'pointer')
            .attr('fill', (d) => d.is_selected ? color.brown : color.light_green);

        circleContainer.selectAll('text')
            .data(data)
            .enter()
            .append('text')
            .attr('fill', 'white')
            .attr('font-size', 18)
            .attr('x', d => xScale(xValue(d)) - 4)
            .attr('y', d => yScale(yValue(d)) + 4)
            .style('cursor', 'pointer')
            .style('font-weight', 600)
            .text((_, i) => i + 1)
            .on('mouseover', d => this.mouseOver(popup, d))
            .on('mousemove', d => this.mouseMove(popup, d))
            .on('mouseout', () => this.mouseOut(popup))
            .on('click', (d) => onClick(d));

        // svg.append("text")
        //     .attr("transform", "rotate(-90)")
        //     .attr("y", 4)
        //     .attr("x", -(height / 2))
        //     .attr("dy", "1em")
        //     .style("text-anchor", "middle")
        //     .attr('font-size', 14)
        //     .style('font-weight', 600)
        //     .text(`<-----  ${yLabel}  ----->`);
        //
        // svg.append("text")
        //     .attr("y", height - 32)
        //     .attr("x", (width / 2))
        //     .attr("dy", "1em")
        //     .style("text-anchor", "middle")
        //     .attr('font-size', 14)
        //     .style('font-weight', 600)
        //     .text(`<-----  ${xLabel}  ----->`);
    };

    render() {
        return (
            <div id={'scatter-plot-chart'} style={{backgroundColor: 'white'}}/>
        );
    }
}

ScatterPlotChart.propTypes = {
    data: PropTypes.array.isRequired,
    onClick: PropTypes.func.isRequired,
    maxX: PropTypes.number.isRequired,
    popupLabels: PropTypes.array.isRequired,
    popupKeys: PropTypes.array.isRequired,
    xTicks: PropTypes.number.isRequired,
    xLabel: PropTypes.string,
    yLabel: PropTypes.string,
};

export default ScatterPlotChart;
