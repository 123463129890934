import {REPORT} from "../actions/report.action";

const initState = {
    isAllowed: true,
    loadingFiltersAndSummary: false,
    step: 0,
    loadingData: false,
    header: {},
    data: [],
    totals: [],
    summary: {},
    responses: [],

    // filters
    clients: [],
    score: [],
    spend: [],
    cycles: [],
    practice_areas: [],
    status: [],
    questions: [],
    evaluatees: [],
    matters: [],
    evaluations: []
};

export default function reportReducer(state = initState, action) {
    // reset
    state.error = [];
    state.isDone = false;
    state.isAllowed = true;

    // filter
    const {type, payload} = action;
    if (type === REPORT.REQUEST_FILTERS_SUMMARY) {
        return {
            ...state,
            totals: [],
            responses: [],
            clients: [],
            score: [],
            spend: [],
            cycles: [],
            practice_areas: [],
            status: [],
            questions: [],
            evaluatees: [],
            matters: [],
            loadingFiltersAndSummary: true
        };
    } else if (type === REPORT.REQUEST_DATA) {
        return {...state, data: [], loadingData: true};
    } else if (type === REPORT.VALIDATION_ERROR) {
        return {...state, error: payload};
    } else if (type === REPORT.ERROR) {
        return {...state, error: [...payload]};
    } else if (type === REPORT.NOT_ALLOWED) {
        return {...state, isAllowed: false};
    } else if (type === REPORT.ALL_MATTERS_REPORT_SUMMARY_AND_FILTERS) {
        const {filters, summary, str_client_firm} = payload;
        if (summary) {
            [
                {title: "Matters", key: 'matters'},
                {title: `${str_client_firm}s`, key: 'clients'},
                {title: "Evaluations", key: 'evaluations'},
                {title: "Evaluators", key: 'evaluators'},
                {title: "Evaluatees", key: 'evaluatees'},
                {title: "Practices Areas", key: 'practices'},
                {title: "Cycles", key: 'cycles'},
            ].forEach((item) => {
                if (summary[item.key]) {
                    state.totals.push({text: item.title, value: summary[item.key]});
                }
            });

            if (summary.responses && summary.responses.length > 0) {
                state.responses = summary.responses;
            }
        }
        state = {...state, ...filters, summary, loadingFiltersAndSummary: false, isDone: true};
        return {...state};
    } else if (type === REPORT.MATTER_REPORT_SUCCESS) {
        state.data = payload;
        return {...state, loadingData: false};
    } else if (type === REPORT.MATTER_REPORT_SUMMARY_AND_FILTERS) {
        state.header = payload.header;
        const {filters: mfilters, summary: msummary, str_client_firm} = payload;
        if (msummary) {
            [
                {title: `${str_client_firm}s`, key: 'clients'},
                {title: "Matters", key: 'matters'},
                {title: "Evaluations", key: 'evaluations'},
                {title: "Evaluators", key: 'evaluators'},
                {title: "Evaluatees", key: 'evaluatees'},
                {title: "Practices Areas", key: 'practices'},
                {title: "Cycles", key: 'cycles'},
            ].forEach((item) => {
                if (msummary[item.key]) {
                    state.totals.push({text: item.title, value: msummary[item.key]});
                }
            });

        }
        if (msummary.responses && msummary.responses.length > 0) {
            state.responses = msummary.responses;
        }
        state = {
            ...state,
            ...mfilters,
            summary: msummary,
            loadingFiltersAndSummary: false,
            isDone: true
        };
        return {...state};
    } else if (type === REPORT.ALL_MATTERS_REPORT_SUCCESS) {
        state.data = payload;
        return {...state, loadingData: false};
    } else if (type === REPORT.MATTER_DETAIL_FILTER_AND_SUMMARY) {
        state.header = payload.header;
        const {filters: dfilters} = payload;
        return {...state, ...dfilters, loadingFiltersAndSummary: false, isDone: true};
    } else if (type === REPORT.MATTER_DETAIL_SUCCESS) {
        state.data = payload.filter((d) => !Array.isArray(d));
        return {...state, loadingData: false};
    } else if (type === REPORT.MATTER_COMMENTS_FILTER_AND_SUMMARY) {
        state.header = payload.header;
        const {filters: cfilters} = payload;
        return {...state, ...cfilters, loadingFiltersAndSummary: false, isDone: true};
    } else if (type === REPORT.MATTER_COMMENTS_SUCCESS) {
        state.data = payload.general_comments;
        state.totals = payload.evaluator_comments;
        return {...state, loadingData: false};
    } else if (type === REPORT.ALL_CLIENTS_REPORT_SUMMARY_AND_FILTERS) {
        const {filters, summary, str_client_firm} = payload;
        if (summary) {
            [
                {title: `${str_client_firm}s`, key: 'clients'},
                {title: "Matters", key: 'matters'},
                {title: "Evaluations", key: 'evaluations'},
                {title: "Evaluators", key: 'evaluators'},
                {title: "Evaluatees", key: 'evaluatees'},
                {title: "Practices Areas", key: 'practices'},
                {title: "Cycles", key: 'cycles'},
            ].forEach((item) => {
                if (summary[item.key]) {
                    state.totals.push({text: item.title, value: summary[item.key]});
                }
            });

            if (summary.responses && summary.responses.length > 0) {
                state.responses = summary.responses;
            }
        }
        state = {...state, ...filters, summary, loadingFiltersAndSummary: false, isDone: true};
        return {...state};
    }

    // CLIENTS

    else if (type === REPORT.CLIENT_COMMENTS_REQUEST) {
        return {...state, loadingData: true};
    } else if (type === REPORT.ALL_CLIENTS_REPORT_SUCCESS) {
        state.data = payload;
        return {...state, loadingData: false};
    } else if (type === REPORT.CLIENT_REPORT_SUCCESS) {
        state.data = payload;
        return {...state, loadingData: false};
    } else if (type === REPORT.CLIENT_REPORT_SUMMARY_AND_FILTERS) {
        state.header = payload.header;
        const {filters, summary, str_client_firm} = payload;
        if (summary) {
            if (summary.responses && summary.responses.length > 0) {
                state.responses = summary.responses;
            }
            [
                {title: `${str_client_firm}s`, key: 'clients'},
                {title: "Matters", key: 'matters'},
                {title: "Evaluations", key: 'evaluations'},
                {title: "Evaluators", key: 'evaluators'},
                {title: "Evaluatees", key: 'evaluatees'},
                {title: "Practices Areas", key: 'practices'},
                {title: "Cycles", key: 'cycles'},
            ].forEach((item) => {
                if (summary[item.key]) {
                    state.totals.push({text: item.title, value: summary[item.key]});
                }
            });
        }
        state = {
            ...state,
            ...filters,
            summary,
            loadingFiltersAndSummary: false,
            isDone: true
        };
        return {...state};
    } else if (type === REPORT.CLIENT_DETAIL_FILTER_AND_SUMMARY) {
        state.header = payload.header;
        return {...state, ...payload.filters, loadingFiltersAndSummary: false, isDone: true};
    } else if (type === REPORT.CLIENT_DETAIL_SUCCESS) {
        const {data} = payload;
        if (data)
            state.data = data.filter((d) => !Array.isArray(d));
        return {...state, loadingData: false};
    } else if (type === REPORT.CLIENT_LAWYERS_FILTER_AND_SUMMARY) {
        state.header = payload.header;
        return {...state, ...payload.filters, loadingFiltersAndSummary: false, isDone: true};
    } else if (type === REPORT.CLIENT_LAWYERS_SUCCESS) {
        state.data = payload.filter((d) => !Array.isArray(d));
        return {...state, loadingData: false};
    }

    // CLIENT COMMENTS

    else if (type === REPORT.CLIENT_COMMENTS_FILTER_AND_SUMMARY) {
        state.header = payload.header;
        const {filters: cfilters} = payload;
        return {...state, ...cfilters, step: 1, isDone: true};
    } else if (type === REPORT.CLIENT_COMMENTS_FILTER_QUES) {
        state.header = payload.header;
        const {filters: cfilters} = payload;
        return {...state, ...cfilters, step: 2, isDone: true};
    } else if (type === REPORT.CLIENT_COMMENTS_SUCCESS) {
        state.data = payload.general_comments;
        state.totals = payload.evaluator_comments;
        return {...state, loadingData: false, loadingFiltersAndSummary: false, step: 3};
    }

    // LAWYER REPORT/ Evaluatee report
    else if (type === REPORT.LAWYER_COMMENTS_REQUEST) {
        return {...state, loadingData: true};
    } else if (type === REPORT.ALL_LAWYERS_REPORT_SUMMARY_AND_FILTERS) {
        const {filters, summary, str_client_firm} = payload;
        if (summary) {
            [
                {title: "Matters", key: 'matters'},
                {title: `${str_client_firm}s`, key: 'clients'},
                {title: "Evaluations", key: 'evaluations'},
                {title: "Evaluators", key: 'evaluators'},
                {title: "Evaluatees", key: 'evaluatees'},
                {title: "Practices Areas", key: 'practices'},
                {title: "Cycles", key: 'cycles'},
            ].forEach((item) => {
                if (summary[item.key]) {
                    state.totals.push({text: item.title, value: summary[item.key]});
                }
            });

            if (summary.responses && summary.responses.length > 0) {
                state.responses = summary.responses;
            }
        }
        state = {...state, ...filters, summary, loadingFiltersAndSummary: false, isDone: true};
        return {...state};
    } else if (type === REPORT.ALL_LAWYERS_REPORT_SUCCESS) {
        state.data = payload;
        return {...state, loadingData: false};
    } else if (type === REPORT.LAWYER_REPORT_SUCCESS) {
        state.data = payload;
        return {...state, loadingData: false};
    } else if (type === REPORT.LAWYER_REPORT_SUMMARY_AND_FILTERS) {
        state.header = payload.header;
        const {filters: mfilters, summary: msummary, str_client_firm} = payload;
        if (msummary) {
            [
                {title: "Matters", key: 'matters'},
                {title: `${str_client_firm}s`, key: 'clients'},
                {title: "Evaluations", key: 'evaluations'},
                {title: "Evaluators", key: 'evaluators'},
                {title: "Evaluatees", key: 'evaluatees'},
                {title: "Practices Areas", key: 'practices'},
                {title: "Cycles", key: 'cycles'},
            ].forEach((item) => {
                if (msummary[item.key]) {
                    state.totals.push({text: item.title, value: msummary[item.key]});
                }
            });
        }
        if (msummary.responses && msummary.responses.length > 0) {
            state.responses = msummary.responses;
        }
        state = {
            ...state,
            ...mfilters,
            summary: msummary,
            loadingFiltersAndSummary: false,
            isDone: true
        };
        return {...state};
    } else if (type === REPORT.LAWYER_DETAIL_FILTER_AND_SUMMARY) {
        state.header = payload.header;
        return {...state, ...payload.filters, loadingFiltersAndSummary: false, isDone: true};
    } else if (type === REPORT.LAWYER_DETAIL_SUCCESS) {
        const {data} = payload;
        if (data)
            state.data = data.filter((d) => !Array.isArray(d));
        return {...state, loadingData: false};
    }

    // LAWYER COMMENTS REPORT

    else if (type === REPORT.LAWYER_COMMENTS_FILTER_AND_SUMMARY) {
        state.header = payload.header;
        const {filters: cfilters} = payload;
        return {...state, ...cfilters, step: 1, isDone: true};
    } else if (type === REPORT.LAWYER_COMMENTS_FILTER_QUES) {
        state.header = payload.header;
        const {filters: cfilters} = payload;
        return {...state, ...cfilters, step: 2, isDone: true};
    } else if (type === REPORT.LAWYER_COMMENTS_SUCCESS) {
        state.data = payload.general_comments;
        state.totals = payload.evaluator_comments;
        return {...state, loadingData: false, loadingFiltersAndSummary: false, step: 3};
    }

    // PRACTICE AREA REPORT

    else if (type === REPORT.ALL_PRACTICE_REPORT_SUMMARY_AND_FILTERS) {
        state.totals = [];
        state.responses = [];
        const {filters, summary, str_client_firm} = payload;
        if (summary) {
            [
                {title: "Matters", key: 'matters'},
                {title: `${str_client_firm}s`, key: 'clients'},
                {title: "Evaluations", key: 'evaluations'},
                {title: "Evaluators", key: 'evaluators'},
                {title: "Evaluatees", key: 'evaluatees'},
                {title: "Practices Areas", key: 'practices'},
                {title: "Cycles", key: 'cycles'},
            ].forEach((item) => {
                if (summary[item.key]) {
                    state.totals.push({text: item.title, value: summary[item.key]});
                }
            });

            if (summary.responses && summary.responses.length > 0) {
                state.responses = summary.responses;
            }
        }
        state = {...state, ...filters, summary, loadingFiltersAndSummary: false, isDone: true};
        return {...state};
    } else if (type === REPORT.ALL_PRACTICE_REPORT_SUCCESS) {
        state.data = payload;
        return {...state, loadingData: false};
    } else if (type === REPORT.ALL_REGIONS_REPORT_SUMMARY_AND_FILTERS) {
        const {filters, summary, str_client_firm} = payload;
        if (summary) {
            [
                {title: "Matters", key: 'matters'},
                {title: `${str_client_firm}s`, key: 'clients'},
                {title: "Evaluations", key: 'evaluations'},
                {title: "Evaluators", key: 'evaluators'},
                {title: "Evaluatees", key: 'evaluatees'},
                {title: "Practices Areas", key: 'practices'},
                {title: "Cycles", key: 'cycles'},
            ].forEach((item) => {
                if (summary[item.key]) {
                    state.totals.push({text: item.title, value: summary[item.key]});
                }
            });

            if (summary.responses && summary.responses.length > 0) {
                state.responses = summary.responses;
            }
        }
        state = {...state, ...filters, summary, loadingFiltersAndSummary: false, isDone: true};
        return {...state};
    } else if (type === REPORT.ALL_REGIONS_REPORT_SUCCESS) {
        state.data = payload;
        return {...state, loadingData: false};
    } else if (type === REPORT.OFFICE_REPORT_SUCCESS) {
        state.data = payload;
        return {...state, loadingData: false};
    } else if (type === REPORT.OFFICE_REPORT_SUMMARY_AND_FILTERS) {
        state.header = payload.header;
        const {filters: mfilters, summary: msummary} = payload;
        if (msummary.responses && msummary.responses.length > 0) {
            state.responses = msummary.responses;
        }
        state = {
            ...state,
            ...mfilters,
            summary: msummary,
            loadingFiltersAndSummary: false,
            isDone: true
        };
        return {...state};
    } else if (type === REPORT.OFFICE_DETAIL_FILTER_AND_SUMMARY) {
        state.header = payload.header;
        return {...state, ...payload.filters, loadingFiltersAndSummary: false, isDone: true};
    } else if (type === REPORT.OFFICE_DETAIL_SUCCESS) {
        const {data} = payload;
        if (data)
            state.data = data.filter((d) => !Array.isArray(d));
        return {...state, loadingData: false};
    } else if (type === REPORT.DIVERSITY_REPORT_SUMMARY_AND_FILTERS) {
        const {filters, summary, str_client_firm} = payload;
        if (summary) {
            [
                {title: "Matters", key: 'matters'},
                {title: `${str_client_firm}s`, key: 'clients'},
                {title: "Evaluations", key: 'evaluations'},
                {title: "Evaluators", key: 'evaluators'},
                {title: "Evaluatees", key: 'evaluatees'},
                {title: "Practices Areas", key: 'practices'},
                {title: "Cycles", key: 'cycles'},
            ].forEach((item) => {
                if (summary[item.key]) {
                    state.totals.push({text: item.title, value: summary[item.key]});
                }
            });

            if (summary.responses && summary.responses.length > 0) {
                state.responses = summary.responses;
            }
        }
        state = {...state, ...filters, summary, loadingFiltersAndSummary: false, isDone: true};
        return {...state};
    } else if (type === REPORT.DIVERSITY_REPORT_SUCCESS) {
        state.data = payload;
        return {...state, loadingData: false};
    } else {
        return {...state};
    }
}