import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Grid, Header, List, Modal, Segment} from "semantic-ui-react";
import * as qs from "query-string";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import evaluationAction from "../../../../../../actions/evaluation.action";
import FullScreenLoader from "../../../../../other/FullScreenLoader";

class EvaluationHeader extends Component {
    constructor(props) {
        super(props);
        this.params = qs.parse(props.location.search);
        this.state = {
            open: false
        };
    }

    componentDidMount() {
        this.isReject = false;
    }

    getCatOfEval = () => {
        const {type} = this.params;
        switch (type) {
            case 'firm':
                return "Firm";
            case 'matter':
                return "Matter";
            case "lawyer":
                return "Evaluatee";
            default:
                return "";
        }
    };

    componentWillReceiveProps(nextProps) {
        const {isRejected, history} = nextProps;
        if (isRejected && this.isReject) {
            history.goBack();
        }
    }

    componentWillUnmount() {
        this.isReject = false;
    }

    render() {
        const {data, dispatch, loading, isView} = this.props;
        const {open} = this.state;
        const {type, action} = this.params;
        const {client_name, name, lawyer_name, cycle_no, practice_area, due_date, id} = data;
        return (
            <div>
                <FullScreenLoader active={this.isReject && loading}/>
                <hr/>
                <Grid columns={2} stackable
                      className={'eval-header'}>
                    <Grid.Column style={{padding: "0"}}>
                        <Segment basic>
                            <List>
                                <List.Item>
                                    Evaluation Category: <b>{this.getCatOfEval()}</b>
                                </List.Item>
                                <List.Item>
                                    Evaluation Name: <b>{name}</b>
                                </List.Item>
                                {client_name && <List.Item>
                                    Evaluation for <b>{client_name}</b>
                                </List.Item>}
                                {lawyer_name && <List.Item>
                                    Evaluatee: <b>{lawyer_name}</b>
                                </List.Item>}
                            </List>
                        </Segment>
                    </Grid.Column>
                    <Grid.Column style={{padding: "0"}}>
                        <Segment basic>
                            {!isView && <Button
                                floated={"right"}
                                circular color={"red"}
                                disabled={action === "edit"}
                                onClick={() => {
                                    this.isReject = false;
                                    this.setState({open: true});
                                }}>
                                Reject Evaluation
                            </Button>}
                            <List>
                                <List.Item>
                                    Practice Area: <b>{practice_area}</b>
                                </List.Item>
                                {/*<List.Item>*/}
                                {/*Evaluator: <b>{evaluator}</b>*/}
                                {/*</List.Item>*/}
                                <List.Item>
                                    Due Date: <b>{due_date}</b>
                                </List.Item>
                                <List.Item>
                                    Cycle No: <b>{cycle_no}</b>
                                </List.Item>
                            </List>
                        </Segment>
                    </Grid.Column>
                </Grid>
                <br/>
                {open && <Modal size={"tiny"} open>
                    <Modal.Header>Reject Evaluation</Modal.Header>
                    <Modal.Content>
                        <Header as={'h4'}>Are you sure you want to reject evaluation?</Header>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button circular onClick={() => this.setState({open: false})} disabled={loading}>No</Button>
                        <Button color={'teal'} circular content='Yes' disabled={loading} loading={loading}
                                onClick={() => {
                                    this.isReject = true;
                                    dispatch(evaluationAction.rejectEvaluation({
                                        id: id
                                    }, type));
                                }}/>
                    </Modal.Actions>
                </Modal>}
            </div>
        );
    }
}

EvaluationHeader.propTypes = {
    data: PropTypes.object,
    isView: PropTypes.bool
};

export default withRouter(connect(
    state => state.evaluations
)(EvaluationHeader));
