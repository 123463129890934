import {LAWYER} from "../actions/lawyer.action";

const initState = {
    isAllowed: true,
    loading: false,
    isDone: false,
    lawyers: [],
    error: [],
    lawyer: null,
    clients: []
};

export default function lawyerReducer(state = initState, action) {
    // reset
    state.error = [];
    state.isDone = false;
    state.loading = false;
    state.isAllowed = true;

    // filter
    switch (action.type) {

        // common
        case LAWYER.REQUEST:
            return {...state, loading: true};

        case LAWYER.VALIDATION_ERROR:
            return {...state, error: action.payload};

        case LAWYER.ERROR:
            return {...state, error: [...action.payload]};

        // not allowed to access
        case LAWYER.NOT_ALLOWED:
            return {...state, isAllowed: false};

        // add lawyer
        case LAWYER.ADD_SUCCESS:
            return {...state, isDone: true};

        // update lawyer
        case LAWYER.UPDATE_SUCCESS:
            return {...state, isDone: true};

        // read all lawyers from server
        case LAWYER.READ_ALL_SUCCESS:
            return {...state, lawyers: action.payload};

        // read single lawyer from server
        case LAWYER.READ_SUCCESS:
            return {...state, lawyer: action.payload};

        case LAWYER.NOT_FOUND:
            return {...state, not_found: true};

        case LAWYER.CHANGE_STATUS_SUCCESS:
            state.lawyers[state.lawyers.findIndex((lawyer) => lawyer.id === action.payload.id)].status = action.payload.status;
            return {...state};

        case LAWYER.READ_ALL_CLIENTS_SUCCESS:
            return {...state, clients: [...action.payload]};

        default:
            return state;
    }
}