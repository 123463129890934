import api from "../helper/api";

function readEvaluationCycles(data) {
    return api.instance().post("org/matters/get-all", data);
}

function readMYEvaluationCycles(data) {
    return api.instance().post("org/matters/get-all", data);
}

function startEvaluation(data, type) {
    let url;
    if (type === 'firm')
        url = "org/firm-evaluations/start-evaluation";
    else if (type === 'lawyer')
        url = "org/lawyer-evaluations/start-evaluation";
    else
        url = "org/matters/start-evaluation";
    return api.instance().post(url, data);
}

function evaluate(data, type) {
    let url;
    if (type === 'firm')
        url = "org/firm-evaluations/evaluate";
    else if (type === 'lawyer')
        url = 'org/lawyer-evaluations/evaluate';
    else
        url = "org/matters/evaluate";
    return api.instance().post(url, data);
}

function evaluateAll(data, type) {
    let url;
    if (type === 'firm')
        url = "org/firm-evaluations/evaluate-all";
    else if (type === "lawyer")
        url = "org/lawyer-evaluations/evaluate-all";
    else
        url = "org/matters/evaluate-all";
    return api.instance().post(url, data);
}

function viewEvaluation(data, type) {
    let url;
    if (type === 'firm')
        url = "org/firm-evaluations/view-evaluation";
    else if (type === 'lawyer')
        url = "org/lawyer-evaluations/view-evaluation";
    else
        url = "org/matters/view-evaluation";
    return api.instance().post(url, data);
}

function rejectEvaluation(data, type) {
    let url;
    if (type === 'firm')
        url = "org/firm-evaluations/reject-evaluation";
    else if (type === 'lawyer')
        url = "org/lawyer-evaluations/reject-evaluation";
    else
        url = "org/matters/reject-evaluation";
    data.flag = true; // for single reject evaluation
    return api.instance().post(url, data);
}

const evaluationService = {
    readEvaluationCycles,
    readMYEvaluationCycles,
    startEvaluation,
    evaluate,
    evaluateAll,
    viewEvaluation,
    rejectEvaluation
};

export default evaluationService;