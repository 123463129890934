import appService from "../services/app.service";

function checkTermsOfUse() {
    return dispatch => {
        appService.checkTermsOfUse()
            .then(response => dispatch(success(response)));
    };

    function success(res) {
        return {type: APP.RESPONSE_TERMS_OF_USE, payload: res.data};
    }
}

function acceptTermsOfUse() {
    return dispatch => {
        appService.acceptTermsOfUse()
            .then(responses => dispatch(success(responses)));

        function success(res) {
            if (res.status === 202) {
                return {type: APP.ACCEPT_TERMS_OF_USE};
            }
        }
    };
}

function getTermsOfUse() {
    return dispatch => {
        dispatch({type: APP.LOADING});
        appService.getTermsOfUse()
            .then(response => dispatch(success(response)));
    };

    function success(res) {
        return {type: APP.GET_TERMS_OF_USE, payload: res.data};
    }
}

function updateTermsOfUse(data) {
    return dispatch => {
        appService.updateTermsOfUse(data)
            .then(response => dispatch(success(response)));
    };

    function success(res) {
        if (res.status === 202) {
            return {type: APP.UPDATE_TERMS_OF_USE, payload: res.data};
        }
    }
}

function closeTCModal() {
    return dispatch => {
        dispatch({type: APP.CLOSE_TERMS_OF_USE_MODAL});
    };
}

const appAction = {
    checkTermsOfUse,
    acceptTermsOfUse,
    closeTCModal,
    getTermsOfUse,
    updateTermsOfUse
};

// constants
export const APP = {
    LOADING: "APP_LOADING",
    RESPONSE_TERMS_OF_USE: "RESPONSE_TERMS_OF_USE",
    ACCEPT_TERMS_OF_USE: "ACCEPT_TERMS_OF_USE",
    CLOSE_TERMS_OF_USE_MODAL: "CLOSE_TERMS_OF_USE_MODAL",
    GET_TERMS_OF_USE: "GET_TERMS_OF_USE",
    UPDATE_TERMS_OF_USE: "UPDATE_TERMS_OF_USE",

    REFRESH_STORE: "REFRESH_STORE"
};

export default appAction;