import clientService from "../services/client.service";

function create(data) {

    return dispatch => {
        dispatch(request());
        clientService.add(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function request() {
        return {type: CLIENT.REQUEST};
    }

    function success(res) {
        if (res.status === 201) {
            return {type: CLIENT.ADD_SUCCESS, payload: res.data};
        }
        return error(res);
    }

    function error(reason) {
        reason = reason.response;
        // validation error
        if (reason.status === 422) {
            return {type: CLIENT.VALIDATION_ERROR, payload: reason.data};
        }
        return {type: CLIENT.ERROR, payload: reason.data};
    }
}

function update(data) {
    return dispatch => {
        dispatch(request());
        clientService.update(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function request() {
        return {type: CLIENT.REQUEST};
    }

    function success(res) {
        if (res.status === 202) {
            return {type: CLIENT.UPDATE_SUCCESS, payload: res.data};
        }
        return error(res);
    }

    function error(reason) {
        reason = reason.response;
        // validation error
        if (reason.status === 422) {
            return {type: CLIENT.VALIDATION_ERROR, payload: reason.data};
        }
        return {type: CLIENT.ERROR, payload: reason.data};
    }
}

function readAll(data) {
    return dispatch => {
        dispatch(request());
        clientService.readAll(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function request() {
        return {type: CLIENT.REQUEST};
    }

    function success(res) {
        return {type: CLIENT.READ_ALL_SUCCESS, payload: res.data};
    }

    function error(reason) {
        reason = reason.response;
        return {type: CLIENT.ERROR, payload: reason.data};
    }
}

function read(data) {
    return dispatch => {
        dispatch(request());
        clientService.read(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function request() {
        return {type: CLIENT.REQUEST};
    }

    function success(res) {
        return {type: CLIENT.READ_SUCCESS, payload: res.data};
    }

    function error(reason) {
        reason = reason.response;
        if (reason.status === 404) {
            return {type: CLIENT.NOT_FOUND};
        }
        return {type: CLIENT.ERROR, payload: reason.data};
    }
}

function changeStatus(data) {
    return dispatch => {
        dispatch(request());
        clientService.changeStatus(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function request() {
        return {type: CLIENT.REQUEST};
    }

    function success(res) {
        if (res.status === 202) {
            return {type: CLIENT.CHANGE_STATUS_SUCCESS, payload: data};
        }
        return error(res);
    }

    function error(reason) {
        reason = reason.response;
        if (reason.status === 404) {
            return {type: CLIENT.NOT_FOUND};
        }
        return {type: CLIENT.ERROR, payload: reason.data};
    }
}

function readAllClientsFirm(data) {
    return dispatch => {
        dispatch(request());
        clientService.readAllClientsFirm(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function request() {
        return {type: CLIENT.REQUEST};
    }

    function success(res) {
        return {type: CLIENT.READ_ALL_SUCCESS, payload: res.data};
    }

    function error(reason) {
        reason = reason.response;
        return {type: CLIENT.ERROR, payload: reason.data};
    }
}

function readAllClientEvals(data) {
    return dispatch => {
        dispatch(request());
        clientService.readAllClientEvals(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function request() {
        return {type: CLIENT.REQUEST};
    }

    function success(res) {
        return {type: CLIENT.READ_ALL_SUCCESS, payload: res.data};
    }

    function error(reason) {
        reason = reason.response;
        return {type: CLIENT.ERROR, payload: reason.data};
    }
}

const clientAction = {
    create,
    update,
    readAll,
    read,
    changeStatus,
    readAllClientsFirm,
    readAllClientEvals
};

// constants
export const CLIENT = {

    REQUEST: "CLIENT_REQUEST",
    ERROR: "CLIENT_ERROR",
    NOT_FOUND: "CLIENT_NOT_FOUND",
    VALIDATION_ERROR: "CLIENT_VALIDATION_ERROR ",
    NOT_ALLOWED: "CLIENT_NOT_ALLOWED",

    ADD_SUCCESS: "CLIENT_ADD_SUCCESS",
    UPDATE_SUCCESS: "CLIENT_UPDATE_SUCCESS",
    READ_ALL_SUCCESS: "CLIENT_READ_ALL_SUCCESS",
    READ_SUCCESS: "CLIENT_READ_SUCCESS",
    CHANGE_STATUS_SUCCESS: "CLIENT_CHANGE_STATUS_SUCCESS"
};

export default clientAction;