import React, {Component} from 'react';
import {connect} from "react-redux";
import {EditUserForm} from "../../../form/users/EditUserForm";
import SABody from "../../other/SABody";
import SASubHeader from "../../other/SASubHeader";

class SAOrgManageUserPage extends Component {
    render() {
        const {loading, match} = this.props;
        return (
            <SABody loading={loading}>
                <SASubHeader title={"Manage User"}/>
                <EditUserForm orgid={match.params.id} userid={match.params.uid}/>
            </SABody>
        );
    }
}

function mapStateToProps(state) {
    return {
        ...state.user
    };
}

const cSAOrgManageUserPage = connect(mapStateToProps)(SAOrgManageUserPage);
export {cSAOrgManageUserPage as SAOrgManageUserPage};