import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Form, Header, Segment, Step} from "semantic-ui-react";
import helper from "../../../../helper/helper";
import QMCheckBox from "../../../other/form/QMCheckBox";
import {E_QUESTION_TYPE} from "../../../../helper/const";

// props - status, setName, groupName, onSave, onSelectAddGroup, isFromGroup(if flow come from group form - flag)
class AddQuestionForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            status: this.props.status,
            answer_type: E_QUESTION_TYPE.ANSWER_TYPE_U_E,
            group_id: null,
        }
    }

    render() {
        const {name, status, answer_type, group_id} = this.state;
        const {setName, groups} = this.props;
        return (
            <Segment basic padded='very'>
                <Form>
                    <Step.Group size='mini'>
                        {setName && <Step title={setName}/>}
                        {/*{groupName && <Step title={groupName}/>}*/}
                        <Step title={"Add Question"} active/>
                    </Step.Group>
                    <br/>
                    <Header>Question Form:</Header>
                    <br/>
                    <Form.Group>
                        <Form.Field label='Question:' control={"textarea"}
                                    rows={2} placeholder='Question' value={name}
                                    onChange={(e) => this.setState({name: e.target.value})}
                                    width={10}/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Dropdown label='Answer Type:'
                                       width={10}
                                       selection value={answer_type}
                                       onChange={(e, {value}) => this.setState({answer_type: value})}
                                       name={"type"}
                                       options={helper.getListOfAnswerType()}/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Dropdown label='Group:'
                                       width={10}
                                       selection value={group_id}
                                       onChange={(e, {value}) => this.setState({group_id: value})}
                                       name={"type"}
                                       options={groups}/>
                    </Form.Group>
                    <Form.Field>
                        <label>Status:</label>
                        <QMCheckBox toggle onChange={(checked) => this.setState({status: checked})}
                                    checked={this.state.status} disabled={!this.props.status}/>
                    </Form.Field>
                    <Form.Field>
                        <Button type='submit' primary
                                onClick={() => {
                                    this.props.onSave({
                                        title: name,
                                        status: status,
                                        answer_type: answer_type,
                                        group_id: group_id
                                    });
                                    this.setState({
                                        name: "",
                                        status: this.props.status,
                                        answer_type: 1,
                                        group_id: null
                                    });
                                }}
                                floated='right'
                                circular
                                disabled={!(name)}>Save & Add New</Button>
                        <Button circular size={"medium"} disabled={!(name)}
                                onClick={() => {
                                    this.props.onSubmit({
                                        title: name,
                                        status: status,
                                        answer_type: answer_type,
                                        group_id: group_id
                                    });
                                }}
                                floated="right" primary>Submit</Button>
                    </Form.Field>
                </Form>
            </Segment>
        );
    }
}

AddQuestionForm.propTypes = {
    status: PropTypes.bool,
    setName: PropTypes.string,
    group_id: PropTypes.string,
    groups: PropTypes.array.isRequired,
    onSave: PropTypes.func,
    onSubmit: PropTypes.func
};

export default AddQuestionForm;
