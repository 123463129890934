import React, {Component} from 'react';
import {connect} from "react-redux";
import subAction from "../../../../actions/sub.action";
import {Divider, Header, Icon, Label, List, Segment, Table} from "semantic-ui-react";
import SABody from "../../qualmetadmin/other/SABody";
import SASubHeader from "../../qualmetadmin/other/SASubHeader";
import {ORG_SUB_STATUS} from "../../../../helper/const";

class SAOrgBillingPage extends Component {
    componentDidMount() {
        this.props.dispatch(subAction.getAll());
    }

    getStatusTitle = status => {
        switch (status) {
            case ORG_SUB_STATUS.PENDING:
                return "Pending";
            case ORG_SUB_STATUS.INVOICED:
                return "Invoiced";
            case ORG_SUB_STATUS.PAID:
                return "Paid";
            case ORG_SUB_STATUS.CANCELED:
                return "Canceled";
            case ORG_SUB_STATUS.DENIED:
                return "Denied";
            case ORG_SUB_STATUS.REFUNDED:
                return "Refunded";
            default:
                return "";
        }
    };

    render() {
        const {loading, items} = this.props;
        let content;
        if (items.length > 0) {
            content = (
                <Segment padded={"very"} basic>
                    <List horizontal>
                        <List.Item>
                            <b>Status:</b>
                        </List.Item>
                        <List.Item>
                            <Label circular color={"red"} empty/> Pending
                        </List.Item>
                        <List.Item>
                            <Label circular color={"yellow"} empty/> Other
                        </List.Item>
                        <List.Item>
                            <Label circular color={"green"} empty/> Paid
                        </List.Item>
                    </List>
                    <Divider/>
                    <Table basic='very' padded selectable stackable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Organization</Table.HeaderCell>
                                <Table.HeaderCell>Plan Type</Table.HeaderCell>
                                <Table.HeaderCell>Date Created</Table.HeaderCell>
                                <Table.HeaderCell>Renewal Date</Table.HeaderCell>
                                <Table.HeaderCell>Last Payment Date</Table.HeaderCell>
                                <Table.HeaderCell>Amount</Table.HeaderCell>
                                <Table.HeaderCell textAlign={"center"}>Last Payment Status</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {items.map((item, index) => {
                                return (
                                    <Table.Row className={"row"} key={index}
                                               warning={item.status && (item.status < 7 && item.status > 2)}
                                               negative={item.status === 2}
                                               positive={item.status === 7}
                                               onClick={() => this.props.history.push(`/superadmin/payment/billing/org/${item.id}?title=${encodeURIComponent(item.name)}`)}>
                                        <Table.Cell>{item.name}</Table.Cell>
                                        <Table.Cell>{item.plan_title}</Table.Cell>
                                        <Table.Cell>{item.created_at}</Table.Cell>
                                        <Table.Cell>{item.expired_at}</Table.Cell>
                                        <Table.Cell>{item.last_payment_date}</Table.Cell>
                                        <Table.Cell>{item.amount ? `$${item.amount}` : ""}</Table.Cell>
                                        <Table.Cell textAlign={"center"}>{this.getStatusTitle(item.status)}</Table.Cell>
                                    </Table.Row>
                                );
                            })}
                        </Table.Body>
                    </Table>
                </Segment>
            );
        } else {
            content = (
                <Segment basic placeholder piled>
                    <Header icon>
                        <Icon name='file outline'/>
                        No organizations are listed.
                    </Header>
                </Segment>
            );
        }
        return (
            <SABody loading={loading}>

                <SASubHeader title={"Plan Billing"}/>

                {content}
            </SABody>
        );
    }
}

export default connect(state => {
    return state.sub;
})(SAOrgBillingPage);
