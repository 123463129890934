import React, {Component} from 'react';
import {Segment} from "semantic-ui-react";
import {connect} from "react-redux";
import {OrgMenuBar} from "../../../other/OrgMenuBar";
import evaluationQuestionAction from "../../../../../../actions/evaluation_question.action";
import AddQuestionSetForm from "../../../../form/questions/AddQuestionSetForm";
import AddQuestionForm from "../../../../form/questions/AddQuestionForm";
import {orgState} from "../../../../../../helper/stateUtil";
import ErrorMessage from "../../../../../other/ErrorMessage";
import OrgSubHeader from "../../../other/OrgSubHeader";
import Body from "../../../../../other/Body";

class OrgAddQuestionSetPage extends Component {

    // data structure
    // {
    //     "name":"First",
    //     "status":false,
    //     "question_groups":[
    //         {
    //             "name":"Group 1",
    //             "status":true,
    //             "questions":[
    //                 {
    //                     "title":"What is a questions?",
    //                     "answer_type":1,
    //                     "status":false
    //                 }
    //                 ]
    //         }
    //         ]
    // }

    constructor(props) {
        super(props);
        this.state = {
            data: {},
            step: 1, // 1 - set, 2 - group, 3 - question
            set: null, // title of set
            group: null
        };
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        this.props.dispatch(evaluationQuestionAction.getAllActiveQueGroups());
    }

    onSubmit() {
        const {data} = this.state;
        data.org_id = this.props.org.id;
        this.props.dispatch(evaluationQuestionAction.createQuestionData(data));
    }

    componentDidUpdate() {
        const {isAllowed, isDone} = this.props.eq;
        if (!isAllowed) {
            this.props.history.replace("/forbidden");
        }
        if (isDone) {
            this.props.history.go(-1);
        }
    }

    render() {
        const {org} = this.props;
        const {loading, error, groups} = this.props.eq;
        const {set, step, data} = this.state;
        let content;
        switch (step) {
            case 1: // question set
                content = (
                    <AddQuestionSetForm
                        firmClientName={org.str_client_firm}
                        onSave={(value) => {
                            this.setState({
                                data: value,
                                set: value,
                                step: 2
                            });
                        }}/>
                );
                break;
            // case 2: // question set's group
            //     content = (
            //         <AddQuestionSetGroupForm status={set.status} setName={set.name} onSave={(value) => {
            //             data.question_groups.push(value);
            //             this.setState({
            //                 data: data,
            //                 group: value,
            //                 step: 3
            //             });
            //         }}/>
            //     );
            //     break;
            case 2:
                content = (
                    <AddQuestionForm
                        groups={groups}
                        status={set.status}
                        setName={set.name}
                        onSave={(value) => {
                            data.questions.push(value);
                            this.setState({data: data});
                        }}
                        onSubmit={(value) => {
                            data.questions.push(value);
                            this.setState({data: data});
                            this.onSubmit();
                        }}/>
                );
                break;
            default:
                content = (<div>Something went wrong!</div>);
        }

        return (
            <Body loading={loading}>

                <OrgMenuBar/>

                <OrgSubHeader backArrow title={this.props.org.name}/>

                <style>{`
                 .ui.steps .step {
                    padding: 0.5em 2em
                 }
            `}</style>

                <Segment basic>
                    <ErrorMessage errors={error}/>
                </Segment>

                {content}
            </Body>
        );
    }
}

function mapStateToProps(state) {
    let s = orgState(state);
    return {...s, eq: state.evaluation_question};
}

const cOrgAddQuestionSetPage = connect(mapStateToProps)(OrgAddQuestionSetPage);
export {cOrgAddQuestionSetPage as OrgAddQuestionSetPage};
