import {PRACTICE} from "../actions/practice.action";

const initState = {
    isAllowed: true,
    loading: false,
    isDone: false,
    practices: [],
    error: [],
    practice: null
};

export default function practiceReducer(state = initState, action) {
    // reset
    state.error = [];
    state.isDone = false;
    state.loading = false;
    state.isAllowed = true;

    // filter
    switch (action.type) {

        // common
        case PRACTICE.REQUEST:
            return {...state, loading: true};

        case PRACTICE.VALIDATION_ERROR:
            return {...state, error: action.payload};

        case PRACTICE.ERROR:
            return {...state, error: [...action.payload]};

        // not allowed to access
        case PRACTICE.NOT_ALLOWED:
            return {...state, isAllowed: false};

        // add practice area
        case PRACTICE.ADD_SUCCESS:
            return {...state, isDone: true};

        // update practice area
        case PRACTICE.UPDATE_SUCCESS:
            return {...state, isDone: true};

        // read all practice areas
        case PRACTICE.READ_ALL_SUCCESS:
            return {...state, practices: action.payload};

        // read single practice area
        case PRACTICE.READ_SUCCESS:
            return {...state, practice: action.payload};

        case PRACTICE.NOT_FOUND:
            return {...state, not_found: true};

        case PRACTICE.CHANGE_STATUS_SUCCESS:
            state.practices[state.practices.findIndex((practice) => practice.id === action.payload.id)].status = action.payload.status;
            return {...state};

        default:
            return state;
    }
}