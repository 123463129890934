import React, {Component} from 'react';
import {orgState} from "../../../../helper/stateUtil";
import {connect} from "react-redux";
import Body from "../../../other/Body";
import {OrgMenuBar} from "../other/OrgMenuBar";
import OrgSubHeader from "../other/OrgSubHeader";
import * as qs from "query-string";
import ListLawyersEvaluations from "../../list/lawyers/ListLawyersEvaluations";
import lawyerAction from "../../../../actions/lawyer.action";

class LawyerEvaluationsPage extends Component {
    constructor(props) {
        super(props);
        this.params = qs.parse(props.location.search);
    }

    componentDidMount() {
        const {match: {params: {id}}} = this.props;
        this.props.dispatch(lawyerAction.readAllEvaluateesEvals({id: id}));
    }

    render() {
        const {loading, org, lawyers: items, history, match: {params: {id: lawyerId}}} = this.props;
        const {h: title} = this.params;
        return (
            <Body loading={loading}>

                <OrgMenuBar/>

                <OrgSubHeader backArrow title={`Evaluatees Evaluations - ${title}`}/>

                <ListLawyersEvaluations
                    items={items}
                    org={org}
                    onClick={(item) => {
                        history.push(`/lawyers/${lawyerId}/evaluation/${item.id}/summary?h=${encodeURIComponent(title)}&e=${encodeURIComponent(item.name)}`);
                    }}
                    onClickOfNewEvaluation={() => history.push(`/lawyers/${lawyerId}/new-evaluation${this.props.location.search}`)}/>

            </Body>
        );
    }
}

function mapStateToProps(state) {
    let s = orgState(state);
    return {...s, ...state.lawyer};
}

const cClientsEvaluationsPage = connect(mapStateToProps)(LawyerEvaluationsPage);
export {cClientsEvaluationsPage as LawyerEvaluationsPage};
