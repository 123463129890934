import orgService from "../services/org.service";
import {AUTH} from "./auth.action";

function create(data) {

    return dispatch => {
        dispatch(request());
        orgService.add(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function request() {
        return {type: ORG.REQUEST};
    }

    function success(res) {
        if (res.status === 201) {
            return {type: ORG.ADD_SUCCESS, payload: res.data};
        }
        return error(res);
    }

    function error(reason) {
        reason = reason.response;
        // validation error
        if (reason.status === 422) {
            return {type: ORG.VALIDATION_ERROR, payload: reason.data};
        }
        return {type: ORG.ERROR, payload: reason.data};
    }
}

function update(data) {
    return dispatch => {
        dispatch(request());
        orgService.update(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function request() {
        return {type: ORG.REQUEST};
    }

    function success(res) {
        if (res.status === 202) {
            return {type: ORG.UPDATE_SUCCESS, payload: res.data};
        }
        return error(res);
    }

    function error(reason) {
        reason = reason.response;
        // validation error
        if (reason.status === 422) {
            return {type: ORG.VALIDATION_ERROR, payload: reason.data};
        }
        return {type: ORG.ERROR, payload: reason.data};
    }
}

// for org admin settings
function updateSettings(data) {
    return dispatch => {
        dispatch(request());
        orgService.updateSettings(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function request() {
        return {type: AUTH.REQUEST};
    }

    function success(res) {
        if (res.status === 202) {
            return {type: ORG.UPDATE_SETTING_SUCCESS, payload: data};
        }
        return error(res);
    }

    function error(reason) {
        reason = reason.response;
        // validation error
        if (reason.status === 422) {
            return {type: AUTH.VALIDATION_ERROR, payload: reason.data};
        }
        return {type: AUTH.ERROR, payload: reason.data};
    }
}

function updateOrgType(data) {
    return dispatch => {
        dispatch(request());
        orgService.updateOrgType(data).then(res => dispatch(success(res)));
    };

    function request() {
        return {type: AUTH.REQUEST};
    }

    function success(res) {
        if (res.status === 202) {
            return {type: ORG.UPDATE_ORG_TYPE_SUCCESS, payload: data};
        }
    }
}

function readAll(flag) {
    return dispatch => {
        dispatch(request());
        orgService.readAll(flag).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function request() {
        return {type: ORG.REQUEST};
    }

    function success(res) {
        return {type: ORG.READ_ALL_SUCCESS, payload: res.data};
    }

    function error(reason) {
        return {type: ORG.ERROR, payload: ["Something went wrong! Please try again..."]};
    }
}

function read(data) {
    return dispatch => {
        dispatch(request());
        orgService.read(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function request() {
        return {type: ORG.REQUEST};
    }

    function success(res) {
        return {type: ORG.READ_SUCCESS, payload: res.data};
    }

    function error(reason) {
        reason = reason.response;
        return {type: ORG.ERROR, payload: reason.data};
    }
}

// for org admin settings
function activateOrg(data) {
    return dispatch => {
        dispatch(request());
        orgService.activateOrg(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function request() {
        return {type: ORG.REQUEST};
    }

    function success(res) {
        if (res.status === 202) {
            return {type: ORG.ACTIVATE_ORG};
        }
        return error(res);
    }

    function error(reason) {
        reason = reason.response;
        // validation error
        if (reason.status === 422) {
            return {type: AUTH.VALIDATION_ERROR, payload: reason.data};
        }
        return {type: AUTH.ERROR, payload: reason.data};
    }
}

function getSSODetails(data) {
    return dispatch => {
        dispatch({type: ORG.REQUEST});
        orgService.getSSODetails(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: ORG.SSO_GET_DETAILS, payload: res.data};
    }

    function error(reason) {
        reason = reason.response;
        return {type: ORG.ERROR, payload: reason.data};
    }
}

function updateSSODetails(data) {
    return dispatch => {
        dispatch({type: ORG.REQUEST});
        orgService.updateSSODetails(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        if (res.status === 202) {
            return {type: ORG.SSO_UPDATE_DETAILS, payload: data};
        }
        return error(res);
    }

    function error(reason) {
        reason = reason.response;
        return {type: ORG.ERROR, payload: reason.data};
    }
}

function updateSettingSSODetails(data) {
    return dispatch => {
        dispatch({type: ORG.REQUEST});
        orgService.updateSettingSSODetails(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        if (res.status === 202) {
            return {type: ORG.SSO_UPDATE_DETAILS, payload: data};
        }
        return error(res);
    }

    function error(reason) {
        reason = reason.response;
        return {type: ORG.ERROR, payload: reason.data};
    }
}

function parseMetadata(data) {
    return dispatch => {
        dispatch({type: ORG.REQUEST});
        orgService.parseMetadata(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        if (res.status === 202) {
            return {type: ORG.SSO_METADATA_PARSE, payload: res.data};
        }
        return error(res);
    }

    function error(reason) {
        reason = reason.response;
        return {type: ORG.ERROR, payload: reason.data};
    }
}

function getDomains(data) {
    return dispatch => {
        dispatch({type: AUTH.REQUEST});
        orgService.getDomains(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: ORG.DOMAINS_SUCCESS, payload: res.data};
    }

    function error(reason) {
        reason = reason.response;
        return {type: ORG.ERROR, payload: reason.data};
    }
}

function updateDomains(data) {
    return dispatch => {
        dispatch({type: AUTH.REQUEST});
        orgService.updateDomains(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        if (res.status === 202) {
            return {type: ORG.UPDATE_DOMAINS_SUCCESS, payload: data.domains};
        }
        return error(res);
    }

    function error(reason) {
        reason = reason.response;
        return {type: ORG.ERROR, payload: reason.data};
    }
}

function getAllPlans(data) {
    return dispatch => {
        dispatch({type: AUTH.REQUEST});
        orgService.getAllPlans(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: ORG.GET_ACTIVE_PLANS, payload: res.data};
    }

    function error(reason) {
        reason = reason.response;
        return {type: ORG.ERROR, payload: reason.data};
    }
}

function deleteOrg(data) {
    return dispatch => {
        dispatch({type: ORG.REQUEST});
        orgService.deleteOrg(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        if (res.status === 202) {
            return {type: ORG.DELETE_ORG};
        }
        return error(res);
    }

    function error(reason) {
        reason = reason.response;
        return {type: ORG.ERROR, payload: reason.data};
    }
}

const orgAction = {
    create,
    update,
    readAll,
    read,
    updateSettings,
    updateOrgType,
    activateOrg,
    getSSODetails,
    updateSSODetails,
    updateSettingSSODetails,
    getDomains,
    updateDomains,
    parseMetadata,
    getAllPlans,
    deleteOrg
};

// constants
export const ORG = {

    REQUEST: "ORG_REQUEST",
    ERROR: "ORG_ERROR",
    VALIDATION_ERROR: "ORG_VALIDATION_ERROR",

    ADD_SUCCESS: "ORG_ADD_SUCCESS",
    UPDATE_SUCCESS: "ORG_UPDATE_SUCCESS",
    READ_ALL_SUCCESS: "ORG_READ_ALL_SUCCESS",
    READ_SUCCESS: "ORG_READ_SUCCESS",
    UPDATE_SETTING_SUCCESS: 'ORG_UPDATE_SETTING_SUCCESS',
    UPDATE_ORG_TYPE_SUCCESS: 'ORG_UPDATE_ORG_TYPE_SUCCESS',
    ACTIVATE_ORG: "ORG_ACTIVATE_ORG",

    SSO_GET_DETAILS: "ORG_SSO_GET_DETAILS",
    SSO_UPDATE_DETAILS: "ORG_SSO_UPDATE_DETAILS",
    SSO_METADATA_PARSE: "ORG_SSO_METADATA_PARSE",

    DOMAINS_SUCCESS: "ORG_GET_DOMAINS_SUCCESS",
    UPDATE_DOMAINS_SUCCESS: "ORG_UPDATE_DOMAINS_SUCCESS",

    GET_ACTIVE_PLANS: "ORG_GET_ACTIVE_PLANS",

    DELETE_ORG: "ORG_DELETE_ORG"

};

export default orgAction;
