import Pusher from 'pusher-js';
import Echo from "laravel-echo";

let socket;

socket = new Echo({
    broadcaster: 'pusher',
    key: process.env.REACT_APP_PUSHER_KEY || '0d824702e870f0e24ef0',
    cluster: 'mt1',
    forceTLS: true
});

export default socket;
