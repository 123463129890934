import React, {Component} from 'react';
import {connect} from "react-redux";
import SABody from "../other/SABody";
import SASubHeader from "../other/SASubHeader";
import helper from "../../../../helper/helper";
import {Button, Form, Segment} from "semantic-ui-react";
import ErrorMessage from "../../../other/ErrorMessage";
import QMCheckBox from "../../../other/form/QMCheckBox";
import helpAction from "../../../../actions/help.action";
import {HELP_CATS} from "../../../../helper/const";

class AddHelpDocsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: '',
            url: '',
            status: true,
            file: null
        };
        this.title = "Help";
        this.menuType = parseInt(props.match.params.key);
        this.type = {};
        helper.helpMenus.forEach((menu) => {
            if (this.menuType === menu.value) {
                this.type = menu;
                this.title = menu.text;
            }
        });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.isDone) {
            nextProps.history.go(-1);
        }
    }

    onSave = () => {
        this.props.dispatch(helpAction.create({
            type: this.menuType,
            ...this.state
        }));
    };

    render() {
        const {error, loading} = this.props;
        const {title, url, status, file} = this.state;
        let field;
        if (this.menuType === HELP_CATS.VIDEOS) {
            field = (
                <Form.Input label='Youtube Video URL:' width={8} value={url}
                            onChange={(e) => this.setState({url: e.target.value})}/>
            );
        } else {
            field = (
                <Form.Field width={8}>
                    <label>Upload file:</label>
                    <input type={'file'} onChange={(e) => this.setState({file: e.target.files[0]})}/>
                </Form.Field>
            );
        }
        return (
            <SABody loading={loading}>

                <SASubHeader title={"Add Document - " + this.title} backArrow/>

                <Segment basic padded='very'>
                    <Form>
                        <ErrorMessage errors={error}/>
                        <Form.Input label='Document Name' placeholder='Document Name' width={8} value={title}
                                    onChange={(e) => this.setState({title: e.target.value})}/>
                        {field}
                        <Form.Field>
                            <label>Status:</label>
                            <QMCheckBox toggle onChange={(checked) => this.setState({status: checked})}
                                        checked={status}/>
                        </Form.Field>

                        <Button type='submit' primary onClick={this.onSave} circular
                                disabled={loading || !(title && (file || (url && url.includes("youtu") )))}
                                floated='right'>Add</Button>
                        <Button type='button' secondary inverted circular
                                disabled={loading}
                                onClick={() => this.props.history.go(-1)}
                                floated='right'>Cancel</Button>
                        <br/>
                    </Form>
                </Segment>

            </SABody>
        );
    }
}

AddHelpDocsPage.propTypes = {};

function mapStateToProps(state) {
    return {...state.help};
}

const cAddHelpDocsPage = connect(mapStateToProps)(AddHelpDocsPage);
export {cAddHelpDocsPage as AddHelpDocsPage};
