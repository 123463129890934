import React, {Component} from 'react';
import {connect} from "react-redux";
import {orgState, roles} from "../../../helper/stateUtil";
import helper from "../../../helper/helper";
import {Button, Form, Header, Icon, Segment, Table} from "semantic-ui-react";
import {EVALUATION_STATUS, MATTER} from "../../../helper/const";
import {withRouter} from "react-router-dom";
import SetupCheckList from "../components/SetupCheckList";
import NewEvaluationBtn from "../components/NewEvaluationBtn";

class ListMyEvaluations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            query: "",
            evalType: -1,
            status: EVALUATION_STATUS.ALL,
            open: false // new evaluation dialog
        };
    }

    getBtnOfEval = item => {
        let url;
        if (item.status === EVALUATION_STATUS.DUE || item.status === EVALUATION_STATUS.STARTED) {
            url = '/evaluation/' + item.id + '?diffId=' + item.diff_id + '&type=' + this.getTypeOfEval(item);
        } else if (item.status === EVALUATION_STATUS.COMPLETED) {
            url = '/evaluation/view/' + item.id + '?diffId=' + item.diff_id + '&type=' + this.getTypeOfEval(item);
        } else {
            return <div/>;
        }
        return <Button size="tiny" color={"teal"}
                       onClick={() => {
                           this.props.history.push(url);
                       }}>
            {this.getBtnTitle(item)}
        </Button>;
    };

    getTypeOfEval = item => {
        switch (item.diff_id) {
            case "fe":
                return "firm";
            case "me":
                return "matter";
            case "ee":
                return "lawyer"; // evaluatee
            default:
                return "none";
        }
    };

    getBtnTitle = item => {
        switch (item.status) {
            case EVALUATION_STATUS.DUE:
                return "Start Evaluation";
            case EVALUATION_STATUS.STARTED:
                return "Complete Evaluation";
            case EVALUATION_STATUS.COMPLETED:
                return "View Evaluation";
            default:
                return "";
        }
    };

    getStrTypeOfEval = item => {
        switch (item.diff_id) {
            case "fe":
                return this.props.org.str_client_firm;
            case "me":
                return "Matter";
            case "ee":
                return "Evaluatee";
            default:
                return "Unknown";
        }
    };

    render() {
        const {org, evaluations, roles} = this.props;
        let items = evaluations;
        let {query, status, evalType} = this.state;
        query = query.toLowerCase();
        if (evalType !== EVALUATION_STATUS.ALL) {
            items = items.filter((item) => item.diff_id === evalType);
        }
        if (status !== EVALUATION_STATUS.ALL) {
            items = items.filter((item) => item.status === status);
        }
        if (query.length > 0) {
            items = items.filter((item) => {
                const {name, lawyer_name, client_name} = item;
                return (
                    name.toLowerCase().includes(query)
                    || (lawyer_name && lawyer_name.toLowerCase().includes(query))
                    || client_name.toLowerCase().includes(query)
                );
            });
        }
        let content;
        if (items.length > 0) {
            content = (
                <Segment basic style={{padding: "0 3em"}}>
                    <Header>
                        Your Evaluations
                    </Header>
                    <Table basic='very' selectable stackable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Due Date</Table.HeaderCell>
                                <Table.HeaderCell>Cycle No</Table.HeaderCell>
                                <Table.HeaderCell>Evaluation Category</Table.HeaderCell>
                                <Table.HeaderCell>Name</Table.HeaderCell>
                                <Table.HeaderCell>{org.str_client_firm} Name</Table.HeaderCell>
                                <Table.HeaderCell>Evaluatee</Table.HeaderCell>
                                <Table.HeaderCell>Status</Table.HeaderCell>
                                <Table.HeaderCell textAlign='center'>Actions</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {items.map((item, index) => {
                                return (
                                    <Table.Row key={index} className={"row"}>
                                        <Table.Cell>{item.due_date}</Table.Cell>
                                        <Table.Cell>{item.cycle_no}</Table.Cell>
                                        <Table.Cell>{this.getStrTypeOfEval(item)}</Table.Cell>
                                        <Table.Cell>{item.name}</Table.Cell>
                                        <Table.Cell>{item.client_name}</Table.Cell>
                                        <Table.Cell>{item.lawyer_name}</Table.Cell>
                                        <Table.Cell>{item.str_status}</Table.Cell>
                                        <Table.Cell textAlign='center'>
                                            {this.getBtnOfEval(item)}
                                        </Table.Cell>
                                    </Table.Row>
                                );
                            })}
                        </Table.Body>
                    </Table>
                </Segment>
            );
        } else {
            content = (
                <Segment basic placeholder piled clearing>
                    <Header icon>
                        <Icon name='file outline'/>
                        No evaluations cycles are listed.
                    </Header>
                </Segment>
            );
        }

        return (
            <div>
                <Segment basic style={{padding: "2em 3em 1em 3em"}}>
                    <Form>
                        <Form.Group>
                            <Form.Input placeholder='Search' value={this.state.query}
                                        onChange={(e) => this.setState({query: e.target.value})}/>
                            <Form.Dropdown selection value={evalType}
                                           onChange={(e, {value}) => this.setState({evalType: value})}
                                           options={helper.evaluationTypes(org)}/>
                            <Form.Dropdown selection value={status}
                                           onChange={(e, {value}) => this.setState({status: value})}
                                           options={helper.evaluationCycleStatus}/>
                            {(roles && roles.includes(MATTER)) && <NewEvaluationBtn org={org}/>}
                        </Form.Group>
                    </Form>
                </Segment>
                <br/>
                {org.is_free_plan && <SetupCheckList org={org}/>}
                <br/>
                {content}
            </div>
        );
    }
}

ListMyEvaluations.propTypes = {};

function mapStateToProps(state) {
    const s = orgState(state);
    const r = roles(state);
    return {...s, ...r, ...state.matter};
}

const cListMyEvaluations = withRouter(connect(mapStateToProps)(ListMyEvaluations));
export {cListMyEvaluations as ListMyEvaluations};
