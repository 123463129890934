import {PLAN} from "../actions/plan.action";

const initState = {
    loading: false,
    isDone: false,
    items: [],
    orgs: [],
    error: [],
    item: {},
    currentPlan: {}
};

export default function planReducer(state = initState, action) {
    // reset
    state.error = [];
    state.isDone = false;
    state.loading = false;

    // filter
    switch (action.type) {

        // common
        case PLAN.REQUEST:
            return {...state, loading: true};

        case PLAN.VALIDATION_ERROR:
            return {...state, error: action.payload};

        case PLAN.ERROR:
            return {...state, error: [...action.payload]};

        // not allowed to access
        case PLAN.NOT_ALLOWED:
            return {...state, isAllowed: false};

        case PLAN.ADD_SUCCESS:
            return {...state, isDone: true};

        case PLAN.UPDATE_SUCCESS:
            return {...state, isDone: true};

        case PLAN.READ_ALL_SUCCESS:
            return {...state, items: action.payload};

        case PLAN.READ_SUCCESS:
            return {...state, item: action.payload};

        case PLAN.NOT_FOUND:
            return {...state, not_found: true};

        case PLAN.CHANGE_STATUS_SUCCESS:
            state.items[action.payload.index].status = action.payload.status;
            return {...state};

        case PLAN.GET_ACTIVE_PLANS:
            return {...state, items: action.payload};

        case PLAN.UNMOUNT:
            return {...initState};

        case PLAN.GET_CURRENT_PLAN:
            return {...state, currentPlan: action.payload};

        case PLAN.GET_ALL_ORGS:
            return {...state, orgs: action.payload};

        case PLAN.REQUEST_PLAN_SUCCESS:
            return {...state, isDone: true};

        default:
            return state;
    }
}