import React, {Component} from 'react';
import {orgState} from "../../../../helper/stateUtil";
import {connect} from "react-redux";
import Body from "../../../other/Body";
import {OrgMenuBar} from "../other/OrgMenuBar";
import OrgSubHeader from "../other/OrgSubHeader";
import clientAction from "../../../../actions/client.action";
import ListClientsEvaluations from "../../list/clients/ListClientsEvaluations";
import * as qs from "query-string";

class ClientsEvaluationsPage extends Component {
    constructor(props) {
        super(props);
        this.params = qs.parse(props.location.search);
    }

    componentDidMount() {
        const {match: {params: {id}}} = this.props;
        this.props.dispatch(clientAction.readAllClientEvals({id: id}));
    }

    render() {
        const {loading, org, clients: items, history, match: {params: {id: clientId}}} = this.props;
        const {h: title} = this.params;
        return (
            <Body loading={loading}>

                <OrgMenuBar/>

                <OrgSubHeader backArrow title={`${org.str_client_firm}s - ${title}`}/>

                <ListClientsEvaluations
                    items={items}
                    org={org}
                    onClick={(item) => {
                        history.push(`/clients-firms/${clientId}/evaluation/${item.id}/summary?h=${encodeURIComponent(title)}&e=${encodeURIComponent(item.name)}`);
                    }}
                    onClickOfNewEvaluation={() => history.push(`/clients-firms/${clientId}/new-evaluation${this.props.location.search}`)}/>

            </Body>
        );
    }
}

function mapStateToProps(state) {
    let s = orgState(state);
    return {...s, ...state.client};
}

const cClientsEvaluationsPage = connect(mapStateToProps)(ClientsEvaluationsPage);
export {cClientsEvaluationsPage as ClientsEvaluationsPage};
