import React, {Component} from 'react';
import {Dropdown, Menu} from "semantic-ui-react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import './../../../../css/menu-bar.css';

class SuperAdminMenuBar extends Component {
    constructor(props) {
        super(props);
        this.mPaths = [
            {icon: 'users', path: '/superadmin/users', text: 'Admin Users'},
            {icon: 'file alternate', path: '/superadmin/master-data/practice-areas', text: 'Practice Areas'},
            {
                icon: 'question circle',
                path: '/superadmin/master-data/evaluation-questions',
                text: 'Evaluation Questions'
            },
            {icon: 'question circle outline', path: '/superadmin/master-data/question-groups', text: 'Question Groups'},
            {icon: 'question', path: '/superadmin/master-data/final-questions', text: 'Final Questions'},
            {icon: 'book', path: '/superadmin/help', text: 'Help - User Guide'},
            {icon: 'file text', path: '/superadmin/terms-of-use', text: 'Terms Of Use'},
        ];
        this.bPaths = [
            {icon: "payment", path: "/superadmin/payment/billing", text: "Billing"},
            {icon: "table", path: "/superadmin/payment/plans/list", text: "Plans"},
        ];
    }

    render() {
        const {path} = this.props.match;
        const {history} = this.props;

        return (
            <Menu pointing secondary stackable>
                <Menu.Item name={"Organizations"} as={"h5"} link value={"/superadmin/orgs"}
                           onClick={() => history.push("/superadmin/orgs")}
                           active={path.includes("/superadmin/orgs")}/>
                <Dropdown item floating text='Master Data' as={"h5"}
                          className={`${path.includes('master-data') || path.includes('superadmin/users') ? "active-item" : ""}`}>
                    <Dropdown.Menu>
                        {this.mPaths.map((m, i) =>
                            <Dropdown.Item key={i}
                                           icon={m.icon}
                                           text={m.text}
                                           active={path.includes(m.path)}
                                           onClick={() => history.push(m.path)}/>
                        )}
                    </Dropdown.Menu>
                </Dropdown>
                <Dropdown item floating text='Payment' as={"h5"}
                          className={`${path.includes('payment')}`}>
                    <Dropdown.Menu>
                        {this.bPaths.map((m, i) =>
                            <Dropdown.Item key={i}
                                           icon={m.icon}
                                           text={m.text}
                                           active={path.includes(m.path)}
                                           onClick={() => history.push(m.path)}/>
                        )}
                    </Dropdown.Menu>
                </Dropdown>
                <Menu.Item name={"App Settings"} as={"h5"} link value={"/superadmin/settings"}
                           onClick={() => history.push("/superadmin/settings")}
                           active={path.includes("/superadmin/settings")}/>
            </Menu>
        );
    }
}

function mapStateToProps() {
    return {};
}

const cSuperAdminMenuBar = withRouter(connect(mapStateToProps)(SuperAdminMenuBar));
export {cSuperAdminMenuBar as SuperAdminMenuBar};
