import React, {Component} from 'react';
import {Button, Form, Header, Input, Segment} from "semantic-ui-react";
import {connect} from "react-redux";
import authAction from "../../../actions/auth.action";
import ColoredBody from "../../other/ColoredBody";
import BoxFooter from "../../other/BoxFooter";
import BoxHeader from "../../other/BoxHeader";
import ErrorMessage from "../../other/ErrorMessage";
import qs from 'query-string';

class ResetPasswordPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            password: '',
            confirm_password: '',
        };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.parsed = qs.parse(this.props.location.search);
    }

    componentDidMount() {
        if (Object.keys(this.parsed).length !== 2) {
            this.props.history.replace("/not-found");
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.isDone) {
            alert("Successfully changed!");
            this.props.history.replace("/login");
        }
    }

    onChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    onSubmit(e) {
        e.preventDefault();
        const {password} = this.state;
        // send request to server
        this.props.dispatch(authAction.resetPassword({
            password,
            ...this.parsed
        }));
    }

    render() {
        const {password, confirm_password} = this.state;
        const {loading, error} = this.props;
        return (
            <ColoredBody textAlign={"center"}>
                <BoxHeader/>
                <Segment padded={'very'} textAlign={'left'}>
                    <Header as={'h2'}>
                        Reset Password
                    </Header>
                    <ErrorMessage errors={error}/>
                    <br/>
                    <br/>
                    <Form autoComplete='off'>
                        <Form.Field>
                            <label>Password</label>
                            <Input
                                fluid
                                icon='lock'
                                iconPosition='left'
                                placeholder='Password'
                                type='password'
                                name='password'
                                onChange={this.onChange}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Confirm Password</label>
                            <Input
                                fluid
                                icon='lock'
                                iconPosition='left'
                                placeholder='Confirm Password'
                                type='password'
                                name='confirm_password'
                                onChange={this.onChange}
                            />
                        </Form.Field>
                        <br/>
                        <Button color='teal' floated={'right'} circular size='large' onClick={this.onSubmit}
                                loading={loading}
                                disabled={loading || !password || password !== confirm_password}>
                            Submit
                        </Button>
                        <br/>
                        <br/>
                    </Form>
                </Segment>
                <BoxFooter/>
            </ColoredBody>
        );
    }
}

function mapStateToProps(state) {
    return state.auth;
}

const connectedLoginPage = connect(mapStateToProps)(ResetPasswordPage);
export {connectedLoginPage as ResetPasswordPage};