import matterService from "../services/matter.service";
import evaluationService from "../services/evaluation.service";

// common
function request() {
    return {type: EVALUATION.REQUEST};
}

function error(reason) {
    if (reason.response) {
        reason = reason.response;
    }
    switch (reason.status) {
        case 403:
            return {type: EVALUATION.NOT_ALLOWED};
        case 404:
            return {type: EVALUATION.NOT_FOUND};
        case 422:
            return {type: EVALUATION.VALIDATION_ERROR, payload: reason.data};
        default:
            return {type: EVALUATION.ERROR, payload: reason.data};
    }
}

function create(data) {

    return dispatch => {
        dispatch(request());
        matterService.add(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        if (res.status === 201) {
            return {type: EVALUATION.ADD_SUCCESS, payload: res.data};
        }
        return error(res);
    }

}

function update(data) {
    return dispatch => {
        dispatch(request());
        matterService.update(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        if (res.status === 202) {
            return {type: EVALUATION.UPDATE_SUCCESS, payload: res.data};
        }
        return error(res);
    }
}

function readAll(data) {
    return dispatch => {
        dispatch(request());
        matterService.readAll(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: EVALUATION.READ_ALL_SUCCESS, payload: res.data};
    }
}

function read(data) {
    return dispatch => {
        dispatch(request());
        matterService.read(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: EVALUATION.READ_SUCCESS, payload: res.data};
    }
}

function changeStatus(data) {
    return dispatch => {
        dispatch(request());
        matterService.changeStatus(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        if (res.status === 202) {
            return {type: EVALUATION.CHANGE_STATUS_SUCCESS, payload: data};
        }
        return error(res);
    }
}

// function readEvaluationCycles(data) {
//     return dispatch => {
//         dispatch(request());
//         evaluationService.readEvaluationCycles(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
//     };
//
//     function success(res) {
//         return {type: EVALUATION.READ_EVALUATIONS_CYCLES, payload: res.data};
//     }
// }

// function readMyEvaluationCycles(data) {
//     return dispatch => {
//         dispatch(request());
//         evaluationService.readMYEvaluationCycles(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
//     };
//
//     function success(res) {
//         return {type: EVALUATION.READ_MY_EVALUATION_CYCLES, payload: res.data};
//     }
// }

function startEvaluation(data, type) {
    const {action} = data;
    delete data.action;
    return dispatch => {
        dispatch(request());
        evaluationService.startEvaluation(data, type).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: action ? EVALUATION.EDIT_EVALUATION : EVALUATION.START_EVALUATION_SUCCESS, payload: res.data};
    }
}

function evaluate(data, type) {
    return dispatch => {
        dispatch(request());
        evaluationService.evaluate(data, type).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        if (res.status === 202) {
            return {type: EVALUATION.EVALUATE_SUCCESS, payload: data};
        }
        return error(res);
    }
}

function evaluateAll(data, type) {
    return dispatch => {
        dispatch(request());
        evaluationService.evaluateAll({evaluations: data}, type).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        if (res.status === 202) {
            return {type: EVALUATION.EVALUATE_ALL_SUCCESS};
        }
        return error(res);
    }
}

function onChange(data, index) {
    return dispatch => {
        dispatch({type: EVALUATION.EVALUATION_ON_CHANGE, payload: {data, index}});
    };
}

function onPrevious() {
    return dispatch => {
        dispatch({type: EVALUATION.EVALUATION_ON_PREVIOUS});
    };
}

function viewEvaluation(data, type) {
    return dispatch => {
        dispatch(request());
        evaluationService.viewEvaluation(data, type).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: EVALUATION.VIEW_EVALUATION_SUCCESS, payload: res.data};
    }
}

function rejectEvaluation(data, type) {
    return dispatch => {
        dispatch(request());
        evaluationService.rejectEvaluation(data, type).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        if (res.status === 202) {
            return {type: EVALUATION.REJECT_EVALUATION_SUCCESS, payload: res.data};
        }
    }
}

const evaluationAction = {
    create,
    update,
    readAll,
    read,
    changeStatus,
    // readEvaluationCycles,
    startEvaluation,
    evaluate,
    evaluateAll,
    onChange,
    onPrevious,
    viewEvaluation,
    rejectEvaluation
};

// constants
export const EVALUATION = {

    REQUEST: "EVALUATION_REQUEST",
    ERROR: "EVALUATION_ERROR",
    NOT_FOUND: "EVALUATION_NOT_FOUND",
    VALIDATION_ERROR: "EVALUATION_VALIDATION_ERROR ",
    NOT_ALLOWED: "EVALUATION_NOT_ALLOWED",

    READ_EVALUATIONS_CYCLES: "EVALUATION_READ_EVALUATIONS_CYCLES",
    READ_MY_EVALUATION_CYCLES: "EVALUATION_READ_MY_EVALUATION_CYCLES",

    START_EVALUATION_SUCCESS: "EVALUATION_START_EVALUATION_SUCCESS",
    EDIT_EVALUATION: "EVALUATION_EDIT_EVALUATION",

    VIEW_EVALUATION_SUCCESS: "EVALUATION_VIEW_EVALUATION_SUCCESS",

    EVALUATE_SUCCESS: "EVALUATION_EVALUATE_SUCCESS",
    EVALUATE_ALL_SUCCESS: "EVALUATION_EVALUATE_ALL_SUCCESS",

    EVALUATION_ON_CHANGE: "EVALUATION_ON_CHANGE",

    EVALUATION_ON_PREVIOUS: "EVALUATION_ON_PREVIOUS",

    REJECT_EVALUATION_SUCCESS: "EVAL_REJECT_EVALUATION_SUCCESS"

};

export default evaluationAction;