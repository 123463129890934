import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import helper from "../../../../helper/helper";
import {Button, Confirm, Form, Header, Icon, Segment} from "semantic-ui-react";
import userAction from "../../../../actions/user.action";
import {SUPER_ADMIN} from "../../../../helper/const";
import QMCheckBox from "../../../other/form/QMCheckBox";
import ErrorMessage from "../../../other/ErrorMessage";

class EditUserForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "",
            status: true,
            roles: this.props.orgid ? [] : [SUPER_ADMIN],
            created_at: "",
            last_login_at: "",
            open: false
        };
        this.onSelectPlan = this.onSelectPlan.bind(this);
        this.onSave = this.onSave.bind(this);
    }

    componentDidMount() {
        this.props.dispatch(userAction.read({
            org_id: this.props.orgid,
            id: this.props.userid
        }));
    }

    componentWillReceiveProps(nextProps) {
        const {history, user, isDone, not_found} = nextProps;
        if (isDone) {
            history.goBack();
        } else if (not_found) {
            history.replace("/not-found");
        } else if (user) {
            this.setState({...user});
        }
    }

    onSave(e) {
        e.preventDefault();
        this.props.dispatch(userAction.update({
            org_id: this.props.orgid,
            user_id: this.props.userid,
            ...this.state
        }));
    }

    onSelectPlan(e, {id}) {
        if (this.state.roles.includes(id)) {
            this.setState((prevState) => ({roles: [...prevState.roles.filter(role => role !== id)]}));
        } else {
            this.setState((preState) => ({roles: [...preState.roles, id]}));
        }
    }

    render() {
        const {error, orgid, auth, isLoggedInUser} = this.props;
        const {name, email, roles, created_at, last_login_at, open} = this.state;
        const allowToDeleteUser = auth && auth.user && auth.user.email && auth.user.email !== email;
        return (
            <Segment basic padded='very'>
                <Form>
                    <ErrorMessage errors={error}/>
                    <Form.Group>
                        <Form.Field width={8}>
                            <label>Name</label>
                            <input placeholder='Name' value={name}
                                   onChange={(e) => this.setState({name: e.target.value})}/>
                        </Form.Field>
                        <Form.Field width={8}>
                            <label>Created At</label>
                            <input placeholder='Created At' readOnly disabled
                                   value={created_at}/>
                        </Form.Field>
                    </Form.Group>
                    <Form.Group>
                        <Form.Field width={8}>
                            <label>Email</label>
                            <input placeholder='Email' value={email} readOnly disabled/>
                        </Form.Field>
                        <Form.Field width={8}>
                            <label>Last Logged In</label>
                            <input placeholder='Last logged in' readOnly disabled
                                   value={last_login_at ? last_login_at : "Not logged in yet!"}/>
                        </Form.Field>
                    </Form.Group>
                    <Form.Field>
                        <label>Status</label>
                        <QMCheckBox toggle onChange={(checked) => this.setState({status: checked})}
                                    disabled={isLoggedInUser}
                                    checked={this.state.status}/>
                    </Form.Field>
                    <Header as="h3">Roles</Header>
                    <br/>

                    {
                        orgid ? helper.roles.map((role, index) => {
                                const active = roles.includes(role.id);
                                return <Button circular size='huge' basic={!active}
                                               disabled={isLoggedInUser}
                                               style={{textAlign: "left", marginTop: "4px"}} key={index}
                                               secondary={active} onClick={this.onSelectPlan} id={role.id}>
                                    <Icon name="check circle"/> {role.title}
                                </Button>
                            }
                        ) : (
                            <Button size='huge' circular basic={!roles.includes(SUPER_ADMIN)}
                                    style={{textAlign: "left", marginTop: "4px"}}
                                    secondary={roles.includes(SUPER_ADMIN)} onClick={this.onSelectPlan}
                                    id={SUPER_ADMIN}>
                                <Icon name="check circle"/> Qualmet Administrator
                            </Button>
                        )
                    }

                    <br/>
                    <br/>
                    {allowToDeleteUser &&
                    <Button type='button' color={"red"} inverted circular disabled={isLoggedInUser}
                            onClick={() => this.setState({open: true})}>
                        Delete User
                    </Button>
                    }
                    <Confirm
                        size={"tiny"}
                        open={open}
                        header='Confirmation required'
                        cancelButton='NO'
                        confirmButton="YES"
                        content={`You are going to delete ${name}(${email}). Deleted user data CANNOT be restored! Are you ABSOLUTELY sure?`}
                        closeOnDocumentClick
                        onCancel={() => this.setState({open: false})}
                        onConfirm={() => {
                            this.props.dispatch(userAction.deleteUser({id: this.props.match.params.id}));
                        }}
                    />
                    <Button type='submit' primary onClick={this.onSave} floated='right' circular
                            disabled={!(this.state.roles.length > 0)}>Update</Button>
                    <Button type='button' secondary inverted floated='right' circular
                            onClick={() => this.props.history.go(-1)}>Cancel</Button>
                    <br/>
                </Form>
            </Segment>
        );
    }
}

function mapStateToProps(state) {
    return {
        ...state.user,
        auth: state.auth
    };
}

const cEditUserForm = withRouter(connect(mapStateToProps)(EditUserForm));
export {cEditUserForm as EditUserForm};
