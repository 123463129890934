import React, {Component} from 'react';
import {Button, Form, Header, Icon, Label, Modal, Segment, Table} from "semantic-ui-react";
import {connect} from "react-redux";
import {OrgMenuBar} from "../../../other/OrgMenuBar";
import userAction from "../../../../../../actions/user.action";
import {orgState} from "../../../../../../helper/stateUtil";
import Body from "../../../../../other/Body";
import TabHeader from "../../../other/TabHeader";
import ErrorMessage from "../../../../../other/ErrorMessage";
import {APP} from "../../../../../../actions/app.action";

class OrgImportUsersPage extends Component {
    constructor(props) {
        super(props);
        this.tabs = [
            {text: "Users", value: 1},
            {text: "SSO Users", value: 2}
        ];
        this.state = {
            modal_import_file: false,
            modal_add: false,
            file: null,
            first_name: '',
            last_name: '',
            email: '',
            roles: []
        };
        this.userRoles = [
            {text: "Matter", value: 1},
            {text: "Evaluator", value: 2},
            {text: "Report Viewer", value: 3},
        ];
        this.onCancel = this.onCancel.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        const {dispatch, org} = this.props;
        dispatch(userAction.getAllImportUsers({org_id: org.id}));
    }

    componentWillReceiveProps(nextProps) {
        const {isDone, dispatch, org} = nextProps;
        if (isDone) {
            dispatch(userAction.getAllImportUsers({org_id: org.id}));
            this.onCancel();
        }
    }

    onCancel = () => {
        this.setState({
            modal_import_file: false,
            modal_add: false,
            file: null,
            first_name: '',
            last_name: '',
            email: '',
            roles: []
        });
        this.props.dispatch({type: APP.REFRESH_STORE});
    };

    onChange = (_, {name, value}) => {
        this.setState({[name]: value});
    };

    render() {
        const {loading, users, dispatch, history, error, org} = this.props;
        const {modal_import_file, modal_add, file, first_name, last_name, email, roles} = this.state;
        let content;
        if (users.length > 0) {
            content = (
                <Segment basic padded={'very'}>
                    <Table basic='very' padded selectable stackable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>User</Table.HeaderCell>
                                <Table.HeaderCell>Roles</Table.HeaderCell>
                                <Table.HeaderCell>Actions</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {users.map((user, index) => {
                                return (
                                    <Table.Row className={"row"} key={index}>
                                        <Table.Cell style={{paddingLeft: "16px"}}>
                                            <Icon name="user" color={"grey"} size={"large"}/>
                                            <span><b>{user.first_name} {user.last_name}</b> ({user.email})</span>
                                        </Table.Cell>
                                        <Table.Cell>
                                            {
                                                user.roles &&
                                                user.roles.map((role, index) => <Label key={index}>
                                                    {role}
                                                </Label>)
                                            }
                                        </Table.Cell>
                                        <Table.Cell collapsing>
                                            <Button circular color={'red'} inverted
                                                    onClick={() => dispatch(userAction.deleteImportUser({
                                                        org_id: org.id,
                                                        email: user.email
                                                    }))}>
                                                Delete
                                            </Button>
                                            &nbsp;&nbsp;&nbsp;
                                            {!user.is_activated &&
                                            <Button circular primary
                                                    onClick={() => dispatch(userAction.activateImportUsers({
                                                        org_id: org.id,
                                                        id: user.id
                                                    }))}>
                                                Activate
                                            </Button>}
                                        </Table.Cell>
                                    </Table.Row>
                                );
                            })}
                        </Table.Body>
                    </Table>
                </Segment>
            );
        } else {
            content = (
                <Segment basic placeholder piled>
                    <Header icon>
                        <Icon name='file outline'/>
                        No imported users are listed.
                    </Header>
                </Segment>
            );
        }
        return (
            <Body loading={loading}>

                <OrgMenuBar/>

                <TabHeader title={`Users - ${this.props.org.name}`} onTabChange={({value}) => {
                    if (value === 1) {
                        history.replace(`/orgadmin/users`);
                    }
                }} activeIndex={1} tabs={this.tabs}>
                    <Button circular size={"medium"}
                            onClick={() => this.setState({modal_add: true, file: null})}
                            title="Add User" floated="right" color={"teal"}>Add User</Button>
                    <Button circular size={"medium"}
                            onClick={() => this.setState({modal_import_file: true, file: null})}
                            title="Import Users" floated="right" color={"teal"}>Import Users</Button>
                    <Button circular secondary floated={"right"}
                            disabled={users && users.filter(u => !u.is_activated).length === 0}
                            onClick={() => dispatch(userAction.activateImportUsers({
                                org_id: org.id
                            }))}>
                        Activate All
                    </Button>
                </TabHeader>

                {content}

                {modal_import_file &&
                <Modal size={"tiny"} open>
                    <Modal.Header>Import Users</Modal.Header>
                    <Modal.Content scrolling>
                        <Header as={'h3'}>Import excel file</Header>
                        <br/>
                        <ErrorMessage errors={error}/>
                        <Form>
                            <Form.Input type={'file'} accept=".xlsx, .xls, .csv"
                                        onChange={e => this.setState({file: e.target.files[0]})}/>
                        </Form>
                        <br/>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button circular onClick={this.onCancel}
                                disabled={loading}>Cancel</Button>
                        <Button color={'teal'} circular content='Upload' disabled={loading || file == null}
                                loading={loading}
                                onClick={() => {
                                    dispatch(userAction.uploadImportUsersFile({org_id: org.id, users: file}));
                                }}/>
                    </Modal.Actions>
                </Modal>}

                {modal_add &&
                <Modal size={"tiny"} open>
                    <Modal.Header>Add User</Modal.Header>
                    <Modal.Content>
                        <Segment basic padded>
                            <ErrorMessage errors={error}/>
                            <Form>
                                <Form.Input label="First Name" name={"first_name"} value={first_name}
                                            onChange={this.onChange}/>
                                <Form.Input label="Last Name" name={"last_name"} value={last_name}
                                            onChange={this.onChange}/>
                                <Form.Input type={"email"} label="Email" name={"email"} value={email}
                                            onChange={this.onChange}/>
                                <Form.Dropdown label='User Roles:'
                                               name={"roles"}
                                               multiple search scrolling selection
                                               value={roles}
                                               onChange={this.onChange}
                                               options={this.userRoles}/>
                            </Form>
                        </Segment>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button circular onClick={this.onCancel}
                                disabled={loading}>Cancel</Button>
                        <Button color={'teal'} circular content='Add'
                                disabled={loading || !(first_name && last_name && email && roles.length > 0)}
                                loading={loading}
                                onClick={() => {
                                    dispatch(userAction.addImportUser({
                                        org_id: org.id,
                                        first_name,
                                        last_name,
                                        email,
                                        roles
                                    }));
                                }}/>
                    </Modal.Actions>
                </Modal>}

            </Body>
        );
    }
}

function mapStateToProps(state) {
    let s = orgState(state);
    return {...s, ...state.user};
}

const connectedOrgUsersPage = connect(mapStateToProps)(OrgImportUsersPage);
export {connectedOrgUsersPage as OrgImportUsersPage};