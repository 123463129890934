import React, {Component} from 'react';
import ColoredBody from "../../other/ColoredBody";
import BoxFooter from "../../other/BoxFooter";
import {connect} from "react-redux";
import BoxHeader from "../../other/BoxHeader";
import {Button, Grid, Header, Icon, Segment} from "semantic-ui-react";

class UserPendingApprovalPage extends Component {
    render() {
        const {history, match} = this.props;
        const {name, email} = match.params;
        return (
            <ColoredBody boxWidth={"584px"} textAlign={'center'}>
                <BoxHeader/>
                <Segment textAlign={"left"} padded>
                    <Header>
                        Pending Organization Administrator Approval
                    </Header>
                    <br/>
                    <Grid>
                        <Grid.Column width={12}>
                            <Header as={'h5'}>
                                Your user account is pending approval by {name} ({email}).
                                <br/>
                                An email will be send once your account is approved and you can begin using the
                                system.
                            </Header>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <Icon name={"pencil"}
                                  size={'big'}
                                  color={'teal'}
                                  circular
                                  inverted
                                  style={{fontSize: '3em'}}/>
                        </Grid.Column>
                    </Grid>
                    <br/>
                    <Button
                        circular
                        color={"teal"}
                        onClick={() => {
                            history.replace("/login");
                        }}>Back to Log-in</Button>
                    <br/>
                    <br/>
                </Segment>
                <BoxFooter/>
            </ColoredBody>
        );
    }
}

function mapStateToProps() {
    return {};
}

const cUserPendingApprovalPage = connect(mapStateToProps)(UserPendingApprovalPage);
export {cUserPendingApprovalPage as UserPendingApprovalPage};
