import api from "../helper/api";

function add(data) {
    let form = new FormData();
    Object.entries(data).forEach((d) => {
        form.append(d[0], d[1]);
    });
    return api.instance().post(`qa/help/store`, form);
}

function update(data) {
    let form = new FormData();
    Object.entries(data).forEach((d) => {
        form.append(d[0], d[1]);
    });
    // form.append("_method", "PUT");
    return api.instance().post(`qa/help/update`, form);
}

function readAll(data) {
    return api.instance().post(`qa/help/get-all`, data);
}

function readAllHelp(data) {
    return api.instance().post(`qa/help/get-all-help`, data);
}

function read(data) {
    return api.instance().post(`qa/help/get`, data);
}

function changeStatus(data) {
    data._method = "PUT";
    return api.instance().put(`qa/help/change-status`, data);
}

const helpService = {
    add,
    update,
    readAll,
    readAllHelp,
    read,
    changeStatus
};

export default helpService;