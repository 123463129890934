import React, {Component} from 'react';
import {Button, Form, Header, Icon, Segment, Table} from "semantic-ui-react";
import planAction from "../../../../actions/plan.action";
import helper from "../../../../helper/helper";
import QMCheckBox from "../../../other/form/QMCheckBox";
import SABody from "../../qualmetadmin/other/SABody";
import SASubHeader from "../../qualmetadmin/other/SASubHeader";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

class PlanListPage extends Component {

    componentDidMount() {
        this.props.dispatch(planAction.getAll({}));
    }

    componentWillUnmount() {
        this.props.dispatch(planAction.unmount());
    }

    render() {
        const {loading, items, history} = this.props;
        let content;
        if (items.length > 0) {
            content = (
                <Segment padded={"very"} basic>
                    <Table basic='very' padded selectable stackable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>#</Table.HeaderCell>
                                <Table.HeaderCell>Plan Title</Table.HeaderCell>
                                <Table.HeaderCell>Plan Tag</Table.HeaderCell>
                                <Table.HeaderCell>Plan Amount</Table.HeaderCell>
                                <Table.HeaderCell>Up to no of users</Table.HeaderCell>
                                <Table.HeaderCell>Duration</Table.HeaderCell>
                                {/*<Table.HeaderCell>Is Custom Plan</Table.HeaderCell>*/}
                                {/*<Table.HeaderCell>Org Name</Table.HeaderCell>*/}
                                <Table.HeaderCell>Action</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {items.map((item, index) => {
                                const dur = helper.planDurationTypes.find((d) => d.value === item.duration_type);
                                return (
                                    <Table.Row className={"row"} key={index}>
                                        <Table.Cell style={{paddingLeft: "4px"}}>
                                            {index + 1}
                                        </Table.Cell>
                                        <Table.Cell>{item.title}</Table.Cell>
                                        <Table.Cell>{item.price_title}</Table.Cell>
                                        <Table.Cell>${item.price}</Table.Cell>
                                        <Table.Cell>{item.is_unlimited_users ? "Unlimited Users" : item.no_users}</Table.Cell>
                                        <Table.Cell>{item.duration} {dur && dur.text}</Table.Cell>
                                        {/*<Table.Cell>{item.is_custom_plan ? "Yes" : "No"}</Table.Cell>*/}
                                        {/*<Table.Cell>{item.org_name}</Table.Cell>*/}
                                        <Table.Cell>
                                            <Form>
                                                <Form.Group inline>
                                                    <Form.Field>
                                                        <Button icon={"pencil"} circular color={"teal"}
                                                                size={"mini"}
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    history.push(`/superadmin/payment/plans/edit/${item.id}`)
                                                                }}/>
                                                    </Form.Field>
                                                    <Form.Field>
                                                        <QMCheckBox checked={item.status}
                                                                    onChange={checked => {
                                                                        this.props.dispatch(planAction.changeStatus({
                                                                            id: item.id,
                                                                            status: checked,
                                                                            index: index
                                                                        }));
                                                                    }}/>
                                                    </Form.Field>
                                                </Form.Group>
                                            </Form>
                                        </Table.Cell>
                                    </Table.Row>
                                );
                            })}
                        </Table.Body>
                    </Table>
                </Segment>
            );
        } else {
            content = (
                <Segment basic placeholder piled>
                    <Header icon>
                        <Icon name='file outline'/>
                        No plans are listed.
                    </Header>
                    <Button color={"teal"} circular
                            onClick={() => history.push(`/superadmin/payment/plans/add`)}>
                        Add Plan
                    </Button>
                </Segment>
            );
        }
        return (
            <SABody loading={loading}>

                <SASubHeader title={"Billing Plans"} backArrow>
                    <Button color={"teal"} circular floated={'right'}
                            onClick={() => history.push(`/superadmin/payment/plans/add`)}>
                        Add New
                    </Button>
                </SASubHeader>

                {content}
            </SABody>
        );
    }
}

function mapStateToProps(state) {
    return {...state.plan};
}

const cPlanListPage = withRouter(connect(mapStateToProps)(PlanListPage));
export {cPlanListPage as PlanListPage};
