import subService from "../services/sub.service";

function request() {
    return {type: SUB.REQUEST};
}

function error(reason) {
    reason = reason.response;
    // validation error
    if (reason.status === 422) {
        return {type: SUB.VALIDATION_ERROR, payload: reason.data};
    }
    return {type: SUB.ERROR, payload: reason.data};
}

function create(data) {

    return dispatch => {
        dispatch(request());
        subService.add(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        if (res.status === 201) {
            return {type: SUB.ADD_SUCCESS, payload: res.data};
        }
        return error(res);
    }
}

function update(data) {

    return dispatch => {
        dispatch(request());
        subService.update(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        if (res.status === 202) {
            return {type: SUB.UPDATE_SUCCESS, payload: res.data};
        }
        return error(res);
    }
}

function getAll() {

    return dispatch => {
        dispatch(request());
        subService.readAll().then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: SUB.READ_ALL_SUCCESS, payload: res.data};
    }
}

function getAllSubs(data) {

    return dispatch => {
        dispatch(request());
        subService.readAllSubs(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: SUB.READ_ALL_SUBS_SUCCESS, payload: res.data};
    }
}

function get(data) {

    return dispatch => {
        dispatch(request());
        subService.read(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: SUB.READ_SUCCESS, payload: res.data};
    }
}


function getAddPaymentData(data) {

    return dispatch => {
        dispatch(request());
        subService.getAddPaymentData(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: SUB.READ_SUCCESS, payload: res.data};
    }
}

function getManagePaymentData(data) {

    return dispatch => {
        dispatch(request());
        subService.getManagePaymentData(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: SUB.READ_SUCCESS, payload: res.data};
    }
}


function changeStatus(data) {

    return dispatch => {
        dispatch(request());
        subService.changeStatus(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        if (res.status === 202) {
            return {type: SUB.CHANGE_STATUS_SUCCESS, payload: data};
        }
    }
}

function getActivePlans(data) {

    return dispatch => {
        dispatch(request());
        subService.getActivePlans(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: SUB.GET_ACTIVE_PLANS, payload: res.data};
    }
}

function addPayment(data) {

    return dispatch => {
        dispatch(request());
        subService.addPayment(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        if (res.status === 201) {
            return {type: SUB.SUB_ADD_PAYMENT_SUCCESS, payload: res.data};
        }
    }
}

function managePayment(data) {

    return dispatch => {
        dispatch(request());
        subService.managePayment(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        if (res.status === 202) {
            return {type: SUB.SUB_ADD_PAYMENT_SUCCESS, payload: res.data};
        }
    }
}

function unmount() {
    return dispatch => dispatch({type: SUB.UNMOUNT});
}

const subAction = {
    create,
    update,
    getAll,
    getAllSubs,
    getAddPaymentData,
    get,
    changeStatus,
    getActivePlans,
    addPayment,
    managePayment,
    getManagePaymentData,
    unmount
};

// constants
export const SUB = {

    REQUEST: "SUB_REQUEST",
    ERROR: "SUB_ERROR",
    NOT_FOUND: "SUB_NOT_FOUND",
    VALIDATION_ERROR: "SUB_VALIDATION_ERROR ",
    NOT_ALLOWED: "SUB_NOT_ALLOWED",

    ADD_SUCCESS: "SUB_ADD_SUCCESS",
    UPDATE_SUCCESS: "SUB_UPDATE_SUCCESS",
    READ_ALL_SUCCESS: "SUB_READ_ALL_SUCCESS",
    READ_SUCCESS: "SUB_READ_SUCCESS",
    CHANGE_STATUS_SUCCESS: "SUB_CHANGE_STATUS_SUCCESS",

    GET_ACTIVE_PLANS: "GET_ACTIVE_PLANS",

    READ_ALL_SUBS_SUCCESS: "READ_ALL_SUBS_SUCCESS",

    SUB_ADD_PAYMENT_SUCCESS: "SUB_ADD_PAYMENT_SUCCESS",

    UNMOUNT: "SUB_UNMOUNT"
};

export default subAction;