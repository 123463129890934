import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Message} from "semantic-ui-react";

class ErrorMessage extends Component {
    render() {
        const {errors} = this.props;
        let content;
        if (errors.length > 0) {
            content = (
                <div>
                    <Message color='red' hidden={errors.length === 0}>
                        <Message.List>
                            {errors.map((item, index) => <Message.Item key={index}>{item}</Message.Item>)}
                        </Message.List>
                    </Message>
                    <br/>
                </div>
            );
        }
        return (<div>{content}</div>);
    }
}

ErrorMessage.propTypes = {
    errors: PropTypes.array.isRequired
};

export default ErrorMessage;
