import React, {Component} from 'react';
import {Segment} from "semantic-ui-react";
import {connect} from "react-redux";
import {OrgMenuBar} from "../other/OrgMenuBar";
import Body from "../../../other/Body";
import {orgState} from "../../../../helper/stateUtil";
import TabHeader from "../other/TabHeader";
import qs from "query-string";
import {ClientEvalSummary} from './components/ClientEvalSummary';
import {ClientEvalDiversity} from './components/ClientEvalDiversity';
import {ClientEvalEvaluations} from './components/ClientEvalEvaluations';
import {ClientEvalComments} from './components/ClientEvalComments';

class ClientSummaryPage extends Component {
    constructor(props) {
        super(props);
        this.params = qs.parse(props.location.search);
        this.tabs = [
            {text: "Summary", value: "summary", render: <ClientEvalSummary/>}
        ];
        if (props.org.diversity_tracking) {
            this.tabs.push({text: "Diversity", value: "diversity", render: <ClientEvalDiversity/>});
        }
        this.tabs.push({
            text: "Evaluations",
            value: "evaluations",
            render: <ClientEvalEvaluations/>
        });
        this.tabs.push({text: "Comments", value: "comments", render: <ClientEvalComments/>});
    }

    render() {
        const {loading, match: {params: {id: clientId, eid, type}}, location: {search: query}, history} = this.props;
        const {h: clientName, e: evalTitle} = this.params;
        let activeIndex = 0;
        let content;
        this.tabs.forEach((tab, index) => {
            if (tab.value === type) {
                activeIndex = index;
                content = tab.render;
            }
        });
        return (
            <Body loading={loading}>

                <OrgMenuBar/>

                <TabHeader
                    title={`${evalTitle} - ${clientName}`}
                    backArrow
                    tabs={this.tabs}
                    activeIndex={activeIndex}
                    onTabChange={(tab) => {
                        history.replace(`/clients-firms/${clientId}/evaluation/${eid}/${tab.value}${query}`);
                    }}/>
                <Segment basic padded>
                    {content}
                </Segment>

            </Body>
        );
    }
}

function mapStateToProps(state) {
    let s = orgState(state);
    return {...s, ...state.matter};
}

const cClientSummaryPage = connect(mapStateToProps)(ClientSummaryPage);
export {cClientSummaryPage as ClientSummaryPage};