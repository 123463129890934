import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Grid, Table} from "semantic-ui-react";
import {orgState} from "../../../../helper/stateUtil";
import {connect} from "react-redux";
import matterAction from "../../../../actions/matter.action";

class MatterViewSummary extends Component {

    componentWillMount() {
        this.loadData()
    }

    componentWillReceiveProps(nextProps) {
        const {isDone} = nextProps;
        if (isDone) {
            this.loadData();
        }
    }

    loadData = () => this.props.dispatch(matterAction.readSummary({
        org_id: this.props.org.id,
        id: this.props.matterId
    }));

    render() {
        const {org} = this.props;
        let {summary: matter} = this.props;
        return (
            <div>
                <Grid columns={"equal"}>
                    <Grid.Row>
                        <Grid.Column>
                            <Table>
                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell>Matter Status:</Table.Cell>
                                        <Table.Cell>{matter.status}</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>Matter No:</Table.Cell>
                                        <Table.Cell>{matter.number}</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>Matter Name:</Table.Cell>
                                        <Table.Cell>{matter.name}</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>{org.str_client_firm}</Table.Cell>
                                        <Table.Cell>{matter.client_name}</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>Practice Area:</Table.Cell>
                                        <Table.Cell>{matter.practice_area}</Table.Cell>
                                    </Table.Row>
                                    {org.location_tracking && <Table.Row>
                                        <Table.Cell>Office:</Table.Cell>
                                        <Table.Cell>{matter.office}</Table.Cell>
                                    </Table.Row>}
                                    <Table.Row>
                                        <Table.Cell>Question Set:</Table.Cell>
                                        <Table.Cell>{matter.question_set_title}</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>Evaluator(s):</Table.Cell>
                                        <Table.Cell>{matter.evaluators}</Table.Cell>
                                    </Table.Row>
                                    {matter.responsible_party && <Table.Row>
                                        <Table.Cell>Responsible Evaluator:</Table.Cell>
                                        <Table.Cell>{matter.responsible_party}</Table.Cell>
                                    </Table.Row>}
                                    {matter.evaluatees && <Table.Row>
                                        <Table.Cell>Evaluatees(s):</Table.Cell>
                                        <Table.Cell>{matter.evaluatees}</Table.Cell>
                                    </Table.Row>}
                                </Table.Body>
                            </Table>
                        </Grid.Column>
                        <Grid.Column>
                            <Table>
                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell>
                                            Evaluation Start Date:
                                        </Table.Cell>
                                        <Table.Cell>{matter.start_date}</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>
                                            Evaluation End Date:
                                        </Table.Cell>
                                        <Table.Cell>{matter.end_date}</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>
                                            Evaluation Style:
                                        </Table.Cell>
                                        <Table.Cell>{matter.evaluation_style}</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>
                                            Evaluation Type:
                                        </Table.Cell>
                                        <Table.Cell>{matter.evaluation_type}</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>
                                            Evaluation Interval:
                                        </Table.Cell>
                                        <Table.Cell>{matter.interval_id}</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>
                                            Email Reminder Interval:
                                        </Table.Cell>
                                        <Table.Cell>{matter.email_reminder}</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>Email Reminder Status:</Table.Cell>
                                        <Table.Cell>{matter.email_reminder_status}</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>Evaluation Cycle Status:</Table.Cell>
                                        <Table.Cell>{matter.ec_status}</Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        );
    }
}

MatterViewSummary.propTypes = {
    matterId: PropTypes.string.isRequired,
};


function mapStateToProps(state) {
    const s = orgState(state);
    return {
        ...s,
        ...state.matter
    };
}

const cMatterViewSummary = connect(mapStateToProps)(MatterViewSummary);
export {cMatterViewSummary as MatterViewSummary};
