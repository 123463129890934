import React, {Component} from 'react';
import {Button, Form, Header, Input, Segment} from "semantic-ui-react";
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import userAction from "../../../actions/user.action";

class UserInfo extends Component {
    constructor(props) {
        super(props);
        if (props.data) {
            const {name, email, created_at, last_login_at} = props.data;
            this.state = {
                name: name,
                created_at: created_at,
                email: email,
                last_login_at: last_login_at
            };
        } else {
            this.state = {};
        }
    }

    componentWillReceiveProps(props) {
        if (props.data) {
            const {name, email, created_at, last_login_at} = props.data;
            this.setState({
                name: name,
                created_at: created_at,
                email: email,
                last_login_at: last_login_at
            });
        }
    }

    render() {
        const {name, created_at, email, last_login_at} = this.state;
        const {loading, user} = this.props;
        return (
            <Segment loading={loading}>
                <Header dividing>Personal Information:</Header>
                <br/>
                <Form>
                    <Form.Group>
                        <Form.Field width={8}>
                            <label>Name:</label>
                            <Input value={name || ""} onChange={(e) => this.setState({name: e.target.value})}/>
                        </Form.Field>
                    </Form.Group>
                    <Form.Group>
                        <Form.Field width={8} readOnly>
                            <label>Email:</label>
                            <Input value={email || ""}/>
                        </Form.Field>
                    </Form.Group>
                    <Form.Group>
                        <Form.Field width={8} readOnly>
                            <label>Created At:</label>
                            <Input value={created_at || ""}/>
                        </Form.Field>
                    </Form.Group>
                    <Form.Group>
                        <Form.Field width={8} readOnly>
                            <label>Last Login At:</label>
                            <Input value={last_login_at || ""}/>
                        </Form.Field>
                    </Form.Group>
                    <Button type='submit' primary
                            onClick={() => {
                                this.props.dispatch(userAction.changeInfo({id: user.id, name: name}));
                            }} floated='right'
                            circular
                            disabled={!(name)}>Update</Button>
                    <br/>
                    <br/>
                </Form>
            </Segment>
        );
    }
}

UserInfo.propTypes = {
    data: PropTypes.object
};

function mapStateToProps(state) {
    return {...state.user};
}

const cUserInfo = connect(mapStateToProps)(UserInfo);
export {cUserInfo as UserInfo};
