import api from "../helper/api";

function checkTermsOfUse() {
    return api.instance().post("check-terms-of-use");
}

function acceptTermsOfUse() {
    return api.instance().post("accept-terms-of-use");
}

function getTermsOfUse() {
    return api.instance().post("qa/terms-of-use");
}

function updateTermsOfUse(data) {
    return api.instance().post("qa/update-terms-of-use", data);
}

const appService = {
    checkTermsOfUse,
    acceptTermsOfUse,
    getTermsOfUse,
    updateTermsOfUse
};

export default appService;