import React, {Component} from 'react';
import PropTypes from 'prop-types';
import CircularProgressbar from "react-circular-progressbar";
import '../../css/circle_progess_bar.css'
import {List} from "semantic-ui-react";

class CircleProgressbar extends Component {
    render() {
        const {percentage, text, title, help, height, width} = this.props;
        let str = text;
        if (!str) {
            str = `${percentage.toFixed(0)}%`;
        }
        return (
            <List style={{height: height || "auto"}}>
                <List.Item style={{textAlign: "center", width: width || "unset"}}>
                    <CircularProgressbar
                        percentage={percentage}
                        text={str}
                        strokeWidth={9}
                    />
                </List.Item>
                <List.Item style={{textAlign: "center"}}>
                    <List.Content verticalAlign='middle'>
                        {title} {help}
                    </List.Content>
                </List.Item>
            </List>
        );
    }
}

CircleProgressbar.propTypes = {
    percentage: PropTypes.any.isRequired,
    text: PropTypes.string,
    title: PropTypes.string,
    help: PropTypes.element,
    height: PropTypes.string,
    width: PropTypes.string
};

export default CircleProgressbar;
