import React, {Component} from 'react';

class QualmetErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false};
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return {hasError: true};
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        console.error(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <div>
                <h1 style={{color: "red"}}>
                    Something went wrong.
                </h1>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a style={{cursor: "pointer"}} onClick={() => window.location.reload(true)}>
                    Click here to reload the page
                </a>
                <br/>
                <br/>
                <h3>Qualmet LLC</h3>
            </div>;
        }
        return this.props.children;
    }
}

export default QualmetErrorBoundary;
