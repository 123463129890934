import React, {Component} from 'react';
import {connect} from "react-redux";
import {OrgMenuBar} from "../../other/OrgMenuBar";
import {EditPracticeAreaForm} from "../../../form/practiceareas/EditPracticeAreaForm";
import {orgState} from "../../../../../helper/stateUtil";
import Body from "../../../../other/Body";
import OrgSubHeader from "../../other/OrgSubHeader";

class OrgManagePracticeAreaPage extends Component {
    render() {
        const {loading, org} = this.props;
        return (
            <Body loading={loading}>

            <OrgMenuBar/>

            <OrgSubHeader title={"Edit Practice Area"} backArrow/>

            <EditPracticeAreaForm orgid={org.id} pid={this.props.match.params.id}/>
            </Body>
        );
    }
}

function mapStateToProps(state) {
    const s = orgState(state);
    return {
        ...s,
        ...state.practice
    };
}

const connectedOrgManagePracticeAreaPage = connect(mapStateToProps)(OrgManagePracticeAreaPage);
export {connectedOrgManagePracticeAreaPage as OrgManagePracticeAreaPage};