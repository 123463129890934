import React, {Component} from 'react';
import {Button, Form, Header, Icon, Message, Segment} from "semantic-ui-react";
import {connect} from "react-redux";
import orgAction from "../../../../actions/org.action";
import helper from "../../../../helper/helper";
import QMCheckBox from "../../../other/form/QMCheckBox";
import {ORG_TYPES} from "../../../../helper/const";
import SABody from "../other/SABody";
import SASubHeader from "../other/SASubHeader";

class AddOrgPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            plan: null,
            type: ORG_TYPES.CLIENT,
            status: true,
            diversity_tracking: false,
            location_tracking: true,
        };
        this.onChange = this.onChange.bind(this);
        this.onSelectPlan = this.onSelectPlan.bind(this);
        this.onSave = this.onSave.bind(this);
    }

    componentDidMount() {
        this.props.dispatch(orgAction.getAllPlans());
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.isDone) {
            nextProps.history.go(-1);
        }
    }

    onSave(e) {
        e.preventDefault();
        this.props.dispatch(orgAction.create({
            organization_name: this.state.name,
            organization_type: this.state.type,
            organization_plan: this.state.plan,
            status: this.state.status,
            diversity_tracking: this.state.diversity_tracking,
            location_tracking: this.state.location_tracking,
        }));
    }

    onChange(e, {name, value}) {
        this.setState({[name]: value});
    }

    onSelectPlan(e) {
        this.setState({plan: e.target.value});
    }

    render() {
        const {error, loading, domains} = this.props;
        return (
            <SABody loading={loading}>
                <SASubHeader title={"Add Organization"}/>
                <Segment basic padded='very'>
                    <Form>
                        <Message color='red' hidden={error.length === 0}>
                            <Message.List>
                                {error.map((item, index) => <Message.Item key={index}>{item}</Message.Item>)}
                            </Message.List>
                        </Message>
                        <br/>
                        <Form.Group>
                            <Form.Input label='Organization Name' placeholder='Organization Name' width={10}
                                        onChange={this.onChange} name={"name"}/>
                            <Form.Dropdown label='Organization Type' placeholder='Organization Type' width={6}
                                           selection defaultValue={ORG_TYPES.CLIENT} onChange={this.onChange}
                                           name={"type"}
                                           options={helper.orgTypes}/>
                        </Form.Group>
                        <Form.Field>
                            <label>Status:</label>
                            <QMCheckBox toggle onChange={(checked) => this.setState({status: checked})}
                                        checked={this.state.status}/>
                        </Form.Field>
                        <br/>
                        <Form.Field>
                            <label>Diversity Tracking:</label>
                            <QMCheckBox onChange={(checked) => this.setState({diversity_tracking: checked})}
                                        checked={this.state.diversity_tracking}/>
                        </Form.Field>
                        <br/>
                        <Form.Field>
                            <label>Location Tracking:</label>
                            <QMCheckBox onChange={(checked) => this.setState({location_tracking: checked})}
                                        checked={this.state.location_tracking}/>
                        </Form.Field>
                        <br/>
                        <Header as="h3">Select Plan</Header>
                        <br/>

                        {domains.map((plan, index) => {
                                const active = this.state.plan === plan.value;
                                return <Button size='huge' basic={!active} fluid
                                               style={{textAlign: "left", marginTop: "4px"}}
                                               key={index}
                                               secondary={active} onClick={this.onSelectPlan}
                                               value={plan.value}>
                                    <Icon name="check circle"/> {plan.title}
                                </Button>
                            }
                        )}

                        <br/>
                        <br/>

                        <Button type='submit' primary onClick={this.onSave} circular
                                disabled={!(this.state.name && this.state.type && this.state.plan)}
                                floated='right'>Create</Button>
                        <Button type='button' secondary inverted circular
                                onClick={() => this.props.history.go(-1)}
                                floated='right'>Cancel</Button>
                        <br/>
                    </Form>
                </Segment>
            </SABody>
        );
    }
}

function mapStateToProps(state) {
    return {
        name: state.auth.name,
        ...state.org
    };
}

const connectedAddOrgPage = connect(mapStateToProps)(AddOrgPage);
export {connectedAddOrgPage as AddOrgPage};