import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Comment, Header, Label, Segment} from "semantic-ui-react";
import ErrorMessage from "../../../../other/ErrorMessage";
import {MatterCommentForm} from "./MatterCommentForm";
import {orgState} from "../../../../../helper/stateUtil";
import {connect} from "react-redux";
import matterAction from "../../../../../actions/matter.action";

class MatterComments extends Component {

    componentDidMount() {
        this.props.dispatch(matterAction.getAllComments({
            org_id: this.props.org.id,
            matter_id: this.props.matterId
        }));
    }

    render() {
        const {comments, org, loading, error} = this.props;
        return (
            <Segment basic padded={"very"}>
                <Comment.Group>
                    <Header as='h3' dividing>
                        Matter Comments:
                    </Header>

                    <ErrorMessage errors={error}/>

                    {comments.map((comment, index) =>
                        <Comment key={index}>
                            <Comment.Content>
                                <Comment.Author as='a'>{comment.comment_by}</Comment.Author>
                                <Comment.Metadata>
                                    <div>{comment.commented_on}</div>
                                </Comment.Metadata>
                                <Comment.Text>{comment.comment}</Comment.Text>
                            </Comment.Content>
                        </Comment>
                    )}

                    {(comments.length === 0) && <Label>No comments...</Label>}

                    <MatterCommentForm loading={loading} onSubmit={(data) => {
                        this.props.dispatch(matterAction.addCommentSuccess({
                            org_id: org.id,
                            matter_id: this.props.matterId,
                            ...data
                        }));
                    }}/>
                </Comment.Group>
            </Segment>
        );
    }
}

MatterComments.propTypes = {
    matterId: PropTypes.string.isRequired
};

function mapStateToProps(state) {
    let s = orgState(state);
    return {...s, ...state.matter};
}

const cMatterComments = connect(mapStateToProps)(MatterComments);
export {cMatterComments as MatterComments};
