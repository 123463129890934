import React, {Component} from 'react';
import {Button, Form, Message, Modal, Segment} from "semantic-ui-react";
import PropTypes from 'prop-types';
import QMCheckBox from "../../../other/form/QMCheckBox";

// props - group, error, loading, onClose, onSave, status
class QuestionGroupFormModal extends Component {
    constructor(props) {
        super(props);
        if (props.group) {
            this.state = {
                title: props.group.title,
                // type: props.group.type,
                status: props.group.status
            };
        } else {
            this.state = {
                group: "",
                // type: FINAL_QUESTION_TYPE.QUE_CONFIRMATION,
                status: true
            };
        }
    }

    render() {
        const {title, status} = this.state;
        const {error, loading, group: item} = this.props;
        // let types = helper.getFinalQueType();
        // if (queType === 'firm') {
        //     types = types.filter((t) => t.value !== 1);
        // }
        // types = types.filter((t) => !ignoreTypes.includes(t.value));
        return (
            <Modal size={"tiny"} open
                   closeOnDimmerClick={false}>
                <Modal.Header>{item ? "Edit" : "Add"} Group</Modal.Header>
                <Modal.Content>
                    <Segment basic>
                        <Form>
                            <Message color='red' hidden={error.length === 0}>
                                <Message.List>
                                    {error.map((item, index) => <Message.Item key={index}>{item}</Message.Item>)}
                                </Message.List>
                            </Message>
                            <br/>
                            <Form.Input label='Name:' placeholder='Group name' value={title}
                                        onChange={(e) => this.setState({title: e.target.value})}/>
                            {/*{!item &&*/}
                            {/*<Form.Dropdown label='Final Question Type:'*/}
                            {/*               selection value={type}*/}
                            {/*               onChange={(e, {value}) => this.setState({type: value})}*/}
                            {/*               name={"type"}*/}
                            {/*               options={types}/>}*/}
                            <Form.Field>
                                <label>Status:</label>
                                <QMCheckBox toggle onChange={(checked) => this.setState({status: checked})}
                                            checked={this.state.status}/>
                            </Form.Field>
                        </Form>
                    </Segment>
                </Modal.Content>
                <Modal.Actions>
                    <Button secondary onClick={() => this.props.onClose()}
                            disabled={loading}>Cancel</Button>
                    <Button color={"teal"} content='Submit'
                            type="submit"
                            loading={loading}
                            onClick={() => {
                                this.props.onSave({
                                    ...item,
                                    title: title,
                                    // type: type,
                                    status: status
                                });
                            }}
                            disabled={(loading) || (!title)}/>
                </Modal.Actions>
            </Modal>
        );
    }
}

QuestionGroupFormModal.propTypes = {
    group: PropTypes.object,
    ignoreTypes: PropTypes.array,
    error: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    onSave: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired
};

export default QuestionGroupFormModal;
