import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Header, Icon, Segment} from "semantic-ui-react";

class NoDataMessage extends Component {
    render() {
        const {active} = this.props;
        return ( active ?
                <Segment placeholder basic>
                    <Header icon color={'grey'}>
                        <Icon name='inbox' color={'grey'}/>
                        <br/>
                        Insufficient data.
                        <br/>
                        This page will be available when more evaluations have been submitted.
                    </Header>
                </Segment>
                :
                <div/>
        );
    }
}

NoDataMessage.propTypes = {
    active: PropTypes.bool.isRequired
};

export default NoDataMessage;
