import React, {Component} from 'react';
import {Form, Segment} from "semantic-ui-react";

class DataFilter extends Component {
    render() {
        return (
            <Segment style={{backgroundColor: "#F2F2F2", borderRadius: "4px", marginBottom: "1em"}} basic>
                <Form>
                    {this.props.children}
                </Form>
            </Segment>
        );
    }
}

export default DataFilter;
