import React, {Component} from 'react';
import {connect} from "react-redux";
import {OrgMenuBar} from "../../other/OrgMenuBar";
import {EditUserForm} from "../../../form/users/EditUserForm";
import {orgState, user} from "../../../../../helper/stateUtil";
import Body from "../../../../other/Body";
import OrgSubHeader from "../../other/OrgSubHeader";
import {Button} from "semantic-ui-react";
import {SendEmailToUser} from './SendEmailToUser';

class OrgManageUserPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false
        };
    }

    render() {
        const {loading, org, authUser} = this.props;
        const {active} = this.state;
        return (
            <Body loading={loading}>

                <OrgMenuBar/>

                <OrgSubHeader title={"Manage User"} backArrow>
                    <Button circular size={"medium"}
                            disabled={this.props.match.params.id === authUser.hash}
                            onClick={() => this.setState({active: true})}
                            floated="right" color={"teal"}>Send Email to User</Button>
                </OrgSubHeader>

                <EditUserForm orgid={org.id} userid={this.props.match.params.id}
                              isLoggedInUser={this.props.match.params.id === authUser.hash}/>

                {active && <SendEmailToUser
                    active={true}
                    onClose={() => {
                        this.setState({active: false});
                    }}/>
                }

            </Body>
        );
    }
}

function mapStateToProps(state) {
    const s = orgState(state);
    return {
        ...user(state),
        ...s,
        ...state.user
    };
}

const connectedOrgManageUserPage = connect(mapStateToProps)(OrgManageUserPage);
export {connectedOrgManageUserPage as OrgManageUserPage};
