import React, {Component} from 'react';
import {Icon, Segment, Table} from "semantic-ui-react";
import {connect} from "react-redux";
import SABody from "../other/SABody";
import SASubHeader from "../other/SASubHeader";
import helper from "../../../../helper/helper";

class HelpCatsPage extends Component {

    render() {
        return (
            <SABody loading={false}>

                <SASubHeader title={'Help'} backArrow/>

                <Segment padded={"very"} basic>
                    <Table basic='very' padded selectable stackable>
                        <Table.Body>
                            {helper.helpMenus.map((menu, index) => {
                                return (
                                    <Table.Row className={"row"} key={index}
                                               onClick={() => this.props.history.push(`/superadmin/help/${menu.value}`)}>
                                        <Table.Cell style={{paddingLeft: "16px"}}>
                                            <Icon name={menu.icon} color={"grey"} size={"large"}/>
                                            <span>{menu.text}</span>
                                        </Table.Cell>
                                    </Table.Row>
                                );
                            })}
                        </Table.Body>
                    </Table>
                </Segment>
            </SABody>
        );
    }
}

function mapStateToProps(state) {
    return state;
}

const cHelpCatsPage = connect(mapStateToProps)(HelpCatsPage);
export {cHelpCatsPage as HelpCatsPage};