import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Form, Header, Icon, Segment, Table} from "semantic-ui-react";

class ListLawyers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            query: ""
        };
    }

    render() {
        let items = this.props.lawyers;
        const {org} = this.props;
        const {query} = this.state;
        items = items.filter((item) => item.status);
        if (query.length > 0) {
            items = items.filter((item) => {
                const {name} = item;
                return (
                    name.toLowerCase().includes(query)
                );
            });
        }
        let content;
        if (items.length > 0) {
            content = (
                <Segment basic style={{padding: "0 3em"}}>
                    <Table basic='very' selectable stackable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Evaluatee</Table.HeaderCell>
                                <Table.HeaderCell>{org.str_client_firm}</Table.HeaderCell>
                                <Table.HeaderCell>Total Evaluations</Table.HeaderCell>
                                <Table.HeaderCell textAlign={"center"}>Last Evaluation</Table.HeaderCell>
                                <Table.HeaderCell textAlign={"center"}>Qualmet Score</Table.HeaderCell>
                                <Table.HeaderCell textAlign={"center"}>Overall Satisfaction Score</Table.HeaderCell>
                                <Table.HeaderCell textAlign={"center"}>Net Promoter Score</Table.HeaderCell>
                                <Table.HeaderCell textAlign={"center"}>Value for Money Score</Table.HeaderCell>
                                {org.diversity_tracking &&
                                <Table.HeaderCell textAlign={"center"}>Diversity Score</Table.HeaderCell>}
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {items.map((item, index) => {
                                return (
                                    <Table.Row key={index} className={"row"} onClick={() => this.props.onClick(item)}>
                                        <Table.Cell><b>{item.name}</b> ({item.email})</Table.Cell>
                                        <Table.Cell>{item.client_name}</Table.Cell>
                                        <Table.Cell textAlign={"center"}>{item.total_evaluations}</Table.Cell>
                                        <Table.Cell textAlign={"center"}>{item.last_evaluation}</Table.Cell>
                                        <Table.Cell textAlign={"center"}>{item.qualmet_score}</Table.Cell>
                                        <Table.Cell textAlign={"center"}>{item.overall_satisfaction_score}</Table.Cell>
                                        <Table.Cell textAlign={"center"}>{item.net_promoter_score}</Table.Cell>
                                        <Table.Cell textAlign={"center"}>{item.value_for_money}</Table.Cell>
                                        {org.diversity_tracking &&
                                        <Table.Cell textAlign={"center"}>{item.diversity_score}</Table.Cell>}
                                    </Table.Row>
                                );
                            })}
                        </Table.Body>
                    </Table>
                </Segment>
            );
        } else {
            content = (
                <Segment basic placeholder piled clearing>
                    <Header icon>
                        <Icon name='file outline'/>
                        No evaluatees are listed.
                    </Header>
                </Segment>
            );
        }

        return (
            <div>
                <Segment basic style={{padding: "2em 3em 1em 3em"}}>
                    <Form>
                        <Form.Group>
                            <Form.Input placeholder='Search' value={this.state.query}
                                        onChange={(e) => this.setState({query: e.target.value.toLowerCase()})}/>
                        </Form.Group>
                    </Form>
                </Segment>
                <br/>
                {content}
            </div>
        );
    }
}

ListLawyers.propTypes = {
    lawyers: PropTypes.array.isRequired,
    onClick: PropTypes.func,
    org: PropTypes.object.isRequired
};

export default ListLawyers;
