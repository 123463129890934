function set(key, value) {
    if (key === undefined || value === undefined) {
        throw new Error("Key or Value can not undefined!");
    }
    if (typeof value === 'object') {
        value = JSON.stringify(value);
    }
    localStorage.setItem(key, window.btoa(value));
}

function get(key, isObject = false) {
    try {
        if (typeof key === "undefined") {
            throw new Error("key can not undefined!");
        }
        let value = localStorage.getItem(key);
        if (value) {
            if (typeof value !== "object") {
                value = window.atob(value);
            }
        }
        if (isObject) {
            return JSON.parse(value);
        }
        return value;
    } catch (e) {
        return null;
    }
}

function has(key) {
    if (typeof key === 'undefined' || typeof key !== 'string') {
        throw new Error("key can not undefined and must be string!");
    }
    const value = localStorage.getItem(key);
    return !!value;
}

function remove(key) {
    if (typeof key === 'undefined' || typeof key !== 'string') {
        throw new Error("key can not undefined and must be string!");
    }
    localStorage.removeItem(key);
}

function reset() {
    for (let i = 0; i < localStorage.length; i++) {
        localStorage.removeItem(localStorage.key(i));
    }
}

const MIGRATION = {
    key: "MIKEY",
    value: 1
};

const migrations = () => {
    if (has(MIGRATION.key)) {
        // do nothing
    } else {
        storage.reset();
        set(MIGRATION.key, MIGRATION.value);
    }
};

const storage = {
    set,
    get,
    reset,
    has,
    remove,
    migrations
};

export default storage;