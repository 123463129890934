// // return roles of user
// function roles(state) {
//     if (state.auth.is_parent_user)
//         return {roles: state.auth.user.roles};
//     return {
//         roles: state.auth.sub_user.roles,
//     };
// }
//
// // return selected organization name
// function orgName(state) {
//     if (state.auth.is_parent_user) {
//         if (state.auth.user.org) {
//             return {org_name: state.auth.user.org.name};
//         }
//     } else {
//         if (state.auth.sub_user.org) {
//             return {org_name: state.auth.sub_user.org.name};
//         }
//     }
//     return {};
// }
//
// // return selected organization
// function org(state) {
//     if (state.auth.is_parent_user) {
//         if (state.auth.user.org) {
//             return {org: state.auth.user.org};
//         }
//     } else {
//         if (state.auth.sub_user.org) {
//             return {org: state.auth.sub_user.org};
//         }
//     }
//     return {};
// }

// return roles of user

export function roles(state) {
    return {roles: state.auth.user.roles};
}

// return selected organization name
export function orgName(state) {
    if (state.auth.user.org) {
        return {org_name: state.auth.user.org.name};
    }
    return {};
}

export function user(state) {
    if (state.auth.user) {
        return ({authUser: state.auth.user});
    }
    return ({authUser: {}});
}

// return selected organization
export function orgState(state) {
    if (state.auth.user && state.auth.user.org) {
        return {org: state.auth.user.org};
    }
    return {};
}

export default orgState;
