import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Icon, List, Segment} from "semantic-ui-react";
import helper from "../../helper/helper";
import Link from "./Link";
import {withRouter} from "react-router-dom";

class BoxFooter extends Component {
    render() {
        const {color, match: {path}} = this.props;
        const isPrivacyPath = path.includes('privacy');
        const isAboutPath = path.includes('about');
        const isContactPath = path.includes('contact');
        return (
            <Segment textAlign={'center'} basic id={'footer'}
                     style={{
                         minHeight: "8vh",
                         width: "100%",
                         backgroundColor: color || "#808080",
                         color: "rgba(255,255,255,.95)",
                         fontWeight: "600",
                         borderBottomLeftRadius: "4px",
                         borderBottomRightRadius: "4px"
                     }}>
                <List>
                    <List.Item>
                        <List horizontal>
                            {!isPrivacyPath && <List.Item>
                                <List.Content verticalAlign={"middle"}>
                                    <Link to={"/privacy"}>Privacy</Link>
                                </List.Content>
                            </List.Item>}
                            {!isPrivacyPath && <List.Item>
                                <List.Content verticalAlign={"middle"}>
                                    &bull;
                                </List.Content>
                            </List.Item>}
                            {!isContactPath && <List.Item>
                                <List.Content verticalAlign={"middle"}>
                                    <Link to={'/contact'}>Contact</Link>
                                </List.Content>
                            </List.Item>}
                            {(!isAboutPath && !isContactPath) && <List.Item>
                                <List.Content verticalAlign={"middle"}>
                                    &bull;
                                </List.Content>
                            </List.Item>}
                            {!isAboutPath && <List.Item>
                                <List.Content verticalAlign={"middle"}>
                                    <a className={"text-black"} style={{color: 'rgba(255,255,255,.95)'}}
                                       href={'https://qualmetlegal.com'}
                                       target={"_blank"}>About</a>
                                </List.Content>
                            </List.Item>}
                        </List>
                    </List.Item>
                    <List.Item>
                        <Icon name={"copyright outline"}/> {helper.copyright}
                    </List.Item>
                </List>
            </Segment>
        );
    }
}

BoxFooter.propTypes = {
    color: PropTypes.string
};

export default withRouter(BoxFooter);
