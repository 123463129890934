import React, {Component} from 'react';
import {Button, Confirm, Form, Header, Icon, Message, Segment} from "semantic-ui-react";
import {connect} from "react-redux";
import orgAction from "../../../../actions/org.action";
import helper from "../../../../helper/helper";
import QMCheckBox from "../../../other/form/QMCheckBox";
import TabHeader from "../../org/other/TabHeader";
import {ORG_TYPES} from "../../../../helper/const";
import SABody from "../other/SABody";

class EditOrgPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            type: ORG_TYPES.CLIENT,
            status: true,
            diversity_tracking: false,
            location_tracking: true,
            open: false, // modal for confirmation
            confirm_text: ""
        };
        this.tabs = [
            {text: "Summary", value: 1},
            {text: "Users", value: 2},
            {text: "SSO (Single Sign On)", value: 3},
        ];
        this.onChange = this.onChange.bind(this);
        this.onSelectPlan = this.onSelectPlan.bind(this);
        this.onSave = this.onSave.bind(this);
    }

    componentDidMount() {
        this.props.dispatch(orgAction.read({org_id: this.props.match.params.id}));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.isDone) {
            this.props.history.go(-1);
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.org) {
            this.setState({
                ...nextProps.org,
                new_plan_id: nextProps.org.plan_id
            });
        }
    }

    onSave(e) {
        e.preventDefault();
        this.props.dispatch(orgAction.update({
            id: this.props.org.id,
            organization_name: this.state.name,
            organization_type: this.state.type,
            organization_plan: this.state.plan,
            status: this.state.status,
            diversity_tracking: this.state.diversity_tracking,
            location_tracking: this.state.location_tracking,
            new_plan_id: this.state.new_plan_id
        }));
    }

    onChange(e, {name, value}) {
        this.setState({[name]: value});
    }

    onSelectPlan(e) {
        this.setState({new_plan_id: e.target.value});
    }

    render() {
        const {error, loading, history, match: {params: {id}}} = this.props;
        let {name, type, plans, no_of_users, plan_id, new_plan_id, open} = this.state;
        if (!plans)
            plans = [];
        let currentPlan = plans.find(p => plan_id === p.id);
        return (
            <SABody loading={loading}>
                <TabHeader title={'Organizations'} backArrow onTabChange={({value}) => {
                    if (value === 2) {
                        history.replace(`/superadmin/orgs/${id}/users`);
                    } else if (value === 3) {
                        history.replace(`/superadmin/orgs/${id}/sso`);
                    } else if (value === 4) {
                        history.replace(`/superadmin/orgs/${id}/billing`);
                    }
                }} activeIndex={0} tabs={this.tabs}/>

                <Segment basic padded='very'>
                    <Form>
                        <Message color='red' hidden={error.length === 0}>
                            <Message.List>
                                {error.map((item, index) => <Message.Item
                                    key={index}>{item}</Message.Item>)}
                            </Message.List>
                        </Message>
                        <br/>
                        <Form.Group>
                            <Form.Input label='Organization Name' placeholder='Organization Name' width={10}
                                        onChange={this.onChange} name={"name"} value={name}/>
                            <Form.Dropdown label='Organization Type' placeholder='Organization Type'
                                           width={6}
                                           selection onChange={this.onChange} value={type}
                                           name={"type"}
                                           options={helper.orgTypes}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Field width={2}>
                                <label>Status:</label>
                                <QMCheckBox toggle onChange={(checked) => this.setState({status: checked})}
                                            checked={this.state.status}/>
                            </Form.Field>
                            <Form.Field>
                                <Button type='button' color={"red"} inverted circular
                                        onClick={() => this.setState({open: true})}>
                                    Delete Organization
                                </Button>
                            </Form.Field>
                        </Form.Group>
                        <br/>
                        <Form.Field>
                            <label>Diversity Tracking:</label>
                            <QMCheckBox toggle
                                        onChange={(checked) => this.setState({diversity_tracking: checked})}
                                        checked={this.state.diversity_tracking}/>
                        </Form.Field>
                        <Form.Field>
                            <label>Location Tracking:</label>
                            <QMCheckBox toggle
                                        onChange={(checked) => this.setState({location_tracking: checked})}
                                        checked={this.state.location_tracking}/>
                        </Form.Field>
                        <Header as="h3">Select Plan</Header>
                        <br/>
                        <Header as="h4">Current Plan: {currentPlan ? currentPlan.title : "No Active Plan"}</Header>
                        <br/>

                        {plans.map((plan, index) => {
                                const active = new_plan_id === plan.id;
                                return <Button size='huge' basic={!active} fluid
                                               style={{textAlign: "left", marginTop: "4px"}}
                                               key={index} disabled={(no_of_users || 0) > (plan.no_users || 9999)}
                                               secondary={active} onClick={this.onSelectPlan}
                                               value={plan.id}>
                                    <Icon name="check circle"/> {plan.title}
                                </Button>
                            }
                        )}

                        <br/>

                        <Button type='submit' primary onClick={this.onSave} circular
                                disabled={!(this.state.name)}
                                floated='right'>Update</Button>
                        <Button type='button' secondary inverted circular
                                onClick={() => this.props.history.go(-1)}
                                floated='right'>Cancel</Button>
                        <br/>
                        <br/>
                    </Form>
                </Segment>
                <Confirm
                    size={"tiny"}
                    open={open}
                    header='Confirmation required'
                    cancelButton='NO'
                    confirmButton="YES"
                    content={`You are going to delete ${name} organization. Deleted organization data CANNOT be restored! Are you ABSOLUTELY sure?`}
                    closeOnDocumentClick
                    onCancel={() => this.setState({open: false})}
                    onConfirm={() => {
                        this.props.dispatch(orgAction.deleteOrg({id: this.props.match.params.id}));
                    }}
                />
            </SABody>
        );
    }
}

function mapStateToProps(state) {
    return {
        ...state.org
    };
}

const connectedEditOrgPage = connect(mapStateToProps)(EditOrgPage);
export {connectedEditOrgPage as EditOrgPage};