import React, {Component} from 'react';
import {Button, Header, Icon, Segment, Table} from "semantic-ui-react";
import {connect} from "react-redux";
import {OrgMenuBar} from "../../other/OrgMenuBar";
import {orgState} from "../../../../../helper/stateUtil";
import Body from "../../../../other/Body";
import OrgSubHeader from "../../other/OrgSubHeader";
import helper from "../../../../../helper/helper";
import ExportRequestForm from "./ExportRequestForm";
import exportAction from "../../../../../actions/export.action";

class AllExportRequestsPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false, // flag for modal open and close
        };
        this.onOpenModal = this.onOpenModal.bind(this);
    }

    onOpenModal(item) {
        if (item != null) {
            this.setState({open: true}); // for edit
        } else {
            this.setState({open: true}); // for add
        }
    }

    getAll = () => this.props.dispatch(exportAction.readAll({
        org_id: this.props.org.id,
    }));

    componentDidMount() {
        this.getAll();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.props.isAllowed) {
            this.props.history.replace("/forbidden");
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.isDone) {
            this.getAll();
            this.setState({open: false});
        }
    }

    render() {
        const {loading, items, error, org} = this.props;
        const {open} = this.state;
        let content;

        if (items.length > 0) {
            content = (
                <Segment padded={"very"} basic>
                    <Table basic='very' selectable stackable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Evaluation Type</Table.HeaderCell>
                                <Table.HeaderCell>Status</Table.HeaderCell>
                                <Table.HeaderCell>Created By</Table.HeaderCell>
                                <Table.HeaderCell>Created At</Table.HeaderCell>
                                <Table.HeaderCell>Action</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {items.map((item, index) => {
                                const i = helper.evaluationTypes(org).find(e => e.value === item.type);
                                return (
                                    <Table.Row className={"row"} key={index}>
                                        <Table.Cell style={{paddingLeft: "16px"}}>
                                            <Icon name="database" color={"grey"} size={"large"}/>
                                            <span><b>{i ? i.text : "Unknown"}</b></span>
                                        </Table.Cell>
                                        <Table.Cell>
                                            {helper.getStatusOfExportData(item.status)}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {item.user_name}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {item.created_at}
                                        </Table.Cell>
                                        <Table.Cell collapsing>
                                            <Button circular size={"medium"}
                                                    disabled={!item.path}
                                                    onClick={() => helper.downloadFile(item.path)}
                                                    floated="right" color={"teal"}>Download</Button>
                                        </Table.Cell>
                                    </Table.Row>
                                );
                            })}
                        </Table.Body>
                    </Table>
                </Segment>
            );
        } else {
            content = (
                <Segment basic placeholder piled>
                    <Header icon>
                        <Icon name='file outline'/>
                        No requests are listed.
                    </Header>
                </Segment>
            );
        }
        return (
            <div>
                <Body loading={!open && loading}>

                    <OrgMenuBar/>

                    <OrgSubHeader title={`Export Data - ${this.props.org.name}`} backArrow>
                        <Button circular size={"medium"}
                                onClick={() => this.onOpenModal(null)}
                                floated="right" color={"teal"}>Create Request</Button>
                    </OrgSubHeader>

                    {content}

                </Body>

                {/* add and edit question modal */}
                {open && <ExportRequestForm
                    org={org}
                    error={error}
                    loading={loading}
                    onSave={(value) => {
                        this.props.dispatch(exportAction.create({
                            org_id: this.props.org.id,
                            ...value
                        }));
                    }}
                    onClose={() => {
                        this.setState({open: false});
                    }}
                />}
            </div>
        );
    }
}

function mapStateToProps(state) {
    let s = orgState(state);
    return {...s, ...state.export};
}

const cAllExportRequestsPage = connect(mapStateToProps)(AllExportRequestsPage);
export {cAllExportRequestsPage as AllExportRequestsPage};