import React, {Component} from 'react';
import {Button, Form, Header, Icon, Segment} from "semantic-ui-react";
import {connect} from "react-redux";
import {OrgMenuBar} from "../../other/OrgMenuBar";
import userAction from "../../../../../actions/user.action";
import helper from "../../../../../helper/helper";
import {orgState} from "../../../../../helper/stateUtil";
import Body from "../../../../other/Body";
import OrgSubHeader from "../../other/OrgSubHeader";
import ErrorMessage from "../../../../other/ErrorMessage";

class OrgViewUserPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            roles: []
        };
        this.onChange = this.onChange.bind(this);
        this.onSelectPlan = this.onSelectPlan.bind(this);
        this.onSave = this.onSave.bind(this);
    }

    componentDidMount() {
        this.props.dispatch(userAction.read({org_id: this.props.org.id, user_id: this.props.match.params.id}));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.isDone) {
            this.props.history.go(-1);
        }
    }

    onSave(e) {
        e.preventDefault();
        this.props.dispatch(userAction.create({
            org_id: this.props.org.id,
            email: this.state.email,
            roles: this.state.roles
        }));
    }

    onChange(e) {
        this.setState({email: e.target.value});
    }

    onSelectPlan(e, {id}) {
        if (this.state.roles.includes(id)) {
            this.setState((prevState) => ({roles: [...prevState.roles.filter(role => role !== id)]}));
        } else {
            this.setState((preState) => ({roles: [...preState.roles, id]}));
        }
    }

    render() {
        const {error, loading} = this.props;
        return (
            <Body loading={loading}>

            <OrgMenuBar/>

            <OrgSubHeader title={'View User'}/>

            <Segment basic padded='very'>
                <Form>
                    <ErrorMessage errors={error}/>

                    <Form.Field>
                        <label>Email</label>
                        <input placeholder='Email' onChange={this.onChange} type="email"/>
                    </Form.Field>

                    <Header as="h3">Roles</Header>
                    <br/>

                    {helper.roles.map((role, index) =>
                        <Button size='huge' fluid style={{textAlign: "left", marginTop: "4px"}} key={index}
                                primary={this.state.roles.includes(role.id)} onClick={this.onSelectPlan}
                                value={(index + 1)} id={role.id}>
                            <Icon name="check circle"/> {role.title}
                        </Button>
                    )}

                    <br/>
                    <br/>
                    <Button type='submit' primary onClick={this.onSave} floated='right' circular
                            disabled={!(this.state.email && this.state.roles.length > 0)}>SAVE</Button>
                    <Button type='button' secondary inverted floated='right' circular
                            onClick={() => this.props.history.go(-1)}>Cancel</Button>
                    <br/>
                </Form>
            </Segment>
            </Body>
        );
    }
}

function mapStateToProps(state) {
    const s = orgState(state);
    return {
        ...s,
        ...state.user
    };
}

const connectedOrgViewUserPage = connect(mapStateToProps)(OrgViewUserPage);
export {connectedOrgViewUserPage as OrgViewUserPage};