import React, {Component} from 'react';
import {Button, Checkbox, Form, Grid, Segment} from "semantic-ui-react";
import {connect} from "react-redux";
import {OrgMenuBar} from "../../other/OrgMenuBar";
import "./../../../../../react-datepicker.css";
import OrgSubHeader from "../../other/OrgSubHeader";
import matterAction from "../../../../../actions/matter.action";
import {orgState} from "../../../../../helper/stateUtil";
import Body from "../../../../other/Body";
import ErrorMessage from "../../../../other/ErrorMessage";
import MatterInformation from "../../other/MatterInformation";
import EvaluationSettings from "../../other/EvaluationSettings";
import {EVALUATION_TYPE} from "../../../../../helper/const";
import helper from "../../../../../helper/helper";

class OrgAddMatterPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                name: "",
                number: "",
                client_id: null,
                sdate: null,
                edate: null,
                client_estimated_spend: "",
                estimated_spend: "",
                actual_spend: "",
                office_id: null,
                practice_area_id: null,
                interval_id: null,
                email_reminder_id: null,
                status: true,
                evaluatees: [],
                evaluators: [],
                question_set: null,
                evaluation_type: 2,
                evaluation_style: 1,
                ec_status: true,
                responsible_evaluator: null,
                email_reminder_status: true
            }
        };
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        this.props.dispatch(matterAction.readMasterData({org_id: this.props.org.id}));
    }

    componentDidUpdate() {
        if (this.props.isDone) {
            this.props.history.go(-1);
        }
    }

    onChange = (data) => {
        const {sdate, edate} = data;
        if (sdate) {
            data.start_date = helper.dateFormat(sdate);
        }
        if (edate) {
            data.end_date = helper.dateFormat(edate);
        }
        this.setState({data: {...this.state.data, ...data}});
    };

    render() {
        const {error, loading, org, master_data} = this.props;
        const {data} = this.state;
        const {
            name,
            client_id,
            start_date,
            end_date,
            status,
            practice_area_id,
            interval_id,
            email_reminder_id,
            evaluatees,
            evaluators,
            question_set,
            evaluation_type,
            responsible_evaluator,
            email_reminder_status
        } = data;

        let isValid = (
            name
            && client_id
            && start_date
            && end_date
            && practice_area_id
            && interval_id
            && question_set
        );

        if (isValid)
            switch (evaluation_type) {
                case EVALUATION_TYPE.EXTERNAL_EVALUATION:
                    isValid = evaluatees.length > 0 && evaluators.length > 0;
                    break;
                case EVALUATION_TYPE.SELF_EVALUATION:
                    isValid = evaluators.length > 0;
                    break;
                case EVALUATION_TYPE.REP_EVAL:
                    isValid = responsible_evaluator && evaluators.length > 0;
                    break;
                default:
                    isValid = false;
                    break;
            }

        if (email_reminder_status) {
            if (!email_reminder_id) {
                isValid = false;
            }
        }

        return (
            <Body loading={loading}>
            <OrgMenuBar/>

            <OrgSubHeader backArrow title={"Add Matter"}/>

            <Segment basic padded='very'>
                <ErrorMessage errors={error}/>
                {/*<ErrorModal errors={error}/>*/}
                <Grid columns='equal' stackable>
                    <Grid.Column>
                        <MatterInformation
                            isUpdate={false}
                            master_data={master_data}
                            org={org}
                            data={data}
                            onChange={this.onChange}/>
                        <EvaluationSettings
                            type={"matter"}
                            isUpdate={false}
                            master_data={master_data}
                            org={org}
                            data={data}
                            onChange={this.onChange}/>
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <Form>
                            <Form.Field>
                                <label>Matter Status:</label>
                                <Checkbox toggle label={data.status ? "Open" : "Close"}
                                          checked={status}
                                          onChange={(e, {checked}) => {
                                              data.status = checked;
                                              this.setState({data: data});
                                          }}/>
                            </Form.Field>
                        </Form>
                    </Grid.Column>
                </Grid>
                <Button type='submit' primary
                        disabled={!isValid}
                        onClick={() => {
                            let req = {...data};
                            req.evaluators = data.evaluators.map((id) => {
                                return {id: id, is_rep: false}
                            });
                            switch (data.evaluation_type) {
                                case EVALUATION_TYPE.REP_EVAL:
                                    req.evaluators.push({id: data.responsible_evaluator, is_rep: true});
                                    break;
                                default:
                                    break;
                            }
                            req = {...req, org_id: org.id};
                            this.props.dispatch(matterAction.create(req));
                        }} floated='right' circular>Create</Button>
                <Button type='button' secondary inverted floated='right' circular
                        onClick={() => this.props.history.go(-1)}>Cancel</Button>
                <br/>
            </Segment>
            </Body>
        );
    }
}

function mapStateToProps(state) {
    const s = orgState(state);
    return {
        ...s,
        ...state.matter
    };
}

const cOrgAddMatterPage = connect(mapStateToProps)(OrgAddMatterPage);
export {cOrgAddMatterPage as OrgAddMatterPage};