import React, {Component} from 'react';
import {Button, Form, Header, Icon, Modal, Segment, Table} from "semantic-ui-react";
import orgAction from "../../../../actions/org.action";
import authAction from "../../../../actions/auth.action";
import SABody from "../other/SABody";
import SASubHeader from "../other/SASubHeader";
import {connect} from "react-redux";

// filter options => Status( -1 - All, 1 - Active, 2 - Deactive)
class ListOrgPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pending: false,
            search_query: "",
            filter: -1,
            org: null
        };
        this.onChange = this.onChange.bind(this);
    }

    onSelect = (org) => {
        org.from_qa = true;
        this.props.dispatch(authAction.assignOrganization(org));
    };

    componentDidMount() {
        this.props.dispatch(orgAction.readAll());
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.isAssigned) {
            this.props.history.push("/orgadmin");
        }
    }

    componentWillReceiveProps(nextProps) {
        const {isDone, dispatch} = nextProps;
        if (isDone) {
            dispatch(orgAction.readAll(true));
            this.setState({org: null});
        }
    }

    getPlanStr = (plan) => {
        switch (plan) {
            case 1:
                return "Level 1";
            default:
                return "No Plan";
        }
    };

    onChange = () => {
        const {pending} = this.state;
        this.props.dispatch(orgAction.readAll(!pending));
        this.setState({pending: !pending});
    };

    onActive = org => this.setState({org: org});

    render() {
        const {loading, dispatch} = this.props;
        let orgs = this.props.orgs;
        const {filter, search_query, pending, org} = this.state;
        if (search_query.length > 0) {
            orgs = this.props.orgs.filter(org => org.name.toLowerCase().includes(this.state.search_query));
        }
        // -1 filter flag for all so more than 0 is other status of org
        if (filter === 1) { // active
            orgs = orgs.filter((org) => org.status);
        } else if (filter === 2) { // inactives
            orgs = orgs.filter((org) => !org.status);
        }
        let content;
        if (orgs.length > 0) {
            content = (
                <Segment basic style={{padding: "0 3em"}}>
                    <Table basic='very' selectable stackable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Organization</Table.HeaderCell>
                                {!pending && <Table.HeaderCell>Plan</Table.HeaderCell>}
                                <Table.HeaderCell>Date Created</Table.HeaderCell>
                                {!pending && <Table.HeaderCell>Status</Table.HeaderCell>}
                                {!pending && <Table.HeaderCell>Users</Table.HeaderCell>}
                                <Table.HeaderCell></Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {orgs.map((org, index) => {
                                return (
                                    <Table.Row key={index} className={"row"}>
                                        <Table.Cell>
                                            <Icon style={{marginLeft: "16px"}} name={"building"} color={"grey"}
                                                  size={"large"}/>
                                            {/*<Label style={{marginLeft: "16px"}} circular*/}
                                            {/*color={"grey"}>{index + 1}</Label>*/}
                                            <span><b>{org.name}</b></span>
                                        </Table.Cell>
                                        {!pending && <Table.Cell>{org.plan_title}</Table.Cell>}
                                        <Table.Cell>{org.created_at}</Table.Cell>
                                        {!pending && <Table.Cell>{org.status ? "Active" : "Inactive"}</Table.Cell>}
                                        {!pending && <Table.Cell>{org.total_users}</Table.Cell>}
                                        <Table.Cell textAlign="right" style={{paddingRight: "12px"}}>
                                            {!pending &&
                                            <Button icon={"pencil"} circular color={'grey'}
                                                    onClick={() => this.props.history.push(`/superadmin/orgs/edit/${org.id}`)}/>}
                                            {!pending && <Button circular color={'grey'}
                                                                 onClick={() => this.onSelect(org)}>
                                                View Organization
                                            </Button>}
                                            {pending && <Button circular color={'grey'}
                                                                onClick={() => this.onActive(org)}>
                                                Activate
                                            </Button>}
                                        </Table.Cell>
                                    </Table.Row>
                                );
                            })}

                        </Table.Body>
                    </Table>
                </Segment>
            );
        } else {
            content = (
                <Segment basic placeholder piled clearing>
                    <Header icon>
                        <Icon name='file outline'/>
                        No {pending && "pending"} organizations are listed.
                    </Header>
                    {!pending &&
                    <Button color={"teal"} circular onClick={() => this.props.history.push("/superadmin/orgs/add")}>
                        Add Organization
                    </Button>}
                </Segment>
            );
        }

        return (
            <SABody loading={loading}>

                <SASubHeader title={"Organizations"} backArrow={false}>
                    <Button floated='right' color={"teal"} circular onClick={() => {
                        this.props.history.push('/superadmin/orgs/add');
                    }}>Add Organization</Button>
                </SASubHeader>

                <Segment basic style={{padding: "2em 3em 1em 3em"}}>
                    <Form>
                        <Form.Group>
                            <Form.Input placeholder='Search' value={this.state.search_query}
                                        onChange={(e) => this.setState({search_query: e.target.value.toLowerCase()})}/>
                            {!pending &&
                            <Form.Dropdown selection value={this.state.filter}
                                           onChange={(e, {value}) => this.setState({filter: value})}
                                           name={"type"}
                                           options={[
                                               {text: "All", value: -1},
                                               {text: "Active", value: 1},
                                               {text: "Inactive", value: 2}]}/>}
                            {/*<Button circular primary={pending}*/}
                            {/*        onClick={this.onChange}>{pending ? "Go Back" : "Pending Organizations"}</Button>*/}
                        </Form.Group>
                    </Form>
                </Segment>
                <br/>
                {content}

                {org != null && <Modal size={"tiny"} open>
                    <Modal.Header>Activate Organization</Modal.Header>
                    <Modal.Content>
                        <Header as={'h4'}>Are you sure you want to activate "{org.name}" organization?</Header>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button circular onClick={() => this.setState({org: null})} disabled={loading}>No</Button>
                        <Button color={'teal'} circular content='Yes' disabled={loading} loading={loading}
                                onClick={() => {
                                    dispatch(orgAction.activateOrg({org_id: org.id, status: true}));
                                }}/>
                    </Modal.Actions>
                </Modal>}

            </SABody>
        );
    }
}

function mapStateToProps(state) {
    return {
        ...state.org,
        isAssigned: state.auth.isDone
    };
}

const connectedListOrgPage = connect(mapStateToProps)(ListOrgPage);
export {connectedListOrgPage as ListOrgPage};