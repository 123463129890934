import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Icon} from "semantic-ui-react";

class RejectIcon extends Component {
    render() {
        return (
            <Icon link name={"remove circle"} size='large' color={"red"}
                  onClick={() => this.props.onClick()}/>
        );
    }
}

RejectIcon.propTypes = {
    onClick: PropTypes.func
};

export default RejectIcon;
