import React, {Component} from 'react';
import {Button, Form, Message, Modal, Segment} from "semantic-ui-react";
import helper from "../../../../../helper/helper";
import QMCheckBox from "../../../../other/form/QMCheckBox";
import PropTypes from "prop-types";
import {E_QUESTION_TYPE} from "../../../../../helper/const";

// props - question, error, loading, onClose, onSave, status
class EditQuestionModal extends Component {
    constructor(props) {
        super(props);
        if (props.question) {
            this.state = {
                title: props.question.title,
                answer_type: props.question.answer_type,
                status: props.question.status,
                group_id: props.question.group_id,
            };
        } else {
            this.state = {
                title: "",
                answer_type: E_QUESTION_TYPE.ANSWER_TYPE_U_E,
                status: props.status,
                group_id: null
            };
        }
    }

    render() {
        const {title, answer_type, status, group_id} = this.state;
        const {error, loading, question, groups} = this.props;
        return (
            <Modal size={"tiny"} open
                   closeOnDimmerClick={false}>
                <Modal.Header>{question ? "Edit" : "Add"} Question</Modal.Header>
                <Modal.Content>
                    <Segment basic>
                        <Form>
                            <Message color='red' hidden={error.length === 0}>
                                <Message.List>
                                    {error.map((item, index) => <Message.Item key={index}>{item}</Message.Item>)}
                                </Message.List>
                            </Message>
                            <br/>
                            <Form.Field label='Question:' control={"textarea"}
                                        rows={2} placeholder='Question' value={title}
                                        onChange={(e) => this.setState({title: e.target.value})}/>
                            <Form.Dropdown label='Answer Type:'
                                           selection value={answer_type}
                                           onChange={(e, {value}) => this.setState({answer_type: value})}
                                           name={"type"}
                                           options={helper.getListOfAnswerType()}/>
                            <Form.Dropdown label='Group:'
                                           selection value={group_id}
                                           onChange={(e, {value}) => this.setState({group_id: value})}
                                           name={"type"}
                                           options={groups}/>
                            {!question && <Form.Field>
                                <label>Status:</label>
                                <QMCheckBox toggle onChange={(checked) => this.setState({status: checked})}
                                            checked={this.state.status} disabled={!this.props.status}/>
                            </Form.Field>}
                        </Form>
                    </Segment>
                </Modal.Content>
                <Modal.Actions>
                    <Button secondary circular onClick={() => this.props.onClose()}
                            disabled={loading}>Cancel</Button>
                    <Button color={"teal"} circular content='Submit'
                            type="submit"
                            loading={loading}
                            onClick={() => {
                                this.props.onSave({
                                    ...question,
                                    title: title,
                                    answer_type: answer_type,
                                    status: status,
                                    group_id: group_id
                                });
                            }}
                            disabled={(loading) || (!title)}/>
                </Modal.Actions>
            </Modal>
        );
    }
}

EditQuestionModal.propTypes = {
    question: PropTypes.object,
    groups: PropTypes.array.isRequired,
    onSave: PropTypes.func.isRequired,
};

export default EditQuestionModal;
