import checklistService from "../services/checklist.service";

function read(data) {
    return dispatch => {
        dispatch(request());
        checklistService.read(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function request() {
        return {type: CHECKLIST.REQUEST};
    }

    function success(res) {
        return {type: CHECKLIST.READ_SUCCESS, payload: res.data};
    }

    function error(reason) {
        reason = reason.response;
        if (reason.status === 404) {
            return {type: CHECKLIST.NOT_FOUND};
        }
        return {type: CHECKLIST.ERROR, payload: reason.data};
    }
}

function update(data) {
    return dispatch => {
        checklistService.update(data);
        dispatch({type: CHECKLIST.UPDATE_SUCCESS, payload: data});
    };
}

const checklistAction = {
    read,
    update
};

// constants
export const CHECKLIST = {

    REQUEST: "CHECKLIST_REQUEST",
    ERROR: "CHECKLIST_ERROR",
    NOT_FOUND: "CHECKLIST_NOT_FOUND",
    VALIDATION_ERROR: "CHECKLIST_VALIDATION_ERROR ",

    UPDATE_SUCCESS: "CHECKLIST_UPDATE_SUCCESS",
    READ_SUCCESS: "CHECKLIST_READ_SUCCESS",
};

export default checklistAction;