import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Form, Modal, Segment} from "semantic-ui-react";
import ErrorMessage from "../../../../../../other/ErrorMessage";

class RegionModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: "",
            alternate_title: ""
        };
    }

    componentWillReceiveProps(nextProps) {
        const {region} = nextProps;
        if (region) {
            this.setState({
                title: region.title,
                alternate_title: region.alternate_title
            });
        } else {
            this.setState({
                title: '',
                alternate_title: ''
            });
        }
    }

    render() {
        const {loading, errors, open, onSubmit, region, onClose} = this.props;

        return (
            <Modal size={"tiny"} open={open}
                   closeOnDimmerClick={false}
                   onClose={() => onClose()}>
                <Modal.Header>{region ? "Edit" : "Add"} Region</Modal.Header>
                <Modal.Content>
                    <Segment basic>
                        <Form>
                            <ErrorMessage errors={errors}/>
                            <Form.Field>
                                <label>Region Title:</label>
                                <input placeholder={`Region Title`}
                                       onChange={(e) => this.setState({title: e.target.value})}
                                       type="text" value={this.state.title}/>
                            </Form.Field>
                            <Form.Field>
                                <label>Alternate Title:</label>
                                <input placeholder={`Alternate Title`}
                                       onChange={(e) => this.setState({alternate_title: e.target.value})}
                                       type="text" value={this.state.alternate_title}/>
                            </Form.Field>
                        </Form>
                    </Segment>
                </Modal.Content>
                <Modal.Actions>
                    <Button secondary onClick={() => onClose()}
                            disabled={loading}>Cancel</Button>
                    <Button color={"teal"} content="Submit"
                            type="submit"
                            loading={loading}
                            onClick={() => {
                                onSubmit(this.state, region);
                            }}
                            disabled={(loading) || (!this.state.title)}/>
                </Modal.Actions>
            </Modal>
        );
    }
}

RegionModal.propTypes = {
    open: PropTypes.bool.isRequired,
    errors: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    region: PropTypes.object
};

export default RegionModal;
