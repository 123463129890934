import {MATTER} from "../actions/matter.action";

const initState = {
    isAllowed: true,
    loading: false,
    isDone: false,
    master_data: {
        "clients": [],
        "practices": [],
        "region_areas": [],
        "location_areas": [],
        "lawyers": [],
        "evaluators": [],
        "intervals": [],
        "email_reminders": [],
        "question_sets": [],
        "question_sets_self_evals": [],
        "question_sets_ext_evals": [],
        "offices": []
    },
    matters: [],
    error: [],
    success: [],
    matter: null,

    // for summary page
    evaluations: [],
    comments: [],
    diversity: {},
    summary: {}
};

export default function matterReducer(state = initState, action) {
    // reset
    state.error = [];
    state.success = [];
    state.isDone = false;
    state.loading = false;
    state.isAllowed = true;

    // filter
    switch (action.type) {

        case MATTER.UNMOUNT:
            return {...initState};

        // common
        case MATTER.REQUEST:
            return {...state, loading: true};

        case MATTER.VALIDATION_ERROR:
            return {...state, error: action.payload};

        case MATTER.ERROR:
            return {...state, error: [...action.payload]};

        // not allowed to access
        case MATTER.NOT_ALLOWED:
            return {...state, isAllowed: false};

        // add MATTER
        case MATTER.ADD_SUCCESS:
            return {...state, isDone: true, matters: [...state.matters, action.payload]};

        case MATTER.UPDATE_SUCCESS:
            return {...state, isDone: true, matter: null};
        case MATTER.UPDATE_DIVERSITY_DATA_SUCCESS:
            return {...state, isDone: true, diversity: action.payload};

        case MATTER.READ_ALL_SUCCESS:
            return {...state, matters: action.payload, matter: null};

        case MATTER.REQUEST_READ_MATTER:
            return {...state, loading: true, matter: null};

        case MATTER.READ_SUCCESS:
            return {...state, matter: action.payload, isDone: true};

        case MATTER.READ_SUMMARY_SUCCESS:
            return {...state, summary: action.payload};

        case MATTER.NOT_FOUND:
            return {...state, not_found: true};

        case MATTER.CHANGE_STATUS_SUCCESS:
            state.matters[state.matters.findIndex((client) => client.id === action.payload.id)].status = action.payload.status;
            return {...state};

        case MATTER.READ_MASTER_DATA:
            return {...state, master_data: action.payload};

        case MATTER.READ_ALL_COMMENTS:
            return {...state, comments: action.payload};

        case MATTER.ADD_COMMENT_SUCCESS:
            return {...state, isDone: true, comments: [...state.comments, action.payload]};

        case MATTER.READ_ALL_EVALUATIONS_SUCCESS:
            return {...state, evaluations: action.payload};

        case MATTER.READ_ALL_MATTERS_EVALUATIONS_CYCLES:
        case MATTER.READ_ALL_MY_EVALUATIONS_SUCCESS:
            const items = action.payload;
            let evaluations = [];

            let temp = [...items.filter((item) => item.status === 3)];
            temp.sort((a, b) => (new Date(a.due_date)) - (new Date(b.due_date)));
            evaluations.push(...temp);

            temp = [...items.filter((item) => item.status === 2)];
            temp.sort((a, b) => (new Date(a.due_date)) - (new Date(b.due_date)));
            evaluations.push(...temp);

            temp = [...items.filter((item) => item.status === 4)];
            temp.sort((a, b) => (new Date(a.due_date)) - (new Date(b.due_date)));
            evaluations.push(...temp);

            temp = [...items.filter((item) => item.status === 1)];
            temp.sort((a, b) => (new Date(a.due_date)) - (new Date(b.due_date)));
            evaluations.push(...temp);

            temp = [...items.filter((item) => item.status === 5)];
            temp.sort((a, b) => (new Date(a.due_date)) - (new Date(b.due_date)));
            evaluations.push(...temp);

            return {...state, evaluations: evaluations};

        case MATTER.START_EVALUATION_SUCCESS:

            return {...state, isDone: true};

        case MATTER.REJECT_EVALUATION_SUCCESS:

            return {...state, isDone: true};

        case MATTER.RESEND_REMINDER_OF_EVALUATION_SUCCESS:
            return {...state, success: ['Email Reminder successfully sent!']};

        case MATTER.EVALUATE_SUCCESS:
            return {...state, isDone: true};

        case MATTER.READ_DIVERSITY_DATA:
            return {...state, diversity: {...action.payload}};

        case MATTER.CLOSE_EVAL_SUCCESS:
            return {...state, isDone: true};

        default:
            return state;
    }
}