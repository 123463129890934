import React, {Component} from 'react';
import {Button, Form, Header, Input, Segment} from "semantic-ui-react";
import {connect} from "react-redux";
import authAction from "../../../actions/auth.action";
import ErrorMessage from "../../other/ErrorMessage";
import ColoredBody from "../../other/ColoredBody";
import BoxHeader from "../../other/BoxHeader";
import BoxFooter from "../../other/BoxFooter";

class ForgotPasswordPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
        };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    onSubmit(e) {
        e.preventDefault();
        const {email} = this.state;
        this.props.dispatch(authAction.forgotPassword({
            email
        }));
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.isDone) {
            this.setState({email: ''});
            alert("An email has been sent to your email id with password reset instructions");
        }
    }

    render() {
        const {email} = this.state;
        const {loading, error} = this.props;
        return (
            <ColoredBody textAlign={"center"}>
                <BoxHeader/>
                <Segment padded={'very'} textAlign={"left"}>
                    <Header as='h2' textAlign='center'>
                        Forgot Password?
                    </Header>
                    <br/>
                    <br/>
                    <ErrorMessage errors={error}/>
                    <Form autoComplete='off'>
                        <Form.Field>
                            <label>Email</label>
                            <Input fluid icon='user' iconPosition='left'
                                   placeholder='E-mail address' type='email' name='email'
                                   value={email}
                                   onChange={this.onChange} focus/>
                        </Form.Field>
                        <br/>
                        <Button color='teal' size='large' floated={'right'} onClick={this.onSubmit}
                                loading={loading} circular
                                disabled={loading || !email}>
                            Submit
                        </Button>
                        <br/>
                        <br/>
                    </Form>
                </Segment>
                <BoxFooter/>
            </ColoredBody>
        );
    }
}

function mapStateToProps(state) {
    return state.auth;
}

const connectedForgotPasswordPage = connect(mapStateToProps)(ForgotPasswordPage);
export {connectedForgotPasswordPage as ForgotPasswordPage};