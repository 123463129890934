import evaluationQuestionService from "../services/evaluation_question.service";
import finalQuestionsService from "../services/final-questions.service";
import questionGroupService from "../services/question-groups.service";

// ########################## COMMON METHODS ##########################
function request() {
    return {type: EVALUATION_QUESTION.REQUEST};
}

function error(reason) {
    reason = reason.response;
    // validation error
    if (reason.status === 422) {
        return {type: EVALUATION_QUESTION.VALIDATION_ERROR, payload: reason.data};
    }
    return {type: EVALUATION_QUESTION.ERROR, payload: reason.data};
}

// ########################## QUESTION SET ##########################
function createQuestionData(data) {

    return dispatch => {
        dispatch(request());
        evaluationQuestionService.addQuestionSet(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        if (res.status === 201) {
            return {type: EVALUATION_QUESTION.ADD_SUCCESS_QUESTION_DATA, payload: res.data};
        }
        return error(res);
    }
}

function createQuestionSetGroup(data) {

    return dispatch => {
        dispatch(request());
        evaluationQuestionService.addQuestionSetGroup(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        if (res.status === 201) {
            return {type: EVALUATION_QUESTION.ADD_SUCCESS_QUESTION_DATA, payload: res.data};
        }
        return error(res);
    }
}

function createQuestionSetGroupQuestion(data) {

    return dispatch => {
        dispatch(request());
        evaluationQuestionService.addQuestionSetGroupQuestion(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        if (res.status === 201) {
            return {type: EVALUATION_QUESTION.ADD_SUCCESS_QUESTION, payload: {...data,id: res.data.id}};
        }
        return error(res);
    }
}

function readAllQuestionSets(data) {
    return dispatch => {
        dispatch(request());
        evaluationQuestionService.readAllQuestionSet(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: EVALUATION_QUESTION.READ_ALL_SUCCESS, payload: res.data};
    }
}

// ########################## QUESTION SET'S GROUP ##########################
function readAllQuestionGroups(data) {
    return dispatch => {
        dispatch(request());
        evaluationQuestionService.readAllQuestionGroup(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: EVALUATION_QUESTION.READ_ALL_SUCCESS, payload: res.data};
    }
}

// ########################## QUESTION ##########################
function readAllQuestions(data) {
    return dispatch => {
        dispatch(request());
        evaluationQuestionService.readAllQuestions(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: EVALUATION_QUESTION.READ_ALL_SUCCESS, payload: res.data};
    }
}

function create(data) {
    return dispatch => {
        dispatch(request());
        evaluationQuestionService.add(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        if (res.status === 201) {
            return {type: EVALUATION_QUESTION.ADD_SUCCESS, payload: data};
        }
        return error(res);
    }
}

function update(data) {
    return dispatch => {
        dispatch(request());
        evaluationQuestionService.update(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        if (res.status === 202) {
            return {type: EVALUATION_QUESTION.UPDATE_SUCCESS, payload: data};
        }
        return error(res);
    }
}

function changeStatus(data) {
    return dispatch => {
        dispatch(request());
        evaluationQuestionService.changeStatus(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        if (res.status === 202) {
            return {type: EVALUATION_QUESTION.CHANGE_STATUS_SUCCESS, payload: data};
        }
        return error(res);
    }
}

function readQuestionSet(data) {
    return dispatch => {
        dispatch(request());
        evaluationQuestionService.readQuestionSet(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: EVALUATION_QUESTION.READ_QUESTION_SET_SUCCESS, payload: res.data};
    }
}

function readQuestionSetGroup(data) {
    return dispatch => {
        dispatch(request());
        evaluationQuestionService.readQuestionSetGroup(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: EVALUATION_QUESTION.READ_QUESTION_SET_GROUP_SUCCESS, payload: res.data};
    }
}

function getAllowedMasterDataEQType(data) {
    return dispatch => {
        dispatch(request());
        evaluationQuestionService.getAllowedMasterDataEQType(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: EVALUATION_QUESTION.SUCCESS_ALLOWED_TYPS, payload: res.data};
    }
}

// ########################## FINAL QUESTIONS ##########################

function getAllFinalQuestions() {
    return dispatch => {
        dispatch(request());
        finalQuestionsService.readAll().then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: EVALUATION_QUESTION.READ_ALL_FINAL_QUESTIONS, payload: res.data};
    }
}

function addFinalQuestion(data) {
    return dispatch => {
        dispatch(request());
        finalQuestionsService.add(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        if (res.status === 201) {
            return {type: EVALUATION_QUESTION.ADD_SUCCESS_FINAL_QUESTION, payload: {...data, ...res.data}};
        }
        return error(res);
    }
}

function updateFinalQuestion(data) {
    return dispatch => {
        dispatch(request());
        finalQuestionsService.update(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        if (res.status === 202) {
            return {type: EVALUATION_QUESTION.UPDATE_SUCCESS_FINAL_QUESTION, payload: data};
        }
        return error(res);
    }
}

// ########################## QUESTION GROUPS ##########################

function getAllQueGroups() {
    return dispatch => {
        dispatch(request());
        questionGroupService.readAll().then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: EVALUATION_QUESTION.READ_ALL_FINAL_QUESTIONS, payload: res.data};
    }
}

function addQueGroup(data) {
    return dispatch => {
        dispatch(request());
        questionGroupService.add(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        if (res.status === 201) {
            return {type: EVALUATION_QUESTION.ADD_SUCCESS_FINAL_QUESTION, payload: {...data, ...res.data}};
        }
        return error(res);
    }
}

function updateQueGroup(data) {
    return dispatch => {
        dispatch(request());
        questionGroupService.update(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        if (res.status === 202) {
            return {type: EVALUATION_QUESTION.UPDATE_SUCCESS_FINAL_QUESTION, payload: data};
        }
        return error(res);
    }
}

function getAllActiveQueGroups() {
    return dispatch => {
        dispatch(request());
        questionGroupService.getAllActiveQueGroups().then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: EVALUATION_QUESTION.READ_ALL_QUESTION_GROUPS, payload: res.data};
    }
}

const evaluationQuestionAction = {

    // COMMON
    createQuestionData,
    createQuestionSetGroup,
    createQuestionSetGroupQuestion,

    create,
    update,
    changeStatus,

    readAllQuestionSets,
    readAllQuestionGroups,
    readAllQuestions,
    readQuestionSet,
    readQuestionSetGroup,

    getAllowedMasterDataEQType,

    getAllFinalQuestions,
    addFinalQuestion,
    updateFinalQuestion,

    // groups
    getAllQueGroups,
    addQueGroup,
    updateQueGroup,
    getAllActiveQueGroups

    // // QUESTION SET
    // createQuestionSet,
    // updateQuestionSet,
    // readAllQuestionSets,
    // changeStatusOfQuestionSet,
    //
    // // QUESTION SET'S GROUP
    // createQuestionGroup,
    // updateQuestionGroup,
    // readAllQuestionGroups,
    // changeStatusOfQuestionGroup,
    //
    // // QUESTION SET'S GROUP'S QUESTIONS
    // createQuestion,
    // updateQuestion,
    // readAllQuestions,
    // changeStatusOfQuestion
};

// constants
export const EVALUATION_QUESTION = {

    REQUEST: "EVALUATION_QUESTION_REQUEST",
    ERROR: "EVALUATION_QUESTION_ERROR",
    NOT_FOUND: "EVALUATION_QUESTION_NOT_FOUND",
    VALIDATION_ERROR: "EVALUATION_QUESTION_VALIDATION_ERROR ",
    NOT_ALLOWED: "EVALUATION_QUESTION_NOT_ALLOWED",

    ADD_SUCCESS_QUESTION_DATA: "EVALUATION_QUESTION_ADD_SUCCESS_QUESTION_DATA",

    ADD_SUCCESS_QUESTION: "EVALUATION_QUESTION_ADD_SUCCESS_QUESTION",
    ADD_SUCCESS: "EVALUATION_QUESTION_ADD_SUCCESS",
    UPDATE_SUCCESS: "EVALUATION_QUESTION_UPDATE_SUCCESS",
    CHANGE_STATUS_SUCCESS: "EVALUATION_QUESTION_CHANGE_STATUS_SUCCESS",
    READ_ALL_SUCCESS: "EVALUATION_QUESTION_READ_ALL_SUCCESS",
    READ_QUESTION_SET_SUCCESS: "EVALUATION_QUESTION_READ_QUESTION_SET_SUCCESS",
    READ_QUESTION_SET_GROUP_SUCCESS: "EVALUATION_QUESTION_READ_QUESTION_SET_GROUP_SUCCESS",

    SUCCESS_ALLOWED_TYPS: "EVALUATION_QUESTION_SUCCESS_ALLOWED_TYPS",

    READ_ALL_FINAL_QUESTIONS: "EVALUATION_QUESTION_READ_ALL_FINAL_QUESTIONS",
    ADD_SUCCESS_FINAL_QUESTION: "EVALUATION_QUESTION_ADD_SUCCESS_FINAL_QUESTION",
    UPDATE_SUCCESS_FINAL_QUESTION: 'EVALUATION_QUESTION_UPDATE_SUCCESS_FINAL_QUESTION',

    READ_ALL_QUESTION_GROUPS: "EVALUATION_QUESTION_READ_ALL_QUESTION_GROUPS"

    // // SET
    // ADD_SUCCESS_QUESTION_SET: "EVALUATION_QUESTION_ADD_SUCCESS_QUESTION_SET",
    // UPDATE_SUCCESS_QUESTION_SET: "EVALUATION_QUESTION_UPDATE_SUCCESS_QUESTION_SET",
    // READ_ALL_QUESTION_SET: "EVALUATION_QUESTION_READ_ALL_QUESTION_SET",
    // CHANGE_STATUS_SUCCESS_QUESTION_SET: "EVALUATION_QUESTION_CHANGE_STATUS_SUCCESS_QUESTION_SET",
    //
    // // GROUP
    // ADD_SUCCESS_QUESTION_GROUP: "EVALUATION_QUESTION_ADD_SUCCESS_QUESTION_GROUP",
    // UPDATE_SUCCESS_QUESTION_GROUP: "EVALUATION_QUESTION_UPDATE_SUCCESS_QUESTION_GROUP",
    // READ_ALL_SUCCESS_QUESTION_GROUP: "EVALUATION_QUESTION_READ_ALL_SUCCESS_QUESTION_GROUP",
    // CHANGE_STATUS_SUCCESS_QUESTION_GROUP: "EVALUATION_QUESTION_CHANGE_STATUS_SUCCESS_QUESTION_GROUP",
    //
    // // QUESTION
    // ADD_SUCCESS_QUESTION: "EVALUATION_QUESTION_ADD_SUCCESS_QUESTION",
    // UPDATE_SUCCESS_QUESTION: "EVALUATION_QUESTION_UPDATE_SUCCESS_QUESTION",
    // READ_ALL_SUCCESS_QUESTION: "EVALUATION_QUESTION_READ_ALL_SUCCESS_QUESTION",
    // CHANGE_STATUS_SUCCESS_QUESTION: "EVALUATION_QUESTION_CHANGE_STATUS_SUCCESS_QUESTION"

};

export default evaluationQuestionAction;
