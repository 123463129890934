// common
import exportService from "../services/export.service";

function request() {
    return {type: EXPORT.REQUEST};
}

function error(reason) {
    reason = reason.response;
    switch (reason.status) {
        case 422:
            return {type: EXPORT.VALIDATION_ERROR, payload: reason.data};
        case 404:
            return {type: EXPORT.NOT_FOUND};
        case 403:
            return {type: EXPORT.NOT_ALLOWED};
        default:
            return {type: EXPORT.ERROR, payload: reason.data};
    }
}

function create(data) {

    return dispatch => {
        dispatch(request());
        exportService.add(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        if (res.status === 201) {
            return {type: EXPORT.ADD_SUCCESS, payload: res.data};
        }
        return error(res);
    }

}

function readAll(data) {
    return dispatch => {
        dispatch(request());
        exportService.readAll(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: EXPORT.READ_ALL_SUCCESS, payload: res.data};
    }
}

const exportAction = {
    create,
    readAll,
};

// constants
export const EXPORT = {

    REQUEST: "EXPORT_REQUEST",
    ERROR: "EXPORT_ERROR",
    NOT_FOUND: "EXPORT_NOT_FOUND",
    VALIDATION_ERROR: "EXPORT_VALIDATION_ERROR ",
    NOT_ALLOWED: "EXPORT_NOT_ALLOWED",

    ADD_SUCCESS: "EXPORT_ADD_SUCCESS",
    UPDATE_SUCCESS: "EXPORT_UPDATE_SUCCESS",
    READ_ALL_SUCCESS: "EXPORT_READ_ALL_SUCCESS",
    READ_SUCCESS: "EXPORT_READ_SUCCESS",
    CHANGE_STATUS_SUCCESS: "EXPORT_CHANGE_STATUS_SUCCESS"
};

export default exportAction;