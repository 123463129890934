import React, {Component} from 'react';
import {Button, Icon, Segment} from "semantic-ui-react";
import {connect} from "react-redux";
import orgState from "../../../../../../helper/stateUtil";

class ReportHeader extends Component {
    render() {
        const {org} = this.props;
        return (
            <Segment basic style={{padding: "0.8em"}}>
                <Button icon circular basic floated={'right'} title={"Print"} onClick={() => {
                    if (org.is_free_plan) {
                        alert("This feature is not available for free trial plan, Please upgrade your plan.");
                    } else {
                        window.print();
                    }
                }}
                        id={'print-btn'}>
                    <Icon name={'print'} size={'large'}/>
                </Button>
                {this.props.children}
                <br/>
            </Segment>
        );
    }
}

export default connect(state => orgState(state))(ReportHeader);
