import React, {Component} from 'react';
import {connect} from "react-redux";
import {OrgMenuBar} from "../other/OrgMenuBar";
import OrgSubHeader from "../other/OrgSubHeader";
import {orgState} from "../../../../helper/stateUtil";
import matterAction from "../../../../actions/matter.action";
import {ListMyEvaluations} from "../../list/ListMyEvaluations";
import Body from "../../../other/Body";

class MyEvaluationsPage extends Component {

    componentDidMount() {
        this.props.dispatch(matterAction.getMyEvaluations({org_id: this.props.org.id}));
    }

    render() {
        const {loading} = this.props;
        return (
            <Body loading={loading}>

                <OrgMenuBar/>

                <OrgSubHeader title={"My Evaluations"} backArrow={false}/>

                <ListMyEvaluations/>

            </Body>
        );
    }
}

function mapStateToProps(state) {
    let s = orgState(state);
    return {...s, ...state.matter};
}

const cMyEvaluationsPage = connect(mapStateToProps)(MyEvaluationsPage);
export {cMyEvaluationsPage as MyEvaluationsPage};