import React, {Component} from 'react';
import BoxFooter from "./BoxFooter";

class PageFooter extends Component {
    render() {
        return (
            <BoxFooter/>
        );
    }
}

export default PageFooter;