import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Checkbox} from "semantic-ui-react";

class QMCheckBox extends Component {
    label = (checked, type) => {
        switch (type) {
            case 1: // on - off
                return checked ? "On" : "Off";
            case 2: // open - close
                return checked ? "Open" : "Close";
            case 3: // Yes - No
                return checked ? "Yes" : "No";
            default:
                return checked ? "Active" : "Inactive";
        }
    };

    render() {
        const {checked, onChange, disabled, type} = this.props;
        return (
            <Checkbox color={'teal'} toggle onChange={(e, {checked}) => onChange(checked)}
                      checked={checked} disabled={disabled}
                      label={this.label(checked, type)}/>
        );
    }
}

QMCheckBox.propTypes = {
    type: PropTypes.oneOf([1, 2, 3]),
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    disabled: PropTypes.bool
};

export default QMCheckBox;
