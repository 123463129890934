import React, {Component} from "react";
import PropTypes from "prop-types";
import {Grid, Header, List, Segment} from "semantic-ui-react";
import InfoProgress from "./InfoProgress";
import CircleProgressbar from "../../../../../other/CircleProgressbar";
import HelpPopup from "../../../../../other/HelpPopup";
import "./../../../../../../css/chart/report-header/summary.css";
import {format} from "d3";
import {log} from "../../../../../../helper/helper";

class ReportSummary extends Component {
    render() {
        const {qualmetScore, overallSatisfaction, valueForMoney, wouldRecommend, totals, diversityTracking, responses} = this.props;
        return (
            <Segment basic style={{padding: "0.8em"}}>
                <Grid stackable>
                    <Grid.Column width={3}>
                        <div className={"summary-row"}>
                            <CircleProgressbar
                                percentage={qualmetScore || 0}
                                title={"Qualmet Score"}
                                help={<HelpPopup message={"" +
                                "The Qualmet Score is the average score of three (3) to four (4) variables: \n(i) the Overall " +
                                "Satisfaction Rating; \n(ii) Value for Money Rating; \n(iii) Recommendation Score (i.e. Net Promoter Score); Total Qualmet Score is indexed on a scale of 0 to 100."}/>}/>
                        </div>
                    </Grid.Column>
                    <Grid.Column width={13}>
                        <div className={"summary-row"}>
                            <Grid stackable>
                                <Grid.Column width={10}>
                                    <Grid stackable centered>
                                        <Grid.Column width={4} className={"report-summary"}>
                                            <CircleProgressbar percentage={overallSatisfaction || 0}
                                                               title={"Overall Satisfaction"}
                                                               help={<HelpPopup
                                                                   message={"The Overall Satisfaction Rating is an average of all of the primary evaluation questions an " +
                                                                   "organization is using, based on a scale of “Significantly Deficient at Best” to “Best in Class.”"}/>}/>
                                        </Grid.Column>
                                        <Grid.Column width={4} className={"report-summary"}>
                                            <CircleProgressbar percentage={valueForMoney || 0}
                                                               title={"Value for Money"}
                                                               help={<HelpPopup
                                                                   message={"The Value for Money Rating is based on the evaluator’s assessment that they received the " +
                                                                   "appropriate amount of value for the amount they were billed, based on a scale of “Significantly Deficient at " +
                                                                   "Best” to “Best in Class.”"}/>}/>
                                        </Grid.Column>
                                        <Grid.Column width={4} className={"report-summary"}>
                                            <CircleProgressbar percentage={wouldRecommend || 0}
                                                               title={"Would Recommend"}
                                                               help={<HelpPopup message={"" +
                                                               "The Recommendation Score is also known as a “Net Promoter” score. The " +
                                                               "evaluator is scoring based on their likelihood to recommend the person or organization being evaluated, to " +
                                                               "another person. The scale for scores ranges from one (1) to ten (10)."}/>}/>
                                        </Grid.Column>
                                        {/*{diversityTracking &&*/}
                                        {/*<Grid.Column width={4} className={"report-summary"}>*/}
                                        {/*    <CircleProgressbar percentage={diversityTracking.percentage || 0}*/}
                                        {/*                       title={"Diversity"}*/}
                                        {/*                       help={*/}
                                        {/*                           <HelpPopup*/}
                                        {/*                               message={"The Diversity Score is a percentage based on the number of diverse people billed on a "*/}
                                        {/*                               + "matter, divided by the total number of people on that same matter. The Diversity Score may also be shown as "*/}
                                        {/*                               + "an aggregate percentage of all matters an organization has, or is handling."}/>*/}
                                        {/*                       }/>*/}
                                        {/*</Grid.Column>}*/}
                                    </Grid>
                                    {(totals && totals.length > 0) && <div className={"summary-row"} style={{marginTop: "1em", textAlign: "center"}}>
                                        <List horizontal className={"report-info-bar"}>
                                            <List.Item>
                                                <List.Header>Totals:</List.Header>
                                            </List.Item>
                                            {totals.map((item, i) =>
                                                <List.Item key={i}>
                                                    {item.text}: <b>{format(",")(item.value)}</b>
                                                </List.Item>)}
                                        </List>
                                    </div>}
                                </Grid.Column>
                                <Grid.Column width={6}>
                                    {diversityTracking && <div>
                                        <Header>Diversity &nbsp;
                                            <small><HelpPopup message="The Diversity Score is a percentage based on the number of diverse people billed on a
                                matter, divided by the total number of people on that same matter. The Diversity Score may also be shown as
                                an aggregate percentage of all matters an organization has, or is handling."/></small>
                                        </Header>
                                        <InfoProgress value={diversityTracking.team_total}
                                                      percentage={diversityTracking.percentage}/>
                                    </div>}
                                    {(responses && responses.length > 0) &&
                                    <div><Header style={{marginTop: "0.5em"}}>Responses
                                        <small>&nbsp;<HelpPopup
                                            message="The Response totals are based on the total number of responses given for a respective rating."/>
                                        </small>
                                    </Header>
                                        {responses.map((res, i) => <InfoProgress value={res.answer}
                                                                                 percentage={res.percentage}
                                                                                 key={i}/>)}
                                        <span className={"report-summary"}>Average rating response per evaluation</span>
                                    </div>
                                    }
                                </Grid.Column>
                            </Grid>

                        </div>
                    </Grid.Column>
                </Grid>
            </Segment>
        );
    }
}

ReportSummary.propTypes = {
    qualmetScore: PropTypes.number,
    overallSatisfaction: PropTypes.number,
    valueForMoney: PropTypes.number,
    wouldRecommend: PropTypes.number,
    totals: PropTypes.array,
    diversityTracking: PropTypes.object,
    responses: PropTypes.array
};

export default ReportSummary;
