import api from "../helper/api";

// matters
function getAllMattersReport(data) {
    const by_matrix = data.by_matrix;
    delete data.data;
    delete data.by_matrix;
    return api.instance().post(`org/reports/matters/${by_matrix ? "report-by-qs" : "report-by-iq"}`, data);
}

function getMatterReport(data) {
    delete data.data;
    delete data.by_matrix;
    return api.instance().post(`org/reports/matters/get-matter-report`, data);
}

function getAllMattersSummaryAndFilters(data) {
    return api.instance().post(`org/reports/matters/get-filters-summary`, data);
}

function getMatterSummaryAndFilters(data) {
    return api.instance().post(`org/reports/matters/get-matter-filters-summary`, data);
}

function getMatterDetailsFilter(data) {
    return api.instance().post(`org/reports/matters/get-matter-details-filter`, data);
}

function getMatterDetails(data) {
    return api.instance().post(`org/reports/matters/get-matter-details`, data);
}

function getMatterCommentsFilter(data) {
    return api.instance().post(`org/reports/matters/get-matter-comments-filter`, data);
}

function getMatterComments(data) {
    return api.instance().post(`org/reports/matters/get-matter-comments`, data);
}

// clients
function getAllClientsReport(data) {
    const by_matrix = data.by_matrix;
    delete data.data;
    delete data.by_matrix;
    return api.instance().post(`org/reports/clients/${by_matrix ? "report-by-qs" : "report-by-iq"}`, data);
}

function getAllClientsSummaryAndFilters(data) {
    return api.instance().post(`org/reports/clients/get-filters-summary`, data);
}

function getClientSummaryAndFilters(data) {
    return api.instance().post(`org/reports/clients/get-client-filters-summary`, data);
}

function getClientReport(data) {
    delete data.data;
    delete data.by_matrix;
    return api.instance().post(`org/reports/clients/get-client-report`, data);
}

function getClientDetailsFilter(data) {
    return api.instance().post(`org/reports/clients/get-client-matter-filter`, data);
}

function getClientDetails(data) {
    return api.instance().post(`org/reports/clients/get-client-matter-details`, data);
}

function getClientCommentsFilter(data) {
    return api.instance().post(`org/reports/clients/get-client-comments-filter`, data);
}

function getClientCommentsQuesFilter(data) {
    return api.instance().post(`org/reports/clients/get-client-filter-by-eval`, data);
}

function getClientEvalComments(data) {
    return api.instance().post(`org/reports/clients/get-client-comments-by-eval`, data);
}

function getClientLawyersFilter(data) {
    return api.instance().post(`org/reports/clients/get-client-lawyer-filter`, data);
}

function getClientLawyers(data) {
    return api.instance().post(`org/reports/clients/get-client-lawyer-details`, data);
}

// lawyers/EVALUATEES
function getAllLawyersReport(data) {
    // const by_matrix = data.by_matrix;
    delete data.data;
    delete data.by_matrix;
    return api.instance().post(`org/reports/lawyers/report-by-qs`, data);
}

function getAllLawyersSummaryAndFilters(data) {
    return api.instance().post(`org/reports/lawyers/get-filters-summary`, data);
}

function getLawyerSummaryAndFilters(data) {
    return api.instance().post(`org/reports/lawyers/get-lawyer-filters-summary`, data);
}

function getLawyerReport(data) {
    delete data.data;
    delete data.by_matrix;
    return api.instance().post(`org/reports/lawyers/get-lawyer-report`, data);
}

function getLawyerDetailsFilter(data) {
    return api.instance().post(`org/reports/lawyers/get-lawyer-details-filter`, data);
}

function getLawyerDetails(data) {
    return api.instance().post(`org/reports/lawyers/get-lawyer-details`, data);
}

function getLawyerCommentsFilter(data) {
    return api.instance().post(`org/reports/lawyers/get-lawyer-comments-filter`, data);
}

function getLawyerCommentsQuesFilter(data) {
    return api.instance().post(`org/reports/lawyers/get-lawyer-filter-by-eval`, data);
}

function getLawyerEvalComments(data) {
    return api.instance().post(`org/reports/lawyers/get-lawyer-comments-by-eval`, data);
}

// practice areas
function getAllPracticeAreaReport(data) {
    delete data.data;
    delete data.by_matrix;
    return api.instance().post(`org/reports/practice-areas/get-report`, data);
}

function getAllPracticeAreaSummaryAndFilters(data) {
    return api.instance().post(`org/reports/practice-areas/get-filters-summary`, data);
}

// regions
function getAllRegionsReport(data) {
    // const by_matrix = data.by_matrix;
    delete data.data;
    delete data.by_matrix;
    return api.instance().post(`org/reports/lawyers/report-by-qs`, data);
}

function getAllRegionsSummaryAndFilters(data) {
    return api.instance().post(`org/reports/lawyers/get-filters-summary`, data);
}

function getOfficeSummaryAndFilters(data) {
    return api.instance().post(`org/reports/lawyers/get-lawyer-filters-summary`, data);
}

function getOfficeReport(data) {
    delete data.data;
    delete data.by_matrix;
    return api.instance().post(`org/reports/lawyers/get-lawyer-report`, data);
}

function getOfficeDetailsFilter(data) {
    return api.instance().post(`org/reports/lawyers/get-lawyer-details-filter`, data);
}

function getOfficeDetails(data) {
    return api.instance().post(`org/reports/lawyers/get-lawyer-details`, data);
}


// diversity
function getDiversityReport(data) {
    // const by_matrix = data.by_matrix;
    delete data.data;
    delete data.by_matrix;
    return api.instance().post(`org/reports/lawyers/report-by-qs`, data);
}

function getDiversitySummaryAndFilters(data) {
    return api.instance().post(`org/reports/lawyers/get-filters-summary`, data);
}

const reportService = {
    // matters
    getAllMattersReport,
    getAllMattersSummaryAndFilters,
    getMatterReport,
    getMatterSummaryAndFilters,
    getMatterDetailsFilter,
    getMatterDetails,
    getMatterCommentsFilter,
    getMatterComments,

    // clients
    getAllClientsReport,
    getAllClientsSummaryAndFilters,
    getClientSummaryAndFilters,
    getClientReport,
    getClientDetailsFilter,
    getClientDetails,
    getClientLawyers,
    getClientLawyersFilter,
    getClientEvalComments,
    getClientCommentsFilter,
    getClientCommentsQuesFilter,

    // lawyers/EVALUATEES
    getAllLawyersReport,
    getAllLawyersSummaryAndFilters,
    getLawyerSummaryAndFilters,
    getLawyerReport,
    getLawyerDetailsFilter,
    getLawyerDetails,
    getLawyerEvalComments,
    getLawyerCommentsFilter,
    getLawyerCommentsQuesFilter,

    // practice areas
    getAllPracticeAreaReport,
    getAllPracticeAreaSummaryAndFilters,

    // regions
    getAllRegionsReport,
    getAllRegionsSummaryAndFilters,
    getOfficeSummaryAndFilters,
    getOfficeReport,
    getOfficeDetailsFilter,
    getOfficeDetails,

    // diversity
    getDiversityReport,
    getDiversitySummaryAndFilters,
};

export default reportService;