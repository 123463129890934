import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Grid, Table} from "semantic-ui-react";
import {orgState} from "../../../../helper/stateUtil";
import {connect} from "react-redux";
import matterAction from "../../../../actions/matter.action";
import {withRouter} from "react-router-dom";

class LawyerEvalViewSummary extends Component {

    componentWillMount() {
        this.loadData();
    }

    componentWillReceiveProps(nextProps) {
        const {isDone} = nextProps;
        if (isDone) {
            this.loadData();
        }
    }

    loadData = () => {
        const {match: {params: {eid}}} = this.props;
        this.props.dispatch(matterAction.readSummaryOfLawyerEval({
            id: eid
        }));
    };

    render() {
        let {summary: matter} = this.props;
        return (
            <div>
                <Grid columns={"equal"}>
                    <Grid.Row>
                        <Grid.Column>
                            <Table>
                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell>Evaluation Status:</Table.Cell>
                                        <Table.Cell>{matter.status}</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>Evaluation Name:</Table.Cell>
                                        <Table.Cell>{matter.name}</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>Practice Area:</Table.Cell>
                                        <Table.Cell>{matter.practice_area}</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>Office:</Table.Cell>
                                        <Table.Cell>{matter.office}</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>Question Set:</Table.Cell>
                                        <Table.Cell>{matter.question_set_title}</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>Evaluator(s):</Table.Cell>
                                        <Table.Cell>{matter.evaluators}</Table.Cell>
                                    </Table.Row>
                                    {matter.responsible_party && <Table.Row>
                                        <Table.Cell>Responsible Evaluator:</Table.Cell>
                                        <Table.Cell>{matter.responsible_party}</Table.Cell>
                                    </Table.Row>}
                                    {matter.evaluatees && <Table.Row>
                                        <Table.Cell>Evaluatees(s):</Table.Cell>
                                        <Table.Cell>{matter.evaluatees}</Table.Cell>
                                    </Table.Row>}
                                </Table.Body>
                            </Table>
                        </Grid.Column>
                        <Grid.Column>
                            <Table>
                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell>
                                            Evaluation Start Date:
                                        </Table.Cell>
                                        <Table.Cell>{matter.start_date}</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>
                                            Evaluation End Date:
                                        </Table.Cell>
                                        <Table.Cell>{matter.end_date}</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>
                                            Evaluation Style:
                                        </Table.Cell>
                                        <Table.Cell>{matter.evaluation_style}</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>
                                            Evaluation Type:
                                        </Table.Cell>
                                        <Table.Cell>{matter.evaluation_type}</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>
                                            Evaluation Interval:
                                        </Table.Cell>
                                        <Table.Cell>{matter.interval_id}</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>
                                            Email Reminder Interval:
                                        </Table.Cell>
                                        <Table.Cell>{matter.email_reminder}</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>Email Reminder Status:</Table.Cell>
                                        <Table.Cell>{matter.email_reminder_status}</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>Evaluation Cycle Status:</Table.Cell>
                                        <Table.Cell>{matter.ec_status}</Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        );
    }
}

LawyerEvalViewSummary.propTypes = {
    onEdit: PropTypes.func
};

function mapStateToProps(state) {
    const s = orgState(state);
    return {
        ...s,
        ...state.matter
    };
}

const cClientEvalViewSummary = withRouter(connect(mapStateToProps)(LawyerEvalViewSummary));
export {cClientEvalViewSummary as LawyerEvalViewSummary};
