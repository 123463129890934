import {CLIENT} from "../actions/client.action";

const initState = {
    isAllowed: true,
    loading: false,
    isDone: false,
    clients: [],
    error: [],
    client: null
};

export default function clientReducer(state = initState, action) {
    // reset
    state.error = [];
    state.isDone = false;
    state.loading = false;
    state.isAllowed = true;
    // state.client = null;

    // filter
    switch (action.type) {

        // common
        case CLIENT.REQUEST:
            return {...state, loading: true};

        case CLIENT.VALIDATION_ERROR:
            return {...state, error: action.payload};

        case CLIENT.ERROR:
            return {...state, error: [...action.payload]};

        // not allowed to access
        case CLIENT.NOT_ALLOWED:
            return {...state, isAllowed: false};

        // add client
        case CLIENT.ADD_SUCCESS:
            return {...state, isDone: true, clients: [...state.clients, action.payload]};

        // update client
        case CLIENT.UPDATE_SUCCESS:
            return {...state, isDone: true};

        // read all clients
        case CLIENT.READ_ALL_SUCCESS:
            return {...state, clients: action.payload};

        // read single client
        case CLIENT.READ_SUCCESS:
            return {...state, client: action.payload};

        case CLIENT.NOT_FOUND:
            return {...state, not_found: true};

        case CLIENT.CHANGE_STATUS_SUCCESS:
            state.clients[state.clients.findIndex((client) => client.id === action.payload.id)].status = action.payload.status;
            return {...state};

        default:
            return state;
    }
}