import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Form, Header, Input} from "semantic-ui-react";
import ErrorMessage from "../../../other/ErrorMessage";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import qs from "query-string";

class AccountVerificationForm extends Component {
    constructor(props) {
        super(props);
        this.params = qs.parse(this.props.location.search);
        this.state = {
            sec: 0,
            code: ''
        };
        this.timeout = 180;
        this.getRemainTime = this.getRemainTime.bind(this);
    }

    componentDidMount() {
        this.startTimer();
        this.pageUnloadAlert(true);
    }

    componentWillReceiveProps(nextProps) {
        const {isDone, history, isCodeSend, token} = nextProps;
        if (isDone) {
            clearInterval(this.timer);
            this.pageUnloadAlert(false);
            if (token)
                history.replace(`/org-setup/done?token=${encodeURIComponent(token)}`);
            else
                history.replace("/login?verified=true");
        }
        if (isCodeSend) {
            this.startTimer();
        }
    }

    startTimer = () => {
        this.timer = setInterval(() => {
            if (this.state.sec >= this.timeout) {
                clearInterval(this.timer);
                this.setState({sec: 0});
                return;
            }
            this.setState((state) => {
                return {sec: state.sec + 1}
            });
        }, 1000);
    };

    pageUnloadAlert = flag => {
        window.onbeforeunload = function () {
            return flag ? "Are you sure you want to leave?" : null;
        };
    };

    getRemainTime = sec => {
        sec = this.timeout - sec;
        const min = Math.floor(sec / 60);
        sec = sec - min * 60;
        return min + ":" + (sec <= 9 ? "0" + sec : sec);
    };

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    render() {
        const {email, loading, onSubmit, onResendCode, error, isCodeSend} = this.props;
        const {sec, code} = this.state;
        return (
            <React.Fragment>
                <Header as={'h5'}>
                    A verification code has been sent to email address <i><u>{email}</u></i>
                    <br/>
                    Please check the mail and enter your verification code below.
                </Header>
                <br/>
                <ErrorMessage errors={error}/>
                <Form>
                    <Form.Group>
                        <Form.Field width={7}>
                            <Input placeholder={"XXXXXX"} onChange={(e) => {
                                this.setState({code: e.target.value});
                            }}>
                                <input
                                    style={{
                                        letterSpacing: "14px",
                                        fontWeight: "600",
                                        fontSize: "14px"
                                    }}
                                    maxLength={6}/>
                            </Input>
                            {isCodeSend &&
                            <label style={{color: 'maroon'}}> Email verification code sent successfully!</label>}
                        </Form.Field>
                        {sec > 0 && <Form.Field width={11} style={{margin: "0.5em"}}>
                            <span>Time Remaining {this.getRemainTime(sec)}</span>
                        </Form.Field>}
                    </Form.Group>
                </Form>
                <Button circular color={"teal"} floated='right'
                        disabled={loading || !code} onClick={() => onSubmit(code)}
                        loading={loading}>
                    <span style={{margin: "auto 0.5em"}}>Verify</span>
                </Button>
                <Button circular color={'teal'} floated='right' disabled={sec > 0} onClick={() => onResendCode()}>
                    <span style={{margin: "auto 0.5em"}}>Resend Code</span>
                </Button>
                <br/>
                <br/>
            </React.Fragment>
        );
    }
}

AccountVerificationForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onResendCode: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return state.auth;
}

const cAccountVerificationForm = withRouter(connect(mapStateToProps)(AccountVerificationForm));
export {cAccountVerificationForm as AccountVerificationForm};
